import React, { createRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Paragraph, SignatureLine } from 'modules/BidInfoForm/components/atoms';
import SignatureCanvas from 'react-signature-canvas';
import { useMediaQuery } from 'react-responsive';
import eraserIcon from 'modules/BidInfoForm/assets/eraser.svg';

import {
  Heading,
  Wrapper,
  Content,
  SignatureField,
  IconHolder,
  Field,
} from './styles';
import { ActionAlertModal, ContentInput } from 'core/components';
import { SectionTitle } from 'modules/BidInfoForm/styles';

function Signature(props, ref) {
  const [signed, setSigned] = React.useState(false);
  const [signBase64, setSignBase64] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  let sigCanvas;

  React.useImperativeHandle(ref, () => ({
    getData: () => ({
      signed,
      signBase64,
    }),
  }));

  const minorThan400 = useMediaQuery({
    query: '(max-device-width: 400px)',
  });
  const biggerThan768 = useMediaQuery({
    query: '(min-device-width: 768px)',
  });

  React.useEffect(() => {
    if (props.value && !signBase64) {
      // fetch(props.value, { mode: 'no-cors' })
      //   .then((res) => res.blob())
      //   .then((blob) => {
      //     var reader = new FileReader();
      //     reader.readAsDataURL(blob);
      //     reader.onloadend = function () {
      //       var base64data = reader.result;
      //       setSignBase64(base64data);
      //     };
      //   });

      setSignBase64(props.value);

      // var xhr = new XMLHttpRequest();
      // xhr.onload = function () {
      //   var reader = new FileReader();
      //   reader.onloadend = function () {
      //     var base64data = reader.result;
      //     setSignBase64(base64data);
      //   };
      //   reader.readAsDataURL(xhr.response);
      // };
      // xhr.open('GET', props.value);
      // xhr.responseType = 'blob';
      // xhr.send();
    }
  }, [props.value]);

  return (
    <Wrapper style={props.style}>
      {showModal && (
        <ActionAlertModal
          style={{ minWidth: minorThan400 ? '70%' : '300px' }}
          renderMessage={() => {
            if (signBase64) {
              setTimeout(() => {
                if (sigCanvas) {
                  sigCanvas.fromDataURL(signBase64, {
                    ratio: 1,
                  });
                }
              }, 0);
            }
            // var ratio = options.ratio || window.devicePixelRatio || 1;
            //         var width = options.width || this._canvas.width / ratio;
            //         var height = options.height || this._canvas.height / ratio
            return (
              <div
                style={{
                  border: 'solid 2px #000',
                  margin: '20px',
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <button
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    padding: '5px',
                    marginLeft: '10px',
                    marginTop: '10px',
                  }}
                  onClick={() => sigCanvas.clear()}
                >
                  {' '}
                  CLEAR{' '}
                </button>
                <SignatureCanvas
                  penColor="black"
                  ref={(ref) => (sigCanvas = ref)}
                  canvasProps={{
                    width: minorThan400 ? 300 : 500,
                    height: 200,
                    className: 'sigCanvas',
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    left: '40px',
                    top: '110px',
                  }}
                >
                  <IconHolder onClick={() => {}}>
                    <FontAwesomeIcon icon={faTimes} size="lg" />
                  </IconHolder>
                </div>
                <hr
                  style={{
                    width: '70%',
                    position: 'absolute',
                    bottom: '50px',
                    height: '1px',
                    backgroundColor: 'gray',
                    border: '0',
                  }}
                />
              </div>
            );
          }}
          onDismiss={() => {
            sigCanvas.clear();
            setShowModal(false);
          }}
          onConfirm={() => {
            setSignBase64(sigCanvas.toDataURL());
            props.setSignatureData(sigCanvas.toDataURL());
            setShowModal(false);
          }}
          isVisible={true}
        />
      )}
      <Content>
        <SignatureField>
          <IconHolder onClick={() => {}}>
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </IconHolder>

          {!signBase64 && (
            <Field onClick={() => setShowModal(true)}>(Click to Sign)</Field>
          )}

          {signBase64 && (
            <ContentInput
              width="100%"
              paddingTop="0"
              style={{ height: '40px', position: 'relative' }}
              onClick={() => {
                setShowModal(true);
              }}
            >
              <SignatureLine
                marginTop="0px"
                style={{
                  position: 'absolute',
                  bottom: 0,
                  visibility: 'visible',
                }}
              />
              <ContentInput
                width="100%"
                padding="0"
                paddingTop="0"
                justify="center"
                margin="-50px 0 0 0"
                height="40px"
              >
                <img
                  src={signBase64}
                  height="80px"
                  style={{
                    position: 'absolute',
                    marginTop: '25px',
                    marginLeft: '0px',
                  }}
                />
              </ContentInput>
            </ContentInput>
          )}
        </SignatureField>
      </Content>
    </Wrapper>
  );
}

export default React.forwardRef(Signature);
