import styled from 'styled-components';
import {
  H1BoldItalic,
  H3SemiBold,
  DisclaimerParagraph,
} from '../../../../styles';

import ecoInvoiceLeaf from '../../../../assets/eco-invoice-leaf.png';

export const InvoiceTitle = styled(H1BoldItalic)`
  color: #219729 !important;
  font-size: 5vw;
  font-style: normal;
  line-height: 7.5vw;
  margin-bottom: 30px;

  span {
    &:nth-child(1) {
      text-decoration: underline;
    }
  }

  @media (min-width: 400px) {
    font-size: 30px;
    line-height: 40px;
  }

  @media (min-width: 768px) {
    font-size: 26px;
    line-height: 35px;
  }
`;

export const InvoiceImageHolder = styled.div`
  img {
    display: block;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 30px;
  }
`;

export const InvoiceButtons = styled.div`
  strong {
    font-size: 16px;
    font-style: italic;
  }

  .radio-button-content {
    display: flex;
    flex-direction: column;
  }

  .radio-button-item {
    margin-bottom: 20px;
  }

  @media (min-width: 769px) {
    .radio-button-content {
      flex-direction: inherit;
    }
    .radio-button-container {
      justify-content: center;
    }

    .radio-button-item:not(:last-of-type) {
      padding-left: 90px;
    }
  }
  position: relative;
`;

export const EcoInvoiceOption = styled.div`
  ::after {
    content: '';
    background-image: url(${ecoInvoiceLeaf});
    background-size: cover;
    width: 24px;
    height: 24px;
    position: absolute;
    margin-left: 5px;
  }
  color: #00952b;
`;

export const Field = styled.div<{ color?: string }>`
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  min-height: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => (color ? color : '#000')};
  width: 100%;
  font-size: 14px;

  strong {
    font-family: OpenSans-Bold;
  }

  @media (min-width: 769px) {
    min-height: 69px;
  }
`;

export const BlockItem = styled.div`
  width: 100%;
`;

export const Block = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${BlockItem} {
    ${DisclaimerParagraph}:empty {
      display: none;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;

    ${H3SemiBold} {
      height: 48px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    ${BlockItem} {
      display: flex;
      flex-direction: column;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      ${DisclaimerParagraph}:empty {
        display: inherit;
      }
    }

    /* ${Field} {
      flex: 1;
    } */
  }
`;
