import styled from 'styled-components';

interface IBox {
  width?: string;
  color?: string;
  maxWidth?: string;
  minWidth?: string;
}

export const Box = styled.div`
  height: 45px;
  border: none;
  background-color: transparent;
  ${({ width }: IBox) => (width ? `width: ${width};` : 'flex: 1;')}
  ${({ maxWidth }: IBox) => (maxWidth ? `max-width: ${maxWidth};` : '')}
  ${({ minWidth }: IBox) => (minWidth ? `max-width: ${minWidth};` : '')}
`;

interface IContent {
  align?: string;
  color?: string;
}

export const Content = styled.div`
  padding: 10px;
  border: 1px solid ${({ color }: IBox) => color || '#999'};
  ${({ align }: IContent) =>
    align
      ? `display: flex; justify-content: ${align}; align-items: ${align};`
      : ''}
  ${({ color }: IContent) => (color ? `color: ${color};` : '')}
`;
