import React from 'react';
import PropTypes from 'prop-types';
import Button from '../components/button.js';
import SoundIcon from '../components/soundIcon';
import Display from '../components/display';
import Styles from '../../scss/calculator.module.scss';
import StylesButton from '../../scss/button.module.scss';

class Calculator extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const keys = this.props.keys.filter(x => x.key !== 'Backspace');
    const backspaceKey = this.props.keys.find(x => x.key === 'Backspace');
    const css = this.props.getButtonClass(backspaceKey, StylesButton);

    return (
      <div className={Styles.calculator}>
        {/* <SoundIcon
          onClick={this.props.muteIconClick}
          muted={this.props.muted}
        /> */}
        <div className={Styles.calculator__header}>
          <div
            ref={this.props.displayRef}
            className={Styles.calculator__display}
          >
            <Display
              className={Styles.calculator__history}
              value={this.props.historyDisplay}
            />
            <Display
              className={Styles.calculator__result}
              value={this.props.displayValue}
            />
          </div>
          <div className={Styles.calculator__backspace}>
            <Button
              ref={backspaceKey.key}
              label={backspaceKey.label}
              id={backspaceKey.key}
              onMouseDown={this.props.onMouseDown}
              onClick={this.props.buttonClick}
              className={this.props.isActiveCSS(
                css,
                backspaceKey.key,
                this.props.keyDown,
                StylesButton
              )}
            />
          </div>
        </div>
        <div className={Styles.calculator__body}>
          {keys.map((elmt, index) => {
            var css = this.props.getButtonClass(elmt, StylesButton);
            return (
              <Button
                key={index}
                ref={elmt.key}
                label={elmt.label}
                id={elmt.key}
                onMouseDown={this.props.onMouseDown}
                onClick={this.props.buttonClick}
                className={this.props.isActiveCSS(
                  css,
                  elmt.key,
                  this.props.keyDown,
                  StylesButton
                )}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

Calculator.propTypes = {
  muteIconClick: PropTypes.func,
  buttonClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  displayValue: PropTypes.string,
  historyDisplay: PropTypes.string,
  keyDown: PropTypes.string,
  muted: PropTypes.bool,
  getButtonClass: PropTypes.func,
  isActiveCSS: PropTypes.func,
  keys: PropTypes.array,
  displayRef: PropTypes.object,
};

export default Calculator;
