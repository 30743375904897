export default function () {
  const list = document.getElementsByTagName('input');
  for (let b of list) {
    if (!!b) {
      // const cap = (b) => {
      //   v.setAttribute('autoCapitalize', 'off');
      //   const v = e ? e.target : b;
      //   if (v) {
      //     const autoCapValue = v.value
      //       ? v.value.length == 0 || v.value[v.value.length - 1] == ' '
      //         ? 'off'
      //         : 'off'
      //       : 'off';
      //     v.setAttribute('autoCapitalize', 'off');
      //   }
      //   if (v) {
      //     v['onchange'] = (e) => cap(e);
      //   }
      // };

      // cap(null, b);
      b.setAttribute('autoCapitalize', 'off');
    }
  }
}
