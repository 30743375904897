import React, { createContext, useContext, useState } from 'react';
import { IAPIResponse } from '../../../modules/BidInfoForm/interfaces';

interface IFormProvider<T> {
  children?: any;
  formValues: T;
  setFormValues?: Function;
  onChange: Function;
  needsSaving?: boolean;
  setNeedsSaving?: Function;
  dirtySections?: Array<string>;
  isSectionDirty?: Function;
  setSectionDirty?: Function;
  cleanSectionDirty?: Function;
  showWarningModal?: Function;
  showDeleteConfirmationModal?: Function;
  showConfirmationModal?: Function;
  postBidInfo?: (x: any) => Promise<IAPIResponse>;
}

const FormCtx = createContext<IFormProvider<any>>({
  formValues: {},
  onChange: () => '',
});

export const useFormProps = () => useContext(FormCtx);

export const withFormProps = (Component: any) => (props: any) => {
  const formProps = useContext(FormCtx);
  return <Component {...props} formProps={formProps} />;
};

export const FormProvider = ({
  children,
  formValues,
  setFormValues,
  onChange,
  showWarningModal,
  showDeleteConfirmationModal,
  showConfirmationModal,
  postBidInfo,
  needsSaving,
  setNeedsSaving,
}: IFormProvider<any>) => {
  const [dirtySections, setDirtySections] = useState([]);

  const isSectionDirty = (sectionName: String) =>
    dirtySections.includes(sectionName);

  const setSectionDirty = (sectionName: String, callback: Function) => {
    setDirtySections((prevState) => [
      ...prevState.filter((item) => item !== sectionName),
      sectionName,
    ]);
    if (callback) callback();
  };

  const cleanSectionDirty = (
    sectionNames: Array<string>,
    callback: Function
  ) => {
    setDirtySections((prevState) => [
      ...prevState.filter((section) => !sectionNames.includes(section)),
    ]);

    if (callback) callback();
  };

  const onChanging = (name: string, value: any) => {
    onChange(name, value);

    if (
      !needsSaving &&
      (formValues.pid ||
        formValues.sid ||
        formValues.control ||
        formValues.bidDate)
    ) {
      setNeedsSaving(true);
    }
  };

  return (
    <FormCtx.Provider
      value={{
        formValues,
        setFormValues,
        onChange: onChanging,
        needsSaving,
        setNeedsSaving,
        isSectionDirty,
        dirtySections,
        setSectionDirty,
        cleanSectionDirty,
        showWarningModal,
        showDeleteConfirmationModal,
        showConfirmationModal,
        postBidInfo,
      }}
    >
      {children}
    </FormCtx.Provider>
  );
};
