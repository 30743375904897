import React, { memo, useEffect, useState } from 'react';
import { withFormik, FormikProps } from 'formik';

import {
  ContentInput,
  Table,
  useFormProps,
  BoxValue,
  InputFloatLabel,
} from 'core/components';
import {
  IBidRestroomFixture,
  IBidRestroomFixtureItem,
} from 'modules/BidInfoForm/interfaces';
import {
  restroomFixturesValues,
  prodGroups,
} from 'modules/shared/bidDefaultValues';
import {
  calculateRestroomFixture,
  calculateManHoursRestroom,
} from 'modules/BidInfoForm/utils';
import { timeMask, timeMaskParams } from 'core/utils';

import './styles.scss';

type IProps = FormikProps<IBidRestroomFixture>;
const RestroomFixtures = memo(({ values, setFieldValue }: IProps) => {
  const { formValues, onChange } = useFormProps();
  const [updateContext, setUpdateContext] = useState(false);
  const { restroomFixtures } = formValues;

  // useEffect(()=>{
  //   if(JSON.stringify(restroomFixtures) !== JSON.stringify(values.restroomFixtures)){
  //     setFieldValue("restroomFixtures", restroomFixtures);
  //   }
  // }, [restroomFixtures])

  useEffect(() => {
    if (
      JSON.stringify(restroomFixtures) !==
      JSON.stringify(values.restroomFixtures)
    ) {
      onChange('restroomFixtures', values.restroomFixtures);
    }
  }, [values.restroomFixtures]);

  const onChangeText = (index: number) => (name: string, value: any) => {
    const result = [
      ...values.restroomFixtures.filter(
        (item: any, indexItem: number) => indexItem !== index
      ),
    ];
    result.splice(index, 0, {
      ...values.restroomFixtures[index],
      [name]: value,
    });
    setFieldValue('restroomFixtures', result);
  };

  const onChangeMinutes = (index: number) => (name: string, value: any) => {
    const result = [
      ...values.restroomFixtures.filter(
        (item: any, indexItem: number) => indexItem !== index
      ),
    ];
    const newValue: any = calculateManHoursRestroom({
      ...values.restroomFixtures[index],
      [name]: value,
    });
    result.splice(index, 0, newValue);
    setFieldValue('restroomFixtures', result);
  };

  const columns = [
    {
      key: 'group',
      label: 'Group',
      width: '10%',
      render: ({ group }: IBidRestroomFixtureItem) => (
        <BoxValue value={group} align="center" />
      ),
    },
    {
      key: 'fixtureName',
      label: 'Name (Optional)',
      width: '25%',
      render: ({ fixtureName }: IBidRestroomFixtureItem, index: number) => (
        <InputFloatLabel
          className="input-fixture-name"
          value={fixtureName}
          label=""
          name="fixtureName"
          onChange={onChangeText(index)}
          onBlur={() => onChange('restroomFixtures', values.restroomFixtures)}
          center
          disabled={formValues.status === 2}
        />
      ),
    },
    {
      key: 'restroomFixtures',
      label: 'Restroom Fixtures',
      width: '20%',
      render: ({ restroomFixtures }: IBidRestroomFixtureItem) => (
        <BoxValue value={restroomFixtures || 0} align="center" />
      ),
    },
    {
      key: 'minutesFixture',
      label: 'Minutes Per Fixture',
      width: '15%',
      render: ({ minutesFixture }: IBidRestroomFixtureItem, index: number) => (
        <InputFloatLabel
          value={minutesFixture}
          label=""
          id={`minutesFixture_${index}`}
          name="minutesFixture"
          onChange={onChangeMinutes(index)}
          onBlur={() => onChange('restroomFixtures', values.restroomFixtures)}
          mask={timeMask}
          maskParams={timeMaskParams}
          center
          onFocusSelect
          disabled={formValues.status === 2}
        />
      ),
    },
    {
      key: 'totalRRManHr',
      label: 'RR Man Hrs',
      width: '15%',
      render: ({ totalRRManHr }: IBidRestroomFixtureItem) => (
        <BoxValue value={totalRRManHr} align="center" />
      ),
    },
  ];

  const { inventory } = formValues;

  useEffect(() => {
    const result = calculateRestroomFixture(restroomFixtures, inventory);
    setFieldValue('restroomFixtures', result);
    onChange('restroomFixtures', result);
    setUpdateContext(true);
    // eslint-disable-next-line
  }, [inventory, restroomFixtures]);

  // useEffect(() => {
  //   onChange('restroomFixtures', values.restroomFixtures);
  //   // eslint-disable-next-line
  // }, [values.restroomFixtures]);

  useEffect(() => {
    if (
      !!updateContext &&
      JSON.stringify(values.restroomFixtures) !==
        JSON.stringify(formValues.restroomFixtures)
    ) {
      onChange('restroomFixtures', values.restroomFixtures);
      setUpdateContext(false);
    }
    // eslint-disable-next-line
  }, [updateContext, values.restroomFixtures]);

  return (
    <div className="restroom-fixtures-root">
      <div className="restroom-fixtures-content">
        <ContentInput width="100%" padding="10px 0px">
          <h3 className="restroom-fixtures-title">Restroom Fixtures</h3>
        </ContentInput>

        <Table
          columns={columns}
          data={values.restroomFixtures.sort(
            (a, b) => prodGroups.indexOf(a.group) - prodGroups.indexOf(b.group)
          )}
        />
      </div>
    </div>
  );
});

const mapForm = {
  mapPropsToValues: () => ({
    ...restroomFixturesValues,
  }),
  handleSubmit: () => '',
};

const EnhancedRestroom = withFormik<any, IBidRestroomFixture>(mapForm)(
  RestroomFixtures
);

export { EnhancedRestroom as RestroomFixtures };
