import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import AppContainer from './container/appContainer.js';
import RootReducer from './reducers/root';
import defaultStore from './model/initialState';
import './../scss/app.scss';

let store = createStore(
  RootReducer,
  defaultStore,
  window.devToolsExtension && window.devToolsExtension()
);

export default function Plugin() {
  return (
    <Provider store={store}>
      <AppContainer />
    </Provider>
  );
}
