import React, { useEffect } from 'react';

import './styles.scss';

interface IInputNumber {
  value: number;
  name: string;
  onChange: Function;
  allowNegative?: boolean;
  disabled?: boolean;
  onBlur?: any;
}

const onMinus = (value: number, allowNegative: boolean | undefined) =>
  allowNegative
    ? value === 0
      ? value - 1
      : value
    : value === 0
    ? value
    : value - 1;

export const InputNumber = ({
  value,
  name,
  onChange,
  allowNegative,
  disabled,
  onBlur,
}: IInputNumber) => {
  useEffect(() => {
    if (disabled && value) {
      onChange(name, 0);
    }
    // eslint-disable-next-line
  }, [disabled]);

  return (
    <div className="input-number-root">
      <button
        className={`minus-button ${disabled ? 'input-disabled' : ''}`}
        disabled={disabled}
        onClick={() =>
          onChange(name, parseFloat(`${onMinus(value || 0, allowNegative)}`))
        }
      >
        -
      </button>
      <input
        className={`input-number ${disabled ? 'input-disabled' : ''}`}
        type="number"
        value={value !== null && value !== undefined ? value : ''}
        onChange={(e: any) =>
          onChange(
            name,
            parseFloat(`${e.target.value}`) > 999
              ? value
              : parseFloat(`${e.target.value || 0}`.replace(/[^0-9]/, ''))
          )
        }
        onFocus={(e: any) => e.target.select()}
        onBlur={onBlur}
        disabled={disabled}
      />
      <button
        className={`plus-button ${disabled ? 'input-disabled' : ''}`}
        disabled={disabled}
        onClick={() =>
          onChange(
            name,
            parseFloat(`${value === 999 ? value : (value || 0) + 1}`)
          )
        }
      >
        +
      </button>
    </div>
  );
};
