import React, { Component } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import * as Sentry from '@sentry/react';
import {
  getPdfBase64,
  getPdfBase64FromUrl,
  getNewPdfBase64,
} from '../../../services/bidInfoService';
import './styles.scss';
import { LoadingPage } from '../../../components/organisms';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function iosCopyToClipboard(el: any) {
  const oldContentEditable = el.contentEditable,
    oldReadOnly = el.readOnly,
    range = document.createRange();

  el.contentEditable = true;
  el.readOnly = false;
  range.selectNodeContents(el);

  const s = window.getSelection();
  s!.removeAllRanges();
  s!.addRange(range);

  el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

  el.contentEditable = oldContentEditable;
  el.readOnly = oldReadOnly;

  document.execCommand('copy');
}

export default class PdfModal extends Component {
  state = {
    numPages: 0,
    pageNumber: 1,
    pdf: '',
    displayButtons: false,
    previousDisabled: false,
    nextDisabled: false,
    isLoading: true,
  };

  public onClickCopy = () => {
    // const { showPdfModal } = this.props;
    // if (!navigator.clipboard) {
    //   iosCopyToClipboard(document.getElementById('pdfToCopy'));
    //   toast.info('PDF Link Copied to Clipboard');
    //   return;
    // }
    // navigator.clipboard.writeText(showPdfModal.pdfUrl);
    // toast.info('PDF Link Copied to Clipboard');
  };

  async componentDidMount() {
    this.props.setIsLoading(true);
    const { pdfId, pdfUniqueId } = this.props;

    if (pdfId) {
      try {
        const serializedPdf = await getPdfBase64(pdfId);

        this.setState({
          pdf: serializedPdf,
        });
      } catch (error) {
        console.log('error pdf modal', error);
        Sentry.captureException(error);
      }

      this.props.setIsLoading(false);
      this.setState({ isLoading: false });
    } else if (pdfUniqueId) {
      const serializedPdf = await getNewPdfBase64(pdfUniqueId);

      this.setState({
        pdf: serializedPdf,
      });

      this.props.setIsLoading(false);
      this.setState({ isLoading: false });
    }
  }

  onDocumentLoadSuccess = (props: any) => {
    const numPages = props.numPages;
    this.setState({ numPages, displayButtons: true, previousDisabled: true });
  };

  onChangePage = (num: number) => {
    const { pageNumber, numPages } = this.state;
    const page = pageNumber + num;

    if (page >= numPages) {
      this.setState({
        pageNumber: numPages,
        previousDisabled: false,
        nextDisabled: true,
      });
    } else if (page <= 1) {
      this.setState({
        pageNumber: 1,
        previousDisabled: true,
        nextDisabled: false,
      });
    } else {
      this.setState({
        pageNumber: page,
        previousDisabled: false,
        nextDisabled: false,
      });
    }
  };

  render() {
    const {
      pageNumber,
      numPages,
      displayButtons,
      previousDisabled,
      nextDisabled,
      pdf,
    } = this.state;

    return (
      <div className="pdf-modal-root">
        {/* <textarea
          id="pdfToCopy"
          onChange={() => {}}
          style={{
            opacity: 0,
          }}
          disabled={true}
          value={showPdfModal.pdfUrl}
        >
          {showPdfModal.pdfUrl}
        </textarea> */}
        <div className="pdf-modal-content">
          {this.state.isLoading && <LoadingPage />}
          <div
            className="modal-close-button"
            onClick={() => this.props.onHitsClose()}
          >
            <FontAwesomeIcon icon={faTimes} size="2x" color="black" />
          </div>
          <div className="pdf-external-box-mobile">
            {this.state.pdf && (
              <>
                <Document
                  file={this.state.pdf}
                  onLoadSuccess={this.onDocumentLoadSuccess}
                  onLoadError={(error) =>
                    console.log('error pdf modal ', error)
                  }
                  // options={options}
                >
                  <Page pageNumber={pageNumber || 1} />
                </Document>

                {numPages > 1 && displayButtons && (
                  <div className="pdf-buttons">
                    {/* <button onClick={() => this.setState((prevState: any) => ({ pageNumber: prevState.pageNumber - 1}))}> */}
                    <button
                      className={
                        previousDisabled
                          ? 'button-secondary square disabled'
                          : 'button-secondary square'
                      }
                      onClick={() => this.onChangePage(-1)}
                      disabled={previousDisabled}
                    >
                      Previous
                    </button>

                    {/* <button
                      onClick={() => this.onClickCopy()}
                      className="button-secondary square border-black"
                    >
                      Copy PDF Link
                    </button> */}

                    <button
                      className={
                        nextDisabled
                          ? 'button-secondary square disabled'
                          : 'button-secondary square'
                      }
                      onClick={() => this.onChangePage(+1)}
                      disabled={nextDisabled}
                    >
                      Next
                    </button>
                  </div>
                )}
                {numPages === 1 && (
                  <div
                    className="pdf-buttons"
                    style={{
                      justifyContent: 'center',
                    }}
                  >
                    {/* <button
                      onClick={() => this.onClickCopy()}
                      className="button-secondary square border-black"
                    >
                      Copy PDF Link
                    </button> */}
                  </div>
                )}
                {displayButtons && (
                  <p>
                    Page {pageNumber} of {numPages}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
