import React, { memo, useMemo, useEffect } from 'react';
import { withFormik, FormikProps } from 'formik';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  IBidAdditionalQuotes,
  IBidAdditionalQuoteItem,
} from 'modules/BidInfoForm/interfaces';
import {
  additionalQuotesValues,
  quotesGroupOptions,
} from 'modules/shared/bidDefaultValues';
import { quotesOptionsWithDescription } from './constants';

import { limitTextAreaRows } from 'modules/shared/utils';
import { extractNumberFromMoneyMask, } from 'core/utils';
import {formatNumberByMask} from 'core/utils/inputFormatsV2';
import { calculateQuote } from 'modules/BidInfoForm/utils';
import { NoteModal } from 'modules/shared/sections/Footer/NoteModal';

import { faEdit } from '@fortawesome/free-solid-svg-icons';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import {
  ContentInput,
  Select,
  FilterSelect,
  InputFloatLabel,
  InputFloatLabelWithValidation,
  ButtonGroup,
  IconButton,
  DisabledBoxValue as BoxValue,
  useFormProps,
} from 'core/components';

import {Select as SelectV2} from 'core/components/SelectV2';
import {
  moneyMask,
  moneyMask6Digits,
  moneyMaskParams,
  moneyMaskParams6Digits,
} from 'core/utils/masks';
import './styles.scss';
import styled, { css } from 'styled-components';

import { StyledButton, Content as BtnContent } from 'core/components/Button';

const Button = styled(StyledButton)`
  width: 100%;
  height: 35px;
  padding-right:20px;
  padding-left:20px;
  margin-left: 40px
  margin-bottom: 15px;
  svg {
    margin-right: 10px;
  }
  @media (max-width: 900px) {
    width: auto;
  }
  @media (min-width: 767px) {
    width: auto;
  }
`;

const ButtonLabel = styled.span`
  font-size: 16px;
`;

const defaultQuoteValue: IBidAdditionalQuoteItem =
  additionalQuotesValues.quotes[0];

type IProps = FormikProps<IBidAdditionalQuotes>;
const AdditionalQuotes = memo(({ backgroundColor, isSpecialty }: IProps) => {
  const {
    onChange,
    formValues,
    showDeleteConfirmationModal,
    setFormValues,
  } = useFormProps();

  const [quotesOptions, setQuotesOptions] = React.useState([]);
  const [serviceDescriptions, setServiceDescriptions] = React.useState({});
  const [editState, setEditState] = React.useState(false);
  const [sqftAppliedOptions, setSqftAppliedOptions] = React.useState([]);

  const quotes = useMemo(()=>{
    return formValues.quotes.length > 0 ? formValues.quotes : [defaultQuoteValue];;
  }, [formValues.quotes])

  console.log({quotes});
    
  const onChangeQuote = (index: number, refreshContext?: boolean) => (
    name: string,
    value: any
  ) => {
    const result = [
      ...quotes.filter((item: any, indexItem: number) => indexItem !== index),
    ];

    const newQuote = { ...quotes[index] };

    if (name === 'quoteSelect') {
      // newQuote.serviceDescription = quotesOptionsWithDescription[value].reduce((acc, i) => {acc+=i; return acc}, '')
      let defaultDesc = '';
      if (value) {
        if (Object.keys(quotesOptionsWithDescription).includes(value)) {
          defaultDesc = quotesOptionsWithDescription[value].reduce((acc, i) => {
            acc += '<p>' + i + '</p>';
            return acc;
          }, '');
          newQuote.serviceDescription = defaultDesc;
          document
            .querySelector(`textarea[name="serviceDescription_${index}"]`)
            .parentNode.querySelector(
              '.sun-editor-editable'
            ).innerHTML = defaultDesc;
        }
      }
    }

    result.splice(index, 0, { ...newQuote, [name]: value });
    onChange('quotes', result);
  };

  React.useEffect(() => {
    if (quotesOptions.length == 0) {
      setQuotesOptions(Object.keys(quotesOptionsWithDescription).sort());
    }
  }, [quotesOptionsWithDescription]);

  React.useEffect(() => {
    if (serviceDescriptions.index != undefined) {
      const index = serviceDescriptions.index;

      const result = [
        ...quotes.filter((item: any, indexItem: number) => indexItem !== index),
      ];
      result.splice(index, 0, {
        ...quotes[index],
        serviceDescription:
          serviceDescriptions.content != '<p><br></p>'
            ? serviceDescriptions.content
            : undefined,
      });

      onChange('quotes', result);
      setServiceDescriptions({});
    }
  }, [serviceDescriptions, quotes]);

  const onRemoveQuote = (index: number) => () => {
    showDeleteConfirmationModal(() => {
      let result = quotes.filter(
        (item: any, dataIndex: number) => dataIndex !== index
      );
      setServiceDescriptions({});
      if(result.length == 0){
        result.push({...defaultQuoteValue})
      }
      onChange('quotes', result);
    });
  };

  const onAddQuote = () => {
    onChange('quotes', [...quotes, defaultQuoteValue]);
    // setTimeout(()=>{
    //   limitTextAreaRows('sun-editor-editable', 'suggestion-counter', false, '.suggestion-wrapper');
    // },100)
  };

  React.useEffect(() => {
    limitTextAreaRows(
      'sun-editor-editable',
      'suggestion-counter',
      false,
      '.suggestion-wrapper',
      null
    );
  }, [quotes]);

  const { floorTypeTotals, sqftTotal, inventory } = formValues;

  useEffect(() => {
    if (
      floorTypeTotals.length === 1 &&
      floorTypeTotals[0].totalSqft === undefined
    ) {
      return;
    }
    let calculatedSqftTotal = sqftTotal;
    if (!sqftTotal) {
      inventory
        .filter((item: any, index: any) => item.floorType)
        .forEach((item: any) => {
          calculatedSqftTotal =
            (calculatedSqftTotal || 0) +
            parseFloat(`${item.totalSqft || 0}`.replace(/[,]/g, ''));
        });
    }

    const result = [];
    floorTypeTotals.forEach(
      (item: any) =>
        item.floorType &&
        result.push({
          key: item.floorType,
          label: item.floorType,
          value: item.totalSqft,
        })
    );
    if (calculatedSqftTotal) {
      result.push({
        key: 'total',
        label: `Total Sq. Ft.`,
        value: calculatedSqftTotal,
      });
    }
    setSqftAppliedOptions(result);
  }, [floorTypeTotals]);

  // const sqftAppliedOptions = useMemo(() => {
  //   let calculatedSqftTotal = sqftTotal;
  //   if (!sqftTotal) {
  //     inventory
  //       .filter((item: any, index: any) => item.floorType)
  //       .forEach((item: any) => {
  //         calculatedSqftTotal =
  //           (calculatedSqftTotal || 0) +
  //           parseFloat(`${item.totalSqft || 0}`.replace(/[,]/g, ''));
  //       });
  //   }

  //   const result = [];
  //   floorTypeTotals.forEach(
  //     (item: any) =>
  //       item.floorType &&
  //       result.push({
  //         key: item.floorType,
  //         label: `${item.floorType}`,
  //         value: item.totalSqft,
  //       })
  //   );
  //   if (calculatedSqftTotal) {
  //     result.push({
  //       key: 'total',
  //       label: `Total Sq. Ft.`,
  //       value: calculatedSqftTotal,
  //     });
  //   }
  //   return result;
  // }, [floorTypeTotals, sqftTotal, inventory]);

  const onSelectApplied = (index: number) => (name: string, item: any) => {
    const value = item.value || item;
    const newQuotes = [...quotes];
    newQuotes[index] = {
      ...newQuotes[index],
      [name]: value,
      [`${name}_key`]: item.key,
      quoteTotal: calculateQuote({ ...newQuotes[index], [name]: value }),
    };
    // setFormValues({
    //   ...formValues,
    //   quotes: newQuotes,
    // });
    // setFormValues({
    //   quotes: newQuotes,
    // });
    onChange('quotes', newQuotes);
  };

  const onBlur = (index: number, name: string, value: any) => {
    const newQuotes = [...quotes];
    newQuotes[index] = {
      ...newQuotes[index],
      [name]: value,
      quoteTotal: calculateQuote({ ...newQuotes[index], [name]: value }),
    };

    onChange('quotes', newQuotes);

    // setFormValues({
    //   quotes: newQuotes,
    // });

    // setValues({
    //   ...formValues,
    //   quotes: newQuotes,
    // });
  };

  return (
    <div
      className="additional-quotes-root"
      style={{ backgroundColor: backgroundColor }}
    >
      <div
        className="additional-quotes-content"
        style={{
          position: 'relative',
          paddingBottom: '70px',
        }}
      >
        {isSpecialty && (
          <ContentInput width="100%" justify="flex-start" alignItems="center">
            <h3 className="additional-quotes-title">
              Specialty Service Quote(s)
            </h3>
          </ContentInput>
        )}

        {quotes.map((quote: IBidAdditionalQuoteItem, index: number) => (
          <div
            key={`additional_quote_${index}`}
            class={`additional_quote_${index}`}
            style={{
              flexDirection: 'column',
              paddingLeft: '5px',
              paddingRight: '5px',
              backgroundColor: '#f2f2f2',
              borderRadius: '10px',
              border: '2px solid silver',
              padding: '20px',
              paddingBottom: '35px',
              position: 'relative',
              marginTop: '20px',
            }}
          >
            <div className="additional-quotes-row">
              <span
                style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  marginRight: '20px',
                }}
              >
                {index + 1}.
              </span>
              <FilterSelect
                placeholder="Select or Enter Service"
                grayPlaceHolder
                value={quote.quoteSelect}
                items={quotesOptions}
                name="quoteSelect"
                className={`validate-quote-select validate-quote-select-${index}`}
                onChange={onChangeQuote(index, true)}
                width="60%"
                inputStyle={{ minWidth: '300px' }}
                canTypeCustomValue={true}
                disabled={formValues.status === 2}
              />

              <div
                className="quote-applied-content"
                style={{
                  maxWidth: '200px',
                }}
              >
                <SelectV2
                  placeholder="Pricing Method"
                  className={`validate-pricing-method validate-pricing-method-${index}`}
                  value={
                    !!quote.perHour
                      ? '1'
                      : quote.perMonth
                      ? '3'
                      : quote.uponRequest
                      ? '-1'
                      : quote.unit
                      ? '2'
                      : quote.perHour === false
                      ? '0'
                      : quote.perHour
                  }
                  grayPlaceHolder
                  items={quotesGroupOptions}
                  name="perHour"
                  getLabelFunction={(item) => typeof item === 'object' ? item.label : item}
                  getValueFunction={(item) => typeof item === 'object' ? item.value : item}
                  onChange={(name, objValue) => {
                    const result = [
                      ...quotes.filter(
                        (item: any, indexItem: number) => indexItem !== index
                      ),
                    ];
                    if (objValue.value === '-1') {
                      result.splice(index, 0, {
                        ...quotes[index],
                        uponRequest: true,
                        perHour: false,
                        perMonth: false,
                        unit: false,
                      });
                    } else if (objValue.value === '2') {
                      result.splice(index, 0, {
                        ...quotes[index],
                        uponRequest: false,
                        perHour: false,
                        perMonth: false,
                        unit: true,
                      });
                    } else if (objValue.value === '3') {
                      result.splice(index, 0, {
                        ...quotes[index],
                        uponRequest: false,
                        perHour: false,
                        perMonth: true,
                        unit: false,
                      });
                    } else {
                      result.splice(index, 0, {
                        ...quotes[index],
                        uponRequest: false,
                        perHour: objValue.value === '1' ? true : false,
                        perMonth: false,
                        unit: false,
                      });
                    }
                    setFormValues({ ...formValues, quotes: result });
                  }}
                  width="100%"
                  disabled={formValues.status === 2}
                  canTypeCustomValue={false}
                  blockCustomInput={true}
                  autoSelectOnBlur
                />
              </div>

              {(quote.perHour || quote.perMonth) && (
                <div
                  className="quote-option-content"
                  style={{
                    position: 'absolute',
                    right: '10px',
                    bottom: '-2px',
                  }}
                >
                  <IconButton
                    label={formValues.quotes.length > 1 || formValues.isSpecialty ? 'Delete' : 'Clear'}
                    onClick={onRemoveQuote(index)}
                    iconSize="lg"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '55px',
                    }}
                    disabled={formValues.status === 2 || (formValues.isSpecialty && formValues.quotes.length <= 1)}
                  />
                </div>
              )}
            </div>

            {(quote.perHour || quote.perMonth) && (
              <div
                className="additional-quotes-row quote-content-space"
                style={{ marginBottom: '0px' }}
              >
                <ContentInput
                  maxWidth="150px"
                  width="13%"
                  padding="0"
                  flex
                  self="center"
                  position="relative"
                >
                  <InputFloatLabelWithValidation
                    mask={moneyMask}
                    maskParams={{
                      ...moneyMaskParams, 
                      thousandsSeparatorSymbol: true
                    }}
                    onBlur={(name, value) => onBlur(index, name, value)}
                    label="Price"
                    name="quotePrice"
                    inputClassName="quotePrice"
                    id={`quotePrice_${index}`}
                    value={quote.quotePrice}
                    onChange={onChangeQuote(index)}
                    onFocusSelect
                    disabled={formValues.status === 2}
                  />
                  <span
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: '11px',
                    }}
                  >
                    {quote.perHour && ' /hr.'}
                    {quote.perMonth && ' /mo.'}
                  </span>
                </ContentInput>
              </div>
            )}

            {quote.unit && (
              <div
                className="additional-quotes-row quote-content-space"
                style={{ marginBottom: '0px' }}
              >
                <ContentInput width="25%">
                <InputFloatLabelWithValidation
                    mask={moneyMask}
                    maskParams={{
                      ...moneyMaskParams, 
                      thousandsSeparatorSymbol: true
                    }}
                    onBlur={(name, value)=>{
                      // onChange(name, value);
                      onBlur(index, name, value)
                    }}
                    label="Price"
                    id={`unitPrice_${index}`}
                    inputClassName="unitPrice"
                    name="unitPrice"
                    type="tel"
                    value={quote.unitPrice}
                    onChange={onChangeQuote(index, true)}
                    disabled={formValues.status === 2}
                  />
                </ContentInput>
                <span style={{marginLeft: '-30px'}}>
                  Per
                </span>
                <ContentInput width="40%">
                  <InputFloatLabel
                    label="Unit Label"
                    id={`unitLabel_${index}`}
                    name="unitLabel"
                    inputClassName="unitLabel"
                    value={quote.unitLabel}
                    onChange={onChangeQuote(index, true)}
                    onBlur={onChangeQuote(index, true)}
                    disabled={formValues.status === 2}
                  />
                </ContentInput>
                  
                <span style={{marginLeft: '-100px'}}>
                  (eg. Window)
                </span>
                <div
                  className="quote-option-content"
                  style={{
                    position: 'absolute',
                    right: '10px',
                    bottom: '-2px',
                  }}
                >
                  <IconButton
                    label={formValues.quotes.length > 1 || formValues.isSpecialty ? 'Delete' : 'Clear'}
                    onClick={onRemoveQuote(index)}
                    iconSize="lg"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '55px',
                    }}
                    disabled={formValues.status === 2 || (formValues.isSpecialty && formValues.quotes.length <= 1)}
                  />
                </div>
              </div>
            )}

            {!quote.perHour &&
              !quote.perMonth &&
              !quote.uponRequest &&
              !quote.unit && (
                <div
                  className="additional-quotes-row quote-content-space"
                  style={{
                    justifyContent: 'space-between',
                    marginBottom: '0px',
                  }}
                >
                  <div
                    className={`quote-applied-content quote-applied-content_${index}`}
                    style={{
                      width:
                        !quote.perHour && !quote.uponRequest && !quote.unit
                          ? '50%'
                          : '300px',
                    }}
                  >
                    <div className="quote-applied-label">Sq. Ft. Applied</div>
                    <SelectV2
                      onlyNumbers
                      // value={formatNumberByMask(quote['sqftApplied'], {
                      //   prefix: '',
                      //   allowDecimal: true,
                      //   // decimalLimit: 2,
                      //   thousandsSeparatorSymbol: true, 
                      //   type: 'tel',
                      // })}
                      value={quote['sqftApplied']}
                      items={sqftAppliedOptions}
                      id={`sqftApplied_${index}`}
                      name="sqftApplied"
                      type="tel"
                      onChange={onSelectApplied(index)}
                      width="100%"
                      getLabelInputFuction={(item: any) =>
                        typeof item === 'object' ? `${item.value}` : item
                      }
                      getLabelFunction={(item: any) =>
                        typeof item === 'object'
                          ? `${item.label} - ${`${formatNumberByMask(item.value, {
                            prefix: '',
                            allowDecimal: true,
                            // decimalLimit: 2,
                            thousandsSeparatorSymbol: true, 
                            type: 'tel',
                          })}`}`
                          : item
                      }
                      getValueFunction={(item: any) =>
                        typeof item === 'object' ? item.value : item
                      }
                      canTypeCustomValue={true}
                      disabled={formValues.status === 2}
                    />
                  </div>
                  <ContentInput width="23%">
                  <InputFloatLabelWithValidation
                      mask={moneyMask}
                      maskParams={{
                        ...moneyMaskParams, 
                        thousandsSeparatorSymbol: true
                      }}
                      onBlur={(name, value)=>{
                        onBlur(index, name, value); 
                        // onChangeQuote(index, true)
                      }}
                      label="$ / Sq. Ft."
                      id={`quoteSqft_${index}`}
                      inputClassName="quoteSqft"
                      name="quoteSqft"
                      type="tel"
                      value={quote.quoteSqft}
                      onChange={onChangeQuote(index, true)}
                      disabled={formValues.status === 2}
                    />
                  </ContentInput>

                  <BoxValue
                    width="50%"
                    labelWidth="60%"
                    label="Calc’d Price: $"
                    value={(quote.quoteTotal || 0).toFixed(2)}
                    disabled={formValues.status === 2}
                  />

                  <ContentInput width="23%">
                    <InputFloatLabelWithValidation
                      mask={moneyMask}
                      maskParams={{
                        ...moneyMaskParams, 
                        thousandsSeparatorSymbol: true
                      }}
                      onBlur={(name, value)=>onBlur(index, name, value)}
                      label="Override"
                      id={`quoteOverride_${index}`}
                      name="quoteOverride"
                      value={quote.quoteOverride}
                      onChange={onChangeQuote(index, true)}
                      disabled={formValues.status === 2}
                    />
                  </ContentInput>

                  <div
                    className="quote-option-content"
                    style={{
                      position: 'absolute',
                      right: '10px',
                      bottom: '-2px',
                    }}
                  >
                    <IconButton
                      label={formValues.quotes.length > 1 || formValues.isSpecialty ? 'Delete' : 'Clear'}
                      onClick={onRemoveQuote(index)}
                      iconSize="lg"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '55px',
                      }}
                      disabled={formValues.status === 2 || (formValues.isSpecialty && formValues.quotes.length <= 1)}
                    />
                  </div>
                </div>
              )}

            {quote.uponRequest && (
              <div
                className="quote-option-content"
                style={{
                  position: 'absolute',
                  right: '10px',
                  bottom: '-12px',
                }}
              >
                <IconButton
                  label={formValues.quotes.length > 1 || formValues.isSpecialty ? 'Delete' : 'Clear'}
                  onClick={onRemoveQuote(index)}
                  iconSize="lg"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '55px',
                  }}
                  disabled={formValues.status === 2 || (formValues.isSpecialty && formValues.quotes.length <= 1)}
                />
              </div>
            )}
            <div
              className="quote-applied-label"
              style={{ width: '100%', marginBottom: '10px' }}
            >
              Service Description:
            </div>
            {/* <textarea
              key={`serviceDescription_${index}`}
              id={`serviceDescription_${index}`}
              name="serviceDescription"
              value={quote.serviceDescription || ''}
              onChange={(e) => {
                onChangeQuote(index, true)(
                  'serviceDescription',
                  e.target.value
                );
              }}
              disabled={formValues.status === 2}
              rows="4"
              style={{
                width: '100%',
                marginBottom: '20px',
                resize: 'none',
                outline: 'none',
                fontSize: '16px',
              }}
            ></textarea> */}
            <div
              style={{ position: 'relative', zIndex: 0 }}
              className="suggestion-wrapper"
            >
              <SunEditor
                height={'150px'}
                key={`serviceDescription_${index}`}
                id={`serviceDescription_${index}`}
                name={`serviceDescription_${index}`}
                setContents={(() => quote.serviceDescription || '')()}
                disable={formValues.status === 2}
                onChange={(content) => {
                  console.log({content})
                  setServiceDescriptions({ index, content });
                  // onChangeQuote(index, true)('serviceDescription', content);
                }}
                setOptions={{
                  height: '82px',
                  formats: [
                    'div',
                    'p',
                    'blockquote',
                    'pre',
                    'h1',
                    'h2',
                    'h3',
                    'h4',
                    'h5',
                    'h6',
                  ],
                  buttonList: [
                    ['undo', 'redo'],
                    ['bold', 'underline', 'italic', 'strike'],
                    ['outdent', 'indent'],
                    ['removeFormat'],
                    ['list'],
                  ],
                }}
              />
              <span className="suggestion-counter">0</span>
            </div>
          </div>
        ))}
        {/* <Button
          color="#F7A11D"
          width="auto"
          padding="0 15px"
          kind="outline"
          reverseSkew={true}
          onClick={() => {
            setEditState(true);
          }}
        >
          <BtnContent>
            <FontAwesomeIcon icon={faEdit} />
            <ButtonLabel></ButtonLabel>
          </BtnContent>
        </Button> */}

        <button
          tabIndex={-1}
          onClick={() => {
            setEditState(true);
          }}
          className={`quote-add-button`}
          style={{
            position: 'absolute',
            left: '20px',
            bottom: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            padding: '15px',
            width: '220px',
            fontSize: '16px',
            backgroundColor: '#F7A11D',
            color: 'white',
            fontFamily: 'OpenSans-Regular',
          }}
        >
          <FontAwesomeIcon icon={faEdit} size="lg" color="white" />
          Edit Service Footer
        </button>

        <button
          tabIndex={-1}
          onClick={onAddQuote}
          className={`quote-add-button`}
          style={{
            position: 'absolute',
            right: '20px',
            bottom: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            padding: '15px',
            width: '100px',
            fontSize: '16px',
            fontFamily: 'OpenSans-Regular',
          }}
        >
          <FontAwesomeIcon icon={faPlus} size="lg" color="#999" />
          Add
        </button>
      </div>
      {editState && (
        <div style={{ zIndex: 3 }}>
          <NoteModal
            onCancel={()=> setEditState(false)}
            canEdit={formValues.status !== 2}
            canRestore
            onClose={() => setEditState(false)}
            genericTitle="Service Footer"
            fieldName="serviceFooter"
            deleteConfirmationMessage="Are you sure you want to clear the service footer?"
          />
        </div>
      )}
    </div>
  );
});

const mapForm = {
  mapPropsToValues: () => ({
    ...additionalQuotesValues,
  }),
  handleSubmit: () => '',
};

const EnhancedAdditionalQuotes = withFormik<any, IBidAdditionalQuotes>(mapForm)(
  AdditionalQuotes
);

export { EnhancedAdditionalQuotes as AdditionalQuotes };
