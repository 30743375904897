import styled, { css } from 'styled-components';

import { StyledButton, Content as BtnContent } from 'core/components/Button';

interface IHeading {
  size?: string;
}

const HeadingBase = css`
  color: #3f3f3f;
  text-transform: unset;
  font-style: normal;
  font-family: 'OpenSans-Bold';
  text-align: center;
  margin-bottom: 15px;

  i {
    font-family: 'OpenSans-BoldItalic';
  }

  @media (min-width: 767px) {
    text-align: left;
  }
`;

export const BaseHeading = styled.h2<IHeading>`
  ${HeadingBase}

  font-size: ${({ size = '36px' }) => size};

  span:last-of-type {
    margin-left: 10px;
    font-weight: normal;
    font-family: OpenSans-Regular;
  }
`;

interface IText {
  size?: string;
}
export const BaseText = styled.p<IText>`
  color: #3f3f3f;
  font-size: ${({ size = '16px' }) => size};

  small {
    font-size: 12px;
  }
`;

export const Text = styled(BaseText)`
  text-align: left;
  color: #fff;
`;

export const Heading = styled(BaseHeading)`
  line-height: 40px;
  font-size: 32px;
  color: #fff;
`;

export const Wrapper = styled.div`
  padding: 50px 20px;
`;

export const Header = styled.div`
  text-align: center;

  ${Heading} {
    text-align: center !important;
    margin-top: 30px;
  }
`;

export const AccordionHeader = styled.div`
  width: 100%;
  ${Text} {
    text-align: center;
  }
  button {
    height: 35px;
  }
`;

export const Footer = styled.div`
  padding: 40px 40px 0;
  display: flex;
  justify-content: center;

  button span {
    font-family: 'OpenSansCondensed-LightItalic';
  }
`;

export const ButtonHolder = styled.div`
  display: flex;
  justify-content: center;

  button span {
    font-family: 'OpenSans-SemiBold';
  }
`;

export const CellHolder = styled.div`
  padding-top: 10px;
  font-size: 24px;
`;

export const EmailTextHolder = styled.p`
  font-size: 14px;
  text-align: left;
  white-space: pre-wrap;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  button {
    width: 170px;

    &:first-child {
      margin-right: 15px;
    }
    &: last-child {
      margin-left: 15px;
    }
  }
`;

export const ButtonLabel = styled.span`
  white-space: nowrap;
  font-size: 16px;
  width: 175px;
  display: block;
  text-align: right;
`;

export const ActionButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 85%;
  justify-content: space-evenly;
`;

export const ActionButtonLabel = styled.div`
  width: 100px;
  line-height: 20px;
`;

export const AccordionBody = styled.div`
  background-color: #fff;
  padding: 10px;
`;

export const IconWrapper = styled.div`
  display: inline-block;
  position: relative;

  svg {
    position: relative;
    z-index: 2;
  }

  &::before {
    content: '';
    background-color: #fff;
    position: absolute;
    width: 90%;
    height: 90%;
    border-radius: 50%;
    z-index: 0;
    top: 5px;
    left: 5px;
  }
`;

export const ModalTopActions = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  button {
    margin: 10px 10px 0 0;
    border: 0 none;
    background-color: transparent;
    color: #fff;
  }
`;

export const EmailItem = styled.div`
  width: 100%;
  text-align: left;
  font-weight: bold;
`;

interface ICell {
  width?: string;
  textAlign?: string;
  label?: string;
}

export const Cell = styled.div<ICell>`
  padding: 5px;
  box-sizing: border-box;
  min-width: ${({ width = '130px' }) => width};
  text-align: ${({ textAlign = 'center' }) => textAlign};
  position: relative;

  ${({ label }) =>
    label
      ? `
    padding-top: 30px;
    &:before {
      height: 30px;
      width: 100%;
      position: absolute;
      content: '${label}';
      top: 4px;
      left: 0;
      font-weight: bold;
    }
  `
      : ''}

  @media (min-width: 767px) {
    padding-top: 0;
    &:before {
      content: none;
    }
  }
`;

export const Row = styled.div<{ wrap?: string }>`
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: ${({ wrap = 'unset' }) => wrap};

  @media (min-width: 767px) {
    flex-direction: row;

    ${({ wrap = 'unset' }) =>
      wrap === 'unset'
        ? `
     ${Cell} {
      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }
    `
        : ''}
  }
`;

export const RowHolder = styled.div`
  margin-bottom: 20px;

  @media (min-width: 767px) {
    margin-bottom: 0;
  }
`;

export const Button = styled(StyledButton)`
  width: 100%;

  svg {
    margin-right: 10px;
  }

  @media (max-width: 900px) {
    width: auto;
  }

  @media (min-width: 767px) {
    width: auto;
  }
`;
