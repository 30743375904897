import React, { useState, useRef } from 'react';
import Chevron from './Chevron';
import Triangle from './Triangle';

import EventEmitter from 'core/utils/events';

import './styles.scss';

export const GenericAccordion = (props) => {
  const hideAccordion = props.hideAccordion || false;
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState(
    hideAccordion ? 'triangle__icon' : 'accordion__icon'
  );
  const [contentOverflow, setContentOverflow] = useState('hidden');
  const [toggleFinished, setToggleFinished] = useState(true);

  const content = useRef(null);

  React.useEffect(() => {
    setHeightState(
      setActive === ''
        ? '0px'
        : `${props.contentHeight || content.current.scrollHeight}px`
    );
  }, [props.children, props.heightChanged]);

  const iconStyle = {
    fill: '#777',
    ...props.iconStyle,
  };
  const autoOpenOnValidation = (sectionsToOpen)=> {
    if(setActive === '' && props.sectionId && sectionsToOpen.includes(props.sectionId)) {
      doToggle();
    }
  }

  React.useEffect(() => {
    EventEmitter.on('AUTO_OPEN_ON_VALIDATE', autoOpenOnValidation)
    return () => {
      EventEmitter.removeListener('AUTO_OPEN_ON_VALIDATE', autoOpenOnValidation);
    };
  }, []);

  const doToggle = () => {
    setToggleFinished(false);
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(
      setActive === 'active'
        ? '0px'
        : `${props.contentHeight || content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === 'active'
        ? hideAccordion
          ? 'triangle__icon'
          : 'accordion__icon'
        : hideAccordion
        ? 'triangle__icon rotate__more'
        : 'accordion__icon rotate'
    );
    if (setActive === 'active') {
      setContentOverflow('hidden');
      setToggleFinished(true);
    } else {
      setTimeout(() => {
        setContentOverflow('visible');
        setToggleFinished(true);
      }, 300);
    }
  };

  function toggleAccordion() {
    if (props.canOpenSection && !props.canOpenSection()) {
      return;
    }

    if(!toggleFinished){
      return;
    }

    doToggle();
  }

  React.useEffect(() => {
    if (props.shouldKeepHidden && setActive == 'active') {
      doToggle();
    }
  }, [props.shouldKeepHidden]);

  return (
    <div className="accordion__section" style={props.containerStyle}>
      {props.disabled && (
        <div className="disabled-overlay" onClick={toggleAccordion}></div>
      )}
      <button
        className={`accordion ${setActive} ${
          hideAccordion ? 'accordion-centered' : ''
        }`}
        onClick={toggleAccordion}
        style={props.headerStyle}
      >
        {props.renderHeader && props.renderHeader()}
        {!props.renderHeader && (
          <p className="accordion__title">{props.title}</p>
        )}
        {!hideAccordion ? (
          <Chevron
            className={`${setRotate}`}
            width={15}
            style={{ marginLeft: '-20px', ...props.iconStyle }}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '5px',
              marginLeft: '10px',
              background: '#FEA23E',
              borderRadius: '5px',
            }}
          >
            <Triangle
              className={`${setRotate}`}
              color="#014984"
              bgColor="#ffff"
              width={15}
              style={{ marginLeft: '-20px', ...props.iconStyle }}
              onClick={toggleAccordion}
            />
          </div>
        )}
      </button>
      <div
        ref={content}
        style={{
          maxHeight: `${setHeight}`,
          backgroundColor: props.headerStyle
            ? props.headerStyle.backgroundColor
            : '',
          ...props.contentStyle,
          overflow: contentOverflow,
        }}
        className="accordion__content"
      >
        {props.children}
      </div>
    </div>
  );
};

export { Chevron };
