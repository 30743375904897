import {
  IBidInfoForm,
  IBidRoomInventory,
  IBidRoomInventoryItem,
  IBidProductionRates,
  IBidProductionRatesItem,
} from '../interfaces';
import { toTitleCase } from '../../../core/utils/formatInputs';
import { booleanValue } from '../../../core/utils/objectUtils';
import { termsAndConditionsValues } from 'modules/shared/bidDefaultValues';

const days = {
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednesday',
  Thu: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
  Sun: 'Sunday',
};

const serviceAreas = {
  Office: 'OfficesArea',
  'Exam Room': 'ExamRoomsArea',
  Kitchen: 'KitchenArea',
  Restroom: 'RestroomArea',
  'Common Area': 'CommonArea',
  Stairwell: 'StairwellsArea',
  'Break Area': 'BreakRoomsArea',
  Lab: 'LabsArea',
  Classroom: 'ClassRoomsArea',
  Elevator: 'ElevatorsArea',
  Warehouse: 'WarehouseArea',
  Showroom: 'ShowroomsArea',
  'Conference Room': 'ConferenceRoomArea',
};

// key = client values(formik style)
// value = api values
const bidInfoKeys = {
  // header
  bidInfoId: 'BidInformationSheetId',
  originalBidInfoId: 'OriginalBidInformationSheetId',
  cityId: 'FranchiseId',
  pid: 'PID',
  sid: 'SID',
  control: 'ControlNumber',
  bidDate: 'Date',
  userId: 'CreatedBy',
  bidVersion: 'BidVersion',

  // client info
  companyName: 'BusinessName',
  address: 'Address',
  suite: 'Suite',
  city: 'City',
  state: 'State',
  postalCode: 'Zip',
  phone: 'Phone',
  mobilePhone: 'MobilePhone',
  county: 'County',
  addressedTo: 'AddressedToWalkThru',
  walkThruName: 'ContactName',
  walkThruEmail: 'EmailAddress',
  walkThruTitle: 'WalkThruTitle',
  decisionMakerName: 'DecisionMaker',
  decisionMakerEmail: 'DecisionMakerEmail',
  decisionMakerTitle: 'DecisionMakerTitle',
  addressedToWalkThru: 'AddressedToWalkThru',

  // account info
  timeWindow: 'TimeOfDay',
  secondTimeWindow: 'SecondTimeOfDay',
  alarm: 'Alarm',
  key: 'Keys',
  addFrequency: 'AddFrequency',
  monthlyAddFrequency: 'MonthlyAddFrequency',
  smartClean: 'SmartClean',
  cleaning: 'TimesPerMonth',
  secondCleaning: 'SecondTimesPerMonth',
  isMonthlyCleaning: 'IsMonthlyCleaning',
  sic: 'SIC',
  sicId: 'SICID',
  optionProtection: 'OptionProtection',

  // service areas
  otherAreas: 'OthersArea',

  // confirmation modal
  pdfAttachments: 'GenerateAttachmentsPDF',
  selectedAttachments: 'SelectedAttachments',
  selectedSystemAttachments: 'SelectedSystemAttachments',
  selectedFranchiseAttachments: 'SelectedFranchiseAttachments',

  janitorialName: 'JanitorialCompanyName',
  janitorialPrice: 'CurrentlyPaying',
  janitorialHours: 'DailyHoursCurrentService',

  cancellationReason: 'CancellationReason',
  cancellationNotice: 'CancellationNotice',
  lengthTerm: 'LenghtOfTerm',
  curePeriod: 'CurePeriod',

  porterHoursDay: 'DayPorterHoursPerDay',
  porterValueHour: 'DayPorterMoneyPerHour',
  porterTimeWindow: 'DayPorterTimeWindow',
  includeInPrice: 'IncludeDayPorterInPrice',

  suppliesLiners: 'DomainLinersId',
  suppliesRestroom: 'DomainRestroomId',
  costSupplies: 'CostOfSupplies',
  overrideCostSupplies: 'CostOfSuppliesOverride',

  bidPriceHour: 'BidAmountPerHour',
  bidPriceHourSecondFrequency: 'BidAmountPerHour2Freq',

  bidCalculatedBasePrice: 'BidCalculatedBasePrice',
  bidOverrideBasePrice: 'BidOverrideBasePrice',

  secondBidCalculatedBasePrice: 'SecondBidCalculatedBasePrice',
  secondBidOverrideBasePrice: 'SecondBidOverrideBasePrice',

  smartCleanCalculatedBasePrice: 'SmartCleanCalculatedBasePrice',
  smartCleanOverrideBasePrice: 'SmartCleanOverrideBasePrice',

  calculatedPrice: 'BidAmount',
  secondCalculatedPrice: 'SecondBidAmount',

  overridePrice: 'BidAmountOverride',
  secondOverride: 'SecondBidAmountOverride',

  sentStatus: 'SentStatus',
  status: 'Status',
  isTemplate: 'IsTemplate',
  isCityTemplate: 'IsCityTemplate',
  uid: 'BidUniqueID',
  specialAttentionAreas: 'SpecialAttentionAreas',
  porterOverride: 'DayPorterPriceOverride',
  monthlyPorterPrice: 'CostOfDayPorter',
  calculatedSmartCleanPrice: 'SmartCleanBidAmount',
  smartCleanOverridePrice: 'SmartCleanBidAmountOverride',
  additionalNote: 'Notes',
  disinfection: 'FirstFrequencyDisinfection',
  secondDisinfection: 'SecondFrequencyDisinfection',
  monthlyDisinfection: 'MonthlyFrequencyDisinfection',
  secondMonthlyDisinfection: 'SecondMonthlyFrequencyDisinfection',
  serviceFooter: 'ServiceFooter',

  isExpired: 'IsExpired',
  isSpecialty: 'IsSS',
  expirationDays: 'ExpirationDays',
  lastUpdate: 'LastUpdate',
  existentClient: 'ExistentClient',
  paragraphV: 'ProposalSection5',
  additionalQuotesTotal: 'SpecialtyServicePricing',

  generateBlankPDF: 'GenerateBlankPDF',

  //terms and conditions
  proposalHolidays: 'ProposalHolidays',
  proposalSection3: 'ProposalSection3',
  proposalSection4: 'ProposalSection4',
  proposalSection5: 'ProposalSection5',
  proposalSection7: 'ProposalSection7',
  proposalSection8: 'ProposalSection8',

  quoteTitle: 'TitleQuote1',
  secondQuoteTitle: 'TitleQuote2',

  protectionSpecificationSubtitle: 'ProtectionSpecificationSubtitle',

  protectionIncludedInPrice: 'ProtectionIncludedInPrice',
  secondProtectionIncludedInPrice: 'SecondProtectionIncludedInPrice',

  isTemp: 'IsTemp',
  originalBidUid: 'BidUniqueIDOriginal',

  franId: 'FranID',
  franName: 'FranchiseNameSS',

  tabId: 'TabUniqID',

  isAutoSave: 'IsAutoSave',
};

const roomKeys = {
  roomType: 'RoomType',
  floorType: 'FloorType',
  length: 'Length',
  width: 'Width',
  totalSqft: 'Total',
  prodGroup: 'ProductionRateGroup',
  fixtures: 'NumberOfFixtures',
};

const ProductionRatesKeys = {
  group: 'ProductionRate',
  productionName: 'Name',
  sqft: 'SquareFeet',
  rate: 'FirstFrequencyRate',
  secondRate: 'SecondFrequencyRate',
};

const facilityTypesKeys = [
  'Auto Dealer',
  'Bowling Alley',
  'Country Club',
  'Day Care',
  'Day Spa',
  'Dental Office',
  'Fitness Center',
  'General Minimum',
  'General Office',
  'Manufacturing',
  'Medical Office',
  'Movie Theater',
  'Multi Tenant',
  'Place of Worship',
  'Restaurant',
  'Retail Grocery',
  'School',
  'Surgical Center',
  'Movie Theaters',
];

const additionalQuotesKeys = {
  id: 'BidInformationAdditionalQuotesId',
  quoteSelect: 'QuotesType',
  quoteTotal: 'Total',
  perHour: 'PerHour',
  perMonth: 'PerMonth',
  uponRequest: 'UponRequest',
  unit: 'Unit',
  unitPrice: 'UnitPrice',
  unitLabel: 'UnitLabel',
  sqftApplied: 'SqFtApplied',
  quoteOverride: 'TotalOverride',
  serviceDescription: 'ServiceDescription',
};

const CHAR_TO_TRIM = /[\u200B-\u200D\uFEFF]/g;

export const parseAdditionalQuotes = (values: any, isFromApi?: boolean) => {
  if (isFromApi) {
    return values['AdditionalQuotes']
      ? values['AdditionalQuotes'].map((item: any) => ({
          ...parseValues(item, isFromApi, additionalQuotesKeys),
          quotePrice:
            item['PerHour'] || item['PerMonth'] ? item['SqFtPrice'] : 0,
          quoteSqft:
            !item['PerHour'] && !item['PerMonth'] ? item['SqFtPrice'] : 0,
        }))
      : [];
  } else {
    return values.quotes
      ? values.quotes.map((item: any) => ({
          ...parseValues(item, isFromApi, additionalQuotesKeys),
          ServiceDescription: item.serviceDescription ? item.serviceDescription.replace(CHAR_TO_TRIM, '') : '',
          SqFtPrice:
            (item.perHour || item.perMonth
              ? item.quotePrice
              : item.quoteSqft) || 0,
          Total:
            (item.perHour || item.perMonth
              ? item.quotePrice
              : item.quoteTotal) || 0,
          TotalOverride:
            (item.perHour || item.perMonth ? null : item.quoteOverride) || 0,
        }))
      : [];
  }
};

export const parseRestroomFixtures = (values: any, isFroMApi?: boolean) => {
  if (isFroMApi) {
    const ret = [];
    if (values['MinutesPerFixture']) {
      ret.push({
        group: 'RR1',
        fixtureName: values['FirstFixtureName'],
        restroomFixtures: values['RestroomFixtures'],
        minutesFixture: values['MinutesPerFixture'],
      });
    }

    if (values['SecondMinutesPerFixture']) {
      ret.push({
        group: 'RR2',
        fixtureName: values['SecondFixtureName'],
        restroomFixtures: values['SecondRestroomFixtures'],
        minutesFixture: values['SecondMinutesPerFixture'],
      });
    }
    if (values['ThirdMinutesPerFixture']) {
      ret.push({
        group: 'RR3',
        fixtureName: values['ThirdFixtureName'],
        restroomFixtures: values['ThirdRestroomFixtures'],
        minutesFixture: values['ThirdMinutesPerFixture'],
      });
    }
    return ret;
  } else {
    const ret = {};
    values.map((i) => {
      if (i.group === 'RR1') {
        ret['FirstFixtureName'] = i.fixtureName;
        ret['RestroomFixtures'] = i.restroomFixtures;
        ret['MinutesPerFixture'] = i.minutesFixture;
      } else if (i.group === 'RR2') {
        ret['SecondFixtureName'] = i.fixtureName;
        ret['SecondRestroomFixtures'] = i.restroomFixtures;
        ret['SecondMinutesPerFixture'] = i.minutesFixture;
      } else if (i.group === 'RR3') {
        ret['ThirdFixtureName'] = i.fixtureName;
        ret['ThirdRestroomFixtures'] = i.restroomFixtures;
        ret['ThirdMinutesPerFixture'] = i.minutesFixture;
      }
    });
    return ret;
  }
};

export const parseProtectionSpecification = (
  values: any,
  isFromApi?: boolean
) => {
  if (isFromApi) {
    const ret = {};
    values['FrequenciesProtection']
      .filter((i) => !i['IsNote'])
      .map((i) => {
        ret[i['Title']] = {};
        ret[i['Title']].items = i['CleaningAreas'].map((ca) => ({title: ca['Title'], uid: Math.random()}));
        ret[i['Title']].uid = Math.random();
        ret[i['Title']].titleColor = i['TitleColor'];
      });
    return ret;
  } else {
    const ret = [];
    Object.keys(values.protectionSpecification).map((key) => {
      ret.push({
        Title: key.toUpperCase(),
        TitleColor: values.protectionSpecification[key].titleColor,
        CleaningAreas: values.protectionSpecification[key].items.map(
          (item) => ({ Title: item.title })
        ),
      });
    });
    return ret;
  }
};

export const parseProtectionPackages = (
  values: any,
  isFromApi?: boolean,
  frequencyNumber: number = 1
) => {
  const ret = [];
  if (isFromApi) {
    values['BidProtectionQuotesPerFrequency']
      .filter((i) => i['QuoteId'] == frequencyNumber)
      .map((item) => {
        ret.push({
          index: item['KeyNumber'],
          total: item['Total'],
          totalOverride: item['TotalOverride'],
          bidId: item['BidInformationSheetId'],
          id: item['BidProtectionQuotesPerFrequencyId'],
          plans: item['BidProtectionQuotesPerFrequencyPlan'].map((p) => {
            return {
              plan: p['PlanName'],
              frequency: p['Frequency'],
              sqFtApplied: p['SqFtApplied'],
              prodRate: p['ProdRate'],
              perHour: p['PerHour'],
              subtotal: p['Subtotal'],
              id: p['BidProtectionQuotesPerFrequencyPlanId'],
              parentId: p['BidProtectionQuotesPerFrequencyId'],
            };
          }),
        });
      });
  } else {
    const mapFunction = (quoteId) => (i) => {
      ret.push({
        KeyNumber: i.index,
        Total: i.total,
        TotalOverride: i.totalOverride,
        QuoteId: quoteId,
        BidInformationSheetId: i.bidId,
        BidProtectionQuotesPerFrequencyId: i.id,
        BidProtectionQuotesPerFrequencyPlan: i.plans.map((p) => {
          return {
            PlanName: p.plan,
            Frequency: p.frequency,
            SqFtApplied: p.sqFtApplied,
            ProdRate: p.prodRate,
            PerHour: p.perHour,
            Subtotal: p.subtotal,
            BidProtectionQuotesPerFrequencyPlanId: p.id,
            BidProtectionQuotesPerFrequencyId: p.parentId,
          };
        }),
      });
    };
    values.protectionPackages.map(mapFunction(1));
    values.secondProtectionPackages.map(mapFunction(2));
  }
  return ret;
};

export const parseCleaningFrequence = (values: any, isFromApi?: boolean) => {
  if (isFromApi) {
    const ret = {};
    values['Frequencies']
      .filter((i) => !i['IsNote'])
      .map((i) => {
        const item = {};
        item.items = i['CleaningAreas'].map((ca) => ({
          title: ca['Title'],
          subTitle: ca['Subtitle'],
          uid: Math.random(),
          frequencyTypes: ca['Specifications'].map((s) => ({value: s['Title'], uid: Math.random()})),
        }));
        item.title = i['Title'] || i['KeyString'];
        item.uid = Math.random();
        ret[i['KeyString'] || i['Title']] = item;
      });
    return ret;
  } else {
    const ret = [];
    Object.keys(values.cleaningFrequency).map((key) => {
      ret.push({
        KeyString:
          key[0].toUpperCase() + key.substr(1, key.length).toLowerCase(),
        Title: toTitleCase(values.cleaningFrequency[key].title || key),
        CleaningAreas: values.cleaningFrequency[key].items.map((ca) => ({
          Title: ca.title || ca.mainTitle,
          Subtitle: ca.subTitle,
          Specifications: ca.frequencyTypes.map((s) => ({ Title: s.value })),
        })),
      });
    });
    return [...ret, ...parseSpecialNotes(values)];
  }
};

export const parseSpecialNotes = (values: any, isFromApi?: boolean) => {
  if (isFromApi) {
    const ret = {};
    values['Frequencies']
      .filter((i) => i['IsNote'])
      .map((item, index) => {
        item['CleaningAreas'].map((ca, idx) => {
          ret[idx] = { description: ca['Title'] };
        });
      });
    return ret;
  } else {
    const ret = [];
    ret.push({
      Title: 'Special Notes',
      IsNote: true,
      CleaningAreas: Object.keys(values.specialNotes).map((key) => ({
        Title: values.specialNotes[key].description,
      })),
    });

    return ret;
  }
};

export const parseDays = (
  prefix: string,
  values: Array<string> | any,
  isFromApi?: boolean
) => {
  if (!prefix || !values || (Array.isArray(values) && !values.length))
    return {};
  let parsedDays = {};
  if (isFromApi) {
    const selectedDays = [];
    Object.keys(days).forEach((key: string) => {
      if (values[`${prefix}${days[key]}`]) {
        selectedDays.push(key);
      }
    });
    // Object.keys(values).forEach((key: string) => {
    //   if (values[key]) {
    //     selectedDays.push(
    //       Object.keys(days).find((day: string) => key.includes(days[day]))
    //     );
    //   }
    // });
    return selectedDays;
  }
  Object.keys(days).forEach((key: string) => {
    parsedDays = {
      ...parsedDays,
      [`${prefix}${days[key]}`]:
        values.frequencyService === 'monthly' ? false : values.includes(key),
    };
  });
  return parsedDays;
};

export const parseAreas = (
  values: Array<string> | any,
  isFromApi?: boolean
) => {
  if (!values || (Array.isArray(values) && !values.length))
    return isFromApi ? [] : {};

  if (isFromApi) {
    const selectedAreas = [];
    Object.keys(values).forEach((key: string) => {
      if (values[key]) {
        selectedAreas.push(
          Object.keys(serviceAreas).find((area: string) =>
            key.includes(serviceAreas[area])
          )
        );
      }
    });
    return selectedAreas.filter((item) => item != undefined);
  }

  let parsedAreas = {};
  Object.keys(serviceAreas).forEach((key: string) => {
    parsedAreas = {
      ...parsedAreas,
      [`${serviceAreas[key]}`]: values.includes(key),
    };
  });
  return parsedAreas;
};

export const parseValues = (values: any, isFromApi?: boolean, keys?: any) => {
  if (!values) return {};
  keys = keys || bidInfoKeys;
  let parsedValues = {};
  Object.keys(keys).forEach((key: string) => {
    parsedValues = {
      ...parsedValues,
      [isFromApi ? key : keys[key]]: values[isFromApi ? keys[key] : key],
    };
  });

  return parsedValues;
};

export const parseRoomInventory = (values: any, isFromApi?: boolean) => {
  if (isFromApi) {
    return values['Room']
      ? values['Room'].map((item: any) => ({
          ...parseValues(item, isFromApi, roomKeys),
        }))
      : [];
  } else {
    return values.inventory
      ? values.inventory.map((item: IBidRoomInventoryItem) => ({
          ...parseValues(item, isFromApi, roomKeys),
        }))
      : [];
  }
};

export const parseProductionRates = (values: any, isFromApi?: boolean) => {
  if (isFromApi) {
    return values['ProductionRates']
      ? values['ProductionRates'].map((item: any) => ({
          ...parseValues(item, isFromApi, ProductionRatesKeys),
        }))
      : [];
  } else {
    return values.productionRates
      ? values.productionRates.map((item: IBidProductionRatesItem) => ({
          ...parseValues(item, isFromApi, ProductionRatesKeys),
        }))
      : [];
  }
};

export const parseToApi = (values: any) => {
  const secondFrequency =
    values.addFrequency && values.secondFrequency
      ? parseDays('SecondDaysCleaned', values.secondFrequency.selecteds)
      : {};
  const parsedValues = {
    ...parseValues(values, false),
    ...parseAreas(values.serviceAreas),
    ...parseDays('DaysCleaned', values.frequency.selecteds),
    ...secondFrequency,
    ...parseDays('DaysPotter', values.porterDays),
    ...parseRestroomFixtures(values.restroomFixtures),
    AdditionalQuotes: parseAdditionalQuotes(values),
    Room: parseRoomInventory(values),
    ProductionRates: parseProductionRates(values),
    Frequencies: parseCleaningFrequence(values),
    FrequenciesProtection: parseProtectionSpecification(values),
    FacilityType:
      facilityTypesKeys.indexOf(values.facilityType) > 0
        ? facilityTypesKeys.indexOf(values.facilityType)
        : facilityTypesKeys.indexOf('General Office'),
    AddressedToWalkThru: values.proposalAddressedTo === 'walkThru',
    TimeOfDay:
      values.frequencyService === 'monthly'
        ? values.timeWindow
        : values.frequency.timeWindow,
    SecondTimeOfDay:
      values.addFrequency && values.secondFrequency
        ? values.secondFrequency.timeWindow
        : values.monthlyAddFrequency
        ? values.secondTimeWindow
        : null,
    IsMonthlyCleaning: values.frequencyService === 'monthly',
    OwnerHasEcoInvoice: values.userHasBetaOptIn,
    SecondBidAmountOverride:
      values.addFrequency && values.secondFrequency
        ? values.secondOverride
        : values.monthlyAddFrequency && values.secondCleaning
        ? values.secondOverride
        : null,
    PID: values.pid ? values.pid.toUpperCase() : values.pid,
    SID: values.sid ? values.sid.toUpperCase() : values.sid,
    ControlNumber: values.control
      ? values.control.toUpperCase()
      : values.control,
    BidProtectionQuotesPerFrequency: parseProtectionPackages(values, false),
  };

  parsedValues.Room = parsedValues['Room'].map((item: any) => ({
    ...item,
    Width:
      item['Width'] != undefined && item['Width'] != null && item['Width'] != ''
        ? item['Width']
        : 0,
    Length:
      item['Length'] != undefined &&
      item['Length'] != null &&
      item['Length'] != ''
        ? item['Length']
        : 0,
  }));
  return parsedValues;
};

export const parseYesNoToBoolean = (value) =>
  value
    ? value.toLowerCase() === 'yes'
      ? true
      : value.toLowerCase() === 'no'
      ? false
      : value
    : false;

export const parseFromApi = (values: IBidInfoForm) => {
  const parsedValues = {
    ...parseValues(values, true),
    inventory: parseRoomInventory(values, true),
    productionRates: parseProductionRates(values, true),
    cleaningFrequency: parseCleaningFrequence(values, true),
    protectionSpecification: parseProtectionSpecification(values, true),
    specialNotes: parseSpecialNotes(values, true),
    restroomFixtures: parseRestroomFixtures(values, true),
    quotes: parseAdditionalQuotes(values, true),
    //selectedAreas: parseAreas(values.serviceAreas, true),
    frequency: {
      selecteds: parseDays('DaysCleaned', values, true),
      timeWindow: values['TimeOfDay'],
    },
    secondFrequency: {
      selecteds: parseDays('SecondDaysCleaned', values, true),
      timeWindow: values['SecondTimeOfDay'],
    },
    serviceAreas: parseAreas(values, true),
    porterDays: parseDays('DaysPotter', values, true),
    protectionPackages: parseProtectionPackages(values, true),
    secondProtectionPackages: parseProtectionPackages(values, true, 2),
  };

  parsedValues.alarm = booleanValue(parseYesNoToBoolean(parsedValues.alarm));
  parsedValues.key = booleanValue(parseYesNoToBoolean(parsedValues.key));
  parsedValues.bidDate = new Date(parsedValues.bidDate);
  parsedValues.facilityType = facilityTypesKeys[values['FacilityType']];
  parsedValues.proposalAddressedTo = values['AddressedToWalkThru']
    ? 'walkThru'
    : 'decisionMaker';

  parsedValues.frequencyService = values['IsMonthlyCleaning']
    ? 'monthly'
    : 'weekly';

  parsedValues.lastUpdate = new Date(parsedValues.lastUpdate);
  parsedValues.bidPriceHourSecondFrequency =
    values['BidVersion'] == 2
      ? parsedValues.bidPriceHourSecondFrequency
      : parsedValues.bidPriceHour;

  parsedValues.bidOverrideBasePrice =
    values['BidVersion'] == 2 ? parsedValues.bidOverrideBasePrice : undefined;
  parsedValues.secondBidOverrideBasePrice =
    values['BidVersion'] == 2
      ? parsedValues.secondBidOverrideBasePrice
      : undefined;
  parsedValues.smartCleanOverridePrice =
    values['BidVersion'] == 2
      ? parsedValues.smartCleanOverridePrice
      : undefined;

  parsedValues.overridePrice =
    values['BidVersion'] == 2 ? parsedValues.overridePrice : undefined;
  parsedValues.secondOverride =
    values['BidVersion'] == 2 ? parsedValues.secondOverride : undefined;

  return parsedValues;
};

export const parseGetToPost = (getData: any) => {
  const postData = { ...getData };
  delete postData['TermsAndConditions'];
  delete postData['BidInformationSheetId'];

  postData['ProposalHolidays'] =
    getData['TermsAndConditions']['HolidaySection'];
  postData['ProposalSection3'] =
    getData['TermsAndConditions']['Section3Section'];
  postData['ProposalSection7'] =
    getData['TermsAndConditions']['Section7Section'] ||
    termsAndConditionsValues['proposalSection7'];
  // 'Client agrees and understands that it is impossible to determine the actual damages caused by its breach of this Agreement. Therefore, any amount due and owed under this Agreement for any remaining part of the term of this Agreement (as though the Agreement had not been terminated) shall be accelerated liquidated damages.';
  postData['ProposalSection8'] =
    getData['TermsAndConditions']['Section8Section'] ||
    termsAndConditionsValues['proposalSection8'];
  // 'Client agrees not to withhold any portion of the monthly Agreement amount for any reason, unless prior written permission is obtained from Company, as the obligation to make payments hereunder is an independent obligation. In the event that enforcement of any obligation owed to Company, is placed in the hands of an attorney for collection, compromise or any other action, Client agrees to pay the reasonable attorney’s fees, cost and necessary disbursements, in addition to any other relief that may be granted.';
  return postData;
};

export const parseAfterPost = (
  postValues: any,
  oldValues: any,
  newId: number
) => {
  const getData = { ...postValues };
  getData['TermsAndConditions'] = oldValues['TermsAndConditions'];
  getData['BidUniqueID'] = newId;
  delete getData['OriginalBidInformationSheetId'];
  return getData;
};
