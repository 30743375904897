import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
`;

interface IBoxAccordion {
  showBorder?: boolean;
}

export const BoxAccordion = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin: 15px; */
  padding: 15px 15px 15px 0;
  margin-left: 15px;
  font-weight: bold;
  cursor: pointer;
  ${({ showBorder }: IBoxAccordion) =>
    showBorder ? `border-bottom: 1px solid #ccc; padding-bottom: 15px;` : ''}
`;

interface IItemContent {
  border?: boolean;
  margin?: boolean;
}

export const ItemWrapper = styled.div`
  display: flex;
  position: relative;
`;
export let ItemContent;
ItemContent = styled.div`
  width: 98%;
  margin-left: 15px;
  ${({ border }: IItemContent) =>
    border ? 'border-bottom: 1px solid #ccc; padding-bottom: 15px;' : ''}
  ${({ margin }: IItemContent) => (margin ? 'margin: 10px' : '')}

  ${ItemContent} ${BoxAccordion} {
    margin-right: 15px;
  }
`;

export const IconContent = styled.div`
  display: flex;
  width: 50px;
  justify-content: flex-end;
  cursor: pointer;
`;

export const IconArea = styled.div`
  display: flex;
  justify-content: flex-end;

  svg {
    width: auto;
  }
`;

export const WrapperRemove = styled.div`
  align-self: center;
`;
