import axios, { AxiosRequestConfig } from 'axios';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import * as Sentry from '@sentry/react';

import {ANAGO_USER_ACCESS_TOKEN, ANAGO_USER_REFRESH_TOKEN, ANAGO_USER_EXPIRE_DATE, ANAGO_CLEANSOURCE_DEV_LOGIN_URL} from './constants';
import {setCookie, getCookie, getAllCookies} from './cookiesUtils';
import EventEmitter from './events';
import renewAuth from './renewAuth';


const publicEndpoints = [
  '/Client',
  '/ServerMaintenance',
  '/CheckMaintenceMode',
  '/itsalive',
  '/GetAllFranchises',
  '/SetSignedDocument',
  '/CheckPendDocSpecificBid',
  '/CheckIsGeneratingPDF',
  '/GetBlankPdfUrl',
  '/getdocumentlink',
  '/DoBlankPdf',
  '/DoSSPDFWithSelection',
  '/DoSSPDF',
  'InspectionReport/getPdfs',
  'InspectionReport/incrementAccess',
  'InspectionReport/getImageUri'
]

const verifyAuth = async (): Promise<void> => {
  try {
    await renewAuth({
      accessToken: getCookie(ANAGO_USER_ACCESS_TOKEN) || '',
      savedRefreshToken: getCookie(ANAGO_USER_REFRESH_TOKEN) || '',
      expireDate: getCookie(ANAGO_USER_EXPIRE_DATE) || '',
    });
  } catch (err) {
    // console.log({err})
    window.location.href = env.REACT_APP_CLEAN_SOURCE_LOGIN_URL || ANAGO_CLEANSOURCE_DEV_LOGIN_URL;
    if (err && err.status != 401 && err.status != 403) {
      throw err;
    }
  }
};

const env = runtimeEnv();

export const apiUrl =
  env.REACT_APP_API_HOST ||
  // 'https://forms-api.dev.anagocleansource-test.com/api';
 'https://forms-api.next.anagocleansource-test.com/api';

const axiosInstance = axios.create({
  baseURL: apiUrl,
  // withCredentials: true
});

const logSentry = (obj) => {
  try{
    const sentryLog = {
      ...JSON.parse(sessionStorage.getItem('SENTRY_DATA')),
    };
  
    if (obj.request.data) {
      sentryLog['REQUEST_PAYLOAD_CONTENT'] = {
        data: obj.request.data,
        params: obj.request.params,
        baseURL: obj.request.baseURL,
        url: obj.request.url,
        method: obj.request.method,
        headers: obj.request.headers,
        config: obj.request.config,
        errorConfig: {
          ...obj.config,
          headers: obj.config.headers,
        },
      };
    }
  
    if (obj.response) {
      sentryLog['SERVER_RESPONSE_CONTENT'] = {
        data: obj.response.data,
        status: obj.response.status,
        statusText: obj.response.statusText,
        headers: obj.response.headers,
        config: obj.response.config,
        errorConfig: {
          ...obj.config,
          headers: obj.config.headers,
        },
      };
    }
  
    Sentry.captureException(obj, {
      extra: sentryLog,
    });
  }catch(e){
    console.log({e})
    EventEmitter.emit('GENERIC_ERROR', e);
  }
  
};

axiosInstance.interceptors.request.use(
  async (config): Promise<AxiosRequestConfig> => {

    if(publicEndpoints.filter(endpoint => config.url.includes(endpoint)).length == 0){
      await verifyAuth();
      
      config.headers.Authorization = `Bearer ${getCookie(
        ANAGO_USER_ACCESS_TOKEN,
      )}`;

      const systemVersion = document.querySelector('meta[name="system-version"]').content;

      config.headers["SystemVersion"] = systemVersion;
    }

    return config;
  },
  async (err) => {
    logSentry(err);
    EventEmitter.emit('SERVER_ERROR', err.response);
    if (err.status === 403) {
      await verifyAuth();
      
      err.response.config.headers.Authorization = `Bearer ${getCookie(
        ANAGO_USER_ACCESS_TOKEN,
      )}`;

      const systemVersion = document.querySelector('meta[name="system-version"]').content;

      err.response.config.headers["SystemVersion"] = systemVersion;

      return axios(err.response.config);
    }

    if (err.status === 401) {
      Promise.reject({ ...err, ...err.response });
    }

    return Promise.reject({ ...err, ...err.response });
  }
);

axiosInstance.interceptors.response.use(
  (res) => res,
  (err) => {
    if(!err.__proto__.__CANCEL__){
      logSentry(err);
      EventEmitter.emit('SERVER_ERROR', err.response);
    }
    return Promise.reject({ ...err, ...err.response });
  }
);

export { axiosInstance };
