import styled from 'styled-components';

import MaskedInput from 'react-text-mask';

interface IBoxInput {
  center: boolean;
  width?: string;
  flex?: boolean;
  color?: string;
}

export const Input = styled.input`
  height: 40px;
  outline: none;
  width: ${({ width }: IBoxInput) => `${width || '60px'}`};
  border: 0;
  ${({ center }: IBoxInput) => (center ? 'text-align: center' : '')}
  ${({ flex }: IBoxInput) => (flex ? `flex: 1;` : '')}
  ${({ color }: IBoxInput) => (color ? `color: ${color};` : '')}

  &:disabled {
    -webkit-text-fill-color: rgba(0,0,0,0.4);
    -webkit-opacity: 1;
    color: rgba(0,0,0,0.4);
    background-color: #e8e8e8;
  }
`;

export const InputMasked = styled(MaskedInput)`
  height: 40px;
  outline: none;
  width: ${({ width }: IBoxInput) => `${width || '60px'}`};
  border: 0;
  ${({ center }: IBoxInput) => (center ? 'text-align: center' : '')}
  ${({ flex }: IBoxInput) => (flex ? `flex: 1;` : '')}
  ${({ color }: IBoxInput) => (color ? `color: ${color};` : '')}

  &:disabled {
    -webkit-text-fill-color: rgba(0,0,0,0.4);
    -webkit-opacity: 1;
    color: rgba(0,0,0,0.4);
    background-color: #e8e8e8;
  }
`;
