import React, { memo, useCallback } from 'react';
import { withFormik, FormikProps } from 'formik';
import { IBidServiceArea } from 'modules/BidInfoForm/interfaces';
import {
  serviceAreaValues,
  serviceAreas,
} from 'modules/shared/bidDefaultValues';

import { ContentInput, CheckboxList, useFormProps } from 'core/components';

import './styles.scss';

type IProps = FormikProps<IBidServiceArea>;
const ServiceArea = memo(({}: IProps) => {
  const { formValues, onChange } = useFormProps();
  const onBlur = useCallback(
    (name: string) => () => onChange(name, formValues[name]),
    [formValues, onChange]
  );

  return (
    <div className="service-area-root">
      <div className="service-area-content" style={{paddingTop: 0}}>
        {/* <ContentInput width="100%">
          <h3 className="service-area-title">Service Areas</h3>
        </ContentInput> */}

        <CheckboxList
          options={serviceAreas}
          optionValue="value"
          optionLabel="label"
          itemsWidth="26.33%"
          selecteds={formValues.serviceAreas}
          name="serviceAreas"
          onChange={onChange}
          additionalValue={formValues.otherAreas}
          additionalOptionName="otherAreas"
          additionalOptionLabel="Other Area(s)"
          onChangeAdditional={onChange}
          onBlurAdditional={onBlur('otherAreas')}
          disabled={formValues.status === 2}
        />
      </div>
    </div>
  );
});

const mapForm = {
  mapPropsToValues: () => ({
    ...serviceAreaValues,
  }),
  handleSubmit: () => '',
};

const EnhancedJanitorial =
  withFormik<any, IBidServiceArea>(mapForm)(ServiceArea);

export { EnhancedJanitorial as ServiceArea };
