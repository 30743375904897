export const isWebView = () => {
  const standalone = (window.navigator as any).standalone;
  const userAgent = window.navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const firefox = /firefox/.test(userAgent);

  if (!standalone && !safari && !firefox) {
    // uiwebview
    return true;
  }

  if (!standalone && (safari || firefox)) {
    // browser
    return false;
  }
};

export const isIpad = () => {
  var userAgent = window.navigator.userAgent;
  // alert(userAgent);
  // return (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/Macintosh/i)) && userAgent.match(/Mac OS X/i);
  return userAgent.match(/Mac OS X/i);
};

export const getBrowserInfo = () => {
  var ua= navigator.userAgent, tem, 
    M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE '+(tem[1] || '');
    }
    if(M[1]=== 'Chrome'){
        tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
        if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
    return M.join(' ');
}

export const isSafari14 = () => {
  return getBrowserInfo().toLowerCase().includes('safari 14');
}
