export const Types = {
  SET_FIRST_FORM: 'inspectionForm/SET_FIRST_FORM',
  SET_SITE_INFORMATION: 'inspectionForm/SET_SITE_INFORMATION',
  SET_NOTE: 'inspectionForm/SET_NOTE',
  SET_AREA: 'inspectionForm/SET_AREA',
  SET_ITEM: 'inspectionForm/SET_ITEM',
  SET_ERROR: 'inspectionForm/SET_ERROR',
  SET_TOKEN: 'inspectionForm/SET_TOKEN',
  SET_USER_AND_FRANCHISE_ID: 'inspectionForm/SET_USER_AND_FRANCHISE_ID',
  OPEN_DRAFT: 'inspectionForm/OPEN_DRAFT',
  OPEN_TEMPLATE: 'inspectionForm/OPEN_TEMPLATE',
  OPEN_SENT: 'inspectionForm/OPEN_SENT',
  SET_IS_SENT: 'inspectionForm/SET_IS_SENT',
  SET_IS_NOT_SENT: 'inspectionForm/SET_IS_NOT_SENT',
  SET_IS_TEMPLATE: 'inspectionForm/SET_IS_TEMPLATE',
  TRANSFORM_INTO_DRAFT: 'inspectionForm/TRANSFORM_INTO_DRAFT',
  REVERT_TRANSFORMED_TO_DRAFT_STATE:
    'inspectionForm/REVERT_TRANSFORMED_TO_DRAFT_STATE',
  SET_INSPECTION_REPORT_ID: 'inspectionForm/SET_INSPECTION_REPORT_ID',

  OPEN_EXIT_MODAL: 'inspectionForm/OPEN_EXIT_MODAL',
  CLOSE_EXIT_MODAL: 'inspectionForm/CLOSE_EXIT_MODAL',

  SET_AREA_SELECTED: 'inspectionForm/SET_AREA_SELECTED',
  CLEAR_AREA_SELECTED: 'inspectionForm/CLEAR_AREA_SELECTED',

  ADD_AREA_REQUEST: 'inspectionForm/ADD_AREA_REQUEST',
  REMOVE_AREA_REQUEST: 'inspectionForm/REMOVE_AREA_REQUEST',

  ADD_PRE_DEF_AREA: 'inspectionForm/ADD_PRE_DEF_AREA',

  ADD_AREA_ITEM_REQUEST: 'inspectionForm/ADD_AREA_ITEM_REQUEST',
  UPDATE_AREA_ITEM_RANGE_REQUEST:
    'inspectionForm/UPDATE_AREA_ITEM_RANGE_REQUEST',
  REMOVE_AREA_ITEM_REQUEST: 'inspectionForm/REMOVE_AREA_ITEM_REQUEST',
  UPDATE_AREA_ITEMS: 'inspectionForm/UPDATE_AREA_ITEMS',
  UPDATE_AREA: 'inspectionForm/UPDATE_AREA',

  ADD_AREA_PHOTO_REQUEST: 'inspectionForm/ADD_AREA_PHOTO_REQUEST',
  REMOVE_AREA_PHOTO_REQUEST: 'inspectionForm/REMOVE_AREA_PHOTO_REQUEST',

  CALCULATE_RATE_REQUEST: 'inspectionForm/CALCULATE_RATE_REQUEST',
  CALCULATE_RATE_SUCCESS: 'inspectionForm/CALCULATE_RATE_SUCCESS',
  CALCULATE_RATE_FAILURE: 'inspectionForm/CALCULATE_RATE_FAILURE',

  UPDATE_DUPLICATED_AREAS: 'inspectionForm/UPDATE_DUPLICATED_AREAS',

  SAVE_REPORT_REQUEST: 'inspectionForm/SAVE_REPORT_REQUEST',
  SAVE_REPORT_SUCCESS: 'inspectionForm/SAVE_REPORT_SUCCESS',
  SAVE_REPORT_FAILURE: 'inspectionForm/SAVE_REPORT_FAILURE',

  OPEN_SENT_MODAL: 'inspectionForm/OPEN_SENT_MODAL',

  SEND_EMAIL_REQUEST: 'inspectionForm/SEND_EMAIL_REQUEST',
  SEND_EMAIL_SUCCESS: 'inspectionForm/SEND_EMAIL_SUCCESS',
  SEND_EMAIL_FAILURE: 'inspectionForm/SEND_EMAIL_FAILURE',

  VALIDATION_FAILURE: 'inspectionForm/VALIDATION_FAILURE',

  GET_EDIT_FORM_REQUEST: 'inspectionForm/GET_EDIT_FORM_REQUEST',
  GET_EDIT_FORM_SUCCESS: 'inspectionForm/GET_EDIT_FORM_SUCCESS',
  GET_EDIT_FORM_FAILURE: 'inspectionForm/GET_EDIT_FORM_FAILURE',
  GET_PUBLIC_EDIT_FORM_REQUEST: 'inspectionForm/GET_PUBLIC_EDIT_FORM_REQUEST',

  SET_RATING_MODAL_STATE: 'inspectionForm/SET_RATING_MODAL_STATE',
  SET_TEMPLATE_MODAL: 'inspectionForm/SET_TEMPLATE_MODAL',
  SET_NOTES_MODAL_STATE: 'inspectionForm/SET_NOTES_MODAL_STATE',
  SET_PHOTOS_MODAL_STATE: 'inspectionForm/SET_PHOTOS_MODAL_STATE',
  SET_AREAS_MODAL_STATE: 'inspectionForm/SET_AREAS_MODAL_STATE',
  SET_ADD_ITEM_MODAL_STATE: 'inspectionForm/SET_ADD_ITEM_MODAL_STATE',
  SET_PDF_MODAL_STATE: 'inspectionForm/SET_PDF_MODAL_STATE',

  SET_WARNING_MODAL_STATE: 'inspectionForm/SET_WARNING_MODAL_STATE',

  GET_INSPECTIONS_REQUEST: 'inspectionMenu/GET_INSPECTIONS_REQUEST',
  GET_INSPECTIONS_SUCCESS: 'inspectionMenu/GET_INSPECTIONS_SUCCESS',
  GET_INSPECTIONS_FAILURE: 'inspectionMenu/GET_INSPECTIONS_FAILURE',

  SORT_INSPECTIONS: 'inspectionMenu/SORT_INSPECTIONS',

  GET_NEXT_INSPECTIONS_PAGE_REQUEST:
    'inspectionMenu/GET_NEXT_INSPECTIONS_PAGE_REQUEST',
  GET_NEXT_INSPECTIONS_PAGE_SUCCESS:
    'inspectionMenu/GET_NEXT_INSPECTIONS_PAGE_SUCCESS',

  ARCHIVE_FORM_REQUEST: 'inspectionMenu/ARCHIVE_FORM_REQUEST',
  ARCHIVE_FORM_SUCCESS: 'inspectionMenu/ARCHIVE_FORM_SUCCESS',
  ARCHIVE_FORM_FAILURE: 'inspectionMenu/ARCHIVE_FORM_FAILURE',

  SET_FORM_ERRORS: 'inspectionMenu/SET_FORM_ERRORS',
  SET_NEEDS_SAVING: 'inspectionMenu/SET_NEEDS_SAVING',

  SET_FORM_CLICKED: 'inspectionMenu/SET_FORM_CLICKED',

  SET_INSPECTED_BY: 'inspectionMenu/SET_INSPECTED_BY',

  SET_LOADING_STATE: 'inspectionMenu/SET_LOADING_STATE'
};
