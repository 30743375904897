import styled from 'styled-components';

type TBox = {
  checked: boolean;
  disabled?: boolean;
  borderColor?: string;
};

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1.0')};
  pointer-events: ${({ disabled, pointerEvents }) =>
    disabled ? (pointerEvents ? pointerEvents : 'none') : 'auto'};
`;

export const BoxLabel = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  padding-left: 10px;
  @media (min-width: 769px) {
    // padding-left: 0;
  }
`;

export const Box = styled.div<TBox>`
  cursor: pointer;
  width: 34px;
  min-width: 34px;
  height: 34px;
  min-height: 34px;
  border-radius: 8px;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ checked }) => (checked ? '#fff' : 'transparent')};
  border-color: ${({ borderColor }) => (borderColor ? borderColor : '#fff')};
`;
