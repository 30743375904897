import React from 'react';

import MaskedInput from 'react-text-mask';

import './styles.scss';
import './floatingLabel.scss';
import { formatNumberByMask, capitalizeInput } from 'core/utils';

interface IInputLabel {
  ref?: any;
  id?: string;
  value: any;
  name: string;
  label: string;
  onChange: Function;
  onBlur?: any;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  mask?: any;
  maskParams?: any;
  type?: string;
  maxLength?: number;
  center?: boolean;
  onFocusSelect?: boolean;
  capitalizeFunction?: Function;
  placeholder?: string;
  style?: any;
  autoCapitalize?: string;
  dataCy?: string;
  hasCounter?: boolean;
}

export const InputFloatLabel = (props: IInputLabel) => {
  const {
    ref,
    id,
    disabled,
    name,
    dataCy,
    label,
    onChange,
    onBlur,
    className,
    inputClassName,
    mask,
    maskParams,
    type,
    maxLength,
    center,
    onFocusSelect = maskParams
      ? maskParams.type === 'number' || maskParams.type === 'tel'
      : false,
    capitalizeFunction = capitalizeInput,
    placeholder,
    style,
    autoCapitalize,
    hasCounter,
    ...otherProps
  } = props;
  const capitalize = (value: string) =>
    capitalizeFunction ? capitalizeFunction(value) : value;

  const value =
    typeof props.value === 'string' ? capitalize(props.value) : props.value;

  const adjustValue = (value: string) => {
    if (maskParams && maskParams.prefix) {
      value = value.replace(maskParams.prefix, '');
    }
    return capitalize(mask ? value.replace(/_/g, '') : value);
  };

  const onBlurWrapper = (e: any) => {
    // if (e.target.value && maskParams) {
    //   onChange(name, formatNumberByMask(e.target.value, maskParams));
    // }
    // if (mask instanceof Function) {
    //   const maskResult = mask(e.target.value);
    //   if (
    //     maskResult instanceof Array &&
    //     maskResult.filter((item) => typeof item == 'string').length >
    //       e.target.value.replace('_', '').length
    //   ) {
    //     onChange(name, '');
    //   }
    // }
    if (onBlur) onBlur();
  };

  const onKeyDown = (e: any) => {
    let cursorPos = Number(e.target.selectionStart);
    let keyCode = Number(e.keyCode);

    if (cursorPos === 0 && keyCode === 194) {
      e.preventDefault();
      e.target.value = '0.';
      e.target.selectionStart = 2;
      if (onChange) {
        onChange(name, e.target.value);
      }
    }
  };

  return (
    <div className={`input-label-content ${className || ''}`} style={style}>
      <div className="div-float-label" data-cy={`${name || dataCy}_wrapper`}>
        {mask ? (
          <MaskedInput
            ref={ref}
            disabled={disabled}
            className={`${center ? 'float-input-center' : ''}${
              inputClassName || ''
            }`}
            data-cy={name || dataCy}
            id={id || name}
            value={value !== null && value !== undefined ? value : ''}
            onKeyDown={onKeyDown}
            onChange={(e) => onChange(name, adjustValue(e.target.value))}
            placeholder={placeholder || ' '}
            type={maskParams && maskParams.type ? maskParams.type : type}
            mask={mask}
            onBlur={onBlur ? onBlurWrapper : () => {}}
            maxLength={maxLength}
            onFocus={(e: any) => onFocusSelect && e.target.select()}
            autoCapitalize={autoCapitalize || 'words'}
          />
        ) : (
          <input
            data-cy={name || dataCy}
            ref={ref}
            disabled={disabled}
            className={`${center ? 'float-input-center' : ''}${
              inputClassName || ''
            }`}
            id={id || name}
            value={value || ''}
            onKeyDown={onKeyDown}
            onChange={(e) => onChange(name, capitalize(e.target.value))}
            placeholder={placeholder || ' '}
            type={type}
            onBlur={onBlur ? onBlur : () => {}}
            maxLength={maxLength}
            onFocus={(e: any) => onFocusSelect && e.target.select()}
            autoCapitalize={autoCapitalize || 'words'}
          />
        )}

        <label htmlFor={id || name}>{label}</label>
        {hasCounter && maxLength && (
          <span
            style={{
              left: 'auto',
              right: 0,
              bottom: '-20px',
              top: 'auto',
            }}
          >
            {`${value.length}/${maxLength}`}
          </span>
        )}
      </div>
    </div>
  );
};
