import React from 'react';
// redux
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { InspectFormActions } from 'modules/InspectForm/redux/actions';
// interfaces
import { IReduxState } from 'core/interfaces';
import {
  IStateToProps,
  IDispatchToProps
} from 'modules/InspectForm/interfaces';
// ui
import './styles.scss';
import './floatingLabel.scss';

import MaskedInput from 'react-text-mask';
import getError from '../../../../../../core/utils/getError';

interface IOwnProps {
  toggleNeedsSaving(): void;
  onEnter: Function;
}

type IProps = IDispatchToProps & IStateToProps & IOwnProps;

class SiteInformationForm extends React.PureComponent<IProps> {
  public state = {
    AccountName: '',
    AccountAddress: '',
    Suite: '',
    City: '',
    State: '',
    Zip: '',
    DaysCleanedMonday: false,
    DaysCleanedTuesday: false,
    DaysCleanedWednesday: false,
    DaysCleanedThursday: false,
    DaysCleanedFriday: false,
    DaysCleanedSaturday: false,
    DaysCleanedSunday: false,
    Contact: '',
    Phone: '',
    FranchiseOwnerName: '',
    Time: ''
  };

  public onKeyDown = (id: string) => (e: any) => {
    if (e!.key === 'Enter') {
      if (id === '') {
        document.activeElement.blur()
      } else {
        this.props.onEnter(id)
      };
    }
  };

  public componentDidMount() {
    const {
      editing,
      AccountName,
      AccountAddress,
      Suite,
      City,
      State,
      Zip,
      DaysCleanedMonday,
      DaysCleanedTuesday,
      DaysCleanedWednesday,
      DaysCleanedThursday,
      DaysCleanedFriday,
      DaysCleanedSaturday,
      DaysCleanedSunday,
      Contact,
      Phone,
      Time,
      FranchiseOwnerName
    } = this.props;
    if (editing && AccountName) {
      this.setState({
        AccountName,
        AccountAddress,
        Suite,
        City,
        State,
        Zip,
        DaysCleanedMonday,
        DaysCleanedTuesday,
        DaysCleanedWednesday,
        DaysCleanedThursday,
        DaysCleanedFriday,
        DaysCleanedSaturday,
        DaysCleanedSunday,
        Contact,
        Phone,
        FranchiseOwnerName,
        Time
      });
    }
  }

  public componentWillReceiveProps(nextProps: IProps) {
    const {
      editing,
      AccountName,
      AccountAddress,
      Suite,
      City,
      State,
      Zip,
      DaysCleanedMonday,
      DaysCleanedTuesday,
      DaysCleanedWednesday,
      DaysCleanedThursday,
      DaysCleanedFriday,
      DaysCleanedSaturday,
      DaysCleanedSunday,
      Contact,
      Phone,
      Time,
      FranchiseOwnerName
    } = nextProps;
    if (editing && AccountName !== this.props.AccountName) {
      this.setState({
        AccountName,
        AccountAddress,
        Suite,
        City,
        State,
        Zip,
        DaysCleanedMonday,
        DaysCleanedTuesday,
        DaysCleanedWednesday,
        DaysCleanedThursday,
        DaysCleanedFriday,
        DaysCleanedSaturday,
        DaysCleanedSunday,
        Contact,
        Phone,
        FranchiseOwnerName,
        Time
      });
    }
  }

  public render() {
    const {
      AccountName,
      AccountAddress,
      Suite,
      City,
      State,
      Zip,
      DaysCleanedMonday,
      DaysCleanedTuesday,
      DaysCleanedWednesday,
      DaysCleanedFriday,
      DaysCleanedSaturday,
      DaysCleanedSunday,
      DaysCleanedThursday,
      Contact,
      Phone,
      FranchiseOwnerName,
      Time
    } = this.state;
    const { setSiteInformation, isSent, errors } = this.props;
    return (
      <div className='second-form-container'>
        <div className='title-row-div'>
          <h2>Site Information</h2>
        </div>

        <div className='second-form-content-container'>
          <div
            className={`has-float-label ${
              getError('AccountName', errors).className
            }`}
          >
            <input
              id='company-name'
              placeholder=' '
              value={AccountName}
              onChange={e => {
                this.setState({ AccountName: e.target.value });
                this.props.toggleNeedsSaving();
              }}
              onBlur={() => setSiteInformation(this.state)}
              disabled={isSent}
              onKeyDown={this.onKeyDown('walk-thru-contact-name')}
            />
            <label htmlFor='company-name'>
              Company Name
              {getError('AccountName', errors).showError &&
                ` (${getError('AccountName', errors).msg})`}
            </label>
          </div>

          <div className='second-form-rows-container'>
            <div className='walk-thru-contact-name-container'>
              <div
                className={`has-float-label ${
                  getError('Contact', errors).className
                }`}
              >
                <input
                  id='walk-thru-contact-name'
                  placeholder=' '
                  value={Contact}
                  onChange={e => {
                    this.setState({ Contact: e.target.value });
                    this.props.toggleNeedsSaving();
                  }}
                  onBlur={() => setSiteInformation(this.state)}
                  disabled={isSent}
                  onKeyDown={this.onKeyDown('phone')}
                />
                <label htmlFor='walk-thru-contact-name'>
                  Walk-thru Contact
                  {getError('Contact', errors).showError &&
                    ` (${getError('Contact', errors).msg})`}
                </label>
              </div>
            </div>

            <div className='walk-thru-contact-phone-container'>
              <div
                className={`has-float-label ${
                  getError('Phone', errors).className
                }`}
              >
                <MaskedInput
                  id='phone'
                  mask={[
                    '(',
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    ')',
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/
                  ]}
                  placeholder=' '
                  value={Phone}
                  onChange={e => {
                    this.setState({ Phone: e.target.value });
                    this.props.toggleNeedsSaving();
                  }}
                  onBlur={() => setSiteInformation(this.state)}
                  disabled={isSent}
                  onKeyDown={this.onKeyDown('address')}
                />
                <label htmlFor='phone'>
                  Phone
                  {getError('Phone', errors).showError &&
                    ` (${getError('Phone', errors).msg})`}
                </label>
              </div>
            </div>
          </div>

          <div className='second-form-rows-container'>
            <div className='address-field-container'>
              <div
                className={`has-float-label ${
                  getError('AccountAddress', errors).className
                }`}
              >
                <input
                  id='address'
                  placeholder=' '
                  value={AccountAddress}
                  onChange={e => {
                    this.setState({ AccountAddress: e.target.value });
                    this.props.toggleNeedsSaving();
                  }}
                  onBlur={() => setSiteInformation(this.state)}
                  disabled={isSent}
                  onKeyDown={this.onKeyDown('suite-number')}
                />
                <label htmlFor='address'>
                  Address
                  {getError('AccountAddress', errors).showError &&
                    ` (${getError('AccountAddress', errors).msg})`}
                </label>
              </div>
            </div>

            <div className='suite-field-container'>
              <div
                className={`has-float-label ${
                  getError('Suite', errors).className
                }`}
              >
                <input
                  id='suite-number'
                  placeholder=' '
                  value={Suite}
                  onChange={e => {
                    this.setState({ Suite: e.target.value });
                    this.props.toggleNeedsSaving();
                  }}
                  onBlur={() => setSiteInformation(this.state)}
                  disabled={isSent}
                  onKeyDown={this.onKeyDown('city')}
                />
                <label htmlFor='suite-number'>
                  Suite #
                  {getError('Suite', errors).showError &&
                    ` (${getError('Suite', errors).msg})`}
                </label>
              </div>
            </div>
          </div>

          <div className='second-form-rows-container'>
            <div className='city-field-container'>
              <div
                className={`has-float-label ${
                  getError('City', errors).className
                }`}
              >
                <input
                  id='city'
                  placeholder=' '
                  value={City}
                  onChange={e => {
                    this.setState({ City: e.target.value });
                    this.props.toggleNeedsSaving();
                  }}
                  onBlur={() => setSiteInformation(this.state)}
                  disabled={isSent}
                  onKeyDown={this.onKeyDown('state-or-province')}
                />
                <label htmlFor='city'>
                  City
                  {getError('City', errors).showError &&
                    ` (${getError('City', errors).msg})`}
                </label>
              </div>
            </div>

            <div className='state-field-container'>
              <div
                className={`has-float-label ${
                  getError('State', errors).className
                }`}
              >
                <input
                  id='state-or-province'
                  className='auto-capitalize'
                  placeholder=' '
                  maxLength={2}
                  value={State}
                  onChange={e => {
                    this.setState({ State: e.target.value });
                    this.props.toggleNeedsSaving();
                  }}
                  onBlur={() => setSiteInformation(this.state)}
                  disabled={isSent}
                  onKeyDown={this.onKeyDown('postal-code')}
                />
                <label htmlFor='state-or-province'>
                  State or Province
                  {getError('State', errors).showError &&
                    ` (${getError('State', errors).msg})`}
                </label>
              </div>
            </div>

            <div className='postal-code-field-container'>
              <div
                className={`has-float-label ${
                  getError('Zip', errors).className
                }`}
              >
                <MaskedInput
                  id='postal-code'
                  mask={rawInputValue => {
                    const firstLetter = rawInputValue.charAt(0);
                    if (isNaN(parseInt(firstLetter))) {
                      // if the first character is a letter
                      // canadian zip code
                      return [
                        /[A-Z]/i,
                        /\d/,
                        /[A-Z]/i,
                        ' ',
                        /\d/,
                        /[A-Z]/i,
                        /\d/
                      ];
                    } else {
                      // american zip code
                      return [/\d/, /\d/, /\d/, /\d/, /\d/];
                    }
                  }}
                  placeholder=' '
                  value={Zip}
                  onChange={e => {
                    this.setState({ Zip: e.target.value });
                    this.props.toggleNeedsSaving();
                  }}
                  onBlur={() => setSiteInformation(this.state)}
                  disabled={isSent}
                  onKeyDown={this.onKeyDown('time-window')}
                />
                <label htmlFor='postal-code'>
                  Postal Code
                  {getError('Zip', errors).showError &&
                    ` (${getError('Zip', errors).className})`}
                </label>
              </div>
            </div>
          </div>

          <div className='second-form-rows-container'>
            <div className='days-cleaned-container'>
              <div className='days-cleaned-div'>
                <div className='div-field'>
                  <p>Days Cleaned</p>
                </div>
                <div className='div-buttons'>
                  <button
                    disabled={isSent}
                    className={`${
                      DaysCleanedSunday
                        ? 'button-selected'
                        : 'button-unselected-day'
                    }`}
                    onClick={() =>
                      !isSent &&
                      this.setState({ DaysCleanedSunday: !DaysCleanedSunday })
                    }
                  >
                    Sun
                  </button>
                  <button
                    disabled={isSent}
                    className={`${
                      DaysCleanedMonday
                        ? 'button-selected'
                        : 'button-unselected-day'
                    }`}
                    onClick={() =>
                      !isSent &&
                      this.setState({ DaysCleanedMonday: !DaysCleanedMonday })
                    }
                  >
                    Mon
                  </button>
                  <button
                    disabled={isSent}
                    className={`${
                      DaysCleanedTuesday
                        ? 'button-selected'
                        : 'button-unselected-day'
                    }`}
                    onClick={() =>
                      !isSent &&
                      this.setState({ DaysCleanedTuesday: !DaysCleanedTuesday })
                    }
                  >
                    Tue
                  </button>
                  <button
                    disabled={isSent}
                    className={`${
                      DaysCleanedWednesday
                        ? 'button-selected'
                        : 'button-unselected-day'
                    }`}
                    onClick={() =>
                      !isSent &&
                      this.setState({
                        DaysCleanedWednesday: !DaysCleanedWednesday
                      })
                    }
                  >
                    Wed
                  </button>
                  <button
                    disabled={isSent}
                    className={`${
                      DaysCleanedThursday
                        ? 'button-selected'
                        : 'button-unselected-day'
                    }`}
                    onClick={() =>
                      !isSent &&
                      this.setState({
                        DaysCleanedThursday: !DaysCleanedThursday
                      })
                    }
                  >
                    Thu
                  </button>
                  <button
                    disabled={isSent}
                    className={`${
                      DaysCleanedFriday
                        ? 'button-selected'
                        : 'button-unselected-day'
                    }`}
                    onClick={() =>
                      !isSent &&
                      this.setState({ DaysCleanedFriday: !DaysCleanedFriday })
                    }
                  >
                    Fri
                  </button>
                  <button
                    disabled={isSent}
                    className={`${
                      DaysCleanedSaturday ? 'button-selected' : ''
                    }`}
                    onClick={() =>
                      !isSent &&
                      this.setState({
                        DaysCleanedSaturday: !DaysCleanedSaturday
                      })
                    }
                  >
                    Sat
                  </button>
                </div>
              </div>
            </div>
            <div className='time-window-container'>
              <div
                className={`has-float-label ${
                  getError('Time', errors).className
                }`}
              >
                <input
                  id='time-window'
                  placeholder=' '
                  value={Time}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({ Time: e.target.value });
                    this.props.toggleNeedsSaving();
                  }}
                  onBlur={() => setSiteInformation(this.state)}
                  disabled={isSent}
                  onKeyDown={this.onKeyDown('franchise-owner')}
                />
                <label htmlFor='time-window'>
                  Time Window
                  {getError('Time', errors).showError &&
                    ` (${getError('Time', errors).msg})`}
                </label>
              </div>
            </div>
          </div>

          <div className='second-form-rows-container'>
            <div
              className={`has-float-label ${
                getError('FranchiseOwnerName', errors).className
              }`}
            >
              <input
                id='franchise-owner'
                placeholder=' '
                value={FranchiseOwnerName}
                onChange={e => {
                  this.setState({ FranchiseOwnerName: e.target.value });
                  this.props.toggleNeedsSaving();
                }}
                onBlur={() => setSiteInformation(this.state)}
                disabled={isSent}
                onKeyDown={this.onKeyDown('')}
              />
              <label htmlFor='franchise-owner'>
                Franchise Owner Name
                {getError('FranchiseOwnerName', errors).showError &&
                  ` (${getError('FranchiseOwnerName', errors).msg})`}
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IReduxState) => ({
  ...state.inspectForm
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...InspectFormActions }, dispatch);

const Connected = connect<
  IStateToProps,
  IDispatchToProps,
  IOwnProps,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(SiteInformationForm);

export { Connected as SiteInformationForm };
