import axios from 'axios';
import {apiUrl} from './requests';
import {ANAGO_USER_ACCESS_TOKEN, ANAGO_USER_REFRESH_TOKEN, ANAGO_USER_EXPIRE_DATE} from './constants';
import {setCookie} from './cookiesUtils';

export const refreshToken = async (
  refreshToken: string,
): Promise<any> => {

  const axiosInstance = axios.create({
    baseURL: apiUrl,
  });

  const { data } = await axiosInstance.post(
    `$/refreshToken?token=${refreshToken}`,
  );

  setCookie(ANAGO_USER_REFRESH_TOKEN, data.refreshToken);
  setCookie(ANAGO_USER_ACCESS_TOKEN, data.accessToken);
  setCookie(ANAGO_USER_EXPIRE_DATE, data.expires);

  return data;
};

export default async ({
    accessToken,
    savedRefreshToken,
    expireDate,
  }): Promise<any> => {
    const unauthorized = {
      title: 'Attention!',
      message: 'Unauthorized.',
      status: 401,
    };
    if (expireDate && new Date(expireDate) < new Date()) {
      throw unauthorized;
    } else if (!accessToken) {
      if (savedRefreshToken) {
        const tokenData = await refreshToken(savedRefreshToken);
        return { tokenData };
      } else {
        throw unauthorized;
      }
    }
};
  