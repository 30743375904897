import React from 'react';

import {
  ButtonGroupContainer,
  ButtonGroupLabel,
  ButtonGroupItem,
  ButtonGroupRadioContainer,
  ButtonGroupRadioLabel,
  ButtonGroupRadioItem,
} from './styles';

interface IButtonGroup {
  value: Array<any> | string | boolean;
  name: string;
  label?: string;
  onChange: Function;
  options: Array<any>;
  optionLabel?: string;
  optionValue?: string;
  disabledValues?: Array<any>;
  type?: 'oneOption' | 'multiple';
  padding?: string;
  style?: any;
  className?: string;
  tabIndexable?: boolean;
  disabled?: boolean;
  buttonsStyle?: any;
  backgroundSelectedColor?: string;
  radio?: string;
}

export const ButtonGroup = ({
  value,
  name,
  label,
  onChange,
  options,
  optionLabel,
  optionValue,
  disabledValues = [],
  disabled = false,
  type = 'multiple',
  padding,
  style,
  className,
  tabIndexable,
  buttonsStyle,
  backgroundSelectedColor,
  radio,
}: IButtonGroup) => {
  const arrayValue = value as Array<any>;

  const onClick = (selected: any) => {
    const result =
      type === 'multiple'
        ? arrayValue.includes(selected)
          ? [...(arrayValue.filter((item) => item !== selected) || [])]
          : [...arrayValue, selected]
        : selected;
    onChange(name, result);
  };

  if (radio) {
    return (
      <ButtonGroupRadioContainer style={style} className={className}>
        {options.map((item: any, index: number) => {
          const props = {
            type: 'button',
            key: `button_group_${
              optionValue ? item[optionValue] : item
            }_${index}`,
            style: { ...buttonsStyle },
            selected:
              type === 'multiple'
                ? arrayValue.includes(optionValue ? item[optionValue] : item)
                : value === (optionValue ? item[optionValue] : item),

            index,
            lastItem: index === options.length - 1,
            onClick: () => {
              if (
                !disabledValues.includes(optionValue ? item[optionValue] : item)
              ) {
                onClick(optionValue ? item[optionValue] : item);
              }
            },
            disabled: disabledValues.includes(
              optionValue ? item[optionValue] : item
            ),
            padding,
            backgroundSelectedColor,
          };

          if (disabled) {
            props.disabled = true;
          }

          if (tabIndexable === false) {
            props['tabIndex'] = -1;
          }

          return (
            <ButtonGroupRadioItem {...props}>
              {optionLabel ? item[optionLabel] : item}
            </ButtonGroupRadioItem>
          );
        })}
      </ButtonGroupRadioContainer>
    );
  }

  return (
    <ButtonGroupContainer style={style} className={className}>
      {label && <ButtonGroupLabel>{label}</ButtonGroupLabel>}
      {options.map((item: any, index: number) => {
        const props = {
          type: 'button',
          key: `button_group_${
            optionValue ? item[optionValue] : item
          }_${index}`,
          style: { ...buttonsStyle },
          selected:
            type === 'multiple'
              ? arrayValue.includes(optionValue ? item[optionValue] : item)
              : value === (optionValue ? item[optionValue] : item),

          index,
          lastItem: index === options.length - 1,
          onClick: () => {
            if (
              !disabledValues.includes(optionValue ? item[optionValue] : item)
            ) {
              onClick(optionValue ? item[optionValue] : item);
            }
          },
          disabled: disabledValues.includes(
            optionValue ? item[optionValue] : item
          ),
          padding,
          backgroundSelectedColor,
        };

        if (disabled) {
          props.disabled = true;
        }

        if (tabIndexable === false) {
          props['tabIndex'] = -1;
        }

        return (
          <ButtonGroupItem {...props}>
            {optionLabel ? item[optionLabel] : item}
          </ButtonGroupItem>
        );
      })}
    </ButtonGroupContainer>
  );
};
