import React from 'react';

import { Box, Content } from './styles';

interface IBoxValue {
  value?: any;
  renderValue?: Function;
  align?: string;
  width?: string;
  color?: string;
  contentStyle?: any;
  maxWidth?: string;
  minWidth?: string;
}

export const BoxValue = ({
  value,
  renderValue,
  align,
  width,
  color,
  contentStyle,
  maxWidth,
  minWidth,
}: IBoxValue) => (
  <Box width={width} minWidth={minWidth} maxWidth={maxWidth} color={color}>
    <Content style={contentStyle} align={align} color={color}>
      {renderValue ? renderValue() : value}
    </Content>
  </Box>
);

export const DisabledBoxValue = (props) => (
  <BoxValue
    width={props.width || '100%'}
    maxWidth={props.maxWidth || props.width}
    contentStyle={{
      display: 'flex',
      background: 'white',
      padding: '0px',
      ...props.contentStyle,
    }}
    renderValue={() => (
      <>
        <span
          style={{
            padding: '10px',
            paddingRight: '2px',
            paddingLeft: '5px',
            width: props.labelWidth || '72%',
            minWidth: props.labelMinWidth || props.labelWidth || '72%',
            ...props.labelStyle,
          }}
        >
          {props.renderLabel ? props.renderLabel() : props.label}
        </span>
        <span
          style={{
            background: '#ededed',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '5px',
            paddingRight: '10px',
            flex: '1',
            textAlign: 'right',
            ...props.valueStyle,
          }}
        >
          {props.value}
        </span>
      </>
    )}
  />
);
