import React, { useState, useEffect } from 'react';
import { Layout, ModalWrapper } from '../../components/layout';
import { Actions } from './styles';
import { Button, ActionAlertModal } from 'core/components';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { reactHtmlReplace } from 'core/utils';
import { termsAndConditionsValues as termsDefaultValues } from 'modules/shared/bidDefaultValues';

import {
  Paragraph,
  DoneButton,
  DoneButtonWrapper,
} from 'modules/BidInfoForm/components/atoms';

import { TextAreaInput, SectionTitle } from '../../styles';

import { toast } from 'react-toastify';

export default ({ data, franchiseData, update, cancel, showing }) => {
  // const termsAndConditionsValues = [];
  const [termsAndConditionsValues, setTermsAndConditionsValues] = useState({});
  const [values, setValues] = useState({});
  const [previewState, setPreviewState] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [termsHtml, setTermsHtml] = useState('');
  const [defaultValues, setDefaultValues] = useState({});

  useEffect(() => {
    if (showing) {
      var el = document.getElementById('terms');
      var ta = el.getElementsByTagName('textarea')[0];
      if (ta) {
        el.scrollTop = ta.offsetTop;
      }
    }
  }, [showing]);

  useEffect(() => {
    if (data && data['IsSS'] && franchiseData) {
      setDefaultValues({
        paragraphV: franchiseData['EWANewProposalSection5'] || '',
      });
      if (Object.keys(values).length == 0) {
        if (data['ProposalSection5']) {
          setValues({
            paragraphV: data['ProposalSection5'],
          });
        } else {
          setValues({
            paragraphV: franchiseData['EWANewProposalSection5'] || '',
          });
        }
      }
    }
  }, [franchiseData, data]);

  useEffect(() => {
    if (data && data['IsSS']) {
      let termsHtml =
        franchiseData && franchiseData['EWANewProposal']
          ? franchiseData['EWANewProposal']
              .replace('[SECTIONV]', '<SECTIONV></SECTIONV>')
              .split('\r\n')
              .join('<br />')
              .split('\n\n')
              .join('<br />')
              .split('\r\r')
              .join('<br />')
          : '';
      termsHtml = termsHtml.replace(
        '[SECTIONVI]',
        franchiseData ? franchiseData['EWANewProposalSection6'] : 'N/A'
      );
      setTermsHtml(termsHtml);
    } else {
      const backendDefaultValues = data ? data['TermsAndConditions'] : [];

      if (data && franchiseData) {
        const tcv = {};
        tcv['Section3Section'] = data['ProposalSection3']
          ? data['ProposalSection3']
          : franchiseData['ProposalSection3'] ||
            termsDefaultValues['proposalSection3'];

        tcv['Section4Section'] = data['ProposalSection4']
          ? data['ProposalSection4']
          : backendDefaultValues['Section4Section'] ||
            termsDefaultValues['proposalSection4'];

        tcv['Section5Section'] = data['ProposalSection5']
          ? data['ProposalSection5']
          : data['IsSS']
          ? franchiseData['EWANewProposalSection5']
          : backendDefaultValues['Section5Section'] ||
            termsDefaultValues['proposalSection5'];

        tcv['Section7Section'] = data['ProposalSection7']
          ? data['ProposalSection7']
          : backendDefaultValues['Section7Section'] ||
            termsDefaultValues['proposalSection7'];

        tcv['Section8Section'] = data['ProposalSection8']
          ? data['ProposalSection8']
          : backendDefaultValues['Section8Section'] ||
            termsDefaultValues['proposalSection8'];

        tcv['HolidaySection'] = data['ProposalHolidays']
          ? data['ProposalHolidays']
          : franchiseData['ProposalHolidays'] ||
            termsDefaultValues['proposalHolidays'];

        setValues(tcv);
        setTermsAndConditionsValues(tcv);
      }

      let termsHtml =
        backendDefaultValues &&
        backendDefaultValues['NewBidProposalCleaningSpecs']
          ? backendDefaultValues['NewBidProposalCleaningSpecs']
              .replace('[HOLIDAYS]', '<HOLIDAYS></HOLIDAYS>')
              .replace('[SECTIONIII]', '<SECTIONIII></SECTIONIII>')
              .replace('[SECTIONIV]', '<SECTIONIV></SECTIONIV>')
              .replace('[SECTIONV]', '<SECTIONV></SECTIONV>')
              .replace('[SECTIONVI]', '<SECTIONVI></SECTIONVI>')
              .replace('[SECTIONVII]', '<SECTIONVII></SECTIONVII>')
              .replace('[SECTIONVIII]', '<SECTIONVIII></SECTIONVIII>')
              .replace('[SECTIONIX]', '<SECTIONIX></SECTIONIX>')
              .split('\r\n')
              .join('<br />')
              .split('\n\n')
              .join('<br />')
              .split('\r\r')
              .join('<br />')
          : '';

      setTermsHtml(termsHtml);
    }
  }, [data, franchiseData]);

  const setOldData = () => {
    if (data && data['IsSS'] && franchiseData) {
      const newValues = {};
      if (data && data['ProposalSection5']) {
        newValues.paragraphV = data['ProposalSection5'];
      } else {
        newValues.paragraphV = franchiseData['EWANewProposalSection5'];
      }

      if (Object.keys(newValues).length > 0) {
        setValues(newValues);
      }
    } else {
      setValues({ ...termsAndConditionsValues });
    }
  };

  const saveValues = () => {
    update(values, data, () => toast.info('Saved Successfully'));
  };

  const onConfirmRestore = () => {
    if (data && data['IsSS']) {
      setShowRestoreModal(false);
      setValues(defaultValues);
    } else {
      setShowRestoreModal(false);
      const newValues = { ...values };
      const backendDefaultValues = data ? data['TermsAndConditions'] : [];

      newValues['Section3Section'] =
        franchiseData['ProposalSection3'] ||
        termsDefaultValues['proposalSection3'];

      newValues['Section4Section'] = termsDefaultValues['proposalSection4'];

      newValues['Section5Section'] = termsDefaultValues['proposalSection5'];

      newValues['Section7Section'] = termsDefaultValues['proposalSection7'];

      newValues['Section8Section'] = termsDefaultValues['proposalSection8'];

      newValues['HolidaySection'] =
        franchiseData['ProposalHolidays'] ||
        termsDefaultValues['proposalSection3'];

      setValues(newValues);
    }
  };

  const customizableSection = (sectionName) => {
    if (previewState) {
      return values[sectionName];
    }
    return (
      <>
        <TextAreaInput
          style={{ width: 'calc(100% - 10px)',
            padding: '5px',
            marginBottom: '-63px' }}
          value={values[sectionName]}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              e.stopPropagation();
              e.preventDefault();
            }
          }}
          onChange={(e) =>
            setValues({
              ...values,
              [sectionName]: e.target.value,
            })
          }
        ></TextAreaInput>
        <div style={{ paddingBottom: '55px' }}></div>
      </>
    );
  };

  return (
    <ModalWrapper>
      <Layout
        padding="40px 0 40px 0"
        margin="40px"
        maxWidth="900px"
        maxHeight="70vh"
        backgroundColor="white"
        position="relative"
      >
        <SectionTitle maxFontSize="22px" textAlign="center">
          Customize Service Procurement Agreement Terms
        </SectionTitle>
        {!previewState && (
          <div style={{ position: 'absolute', right: '35px', top: '20px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: ' 48px',
                marginBottom: '24px',
                cursor: 'pointer',
              }}
              onClick={() => setShowRestoreModal(true)}
            >
              <i className="refresh-icon" />
              <span
                style={{
                  fontSize: '12px',
                  textDecoration: 'underline',
                }}
              >
                Reset
              </span>
            </div>
          </div>
        )}
        <Layout
          display="block"
          paddingBottom="0"
          margin="20px"
          paddingRight="10px"
          overflow="auto"
          id="terms"
        >
          <Paragraph as="div" style={{ textAlign: 'justify' }}>
            {reactHtmlReplace(termsHtml, (tag, props) => {
              if (tag === 'HOLIDAYS') {
                if (previewState) {
                  return values['HolidaySection'];
                }
                return (
                  <>
                    <br />
                    <TextAreaInput
                      style={{ width: 'calc(100% - 10px)',
                      padding: '5px',
                      marginBottom: '-63px' }}
                      value={values['HolidaySection']}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          e.stopPropagation();
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          HolidaySection: e.target.value,
                        })
                      }
                    ></TextAreaInput>
                    <div style={{ paddingBottom: '55px' }}></div>
                  </>
                );
              }
              if (tag === 'SECTIONIII') {
                return customizableSection('Section3Section');
              }
              if (tag === 'SECTIONIV') {
                return customizableSection('Section4Section');
              }
              if (tag === 'SECTIONV') {
                if (data['IsSS']) {
                  return customizableSection('paragraphV');
                }
                return customizableSection('Section5Section');
              }
              if (tag === 'SECTIONVI') {
                return data['TermsAndConditions']['ProposalSection6'];
              }
              if (tag === 'SECTIONVII') {
                return customizableSection('Section7Section');
              }
              if (tag === 'SECTIONVIII') {
                return customizableSection('Section8Section');
              }
              if (tag === 'SECTIONIX') {
                return data['TermsAndConditions']['Section9Section'];
              }
              if (tag === 'br') {
                return <br />;
              }
            })}
          </Paragraph>
        </Layout>
      </Layout>

      <Actions>
        <DoneButtonWrapper>
          {!previewState && (
            <DoneButton
              style={{ marginRight: '70px' }}
              onClick={() => {
                setPreviewState(false);
                setOldData();
                cancel();
              }}
            >
              <FontAwesomeIcon icon={faReply} />
              BACK
            </DoneButton>
          )}

          {!previewState && (
            <Button
              reverseSkew
              kind="outline"
              color="#eda328"
              bgColor="#fff"
              onClick={() => setPreviewState(true)}
            >
              PREVIEW
            </Button>
          )}

          {previewState && (
            <DoneButton reverseSkew onClick={() => setPreviewState(false)}>
              DONE
            </DoneButton>
          )}

          {!previewState && (
            <Button
              reverseSkew
              bgColor="#eda328"
              onClick={() => {
                saveValues();
              }}
            >
              SAVE
            </Button>
          )}
        </DoneButtonWrapper>
      </Actions>
      <ActionAlertModal
        title=""
        renderMessage={() => (
          <p className="alert-message">
            This will reset the default language for <i>all fields,</i> are you
            sure you want to proceed?
          </p>
        )}
        onConfirm={onConfirmRestore}
        onDismiss={() => setShowRestoreModal(false)}
        isVisible={showRestoreModal}
      />
    </ModalWrapper>
  );
};
