import React from 'react';
import { EditTextArea } from './styles';

const adjustHeight = (e)=>{
  const el = e.currentTarget || e.target;
  if(el){
    el.style.height = "auto";
    const elPaddingTop = window.getComputedStyle(el, null).getPropertyValue('padding-top').split('px')[0] || 0;
    const elPaddingBottom = window.getComputedStyle(el, null).getPropertyValue('padding-bottom').split('px')[0] || 0;
    el.style.height = (el.scrollHeight - Number(elPaddingTop) - Number(elPaddingBottom))+"px";
  }
}

const adjustHeightListeners = ['onKeyUp', 'onPaste', 'onCut', 'onDrop'].reduce((acc, key)=>{
  acc[key]= adjustHeight;
  return acc;
}, {});

export const ClickToEditSpan = ({
  name,
  value,
  onChange,
  renderSpan,
  textAreaStyles,
  rows,
}) => {
  const inputRef: any = React.useRef(null);

  return (
    <EditTextArea
      {...adjustHeightListeners}
      rows={rows}
      ref={inputRef}
      style={textAreaStyles}
      value={value}
      onChange={(e) => onChange(name, e.currentTarget.value)}
    />
  );
};
