import React from 'react';

import {
  Layout,
  RoundSubSection,
  SubSectionHeader,
} from 'modules/BidInfoForm/components/layout';
import { DatePicker } from 'core/components';

import { limitTextAreaRows } from 'modules/shared/utils';

import { SectionTitle } from 'modules/BidInfoForm/styles';

import { ButtonGroup, useFormProps } from 'core/components';

import { performanceEvaluationDefaultValues } from 'modules/PerformanceEvaluation/defaultValues';
import { withFormik } from 'formik';

import { getUserIp } from '../../../../services/performanceEvaluationService';

import './styles.scss';

import Signature from './Signature';

const Evaluation = (props: any) => {
  const [equalsOrBiggerThan768, setEqualsOrBiggerThan768] = React.useState(
    false
  );
  const [biggerThan796, setBiggerThan796] = React.useState(false);
  const [biggerThan768, setBiggerThan768] = React.useState(false);
  const [minorThan768, setMinorThan768] = React.useState(false);
  const [minorThan450, setMinorThan450] = React.useState(false);
  const [isTouchable, setIsTouchable] = React.useState(false);

  const setBreakpoints = () => {
    setEqualsOrBiggerThan768(window.innerWidth >= 768);
    setBiggerThan796(window.innerWidth >= 796);
    setBiggerThan768(window.innerWidth >= 769);
    setMinorThan768(window.innerWidth < 768);
    setMinorThan450(window.innerWidth < 450);
    setIsTouchable('ontouchstart' in document.documentElement);
  };

  const validateComments = (value) => {
    props.showCommentsWarning(value.length);
  };

  const onChangeWrapper = (name: string, value: string) => {
    onChange('date', new Date());
    onChange(name, value);
    props.setIsDirty(true);

    setTimeout(async () => {
      const ipAddress = await getUserIp();
      onChange('ipAddress', ipAddress.data);
    });
  };
  React.useEffect(() => {
    setBreakpoints();

    if (props.userEvaluation) {
      setTimeout(async () => {
        const ipAddress = await getUserIp();
        onChange('ipAddress', ipAddress.data);
      });
    }
    limitTextAreaRows('limit-lines', 'suggestion-counter');
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  const signatureRef = React.useRef();
  const { formValues, onChange } = useFormProps();
  return (
    <div
      style={{
        marginBottom: '25px',
        width: 'calc(100% - 50px)',
        margin: 'auto',
      }}
      className="evaluation"
    >
      <RoundSubSection
        marginTop="30px"
        paddingBottom={0}
        width="100%"
        backgroundColor="#ededed"
      >
        <SubSectionHeader
          backgroundColor="#216eab"
          color="#fff"
          padding={'20px 0 20px 0'}
          height={minorThan768 ? '25px' : '10px'}
        >
          <SectionTitle
            maxFontSize="20px"
            marginLeft={minorThan768 ? '0px' : '25px'}
            paddingLeft={minorThan768 ? '0px' : '20px'}
            biggerThan768
            color="#fff"
            style={{
              textAlign: minorThan768 ? 'center' : 'left',
            }}
          >
            PERFORMANCE EVALUATION {minorThan768 && <br />}
            <span
              style={{ fontSize: '75%', fontFamily: 'OpenSans-RegularItalic' }}
            >
              (Procured Janitorial Service)
            </span>
          </SectionTitle>
        </SubSectionHeader>
        <Layout width="100%" margin="0" backgroundColor="#ededed">
          <div
            style={{
              margin: '20px',
              marginBottom: '15px',
              pointerEvents: props.isCompleted ? 'none' : 'auto',
            }}
          >
            <ButtonGroup
              radio={minorThan768}
              tabIndexable={false}
              label=""
              type="oneOption"
              options={['Superior', 'Excellent', 'Good', 'Fair']}
              name="selectedSatisfaction"
              onChange={onChangeWrapper}
              value={
                formValues.selectedSatisfaction ||
                performanceEvaluationDefaultValues.selectedSatisfaction
              }
              style={{ width: '100%', opacity: props.isCompleted ? 0.6 : 1 }}
              buttonsStyle={{
                width: minorThan768 ? '100%' : '25%',
                backgroundColor: '#ededed',
                height: '50px !important',
                textAlign: 'center',
              }}
              backgroundSelectedColor={
                formValues.selectedSatisfaction === 'Good' ||
                formValues.selectedSatisfaction === 'Excellent'
                  ? 'rgb(0, 96, 255) !important'
                  : formValues.selectedSatisfaction === 'Fair'
                  ? 'rgb(178, 156, 17)  !important'
                  : 'rgb(0, 176, 72) !important'
              }
            />
            <div style={{ position: 'relative' }}>
              <textarea
                key={`suggestions`}
                id={`suggestions`}
                name="suggestions"
                value={formValues.suggestions}
                onChange={(e) => onChangeWrapper('suggestions', e.target.value)}
                onBlur={(e) => validateComments(e.target.value)}
                placeholder="Comments (optional)"
                className="suggestions-box limit-lines show-counter"
                rows="8"
                maxLength={584}
                disabled={props.isCompleted}
              ></textarea>
              <div
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '10px',
                }}
              >
                <span className="suggestion-counter">0</span>/584
              </div>
            </div>
          </div>
        </Layout>
      </RoundSubSection>

      {props.userEvaluation && (
        <RoundSubSection
          marginTop="30px"
          width="100%"
          backgroundColor="#ededed"
          paddingBottom={minorThan768 ? '50px' : '20px'}
        >
          <SubSectionHeader
            backgroundColor="#216eab"
            color="#fff"
            padding={'20px 0 20px 0'}
            height={minorThan768 ? '25px' : '10px'}
          >
            <SectionTitle
              maxFontSize="20px"
              marginLeft={minorThan768 ? '0px' : '25px'}
              paddingLeft={minorThan768 ? '0px' : '20px'}
              biggerThan768
              color="#fff"
              style={{
                textAlign: minorThan768 ? 'center' : 'left',
              }}
            >
              AUTHORIZATION
            </SectionTitle>
          </SubSectionHeader>
          <Layout
            width="100%"
            margin="0"
            marginBottom={!isTouchable ? '20px !important' : '0px'}
            backgroundColor="#ededed"
          >
            <div
              style={{
                width: minorThan768 ? '100%' : 'calc(100% - 40px)',
                display: 'flex',
                flexDirection: minorThan768 ? 'column' : 'row',
                marginTop: minorThan768 ? '25px' : '20px',
                alignItems: 'center',
                paddingLeft: minorThan768 ? '0px' : '20px',
              }}
            >
              <div
                className="bid-input-content contact"
                style={{
                  padding: 0,
                  paddingTop: '0px',
                  width: minorThan768 ? '100%' : '50%',
                }}
              >
                <span className="contact-title" style={{ paddingLeft: '10px' }}>
                  Print Name
                </span>
                <input
                  className="input validate-print-name evaluation"
                  value={formValues.printName}
                  onChange={(e) => onChangeWrapper('printName', e.target.value)}
                  style={{
                    outline: 'none',
                    width: '100%',
                    marginTop: '-10px',
                    paddingLeft: '10px',
                  }}
                  disabled={props.isCompleted}
                  autoCapitalize="words"
                />
                {isTouchable && (
                  <>
                    <div
                      style={{
                        height: '20px',
                        width: '100%',
                        backgroundColor: '#ededed',
                      }}
                    />
                    <span
                      className="contact-title"
                      style={{ paddingLeft: '10px' }}
                    >
                      Email Address
                    </span>
                    <input
                      className="input validate-email-address evaluation"
                      value={formValues.emailAddress}
                      onChange={(e) =>
                        onChangeWrapper('emailAddress', e.target.value)
                      }
                      disabled={props.isCompleted}
                      style={{
                        outline: 'none',
                        width: '100%',
                        marginTop: '-10px',
                        paddingLeft: '10px',
                      }}
                    />
                  </>
                )}
              </div>

              {isTouchable && (
                <Signature
                  ref={signatureRef}
                  setSignatureData={props.setSignatureData}
                  value={formValues.signatureUri}
                  style={{
                    width: !minorThan768 ? '50%' : 'calc(100% - 82px)',
                    height: '40px',
                    marginTop: minorThan768 ? '20px' : '-35px',
                    marginBottom: '-33px',
                    marginRight: '0px',
                    marginLeft: !minorThan768 ? '20px' : '0px',
                    pointerEvents: props.isCompleted ? 'none' : 'auto',
                    opacity: props.isCompleted ? 0.4 : 1,
                  }}
                />
              )}

              {!isTouchable && (
                <div
                  className="bid-input-content contact"
                  style={{
                    padding: 0,
                    paddingTop: '0px',
                    width: minorThan768 ? '100%' : '50%',
                    marginLeft: minorThan768 ? '0px' : '20px',
                    position: 'relative',
                  }}
                >
                  <span
                    className="contact-title"
                    style={{ paddingLeft: '10px' }}
                  >
                    Email Address
                  </span>
                  <input
                    className="input validate-email-address evaluation"
                    value={formValues.emailAddress}
                    onChange={(e) =>
                      onChangeWrapper('emailAddress', e.target.value)
                    }
                    disabled={props.isCompleted}
                    style={{
                      outline: 'none',
                      width: '100%',
                      marginTop: '-10px',
                      paddingLeft: '10px',
                    }}
                  />

                  <span
                    style={{
                      backgroundColor: '#ededed',
                      position: 'absolute',
                      bottom: '-35px',
                      left: '0',
                      fontSize: '10px',
                      color: 'lightslategray',
                    }}
                  >
                    IP Address: {formValues.ipAddress}{' '}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Time Stamp:{' '}
                    {formValues.date
                      ? formValues.date.toLocaleString('en-US', {
                          day: 'numeric',
                          month: 'numeric',
                          year: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: true,
                        })
                      : ''}
                  </span>
                </div>
              )}
            </div>
          </Layout>
        </RoundSubSection>
      )}
    </div>
  );
};

const mapForm = {
  mapPropsToValues: () => ({
    ...performanceEvaluationDefaultValues,
  }),
  handleSubmit: () => '',
  enableReinitialize: true,
};

const EnhancedEvaluation = withFormik<any, any>(mapForm)(Evaluation);

export { EnhancedEvaluation as Evaluation };
