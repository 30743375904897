import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex: 1;
`;

interface IInput {
  bold?: boolean;
  textTransform?: string;
}

export const Input = styled.textarea`
  ${({ bold }: IInput) =>
    bold ? `font-family: OpenSans-SemiBold;` : 'font-family: OpenSans-Regular;'}
  background-color: transparent;
  border: none;
  outline: none;
  width: 95%;
  height: 25px;
  font-size: 16px;
  resize: none;

  ${({ textTransform }: IInput) =>
    textTransform ? `text-transform: ${textTransform};` : ''}
`;

export const DataContent = styled.div`
  position: absolute;
  background-color: #fff;
  border-top: 0;
  margin-top: 41px;
  max-height: 200px;
  overflow: auto;
  cursor: pointer;
  width: auto;
  max-width: 400px;
  z-index: 5;
`;

interface IItemContent {
  isSelected: boolean;
  index: number;
}

export const ItemContent = styled.div`
  ${({ isSelected, index }: IItemContent) =>
    isSelected
      ? 'background-color: #9a9a9a; color: #fff;'
      : index % 2 === 0
      ? 'background-color: #fff;'
      : 'background-color: #eee;'}
  padding: 10px;
`;
