import React, { memo, useCallback } from 'react';
import { withFormik, FormikProps } from 'formik';
import { IBidProtectionSpecifications } from 'modules/BidInfoForm/interfaces';
import {
  ContentInput,
  InputFloatLabel,
  Accordion,
  IconButton,
  useFormProps,
  ClickToEditSpan,
} from 'core/components';
import { moneyMask, moneyMaskParams } from 'core/utils/masks';
import { getProtectionJson } from 'modules/BidInfoForm/services/bidInfoService';
import { EditButton } from '../CleaningSpecification/styles';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import {useGenericErrorHandler} from 'core/hooks/useGenericErrorHandler';
import './styles.scss';
import { protectionSpecificationValues } from '../../../../../shared/bidDefaultValues';
import { objectToArray, arrayToObject } from 'core/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionAlertModal, Tooltip } from '../../../../../../core/components';

type IProps = FormikProps<IBidProtectionSpecifications>;
const ProtectionSpecification = memo(
  ({
    values,
    setFieldValue,
    setValues,
    changeHeight,
    formValues,
    onChange,
    showDeleteConfirmationModal,
  }: IProps) => {
    const onBlur = useCallback(
      (name: string) => () => onChange(name, formValues[name]),
      [formValues, onChange]
    );

    const [minorThan1024, setMinorThan1024] = React.useState(false);
    const [protectionDefault, setProtectionDefault] = React.useState(null);
    const [showRestoreModal, setShowRestoreModal] = React.useState(null);
    const setBreakpoints = () => {
      setMinorThan1024(window.innerWidth < 1024);
    };

    React.useEffect(() => {
      setBreakpoints();
    }, [window.innerWidth]);

    React.useLayoutEffect(() => {
      window.addEventListener('resize', setBreakpoints);
      setBreakpoints();
      return () => window.removeEventListener('resize', setBreakpoints);
    }, []);

    const [availablePlans, setAvailablePlans] = React.useState([]);
    const [checkboxSelecteds, setCheckboxSelecteds] = React.useState([]);
    const [editState, setEditState] = React.useState(false);
    const fixedKeys = ['BLUE', 'ORANGE', 'PLATINUM'];
    const {setError} = useGenericErrorHandler();
    
    const onConfirmRestore = () => {
      try{
      // setNote(additionalQuotesValues.serviceFooter);
      // onChange(fieldName, additionalQuotesValues.serviceFooter);
      const newPlans = JSON.parse(
        JSON.stringify(formValues.protectionSpecification)
      );
      newPlans[showRestoreModal] = protectionDefault[showRestoreModal];

      setFieldValue(
        'protectionSpecification',
        filterByAvailable(newPlans, availablePlans)
      );

      setShowRestoreModal(false);
      }catch(e){
        setError(e);
      }
    };

    const getSortedPlans = (values) => {
      const arrayToSort = objectToArray(values);

      const refArray = fixedKeys.map((item) => item.toLowerCase());

      arrayToSort.sort((a: Array<any>, b: Array<any>) => {
        return (
          (refArray.indexOf(a[0].toLowerCase()) >= 0
            ? refArray.indexOf(a[0].toLowerCase())
            : refArray.length) -
          (refArray.indexOf(b[0].toLowerCase()) >= 0
            ? refArray.indexOf(b[0].toLowerCase())
            : refArray.indexOf(a[0].toLowerCase()) + 1)
        );
      });

      return arrayToObject(arrayToSort);
    };

    const removeSelecteds = () => {
      showDeleteConfirmationModal(() => {
        try{
        const specifications = JSON.parse(
          JSON.stringify(values.protectionSpecification)
        );

        const invalidState = [];

        for(var el of document.getElementsByClassName('protection-specification-content')[0].getElementsByClassName('is-invalid')){
          invalidState.push(el.id);
          el.classList.remove('is-invalid');
        }

        checkboxSelecteds.map((i) => {
          const values = i.split('_');
          if (values.length == 1) {
            if (!fixedKeys.includes(values[0])) {
              delete specifications[values[0]];
            } else {
              specifications[values[0]].items.push({title: '', uid: Math.random()});
            }
          } else if (values.length == 2) {
            const item = specifications[values[0]];
            if (item) {
              // const idx = item.items.findIndex((i) => i == values[1]);
              const idx =values[1].split('#')[1];
              if (idx >= 0) {
                item.items.splice(idx, 1, undefined);
              }
            }
          }
        });

        Object.keys(specifications).forEach(key=>{
          specifications[key].items = specifications[key].items.filter(i=>i != undefined);
        })

        onChange('protectionSpecification', specifications);
        setCheckboxSelecteds([]);
        setEditState(false);

        setTimeout(() => {
          for(var id of invalidState){
            if(document.getElementById(id)){
              document.getElementById(id).classList.add('is-invalid');
            }
          }
        })
      }catch(e){
        setError(e);
      }
        // console.log({ checkboxSelecteds });
      }, checkboxSelecteds.length > 1);
    };

    React.useEffect(() => {
      const plans = [];
      console.log(formValues.protectionPackages);

      formValues.protectionPackages &&
        formValues.protectionPackages.map((item) => {
          if (
            item.plans.filter(
              (i) => i.plan && i.plan.toString().toLowerCase() == 'platinum'
            ).length > 0
          ) {
            plans.push('PLATINUM', 'ORANGE', 'BLUE');
          } else if (
            item.plans.filter(
              (i) => i.plan && i.plan.toString().toLowerCase() == 'orange'
            ).length > 0
          ) {
            plans.push('ORANGE', 'BLUE');
          } else if (
            item.plans.filter(
              (i) => i.plan && i.plan.toString().toLowerCase() == 'blue'
            ).length > 0
          ) {
            plans.push('BLUE');
          }
        });

      formValues.secondProtectionPackages &&
        formValues.secondProtectionPackages.map((item) => {
          if (
            item.plans.filter(
              (i) => i.plan && i.plan.toString().toLowerCase() == 'platinum'
            ).length > 0 &&
            !plans.includes('PLATINUM')
          ) {
            plans.splice(0, plans.length);
            plans.push('PLATINUM', 'ORANGE', 'BLUE');
          } else if (
            item.plans.filter(
              (i) => i.plan && i.plan.toString().toLowerCase() == 'orange'
            ).length > 0 &&
            !plans.includes('ORANGE')
          ) {
            plans.push('ORANGE');
          } else if (
            item.plans.filter(
              (i) => i.plan && i.plan.toString().toLowerCase() == 'blue'
            ).length > 0 &&
            !plans.includes('BLUE')
          ) {
            plans.push('BLUE');
          }
        });

      setAvailablePlans(plans);
      changeHeight();

      if (
        !values.protectionSpecification ||
        Object.keys(values.protectionSpecification).length == 0
      ) {
        setFieldValue(
          'protectionSpecification',
          filterByAvailable(protectionDefault || {}, plans)
        );
      } else {
        const source = {};
        plans.map((p) => {
          if (values.protectionSpecification[p]) {
            source[p] = values.protectionSpecification[p];
          } else {
            source[p] = protectionDefault[p];
          }
        });
        setFieldValue(
          'protectionSpecification',
          getSortedPlans(filterByAvailable(source || {}, plans))
        );
      }
    }, [formValues.protectionPackages, formValues.secondProtectionPackages]);

    React.useEffect(() => {
      if (!Object.keys(values).length) {
        getProtectionJson().then((result) => {
          try{
          const protection = JSON.parse(result.data);
          Object.keys(protection).forEach(key => {
              protection[key].uid = Math.random();
              protection[key].items = protection[key].items.map(item=>({title: item, uid: Math.random()}))
          });
          setProtectionDefault(protection);
          setFieldValue(
            'protectionSpecification',
            filterByAvailable(protection, availablePlans)
          );

          console.log({protection});
          }catch(e){
            setError(e);
          }
          // onChange('protectionSpecification', JSON.parse(result.data));
        });
      }
    }, []);

    const filterByAvailable = (protectionSpecification, availablePlans) => {
      const filtered = {};
      Object.keys(protectionSpecification)
        .filter((key) => availablePlans.includes(key.toUpperCase()))
        .map((key) => {
          filtered[key] = { ...protectionSpecification[key] };
        });

      return filtered;
    };

    React.useEffect(() => {
      if (
        !!Object.keys(formValues.protectionSpecification).length &&
        JSON.stringify(values.protectionSpecification) !=
          JSON.stringify(formValues.protectionSpecification)
      ) {
        setFieldValue(
          'protectionSpecification',
          formValues.protectionSpecification
        );
      }
    }, [formValues.protectionSpecification]);

    React.useEffect(() => {
      if (values.protectionSpecification) {
        onChange('protectionSpecification', values.protectionSpecification);
      }
    }, [values.protectionSpecification]);

    const [accordionState, setAccordionState] = React.useState({});
    const [resetRender, setResetRender] = React.useState(true);

    const setAccState = (value: any) => {
      setAccordionState((prevState) => ({
        ...prevState,
        ...(value || {}),
      }));
      changeHeight();
    };

    const onRemoveData = (key: string) => (index: number) => {
      showDeleteConfirmationModal(() => {
        var result = { ...values.protectionSpecification };
        const invalidState = [];
        for (var el of document
          .getElementsByClassName('protection-specification-content')[0]
          .getElementsByClassName('is-invalid')) {
          invalidState.push(el.id);
          el.classList.remove('is-invalid');
        }
        result[key].items = result[key].items.filter(
          (item: any, dataIndex: number) => dataIndex !== index
        );

        setFieldValue('protectionSpecification', result);

        setTimeout(() => {
          for (var id of invalidState) {
            if (document.getElementById(id)) {
              document.getElementById(id).classList.add('is-invalid');
            }
          }
        });
      });
    };

    const onAddData = (key: string) => () => {
      try{

      var result = JSON.parse(JSON.stringify(values.protectionSpecification));

      result[key].items.push({title: '', uid: Math.random()});

      setFieldValue('protectionSpecification', result);
      }catch(e){
        setError(e);
      }
    };

    const onDragAreasEnd = (key, result) => {
      const newValues = {
        ...values.protectionSpecification,
        [key]: {
          ...values.protectionSpecification[key],
          items: result,
        },
      };

      setFieldValue('protectionSpecification', newValues);
    };

    if (!availablePlans.length) {
      return <></>;
    }

    return (
      <div className="protection-specification-root">
        <div className="protection-specification-content">
          <ContentInput
            width="100%"
            justify="flex-start"
            flexDirection="column"
          >
            <h3 className="protection-specification-title">
              Anago Protection+ Disinfection&trade; Specifications
            </h3>
            <div className="protection-specification-subtitle">
              <ClickToEditSpan
                rows={minorThan1024 ? 3 : 2}
                name="protectionSpecificationSubtitle"
                onChange={onChange}
                value={formValues.protectionSpecificationSubtitle}
              />
            </div>
          </ContentInput>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              paddingRight: '70px',
            }}
          >
            {!editState && (
              <>
                <Tooltip
                  overlay={
                    <span
                      style={{
                        fontSize: '13px',
                        display: 'block',
                        textAlign: 'left',
                      }}
                    >
                      To delete multiple Protection+ Specifications, use the
                      Edit button to initiate the Multi-Delete tool.
                    </span>
                  }
                  trigger="click"
                  placement="topLeft"
                  alignedItemId="multiDeleteInfoIcon"
                  positioningFunction={(node, align) => {
                    const targetEl = document.getElementById(
                      'multiDeleteInfoIcon'
                    );
                    const rect = targetEl.getBoundingClientRect();
                    node.style.left = `${rect.left - 280}px`;
                  }}
                >
                  <div
                    className="protection-specification-button-tooltip"
                    id="multiDeleteInfoIcon"
                  >
                    <FontAwesomeIcon icon={faInfo} size="xs" color="#216eab" />
                  </div>
                </Tooltip>
                <EditButton
                  tabIndex={-1}
                  onClick={() => setEditState(true)}
                  className={`quote-add-button`}
                >
                  Edit
                </EditButton>
              </>
            )}
            {editState && (
              <>
                <EditButton
                  tabIndex={-1}
                  onClick={() => {
                    setCheckboxSelecteds([]);
                    setEditState(false);
                  }}
                  className={`quote-add-button`}
                  style={{
                    backgroundColor: '#216eab',
                    marginRight: '15px',
                  }}
                >
                  Cancel
                </EditButton>
                <EditButton
                  tabIndex={-1}
                  onClick={() => removeSelecteds()}
                  className={`quote-add-button`}
                  style={{
                    backgroundColor: '#ff4040',
                  }}
                >
                  Delete
                </EditButton>
              </>
            )}
          </div>

          <ContentInput
            width="100%"
            justify="flex-start"
            paddingTop="0px"
            flexDirection="column"
          >
            {!!Object.keys(values).length &&
              Object.keys(values.protectionSpecification).map(
                (key: string, index: number) => (
                  <div className="accordion-content" id={key} key={`${key}_${index}`}>
                    <Accordion
                      canRemoveParent
                      multiDelete
                      editState={editState}
                      addSelectedOption={(options) => {
                        // alert(options);
                        setCheckboxSelecteds([
                          ...checkboxSelecteds,
                          ...options,
                        ]);
                      }}
                      removeSelectedOptions={(options) => {
                        setCheckboxSelecteds(
                          checkboxSelecteds.filter(
                            (item) => !options.includes(item)
                          )
                        );
                      }}
                      selectedOptions={checkboxSelecteds}
                      itemKey={key}
                      title={`Protection+ ${key.toUpperCase()}`}
                      titleStyle={{
                        fontWeight: 'bolder',
                        textShadow: `0px 0px 1px ${values.protectionSpecification[key].titleColor}`,
                      }}
                      parentItemStyle={{
                        textShadow: `0px 0px 1px ${values.protectionSpecification[key].titleColor}`,
                      }}
                      titleColor={
                        values.protectionSpecification[key].titleColor
                      }
                      data={values.protectionSpecification[key].items}
                      onChange={(value: any) => {
                        setFieldValue('protectionSpecification', {
                          ...values.protectionSpecification,
                          [key]: {
                            ...values.protectionSpecification[key],
                            items: value,
                          },
                        });
                      }}
                      removeData={onRemoveData(key)}
                      addData={onAddData(key)}
                      setAutoCompleteFilter={() => {}}
                      onDragEnd={onDragAreasEnd}
                      draggable={formValues.status !== 2}
                      disabled={formValues.status === 2}
                      setAccordionState={setAccState}
                      uid={values.protectionSpecification[key].uid}
                      isOpen={accordionState[key] && accordionState[key].state}
                      subListOpened={
                        accordionState[key] && accordionState[key].subListState
                      }
                    />
                    <div style={{ width: '77px', height: '40px' }}>
                      <div
                        className="action-container"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowRestoreModal(key)}
                      >
                        <i className="refresh-icon" />
                        <span>Reset</span>
                      </div>
                    </div>
                  </div>
                )
              )}
          </ContentInput>
        </div>
        <ActionAlertModal
          title=""
          message="Are you sure you want to restore the Protection+ Specifications?"
          onConfirm={onConfirmRestore}
          onDismiss={() => setShowRestoreModal(false)}
          isVisible={showRestoreModal}
        />
      </div>
    );
  }
);

const mapForm = {
  mapPropsToValues: () => ({}),
  handleSubmit: () => '',
};

const EnhancedProtectionSpecification = withFormik<
  any,
  IBidProtectionSpecifications
>(mapForm)(ProtectionSpecification);

export { EnhancedProtectionSpecification as ProtectionSpecification };
