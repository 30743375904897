import styled from "styled-components";

export const TableContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StyledTable = styled.table`
  width: 100%;
`;

export const Header = styled.th`
  color: #777;
  height: 30px;
`;

interface ITableDataCell {
  width: string;
}

export const TableDataCell = styled.td`
  ${({ width }: ITableDataCell) => `width: ${width};`}
`;

export const Cell = styled.div`
  display: flex;
  padding: 5px;
  justify-content: center;
`;
