import styled from 'styled-components';

export const PdfFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: 10px;
  padding-bottom: 10px
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & button:not(:first-child) {
    margin-left: 20px;
  }
`;
