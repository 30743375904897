import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import logo from '../../assets/logo.png';
import headerPic from '../../assets/header-pic.png';
import logoGlowWhitePic from '../../assets/logo-white-glow.png';
import pdfIcon from '../../assets/pdfIcon.png';
import { isWebView } from 'core/utils';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import {
  Logo,
  HeaderLogo,
  BackgroundLogo,
  HeaderPic,
  Background,
  Content,
  HeaderTitle,
  HeaderCompanyTitle,
  HeaderCompanyAddress,
  HeaderPrepared,
  HeaderPreparedPerson,
  Paragraph,
  FakeAnchor,
  HeaderSection,
  BlueTitle,
  HiddenAnchor,
} from '../atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Message = styled.div`
  a {
    /*optional*/
    // font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Header = ({ data, showHeaderAddress, page, franchiseData, downloadBlankPdf }) => {
  const values = data || [];
  const biggerThan768 = window.innerWidth >= 769;

  const getValue = (key: string) => {
    return values[key] || '';
  };

  const getPreparedFor = (key: string) => {
    switch (key) {
      case 'name':
        if (values['AddressedToWalkThru']) {
          return values['ContactName'];
        } else {
          return values['DecisionMaker'];
        }

      case 'title':
        if (values['AddressedToWalkThru']) {
          return `${getValue('WalkThruTitle') ? ' - ' : ''}${getValue(
            'WalkThruTitle'
          )}`;
        } else {
          return `${getValue('DecisionMakerTitle') ? ' - ' : ''}${getValue(
            'DecisionMakerTitle'
          )}`;
        }

      default:
        return values[key] ? values[key] : '';
    }
  };

  const joinFieldValue = (joinChar = ',', ...values) =>
    values.reduce((acc, i) => {
      if (acc != '' && i != '') {
        acc += `${joinChar} `;
      }
      acc += i.trim();
      return acc;
    }, '');

  const getCompanyAddress = () => {
    return values
      ? joinFieldValue(
          ',',
          getValue('Address'),
          getValue('Suite'),
          getValue('City'),
          `${getValue('State')} ${getValue('Zip')}`
        )
      : '';
  };

  return (
    <HeaderSection data-cy="proposal-header">
      <div
        onClick={() => {
          downloadBlankPdf();
        }}
        style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9
        }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <FontAwesomeIcon icon={faPrint} size="1x" color="#333" />
          <span style={{ paddingTop: 10, fontSize: '14px' }}>View PDF</span>
        </div>
      </div>
      {page == 0 && (
        <Content padding="0">
          <HeaderLogo src={logo} />
        </Content>
      )}
      {page > 0 && (
        <BackgroundLogo>
          <HeaderLogo src={logoGlowWhitePic} style={{ zIndex: 99 }} />
        </BackgroundLogo>
      )}
      {page == 0 && (
        <Content
          padding="0"
          style={{
            width: '100%',
            textAlign: 'center',
            position: 'relative',
          }}
        >
          <HeaderPic src={headerPic} />
          <Background />
          <BlueTitle>
            {' '}
            {franchiseData && franchiseData['AnagoOfCityName']}{' '}
          </BlueTitle>
        </Content>
      )}
      <Content paddingTop="20px">
        <HeaderTitle
          marginTop="10px"
          maxFontSize="40px"
          data-cy="proposal-header-title"
        >
          {values['IsSS']
            ? 'Specialty Services Proposal'
            : 'Janitorial Services Proposal'}
        </HeaderTitle>
      </Content>
      <Content flex direction="column" paddingTop="15px">
        <HeaderCompanyTitle
          maxFontSize="26px"
          data-cy="proposal-header-company"
        >
          {getValue('BusinessName')}
        </HeaderCompanyTitle>
        <HeaderCompanyAddress
          maxFontSize="18px"
          data-cy="proposal-header-address"
        >
          {getCompanyAddress()}
        </HeaderCompanyAddress>
      </Content>
      <Content flex paddingTop={'2%'}>
        <HeaderPrepared
          maxFontSize="16px"
          data-cy="proposal-header-prepared-for-title"
        >
          Prepared for:
        </HeaderPrepared>
        <HeaderPreparedPerson
          maxFontSize="16px"
          data-cy="proposal-header-prepared-for"
        >{`${getPreparedFor('name')}${getPreparedFor(
          'title'
        )}`}</HeaderPreparedPerson>
      </Content>
      <Content flex paddingTop={'0'}>
        <HeaderPrepared
          maxFontSize="16px"
          data-cy="proposal-header-prepared-by-title"
        >
          Prepared by:
        </HeaderPrepared>
        <HeaderPreparedPerson
          maxFontSize="16px"
          data-cy="proposal-header-prepared-by"
        >{`${getValue('CreatedByName')} ${
          getValue('SalesRepresentativeTitle') ? ' - ' : ''
        }${getValue('SalesRepresentativeTitle')}`}</HeaderPreparedPerson>
      </Content>
    </HeaderSection>
  );
};

export const FixedPageWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 10999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SignNowLoadingPage = () => {
  const [loadingWidth, setLoadingWidth] = React.useState(0);
  const [timerInterval, setTimerInterval] = React.useState(null);

  const getCurrentText = () => {
    if (loadingWidth < 15) {
      return 'Compiling Information';
    } else if (loadingWidth < 45) {
      return 'Dusting off the Details';
    } else if (loadingWidth < 75) {
      return 'Polishing Proposal';
    } else {
      return 'Applying Finishing Touches';
    }
  };

  React.useEffect(() => {
    if (!timerInterval) {
      setTimerInterval(
        setInterval(() => {
          if (loadingWidth >= 100) {
            clearInterval(timerInterval);
            setTimerInterval(null);
          }
          setLoadingWidth((loadingWidth) => loadingWidth + 0.1);
        }, 70)
      );
    }
  }, [loadingWidth]);

  return (
    <FixedPageWrapper>
      <Content padding="0">
        <Logo src={logo} />
      </Content>
      <Content
        paddingTop="75px"
        width="300px"
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            marginBottom: '40px',
            position: 'relative',
            overflow: 'hidden',
            width: '100%',
            borderRadius: '9px',
            boxShadow: 'inset 0px 1px 3px rgba(0,0,0,0.2)',
          }}
        >
          <div
            style={{
              transition: 'none 0s ease 0s',
              width: `${loadingWidth > 95 ? 95 : loadingWidth}%`,
              height: '18px',
              borderRadius: '9px',
              animation: 'cssProgressActive 2s linear infinite',
              backgroundColor: '#66bb6a',
              display: 'block',
              float: 'left',
              boxShadow: 'inset 0px -1px 2px rgba(0,0,0,0.1)',
              backgroundImage:
                'linear-gradient(-45deg, rgba(255,255,255,0.125) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.125) 50%, rgba(255,255,255,0.125) 75%, transparent 75%, transparent)',
              backgroundSize: '35px 35px',
            }}
          >
            <span
              style={{
                lineHeight: '18px',
                position: 'absolute',
                overflow: 'hidden',
                left: '0px',
                right: '0px',
                color: 'rgba(0,0,0,0.6)',
                fontSize: '0.7em',
                textAlign: 'center',
                textShadow: '0px 1px rgba(0,0,0,0.3)',
                boxSizing: 'border-box',
              }}
            >
              Loading...
            </span>
          </div>
        </div>

        {getCurrentText()}
      </Content>
    </FixedPageWrapper>
  );
};

export const LoadingPage = () => (
  <FixedPageWrapper>
    <Content padding="0">
      <Logo src={logo} />
    </Content>
    <Content paddingTop="75px" width="300px">
      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
          width: '100%',
          borderRadius: '9px',
          boxShadow: 'inset 0px 1px 3px rgba(0,0,0,0.2)',
        }}
      >
        <div
          style={{
            transition: 'none 0s ease 0s',
            width: '100%',
            height: '18px',
            borderRadius: '9px',
            animation: 'cssProgressActive 2s linear infinite',
            backgroundColor: '#66bb6a',
            display: 'block',
            float: 'left',
            boxShadow: 'inset 0px -1px 2px rgba(0,0,0,0.1)',
            backgroundImage:
              'linear-gradient(-45deg, rgba(255,255,255,0.125) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.125) 50%, rgba(255,255,255,0.125) 75%, transparent 75%, transparent)',
            backgroundSize: '35px 35px',
          }}
        >
          <span
            style={{
              lineHeight: '18px',
              position: 'absolute',
              overflow: 'hidden',
              left: '0px',
              right: '0px',
              color: 'rgba(0,0,0,0.6)',
              fontSize: '0.7em',
              textAlign: 'center',
              textShadow: '0px 1px rgba(0,0,0,0.3)',
              boxSizing: 'border-box',
            }}
          >
            Loading...
          </span>
        </div>
      </div>
    </Content>
  </FixedPageWrapper>
);

export const ThanksPage = ({
  fromEmail,
  fromPerformance,
  bidData,
  pefData,
  onBack,
  pdfName,
  onClickDownload
}) => {
  const [biggerThan796, setBiggerThan796] = React.useState(false);

  const setBreakpoints = () => {
    setBiggerThan796(window.innerWidth >= 796);
  };

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);
  

  return (
    <>
      <HeaderSection paddingBottom="0px">
        <Content padding="0">
          <HeaderLogo src={logo} />
          {isWebView() && (
            <div
              className="back-button-container"
              style={{
                position: 'absolute',
                right: 0,
                top: '58px',
              }}
            >
              <button className="button" onClick={() => onBack()}>
                BACK
              </button>
            </div>
          )}
        </Content>
        <Content
          padding="0"
          style={{
            width: '100%',
            textAlign: 'center',
            position: 'relative',
          }}
        >
          <HeaderPic src={headerPic} />
          <Background />
        </Content>
        <Content
          style={{
            paddingTop: biggerThan796 ? '5vw' : '8vw',
            paddingBottom: biggerThan796 ? '5vw' : '8vw',
          }}
        >
          <HeaderTitle marginTop="15px">Thank You!</HeaderTitle>
        </Content>
      </HeaderSection>
      <Content padding="30px">
        <Paragraph
          textAlign="center"
          fontSize="18px"
          style={{ fontWeight: 'bold' }}
        >
          {fromPerformance &&
            'Thank you for providing us with a rating of your Janitorial Services. Your business is greatly appreciated.'}
          {!fromPerformance &&
            'Thank you for choosing Anago! A Representative will contact you regarding the next step.'}
        </Paragraph>

        <Paragraph
          textAlign="center"
          fontSize="18px"
          style={{ fontWeight: 'bold' }}
        >
          {!fromEmail &&
            !fromPerformance &&
            'An executed copy of the Agreement has been emailed to you or you may click below to download.'}
          {fromEmail &&
            !fromPerformance &&
            'If your Contract fail to download, please click the link below.'}
        </Paragraph>

        <Paragraph
          textAlign="center"
          fontSize="18px"
          style={{
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={pdfIcon} width="48px" />
          <FakeAnchor onClick={onClickDownload}>
            {bidData
              ? `Download Proposal ${pdfName} - ${bidData['BusinessName']}`
              : pefData
              ? `Download ${pdfName} - ${pefData['Company']}`
              : 'Download document'}
          </FakeAnchor>
        </Paragraph>
      </Content>
    </>
  );
};

export const ExpiredPage = ({
  headerMessage,
  bodyMessage,
  footerMessage,
  onBack,
  invertedMessage,
  deprecatedPage,
}) => {
  const [biggerThan796, setBiggerThan796] = React.useState(false);

  const setBreakpoints = () => {
    setBiggerThan796(window.innerWidth >= 796);
  };

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  return (
    <>
      <HeaderSection paddingBottom="0px">
        <Content padding="0">
          <HeaderLogo src={logo} />
          {isWebView() && !deprecatedPage && (
            <div
              className="back-button-container"
              style={{
                position: 'absolute',
                right: 0,
                top: '58px',
              }}
            >
              <button
                className="button"
                onClick={() => (!!onBack ? onBack() : {})}
              >
                BACK
              </button>
            </div>
          )}
        </Content>
        {invertedMessage && (
          <Content
            style={{
              paddingTop: biggerThan796 ? '5vw' : '8vw',
              paddingBottom: biggerThan796 ? '5vw' : '8vw',
            }}
          >
            <HeaderTitle marginTop="15px">
              {headerMessage}
              {!headerMessage && 'Proposal has been expired!'}
            </HeaderTitle>
          </Content>
        )}

        {invertedMessage && (
          <Content padding="30px">
            <Paragraph
              textAlign="center"
              fontSize="18px"
              style={{ fontWeight: 'bold' }}
            >
              {bodyMessage && (
                <Message dangerouslySetInnerHTML={{ __html: bodyMessage }} />
              )}
              {!bodyMessage && 'Contact your Anago Regional Office.'}

              {footerMessage && (
                <>
                  <br />
                  <br /> {footerMessage}
                </>
              )}
            </Paragraph>
          </Content>
        )}

        <Content
          padding="0"
          style={{
            width: '100%',
            textAlign: 'center',
            position: 'relative',
            marginTop: invertedMessage ? '100px' : null,
          }}
        >
          <HeaderPic src={headerPic} />
          <Background />
        </Content>
        {!invertedMessage && (
          <Content
            style={{
              paddingTop: biggerThan796 ? '5vw' : '8vw',
              paddingBottom: biggerThan796 ? '5vw' : '8vw',
            }}
          >
            <HeaderTitle marginTop="15px">
              {headerMessage}
              {!headerMessage && 'Proposal has been expired!'}
            </HeaderTitle>
          </Content>
        )}
      </HeaderSection>

      {!invertedMessage && (
        <Content padding="30px">
          <Paragraph
            textAlign="center"
            fontSize="18px"
            style={{ fontWeight: 'bold' }}
          >
            {bodyMessage && (
              <Message dangerouslySetInnerHTML={{ __html: bodyMessage }} />
            )}
            {!bodyMessage && 'Contact your Anago Regional Office.'}

            {footerMessage && (
              <>
                <br />
                <br /> {footerMessage}
              </>
            )}
          </Paragraph>
        </Content>
      )}
    </>
  );
};

const BlockingOverlayBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: gray;
  opacity: 0.5;
  z-index: 99;
`;

const BlockingOverlayWrapper = styled(BlockingOverlayBackground)`
  opacity: 1;
  background-color: transparent;
  display: flex;
  justify-content: center;
  padding-top: 100px;

  span {
    font-weight: bolder;
    font-size: 18px;
    color: red;
  }
`;

export const BlockingOverlay = (props) => {
  return (
    <>
      <BlockingOverlayBackground />
      {/* <BlockingOverlayWrapper>{props.children}</BlockingOverlayWrapper> */}
      {props.children}
    </>
  );
};
