import React from 'react';
import styled, { css } from 'styled-components';
import { StyledButton, Content as BtnContent } from 'core/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Button = styled(StyledButton)`
  width: 100%;

  svg {
    margin-right: 10px;
  }

  @media (max-width: 900px) {
    width: auto;
  }

  @media (min-width: 767px) {
    width: auto;
  }
`;

const ButtonLabel = styled.span`
  font-size: 20px;
`;

export const IconButton = (props) => (
  <Button
    color="#F7A11D"
    width="auto"
    padding="0 15px"
    kind="outline"
    reverseSkew={true}
    onClick={props.onClick}
  >
    <BtnContent
      style={{
        paddingRight: '10px',
        paddingLeft: '10px',
      }}
    >
      <FontAwesomeIcon icon={props.icon} />
      <ButtonLabel>{props.title}</ButtonLabel>
    </BtnContent>
  </Button>
);
