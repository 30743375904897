import React from 'react';

import { Input, InputMasked } from './styles';
import {
  formatNumberByMask,
  capitalizeInput,
  convertStringToNumber,
} from 'core/utils';

interface IBoxInput {
  value: string | number;
  name: string;
  onChange: Function;
  mask?: any;
  maskParams?: any;
  type?: string;
  maxLength?: number;
  alignCenter?: boolean;
  onBlur?: any;
  width?: string;
  color?: string;
  flex?: boolean;
  disabled?: boolean;
  inputClassName?: string;
  capitalizeFunction?: Function;
  inputStyle?: any;
  flexDirection?: string;
  minWidth?: string;
  maxWidth?: string;
  tabIndex?: string;
  id?: string;
}

export const BoxInput = ({
  value,
  name,
  onChange,
  mask,
  maskParams,
  maxLength,
  type,
  alignCenter = false,
  onBlur,
  width,
  color,
  flex,
  disabled,
  inputClassName,
  capitalizeFunction = capitalizeInput,
  flexDirection,
  inputStyle,
  minWidth,
  maxWidth,
  tabIndex,
  id,
}: IBoxInput) => {
  const onBlurWrapper = (e: any) => {
    if (e.target.value && maskParams) {
      onChange(name, formatNumberByMask(e.target.value, maskParams));
    } else {
      if (!e.target.value || value.toString() !== e.target.value.toString()) {
        // console.log({ value, target: e.target.value });
        onChange(name, e.target.value);
      }
    }
    onBlur();
  };

  const capitalize = (value: string) => {
    return capitalizeFunction ? capitalizeFunction(value) : value;
  };

  if (!!mask) {
    return (
      <InputMasked
        // value={formatNumberByMask(value !== null && value !== undefined ? value.toString() : '', maskParams || {})}
        value={value !== null && value !== undefined ? value : ''}
        name={name}
        id={id}
        key={name}
        mask={mask}
        className={`${inputClassName || ''}`}
        type={maskParams && maskParams.type ? maskParams.type : type}
        onKeyUp={(e) => {
          if (
            (e.keyCode == 8 || e.keyCode == 46) &&
            convertStringToNumber(value) == 0 &&
            e.target &&
            e.target.value === ''
          ) {
            onChange(name, e.target.value);
          }
        }}
        onChange={(e: any) => {
          if (e.target.value) {
            console.log(e.target.value);
          }

          onChange(
            name,
            capitalize(
              maskParams && maskParams.prefix
                ? e.target.value.replace(maskParams.prefix, '')
                : e.target.value
            )
          );
        }}
        maxLength={maxLength}
        center={alignCenter}
        onBlur={onBlurWrapper}
        onFocus={(e: any) => e.target.select()}
        width={width}
        color={color}
        flex={flex}
        flexDirection={flexDirection}
        minWidth={minWidth}
        maxWidth={maxWidth}
        disabled={disabled}
        style={inputStyle}
        autoCapitalize="words"
        tabIndex={tabIndex}
      />
    );
  } else {
    return (
      <Input
        value={value !== null && value !== undefined ? value : ''}
        key={name}
        name={name}
        type={type}
        className={`${inputClassName || ''}`}
        onChange={(e: any) => onChange(name, capitalize(e.target.value))}
        maxLength={maxLength}
        center={alignCenter}
        onBlur={onBlur}
        onFocus={(e: any) => e.target.select()}
        width={width}
        flex={flex}
        flexDirection={flexDirection}
        disabled={disabled}
        color={color}
        style={inputStyle}
        autoCapitalize="words"
        tabIndex={tabIndex}
        id={id}
      />
    );
  }
};
