import React, { memo } from 'react';
import { withFormik, FormikProps } from 'formik';
import { IBidHotButtonItems } from 'modules/BidInfoForm/interfaces';
import { hotButtonItemsValue } from 'modules/shared/bidDefaultValues';

import { ContentInput, BoxInput, useFormProps } from 'core/components';

import './styles.scss';

type IProps = FormikProps<IBidHotButtonItems>;
const HotButtomItems = memo(({}: IProps) => {
  const { onChange, formValues } = useFormProps();

  return (
    <div className="hot-button-items-root">
      <div className="hot-button-items-content">
        <ContentInput width="100%" justify="flex-start">
          <h3 className="hot-button-items-title">Hot Button Items</h3>
        </ContentInput>

        <div className="button-items-input">
          <div className="button-items-label">Special Attention Areas:</div>
          <BoxInput
            value={formValues.specialAttentionAreas}
            name="specialAttentionAreas"
            onChange={onChange}
            onBlur={() =>
              onChange(
                'specialAttentionAreas',
                formValues.specialAttentionAreas
              )
            }
            capitalizeFunction={(item: string) =>
              // item.charAt(0).toUpperCase() + item.slice(1)
              item
            }
            flex
            disabled={formValues.status === 2}
          />
        </div>
      </div>
    </div>
  );
});

const mapForm = {
  mapPropsToValues: () => ({
    ...hotButtonItemsValue,
  }),
  handleSubmit: () => '',
};

const EnhancedHotButtonItems = withFormik<any, IBidHotButtonItems>(mapForm)(
  HotButtomItems
);

export { EnhancedHotButtonItems as HotButtomItems };
