import React from 'react';
import { Layout, HiddenLayout } from 'modules/BidInfoForm/components/layout';
import { Paragraph, Anchor } from 'modules/BidInfoForm/components/atoms';

export const TermsAndConditions = ({
  values,
  franchiseValues,
  isTermsChecked,
  setIsTermsChecked,
  isSS,
  pageChanged,
}: any) => {
  const [opened, setOpened] = React.useState(false);

  React.useEffect(() => {
    setOpened(false);
  }, [pageChanged]);

  const termsAndConditionsValues = values['TermsAndConditions'] || [];

  const stripHtml = (html) => {
    var tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const termsValues = isSS
    ? values['NewSSProposalCleaningSpecsReplaced']
    : values['NewBidProposalCleaningSpecsReplaced'];

  return (
    <Layout margin="0" width="100%" maxWidth="900px">
      <Layout padding="0">
        <Layout flexDirection="row">
          {/* <ContentInput padding="9px" paddingRight="0">
            <Checkbox
              borderColor="gray"
              name="agreed"
              checked={isTermsChecked}
              onChange={(...args) => {
                setIsTermsChecked(!isTermsChecked);
              }}
            />
          </ContentInput> */}
          <Paragraph fontSize="16px" textAlign="center">
            <Anchor
              onClick={() => setOpened(!opened)}
              data-cy="terms-and-conditions-link"
            >
              Terms &amp; Conditions
            </Anchor>
          </Paragraph>
        </Layout>

        <HiddenLayout opened={opened} hasSubtitle maxWidth="900px">
          <Paragraph textAlign="center" data-cy="terms-and-conditions-title">
            Service Procurement Agreement
          </Paragraph>
          <Paragraph
            textAlign="justify"
            data-cy="terms-and-conditions-content"
            style={{
              whiteSpace: 'unset',
            }}
            dangerouslySetInnerHTML={{
              __html: termsValues
                ? termsValues
                    .split('\r\n')
                    .join('<br />')
                    .split('\n\n')
                    .join('<br />')
                    .split('\r\r')
                    .join('<br />')
                : '',
            }}
          ></Paragraph>
        </HiddenLayout>
      </Layout>
    </Layout>
  );
};
