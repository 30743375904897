import React from 'react';
// ui
import './styles.scss';

//magnifying glass for search
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { SearchModal } from './SearchModal';
import ActionAlertModal from 'core/components/ActionAlertModal';
import { getCookie } from 'core/utils/cookiesUtils';

export default (props) => {
  const { data, background, doneAction } = props;
  const {
    renderSignedSection,
    renderCityTemplatesSection,
    renderTemplateSection,
    renderDraftSection,
    renderSentSection,
  } = props;
  const [state, setStateFunction] = React.useState({
    sectionSelected: '',
    sortByMostRecent: true,
    azAscending: true,
    dateMostRecent: true,
    showSearchModal: false,
    showAlertModal: false,
    formSelected: null,
    currentSortFunction: () => {},
  });

  const isCityUser = getCookie('ANAGO_USER_IS_CITY_USER') === 'true';

  const setState = (value: any) => {
    setStateFunction((prevState) => ({
      ...prevState,
      ...(value || {}),
    }));
  };

  const setType = (arr) => {
    arr['Signed'] = arr['Signed'].map((item) => {
      item.type = 'signed';
      return item;
    });
    arr['Drafts'] = arr['Drafts'].map((item) => {
      item.type = 'drafts';
      return item;
    });
    arr['Templates'] = arr['Templates'].map((item) => {
      item.type = 'templates';
      return item;
    });
    arr['CityTemplates'] = arr['CityTemplates'].map((item) => {
      item.type = 'citytemplates';
      return item;
    });
    arr['Sents'] = arr['Sents'].map((item) => {
      item.type = 'sents';
      return item;
    });
  };

  React.useEffect(() => {
    if (Object.keys(data).length > 0) {
      setType(data);

      setState({
        totalOfSignedItems: data['TotalSigned'],
        totalOfDraftItems: data['TotalDrafts'],
        totalOfSentItems: data['TotalSents'],
        totalOfTemplateItems: data['TotalTemplates'],
        totalOfCityTemplateItems: data['TotalCityTemplates'],
        signedItems: data['Signed'].sort(state.currentSortFunction),
        sentItems: data['Sents'].sort(state.currentSortFunction),
        draftItems: data['Drafts'].sort(state.currentSortFunction),
        templateItems: data['Templates'].sort(state.currentSortFunction),
        cityTemplateItems: data['CityTemplates'].sort(state.currentSortFunction),
      });
    }
  }, [data]);

  const setSectionSelected = (section: string, target: Element = undefined) => {
    setState({ sectionSelected: section });
    setTimeout(() => {
      if (target) {
        document
          .querySelector('.bid-menu-container')
          .scrollTo(0, target.offsetTop - 80);
      }
    }, 0);
  };

  const onClickNote = (form: any, isSent: boolean) => {
    const { showNotesModal } = props;
    showNotesModal(!isSent, form);
  };

  const onClickDismiss = (form: any) => {
    setState({
      showAlertModal: true,
      formSelected: form,
    });
  };

  const onConfirmDeletion = () => {
    const { formSelected } = state;
    props.archiveForm(formSelected);
    setState({
      showAlertModal: false,
      formSelected: null,
    });
  };

  const onClickLoadMore = (type) => {
    props.getNextPage(type);
  };

  const onClickSearchIcon = (type) => {
    setState({
      type,
      showSearchModal: true,
    });
  };

  const truncateName = (name: string) => {
    if (name && name.length > 10) {
      return name.slice(0, 10) + '...';
    } else {
      return name;
    }
  };

  const renderTableItems = (
    item: any,
    index: number,
    onClickAction: (form: any) => void,
    type: string
  ) => {
    return (
      <tr
        data-id={item.BidInformationSheetId}
        key={index}
        onClick={() => onClickAction(item)}
      >
        <td className="td-auto-save">
          {item.IsAutoSave || item.HaveTemp ? (
            <div className="action-container">
              <i className="auto-save-icon" />
            </div>
          ) : (
            ''
          )}
        </td>
        <td className="td-title">{truncateName(item.PID)}</td>
        <td className="td-last-update">
          Last Updated: {new Date(item.LastChange).toLocaleDateString()}
          <br />
          {new Date(item.LastChange).toLocaleTimeString()}
        </td>
        <td
           className="td-actions"
           style={{
             opacity:
               !item.Notes && (type === 'sent' || type === 'signed' || (type === 'citytemplate' && !isCityUser)) ? 0.5 : 1,
           }}
           onClick={(e: any) => {
             e.stopPropagation(); // adding this so when pressing the EditNote button, the modal stays on the /menu route
             e.preventDefault();
             if (!(!item.Notes && (type === 'sent' || type === 'signed' || (type === 'citytemplate' && !isCityUser)))) {
               onClickNote(item, type === 'sent' || type === 'signed' || (type === 'citytemplate' && !isCityUser));
             }
           }}
         >
           <i className="note-icon" />
           {type === 'sent' || type === 'signed' || (type === 'citytemplate' && !isCityUser) ? (
             <span>View Note</span>
           ) : (
             <span>Edit Note</span>
           )}
         </td>
         {(type != 'citytemplate' || isCityUser) && <td
          className="td-actions"
          onClick={(e) => {
            e.stopPropagation();
            onClickDismiss(item);
          }}
        >
          <i className="trash-icon" />
          <span>Archive</span>
        </td>}
      </tr>
    );
  };

  const renderSigned = () => {
    const { sectionSelected, totalOfSignedItems, signedItems } = state;
    return (
      <div className="section-container">
        <div className="title-container">
          <div className="content-container">
            <p>SIGNED</p>
            {sectionSelected === 'Signed' ? (
              <i
                className="arrow-up-icon"
                onClick={() => setSectionSelected('')}
              />
            ) : (
              <i
                className="arrow-down-icon"
                onClick={(e) => setSectionSelected('Signed', e.currentTarget)}
              />
            )}
          </div>
        </div>
        {sectionSelected === 'Signed' && (
          <table className="table-container">
            <tbody>
              {signedItems.map((item, index) =>
                renderTableItems(item, index, props.navigateToForm, 'signed')
              )}
              {signedItems.length < totalOfSignedItems && (
                <tr className="load-more-wrapper">
                  <button
                    className="button-secondary"
                    onClick={() => onClickLoadMore('Signed')}
                  >
                    LOAD MORE
                  </button>
                  <div onClick={() => onClickSearchIcon('Signed')}>
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="2x"
                      color="#216eab"
                    />
                  </div>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    );
  };

  const renderDrafts = () => {
    const { sectionSelected, totalOfDraftItems, draftItems } = state;
    return (
      <div className="section-container">
        <div className="title-container">
          <div className="content-container">
            <p>DRAFTS</p>
            {sectionSelected === 'Drafts' ? (
              <i
                className="arrow-up-icon"
                onClick={() => setSectionSelected('')}
              />
            ) : (
              <i
                className="arrow-down-icon"
                onClick={(e) => setSectionSelected('Drafts', e.currentTarget)}
              />
            )}
          </div>
        </div>
        {sectionSelected === 'Drafts' && (
          <table className="table-container">
            <tbody>
              {draftItems.map((item, index) =>
                renderTableItems(item, index, props.navigateToForm, 'draft')
              )}
              {draftItems.length < totalOfDraftItems && (
                <tr className="load-more-wrapper">
                  <button
                    className="button-secondary"
                    onClick={() => onClickLoadMore('Drafts')}
                  >
                    LOAD MORE
                  </button>
                  <div onClick={() => onClickSearchIcon('Drafts')}>
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="2x"
                      color="#216eab"
                    />
                  </div>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    );
  };

  const renderMyTemplates = () => {
    const { sectionSelected, totalOfTemplateItems, templateItems } = state;
    return (
      <div className="section-container">
        <div className="title-container">
          <div className="content-container">
            <p>MY TEMPLATES</p>
            {sectionSelected === 'Templates' ? (
              <i
                className="arrow-up-icon"
                onClick={() => setSectionSelected('')}
              />
            ) : (
              <i
                className="arrow-down-icon"
                onClick={(e) =>
                  setSectionSelected('Templates', e.currentTarget)
                }
              />
            )}
          </div>
        </div>
        {sectionSelected === 'Templates' && (
          <table className="table-container">
            <tbody>
              {templateItems.map((item, index) =>
                renderTableItems(
                  item,
                  index,
                  (formItem) => props.navigateToForm(formItem, 'myTemplate'),
                  'myTemplate'
                )
              )}
              {templateItems.length < totalOfTemplateItems && (
                <tr className="load-more-wrapper">
                  <button
                    className="button-secondary"
                    onClick={() => onClickLoadMore('Templates')}
                  >
                    LOAD MORE
                  </button>
                  <div onClick={() => onClickSearchIcon('Templates')}>
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="2x"
                      color="#216eab"
                    />
                  </div>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    );
  };

  const renderCityTemplates = () => {
    const { sectionSelected, totalOfCityTemplateItems, cityTemplateItems } = state;
    return (
      <div className="section-container">
        <div className="title-container">
          <div className="content-container">
            <p>CITY TEMPLATES</p>
            {sectionSelected === 'CityTemplates' ? (
              <i
                className="arrow-up-icon"
                onClick={() => setSectionSelected('')}
              />
            ) : (
              <i
                className="arrow-down-icon"
                onClick={(e) =>
                  setSectionSelected('CityTemplates', e.currentTarget)
                }
              />
            )}
          </div>
        </div>
        {sectionSelected === 'CityTemplates' && (
          <table className="table-container">
            <tbody>
              {cityTemplateItems.map((item, index) =>
                renderTableItems(
                  item,
                  index,
                  (formItem) => props.navigateToForm(formItem, 'citytemplate'),
                  'citytemplate'
                )
              )}
              {cityTemplateItems.length < totalOfCityTemplateItems && (
                <tr className="load-more-wrapper">
                  <button
                    className="button-secondary"
                    onClick={() => onClickLoadMore('CityTemplates')}
                  >
                    LOAD MORE
                  </button>
                  <div onClick={() => onClickSearchIcon('CityTemplates')}>
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="2x"
                      color="#216eab"
                    />
                  </div>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    );
  };


  const renderSent = () => {
    const { sectionSelected, totalOfSentItems, sentItems } = state;
    return (
      <div className="section-container">
        <div className="title-container">
          <div className="content-container">
            <p>SENT</p>
            {sectionSelected === 'Sent' ? (
              <i
                className="arrow-up-icon"
                onClick={() => setSectionSelected('')}
              />
            ) : (
              <i
                className="arrow-down-icon"
                onClick={(e) => setSectionSelected('Sent', e.currentTarget)}
              />
            )}
          </div>
        </div>
        {sectionSelected === 'Sent' && (
          <table className="table-container">
            <tbody>
              {sentItems.map((item, index) =>
                renderTableItems(item, index, props.navigateToForm, 'sent')
              )}
              {
                // when there is more than 20 items in the backend and we have less items loaded in the frontend than the number found in the backend, show both buttons
                totalOfSentItems > 20 && sentItems.length < totalOfSentItems && (
                  <tr className="load-more-wrapper">
                    <button
                      className="button-secondary"
                      onClick={() => onClickLoadMore('Sents')}
                    >
                      LOAD MORE
                    </button>
                    <div onClick={() => onClickSearchIcon('Sents')}>
                      <FontAwesomeIcon
                        icon={faSearch}
                        size="2x"
                        color="#216eab"
                      />
                    </div>
                  </tr>
                )
              }
              {/* {
                // show only search icon if there is no more items to load from backend
                sentItems.length >= totalOfSentItems && (
                  <tr className="load-more-wrapper">
                    <td>
                      <div onClick={onClickSearchIcon}>
                        <FontAwesomeIcon
                          icon={faSearch}
                          size="2x"
                          color="#216eab"
                        />
                      </div>
                    </td>
                  </tr>
                )
              } */}
            </tbody>
          </table>
        )}
      </div>
    );
  };

  const closeModal = () => {
    setState({
      showModal: false,
    });
    if (doneAction) {
      doneAction();
    }
  };

  const closeSearchModal = () => {
    setState({
      showSearchModal: false,
    });
  };

  const sortByDate = () => {
    const sortFunction = (a, b) => {
      if (state.dateMostRecent) {
        return new Date(a.LastChange) - new Date(b.LastChange);
      }
      return new Date(b.LastChange) - new Date(a.LastChange);
    };
    const currSortFunc = sortFunction;
    setState({
      sortByMostRecent: true,
      dateMostRecent: state.sortByMostRecent ? !state.dateMostRecent : true, // if condition is false, it means it came from AZ to date button, so we set the initial state, otherwise, we just toggle
      azAscending: true, // settting it true so when clicking the date button again, we see the labels changing back to its default value
      draftItems: state.draftItems.sort(sortFunction),
      sentItems: state.sentItems.sort(sortFunction),
      templateItems: state.templateItems.sort(sortFunction),
      cityTemplateItems: state.cityTemplateItems.sort(sortFunction),
      currentSortFunction: currSortFunc,
    });
  };

  const sortByAZ = () => {
    const sortFunction = (a, b) => {
      if (state.azAscending && !state.sortByMostRecent) {
        return b.PID ? b.PID.localeCompare(a.PID) : -1;
      }
      return a.PID ? a.PID.localeCompare(b.PID) : -1;
    };

    const currSortFunc = sortFunction;

    setState({
      sortByMostRecent: false,
      dateMostRecent: false,
      azAscending: state.sortByMostRecent ? true : !state.azAscending, // if condition is true, it means it came from the date button, so we set the initial state, otherwise, we just toggle
      // dateMostRecent: false, // settting it false so when clicking to sort by date it returns to its default value
      draftItems: state.draftItems.sort(sortFunction),
      sentItems: state.sentItems.sort(sortFunction),
      templateItems: state.templateItems.sort(sortFunction),
      cityTemplateItems: state.cityTemplateItems.sort(sortFunction),
      currentSortFunction: currSortFunc,
    });
  };

  const { showModal, sortByMostRecent, showSearchModal } = state;
  // const { showModal, showSearchModal } =  state;

  const getSortButtonClass = (type: 'date' | 'alpha') => {
    if (type === 'date') {
      return sortByMostRecent ? 'button' : 'button-secondary';
    } else {
      return sortByMostRecent ? 'button-secondary' : 'button';
    }
  };

  return (
    <>
      <div
        className={
          background ? 'modal-background-bid' : 'modal-bid-no-background'
        }
      >
        <div className="modal-content-container">
          <div className="modal-title-container">
            <h3>{props.title}</h3>
            <div className="buttons-row">
              <p>Sort by:</p>
              <div className="buttons-row-wrapper">
                <button
                  className={`button-filter-date ${getSortButtonClass(
                    'date'
                  )} ${state.dateMostRecent ? 'button-invert' : ''}`}
                  onClick={() => sortByDate()}
                >
                  Date
                </button>
                <button
                  className={`button-filter-sort ${getSortButtonClass(
                    'alpha'
                  )}`}
                  onClick={() => sortByAZ()}
                >
                  {state.azAscending ? 'A-Z' : 'Z-A'}
                </button>
              </div>
              {/* {sortByMostRecent ? "Sorting by Most Recent" : "Sorting by A-Z"} */}
            </div>
          </div>
          {console.log(renderDraftSection)}
          <div className="bid-menu-container">
            {renderDraftSection && renderDrafts()}
            {renderTemplateSection && renderMyTemplates()}
            {renderCityTemplatesSection && renderCityTemplates()}
            {renderSentSection && renderSent()}
            {renderSignedSection && renderSigned()}
          </div>
        </div>
        <div className="modal-done-button" onClick={() => closeModal()}>
          <button className="button">DONE</button>
        </div>
      </div>
      {showSearchModal && (
        <SearchModal
          onCloseModal={closeSearchModal}
          renderTableItems={renderTableItems}
          data={data}
          type={state.type}
          isSpecialty={props.isSpecialty}
          onClickSent={props.navigateToForm}
        />
      )}

      <ActionAlertModal
        title=""
        message="Are you sure you want to archive this item?"
        onConfirm={() => onConfirmDeletion()}
        // onDismiss={ props.closeExitModal}
        onDismiss={() => {
          setState({
            showAlertModal: false,
            formSelected: null,
          });
        }}
        isVisible={state.showAlertModal}
      />
    </>
  );
};
