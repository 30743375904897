import React from 'react';
import { Button } from '../Button';
import styled from 'styled-components';

interface IBubbleButon {
  children: any;
  onClick: Function;
  color?: string;
  bgColor?: string;
  kind?: string;
  margin?: string;
  disabled?: boolean;
  showBubble: boolean;
  onCloseBubble: Function;
  bubbleClasses?: string;
  bubbleStyles?: any;
  bubbleItems: Array<any>;
  style?: any;
}

interface IBubbleButonItem {
  underlined: boolean;
}
const Backdrop = styled.div`
  background-color: transparent;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
`;

const Bubble = styled.div`
  position: absolute;
  top: -15vh;
  //width: 25vw;
  height: 14vh;
  padding: 0px;
  background: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-left: -10px;
  border: #9a9a9a solid 2px;
  @media (max-width: 768px) {
    left: 50%;
  }
  @media (max-width: 900px) {
    // left: 67%;
    left: 53%;
  }

  ${({ left }: any) => (left ? `left: ${left};` : '')}

  &:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 10px 10px 0;
    border-color: #ffffff transparent;
    display: block;
    width: 0;
    z-index: 1;
    bottom: -9px;
    left: calc(50% - 10px);
  }
  &:before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 11px 11px 0;
    border-color: #9a9a9a transparent;
    display: block;
    width: 0;
    z-index: 0;
    bottom: -12px;
    left: calc(50% - 11px);
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-size: 24px;
  font-family: 'OpenSans-SemiBoldItalic';
  padding-left: 24px;
  padding-right: 24px;
`;

const BubbleItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50%;
  width: 100%;
  background: none;
  font-size: 1em;
  border-bottom: ${({ underlined }: IBubbleButonItem) =>
    underlined ? '2px solid #9a9a9a' : 'none'};
`;

export const BubbleButton = (props: IBubbleButon) => {
  const {
    children,
    showBubble,
    onCloseBubble,
    bubbleClasses,
    bubbleStyles,
    bubbleItems,
    style,
  } = props;

  if (!showBubble) {
    return <Button {...props} />;
  }

  return (
    <div>
      <Backdrop onClick={() => onCloseBubble()} />
      <Bubble left={style.left}>
        <Content>
          {bubbleItems.map((item, index) => (
            <BubbleItem
              underlined={index < bubbleItems.length - 1}
              className={bubbleClasses}
              style={{ ...bubbleStyles }}
              onClick={item.onClick}
            >
              {item.value}
            </BubbleItem>
          ))}
        </Content>
      </Bubble>
      <Button {...props}>{children}</Button>
    </div>
  );
};
