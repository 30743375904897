import runtimeEnv from '@mars/heroku-js-runtime-env';
const env = runtimeEnv();

export const ANAGO_USER_REFRESH_TOKEN = 'ANAGO_USER_REFRESH_TOKEN';
export const ANAGO_USER_EXPIRE_DATE = 'ANAGO_USER_EXPIRE_DATE';
export const
  ANAGO_USER_ACCESS_TOKEN = 'ANAGO_USER_ACCESS_TOKEN';
export const ANAGO_CLEANSOURCE_DEV_LOGIN_URL = env.REACT_APP_CLEAN_SOURCE_LOGIN_URL || 'https://cleansource.dev.anagocleansource-test.com/login';

export const BID_INFORMATION_SHEET_ID = 9;
export const CONTRACT_DECLINE_ID = 7;
export const SPECIALTY_FORM_ID = 8;
export const INSPECTION_REPORT_FORM_ID = 6;
export const PERFORMANCE_EVALUATION_FORM_ID = 2;
