export const oneDayTypes = {
  'Auto Dealer': {
    weekly: [
      {
        title: 'Showroom, Corridors, and Common Areas',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area. ',
          'Thoroughly clean entrance glass from edge to edge.',
          'Dust counters, ledges, furnishings, and other horizontal surfaces.',
          'Damp wipe or spot clean counters, ledges, furnishings, and other horizontal surfaces as needed to remove spills and soil, per Client’s instruction.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Spot clean carpets to remove any new spills.',
          'Dust baseboards.',
          'Dust all blinds and verticals.',
          'Dust mop all hard surface floors.',
          'Damp mop all hard surface floors.',
          'Auto scrub all hard surfaces floors.',
          'Sweep stairwell, dust handrails, and clean elevators.',
          'Dust mop or vacuum elevator and clean and polish door fronts.',
        ],
      },
      {
        title:
          'Sales and Management Offices, Waiting Areas, and Service Offices',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area. ',
          'Dust mop, then damp mop floors. ',
          'Vacuum traffic areas of carpeting, including mats and runners.',
          'Dust desks, credenzas, tables, file cabinets, and other furnishings and office equipment, per Client’s instruction.',
          'Damp wipe or spot clean exposed horizontal surfaces as needed.',
          'Remove trash to the collection point and replace liners as needed.',
          'Spot clean partition glass to remove fingerprints and smudges.',
          'Spot clean carpets to remove any new spills.',
          'Spot check all partition glass.',
          'Dust baseboards.',
          'Clean and disinfect telephones, including receivers and cradles.',
        ],
      },
      {
        title: 'Lunchroom and Break Room Areas',
        frequencyTypes: [
          'All receptacles will be emptied and trash removed to the dumpster area.',
          'Wipe clean exterior of trash receptacles and wall behind receptacles.',
          'Damp wipe all tables and spot clean chairs.',
          'Dust mop, then wet mop floors with germicidal disinfectant. ',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Clean and disinfect counters and sinks.',
          'Wipe clean exterior of all countertop appliances in the Kitchen with disinfectant, including the interior of microwaves and vending machine touchpoints.',
          'Wipe down coffee machines and coffee stations. Check to be sure coffee machines have been turned off.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty trash receptacles and wipe, if needed.',
          'Wipe clean exposed pipes, counters, ledges, mirrors, and air grills with approved disinfectant.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Clean sinks, disinfect counters, and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
          'Wipe clean and disinfect tile walls and handrails.',
        ],
      },
    ],
    monthly: [
      {
        title: 'Showroom, Corridors, and Common Areas',
        frequencyTypes: [
          'Spot clean and disinfect light switches',
          'Spot clean door frames',
          'Machine scrub floors to remove soil and, if the floor is finished, buff/burnish as needed.',
          'Dust all high reach areas (above eye level) including, but not limited to, door frames, ceiling vents and grills, partitions tops, picture frames, high shelves, and cabinet tops, per Client’s instruction.',
          'Dust all light fixtures.',
          'Spot clean waste bins as needed.',
          'Vacuum upholstered furniture.',
        ],
      },
      {
        title:
          'Sales and Management Offices, Waiting Areas, and Service Offices',
        frequencyTypes: [
          'Dust all blinds and verticals.',
          'Dust all high reach areas (above eye level) including, but not limited to, door frames, ceiling vents and grills, partitions tops, picture frames, high shelves, and cabinet tops, per Client’s instruction.',
          'Dust all light fixtures.',
          'Spot clean waste bins as needed.',
          'Vacuum upholstered furniture.',
        ],
      },
      {
        title: 'Lunchroom and Break Room Areas',
        frequencyTypes: [
          'Spot clean walls, especially behind trash receptacles.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Thoroughly clean and disinfect all tile stalls, leave in streak-free condition.',
          'Wipe and disinfect all metal partitions, walls, kick plates, and thresholds.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
      {
        title: 'Shop Area',
        frequencyTypes: [
          'Clean shop office as per office cleaning schedule – performed Xweek.',
          'Clean shop restroom as per restroom cleaning schedule – performed Xweek.',
          'Auto scrub all shop Floors as per restroom cleaning schedule – performed Xweek.',
        ],
      },
    ],
  },
  'Bowling Alley': {
    weekly: [
      {
        title: 'Common Areas',
        frequencyTypes: [
          'All receptacles will be emptied and trashed removed to the dumpster area.',
          'Wipe clean exterior of trash receptacles and wall behind receptacles',
          'Empty all outside trash receptacles, replacing liners, and cigarette urns.',
          'Clean entrance area, including windows and door glass.',
          'Clean and disinfect door handles.',
          'Spot clean partition glass as needed.',
          'Clean and disinfect drinking fountains.     ',
          'Vacuum all areas of carpeting, including mats and runners.',
          'Dust mop or vacuum all hard surface floors.',
          'Damp mop all hard surface floors.',
        ],
      },
      {
        title: 'Game Room, Party Room, and Restaurant/Bar Areas',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trashed removed to the dumpster area.',
          'Vacuum all areas of carpeting, including mats and runners.',
          'Dust mop or vacuum all hard surface floors.',
          'Damp mop all hard surface floors.',
          'Damp wipe arcade games.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty trash receptacles and wipe, if needed.',
          'Wipe clean exposed pipes, counters, ledges, mirrors, and air grills with approved disinfectant.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Clean sinks and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and disinfect walls in restrooms.',
          'Clean and polish mirrors.',
          'Machine scrub and damp mop restroom floors with disinfectant, pouring water down drains to eliminate odors.',
        ],
      },
    ],
    monthly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Spot clean and disinfect light switches ',
          'Spot clean door frames.',
          'Disinfect telephones',
          'High dust HVAC vents and louvers.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: ['Clean corners and edges of restroom floors.'],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  'Country Club': {
    weekly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'All receptacles will be emptied and trash removed to the dumpster area. ',
          'Wipe clean exterior of trash receptacles and wall behind receptacles.',
          'Wipe clean all horizontal surfaces of desktops, tables, counters, and filing cabinets.',
          'Dust all vertical surfaces of desks, file cabinets, and other office furniture.',
          'Detail vacuum all carpet edges, corners, and beneath furniture.',
          'Damp mop hard surface floors, including corners, edges, and under office furniture.',
          'Disinfect telephones in offices.',
          'Spot clean elevator’s interior stainless steel and chrome surface of forward-facing cab wall and doors.',
          'Spot clean outside surfaces of all elevator doors and frames.',
          'Spot clean elevator cab floor.',
          'Dust all office equipment, including behind computers, monitors and their bases, and any movable objects on desks.',
          'Dust window sills.',
          'Dust picture frames.',
          'Fully clean entrance door windows. ',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
        ],
      },
      {
        title: 'Dining, Lounge, and Bar Areas',
        frequencyTypes: [
          'All receptacles will be emptied and trash removed to the dumpster area. ',
          'Wipe clean exterior of trash receptacles and wall behind receptacles.',
          'Spot clean all entrance area door glass.',
          'Sweep entranceways, inspect areas and remove trash.',
          'Clean and disinfect drinking fountains.',
          'Remove floor mats in bar areas and hose down in designated areas with client to place back after mats dry. ',
          'Clean tops of floor drains in bar areas.',
          'Dust base of tables. ',
          'Vacuum all areas of carpeting, including mats and runners.',
          'Dust mop all hard surface floors.',
          'Damp mop all hard surface floors.',
          'Sweep balcony patio.',
        ],
      },
      {
        title: 'Restrooms, Lockers, and Saunas',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty sanitary napkin receptacles, disinfect, and re-line from stock.',
          'Empty trash receptacles and wipe, as needed.',
          'Wipe clean exposed pipes, counters, ledges, and mirrors with approved disinfectant.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Spot clean partitions and dust tops.',
          'Clean sinks, disinfect counters, and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
          'Wipe clean and disinfect tile walls and handrails.',
          'Deck brush showers.',
          'Dust top of lockers',
          'Spot clean Sauna entrance glass and remove any debris. ',
        ],
      },
    ],
    monthly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Spot clean and disinfect light switches',
          'Spot Clean door frames',
          'Dust vertical and Venetian blinds.',
          'High dust HVAC vents and louvers.',
          'Dust ceiling corners and remove cobwebs.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  'Day Care': {
    weekly: [
      {
        title: 'Offices and Classrooms',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area. ',
          'Wipe clean and disinfect Classroom tables and properly arrange chairs.',
          'Wipe clean and disinfect all classroom sinks and countertops. Polish all chrome fittings.',
          'Disinfect all office equipment, including behind computers, monitors, and their bases, per Client’s instruction.',
          'Vacuum all areas of carpeting, including mats and runners.',
          'Wipe clean chalkboard/whiteboard ledges.',
          'Dust mop all hard surface floors.',
          'Damp mop hard surface floors, including corners, edges, and under office furniture.',
          'Wipe clean baseboards per Client’s instructions.',
          'Clean entrance area door glass.',
          'Spot clean partition glass, as needed.',
          'Clean and disinfect all drinking fountains and water coolers.',
          'Wipe clean and disinfect window sills.',
          'Wipe clean and disinfect picture frames.',
          'Disinfect inside of lockers.',
        ],
      },
      {
        title: 'Kitchen Areas',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area. ',
          'Wipe clean and disinfect sink, counters, and exterior of cabinets in Kitchen.',
          'Wipe clean exterior of all countertop appliances in Kitchen with disinfectant, including interior of microwave. ',
          'Sweep and damp mop all hard surface floors.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty trash receptacles and wipe with disinfectant, as needed.',
          'Wipe clean exposed pipes, counters, ledges, mirrors, and air grills with approved disinfectant.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Clean sinks, disinfect counters, and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors and fixtures.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
          'Wipe clean tile walls with a disinfectant.',
        ],
      },
    ],
    monthly: [
      {
        title: 'Offices and Classrooms',
        frequencyTypes: [
          'Spot clean and disinfect light switches.',
          'Spot clean door frames.',
          'Disinfect telephones.',
          'Dust all blinds and verticals.',
          'High dust HVAC vents and louvers.',
          'High dust ceiling corners and remove cobwebs.',
          'Clean interior of refrigerator with an approved solution. Client is responsible for removal of food.',
        ],
      },
      {
        title: 'Recommended Options',
        frequencyTypes: ['Disinfect inside of lockers'],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  'Day Spa': {
    weekly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'All receptacles will be emptied and trash removed to the dumpster area.',
          'Wipe clean exterior of trash receptacles and wall behind receptacles.',
          'Wipe clean all surfaces of Reception counter, tables, and counters with approved disinfectant.',
          'Wipe clean all vertical surfaces of desks, file cabinets, and other office furniture.',
          'Dust all office equipment, including behind computers, monitors, and their bases. ',
          'Wipe clean telephones.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Clean entrance area door glass',
          'Spot clean partition glass, as needed.',
          'Spot clean all mirrors.',
          'Spot clean and disinfect light switches     ',
          'Spot clean door frames and baseboards.',
          'Wipe clean all glass tables and display glass with glass cleaner.',
          'Wipe clean ledges and counters with disinfectant.',
          'Clean and disinfect drinking fountains.',
          'Remove debris from the outside patio area.     ',
          'Dust mop all hard surface floors.',
          'Damp mop all hard surface floors.',
          'Detail mop hard surface floors, including corners, edges, and under office and spa furniture.',
          'Dust window sills.',
          'Dust picture frames.',
          'Dust baseboards.',
          'Dust ceiling corners and remove cobwebs.',
          'Clean interior and exterior of elevator cabs. Polish chrome.',
        ],
      },
      {
        title: 'Spa Area',
        frequencyTypes: [
          'Wipe clean all counters, cabinets, and ledges in Treatment Rooms/Suits with approved disinfectant.',
          'Wipe clean treatment chairs, massage beds, and Mani/Pedi chairs including their bases and extensions with an approved disinfectant.',
          'Disinfect all sinks, tubs, and showers in Treatment Rooms/Suites. Polish all chrome fittings.',
          'Detail vacuum with attachment under treatment chairs, massage beds, and Mani/Pedi chairs including their bases and extensions.',
        ],
      },
      {
        title: 'Fitness Center',
        frequencyTypes: [
          'Vacuum and dust mop rubber flooring in the Fitness center.',
          'Detail vacuum with attachment under fitness machines, treatment chairs.',
          'Damp mop rubber flooring with the appropriate solution.',
          'Wipe clean and disinfect cardio equipment, pull up system, weight racks, weights, balls, and AB mats.',
        ],
      },
      {
        title: 'Restrooms & Lockers',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe front of dispensers.',
          'Empty trash receptacles and wipe, as needed.',
          'Wipe clean exposed pipes, counters, ledges, mirrors, and air grills with approved disinfectant.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Clean sinks and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Damp mop restroom floors with disinfectant, pouring water down drains to eliminate odors.',
          'Wipe clean and disinfect saunas with appropriate solutions.',
          'Wipe clean and disinfect showers, including outside showers, tile walls, and handrails. Polish all chrome fittings.',
          'Wipe clean and disinfect bathtub in Couple’s Room. Polish all chrome fittings.',
        ],
      },
    ],
    monthly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Disinfect telephones.',
          'High dust HVAC vents and louvers.',
          'Clean all mirrors streak-free.',
          'Dust all blinds and verticals.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Clean corners and edges of restroom floors.',
          'Polish all brass fittings.',
          'High dust tops of lockers and cabinets.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  'Dental Office': {
    weekly: [
      {
        title: 'General Office',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area. ',
          'Wipe clean telephones.',
          'Disinfect all vertical surfaces of desks, file cabinets, and other office furniture.',
          'Vacuum all areas of carpeting, including mats and runners.',
          'Detail vacuum all carpet edges, corners, and beneath furniture.',
          'Spot clean partition glass, as needed.',
          'Dust mop all hard surface floors.',
          'Damp mop all hard surface floors.',
          'Disinfect windowsills.',
          'Disinfect picture frames.',
          'Clean and disinfect all drinking fountains/water coolers. Clean tray.',
        ],
      },
      {
        title: 'Waiting Area',
        frequencyTypes: [
          'Wipe clean Waiting Area tables and Front Counter. Properly arrange chairs and periodicals.',
          'Clean entrance area door glass.',
          'Wipe clean glass tables with glass cleaner.',
          'Wipe clean ledges and counters with disinfectant.',
        ],
      },
      {
        title: 'Exam and Lab Rooms',
        frequencyTypes: [
          'Disinfect tops of all chair legs, Operatory chair bases, and medical equipment bases and/or legs.',
          'Disinfect tables, chairs, sink, counters, and exterior of cabinets in Lab and Exam rooms.',
          'Pull out and wipe under all items on shelves in Lab Areas, per Client’s instruction.',
          'Disinfect all medical equipment, per Client’s instructions.',
        ],
      },
      {
        title: 'Kitchen and Break Area',
        frequencyTypes: [
          'Wipe clean tables, chairs, sinks, counters, and exterior of cabinets in Kitchen.',
          'Wipe clean exterior of all countertop appliances in Kitchen with disinfectant, including the interior of microwaves.',
        ],
      },
      {
        title: 'Restroom',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty trash receptacles and wipe, if needed.',
          'Wipe clean exposed pipes, counters, ledges, mirrors, and air grills with approved disinfectant.',
          'Disinfect interior and exterior of toilets and toilet seats.  Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Clean sinks and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Sweep tile floor.',
          'Damp mop restroom floors with disinfectant, pouring water down drains to eliminate odors.',
          'Wipe clean and disinfect tile walls and handrails.',
        ],
      },
    ],
    monthly: [
      {
        title: 'General Office',
        frequencyTypes: [
          'Spot clean and disinfect light switches.',
          'Spot clean door frames',
          'Dust all blinds and verticals.',
          'Disinfect Telephones',
          'High dust HVAC vents and louvers.',
          'High dust ceiling corners and remove cobwebs.',
        ],
      },
      {
        title: 'Restroom',
        frequencyTypes: [
          'Clean and disinfect corners and edges of restroom floors.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  'Fitness Center': {
    weekly: [
      {
        title: 'Common Areas',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area. ',
          'Wipe clean exterior of trash receptacles and wall behind receptacles.',
          'Wipe clean all surfaces of desks, tables, counters, filing cabinets, and other office equipment, per Client’s instruction.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Dust mop, then damp mop all hard surface floors.',
          'Clean entrance area door glass.',
          'Spot clean partition glass, as needed.',
          'Wipe clean front desk counter with an approved disinfectant.',
          'Wipe clean all glass tops and display glass with glass cleaner.',
          'Dust all vertical surfaces of desks and file cabinets.',
          'Damp mop hard surface floors, including corners, edges, and under office furniture.',
          'Dust picture frames.',
        ],
      },
      {
        title: 'Kitchen and Cafe Areas',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area. ',
          'Wipe clean exterior of all countertop appliances in the kitchen with disinfectant, including the interior of microwaves and vending machine touch points.',
          'Damp wipe all tables and spot clean chairs.',
          'Clean and disinfect counters and sinks.',
          'Vacuum all mats and runners.',
          'Dust mop, then damp mop all hard surface floors.',
        ],
      },
      {
        title: 'Gym Floor Areas',
        frequencyTypes: [
          'Disinfect and polish drinking fountains/water cooler.',
          'Vacuum and dust mop rubber flooring.',
          'Damp mop rubber flooring with the appropriate solution.',
          'Spot clean all mirrors.',
          'Dust and spot clean TVs.',
          'Fill dispensers with towels and hand sanitizer. Wipe dispenser fronts.',
          'Wipe down weights, balls, and AB mats with disinfectant.',
          'Wipe clean all fitness equipment with disinfectant.',
          'Vacuum, with attachment, under fitness machines.',
        ],
      },
      {
        title: 'Aerobic Areas, Spinning Rooms, and Athletic Courts',
        frequencyTypes: [
          'Dust mop, then damp all hard surface floors.',
          'Vacuum all carpeted areas, as needed.',
          'Spot clean partition glass and mirrors, as needed.',
        ],
      },
      {
        title: 'Restrooms and Lockers ',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap.  Wipe dispenser fronts.',
          'Empty trash receptacles and wipe, if needed.',
          'Wipe clean exposed pipe, counters, ledges, mirrors, and air grills.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats.  Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals.  Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Clean sinks, disinfect counters, and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
          'Wipe clean all showers with a disinfectant. Polish all chrome fittings.',
          'Wipe clean and disinfect tile walls.',
        ],
      },
      {
        title: 'Recommended Options',
        frequencyTypes: [
          'Wipe clean and disinfect cardio equipment, pull up system, and weight racks.',
        ],
      },
      {
        title: 'Child Care Areas',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Dust mop, then wet mop floors with germicidal disinfectant.',
          "Wipe clean and disinfect all surfaces of desks, tables, and counters per Client's instruction.",
          'Spot clean partition glass and mirrors, as needed.',
        ],
      },
    ],
    monthly: [
      {
        title: 'General Areas',
        frequencyTypes: [
          'Spot clean and disinfect light switches.',
          'Spot clean door frames.',
          'Dust baseboards.',
          'Dust window sills.',
          'Disinfect telephones.',
          'High dust HVAC vents and louvers.',
          'Dust ceiling corners and remove cobwebs.',
          'Clean all mirrors.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Recommended Options',
        frequencyTypes: [
          'Detail vacuum under exercise equipment. Client is responsible for moving machines.',
          'Pressure clean and disinfect showers.',
          'Detail clean all partition glass and mirrors.',
        ],
      },
      {
        title: 'Exclusions',
        frequencyTypes: [
          'Maintenance, repair, painting, handyman, or remodeling services',
          'Exterior building parking lot.',
          'Insect control.',
        ],
      },
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  'General Minimum': {
    weekly: [
      {
        title: 'General Office',
        frequencyTypes: [
          'All trash receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Wipe clean all surfaces of desks, tables, counters, and filing cabinets per Client’s instruction.',
          'Wipe clean tables, chairs, sink, counters, and exterior of cabinets in the Kitchen.',
          'Wipe clean exterior of all countertop appliances in Kitchen with disinfectant, including the interior of microwaves ',
          'Vacuum all areas of carpeting, including mats and runners.',
          'Dust mop, then spot mop all hard surface floors.',
          'Clean entrance area door glass.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty trash receptacles and wipe, if needed.',
          'Wipe clean exposed pipe, counters, ledges, tops of partitions, mirrors, and air grills.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats.  Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Clean sinks, disinfect counters, and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
        ],
      },
    ],
    monthly: [
      {
        title: 'General Office',
        frequencyTypes: [
          'Disinfect Telephones',
          'Dust HVAC vents and louvers.',
          'Dust window sills.',
          'High Dust ceiling corners and remove cobwebs.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  'General Office': {
    weekly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'All trash receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Wipe clean all surfaces of desks, tables, counters, and filing cabinets per Client’s instruction.',
          'Wipe clean Conference Room tables and properly arrange chairs.',
          'Vacuum all areas of carpeting, including mats and runners.',
          'Detail vacuum all carpet edges, corners, and beneath furniture.',
          'Clean and disinfect drinking fountains.',
          'Dust mop all hard surface floors.',
          'Damp mop all hard surface floors, including corners, edges, and under office furniture.',
          'Clean entrance area door glass.',
          'Spot clean partition glass, as needed.',
          'Clean interior and exterior of elevator cabs.',
        ],
      },
      {
        title: 'Kitchen Areas',
        frequencyTypes: [
          'Wipe clean tables, chairs, sink, counters, and exterior of cabinets in Kitchen.',
          'Wipe clean exterior of all countertop appliances in Kitchen with disinfectant, including the interior of microwaves and vending machine touch points.',
          'Wipe down coffee machines and coffee stations. Check to be sure coffee machines have been turned off.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty sanitary napkin receptacles, disinfect, and re-line from stock.',
          'Empty trash receptacles and wipe, if needed.',
          'Wipe clean exposed pipe, counters, ledges, tops of partitions, mirrors, and air grills.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats.  Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Clean sinks, disinfect counters, and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
        ],
      },
    ],
    monthly: [
      {
        title: 'General Office',
        frequencyTypes: [
          'Spot clean and disinfect light switches ',
          'Spot clean door frames.',
          'Disinfect telephones.',
          'Dust all blinds and verticals.',
          'Dust HVAC vents and louvers.',
          'Dust picture frames.',
          'Dust window sills.',
          'Dust baseboards.',
          'High Dust ceiling corners and remove cobwebs.',
          'Remove debris from the stairwell and spot mop as needed.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  Manufacturing: {
    weekly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Wipe clean all surfaces of desks, tables, counters, and filing cabinets.',
          'Wipe clean Conference Room Tables and properly arrange chairs.',
          'Dust all office equipment, including behind computers, monitors and their bases, and any movable objects on desks.',
          'Vacuum all areas of carpeting, including mats and runners.',
          'Detail vacuum all carpet edges, corners, and beneath furniture.',
          'Clean and disinfect drinking fountains.',
          'Dust mop all hard surface floors.',
          'Damp mop all hard surface floors. ',
          'Clean entrance area door glass.',
          'Spot clean all partition glass, as needed.',
          'Dust, vacuum, and/or wet wipe furniture',
          'Dust lights and lamps.',
          'Dust telephones.',
          'Wipe clean window ledges.',
          'Dust baseboards.',
          'Dust window sills.',
          'Dust picture frames.',
          'High dust ceiling corners and remove cobwebs.',
          'Mop hard surface floors, including corners, edges, and under office furniture.',
          'Spot clean all walls, counter fronts, doors, door handles, and push places.',
          'Wet mop all plastic carpet protectors.',
        ],
      },
      {
        title: 'Kitching and Dining Area',
        frequencyTypes: [
          'All receptacles will be emptied and trash removed to the dumpster area.',
          'Wipe clean exterior of trash receptacles and wall behind receptacles.',
          'Wipe clean tables, chairs, sink, counters, and exterior of cabinets in Cafeteria and Kitchenettes.',
          'Wipe clean exterior of all countertop appliances in Kitchenettes and Cafeteria with disinfectant, including the interior of microwaves and vending machine touch points.',
          'Clean and disinfect refrigerators. Discard spoiled foods and containers per Client’s instruction.',
        ],
      },
      {
        title: 'Restrooms & Locker Rooms',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Wipe clean exposed pipes, counters, ledges, mirrors, and air grills.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Clean sinks, and polish chrome fittings.',
          'Clean and disinfect benches.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Dust lights and lamps.',
          'Wipe clean window ledges.',
          'Machine scrub and or wet mop restroom floors with disinfectant, pouring water down drains to eliminate odors.',
        ],
      },
    ],
    monthly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Spot clean and disinfect light switches ',
          'Spot clean door frames.',
          'Disinfect Telephones',
          'Dust all blinds and verticals.',
          'High dust HVAC vents and louvers.',
          'High speed burnish all vinyl tile flooring in Cafeteria and hallways.',
          'Vacuum and spot clean chairs in the lobby.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Clean corners and edges of restroom floors.',
          'Machine scrub all restroom and locker room floors with a germicidal solution',
        ],
      },
    ],
    quarterly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Dust out all light fixtures on a rotational basis. To be done as one section per month until all is completed quarterly.',
        ],
      },
    ],
    annual: [
      {
        title: 'General Facility (6 months after Start Date)',
        frequencyTypes: [
          'Top scrub and recoat all vinyl tile flooring with 3 coats of 21% solid content floor finish or better. ',
          'Bonnet clean all traffic areas of carpeting, pretreating any soiled areas.',
        ],
      },
      {
        title: 'General Facility (12 months after Start Date)',
        frequencyTypes: [
          'Strip and refinish all vinyl tile flooring with 5 coats of 21% solid content floor finish or better. ',
          'Hot water extract all carpeted areas, pretreat any soiled areas.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  'Medical Office': {
    weekly: [
      {
        title: 'General Office',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area. ',
          'Dust telephones.',
          'Wipe clean all surfaces of desks, tables, counters, filing cabinets, and other office equipment pet Client’s instruction.',
          'Spot clean partition glass, as needed.',
          'Detail vacuum all carpet areas, including corners, edges, and beneath furniture.',
          'Mop and disinfect hard surface floors, including corners, edges, baseboards, and under office furniture.',
          'Vacuum all traffic areas of carpeting, including mats and runners. ',
          'Dust window sills.',
          'Dust picture frames.',
        ],
      },
      {
        title: 'Waiting Area',
        frequencyTypes: [
          'Wipe clean Waiting Area tables. Properly arrange chairs and periodicals.',
          'Clean entrance area door glass.',
          'Wipe clean glass tables with glass cleaner.',
          'Wipe clean ledges and counters with disinfectant.',
          'Wipe and disinfect tables.',
        ],
      },
      {
        title: 'Exam Rooms',
        frequencyTypes: [
          'Wipe clean tables, chairs, sink, counters, and exterior of cabinets in Lab and Exam rooms with an approved disinfectant.',
          'Disinfect all medical equipment per Client’s instruction.',
          'Dust mop, then wet mop floors with germicidal disinfectant.',
        ],
      },
      {
        title: 'Kitchen and Break Area',
        frequencyTypes: [
          'Wipe clean tables, chairs, sinks, counters, and exterior cabinets in the Kitchen.',
          'Wipe clean exterior of all countertop appliances in Kitchen with disinfectant, including the interior of microwaves.',
          'Disinfect and polish drinking fountains/water coolers. Clean tray.',
        ],
      },
      {
        title: 'Restroom',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty trash receptacles and wipe, if needed.',
          'Disinfect sink traps, counters, ledges, mirrors, and air grills.',
          'Dust mop or vacuum tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats.  Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Clean sinks, disinfect counters, and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
          'Wipe clean and disinfect tile walls and handrails',
        ],
      },
    ],
    monthly: [
      {
        title: 'General Office',
        frequencyTypes: [
          'Spot clean and disinfect light switches ',
          'Spot clean door frames.',
          'Disinfect telephones.',
          'Dust vertical and Venetian blinds.',
          'Dust HVAC vents and louvers.',
          'Dust ceiling corners and remove cobwebs.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  'Movie Theater': {
    weekly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Clean all entrance door glass, including ticket windows inside and out.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Dust mop all hard surface floors.',
          'Damp mop all hard surface floors.',
          'Sweep entranceways, inspect areas and remove trash. ',
          'Clean and disinfect drinking fountains.',
          'Wipe clean baseboards, as needed, to remove scuffs.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap.  Wipe dispenser fronts.',
          'Empty sanitary napkin receptacles, disinfect, and replace liners.',
          'Empty trash receptacles and wipe, if needed.',
          'Clean and polish mirrors and fixtures.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Wipe clean exposed pipes, counters, ledges, and mirrors with approved disinfectant.',
          'Remove splash marks from walls around sinks and urinals.',
          'Spot clean toilet partitions and dust tops.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
        ],
      },
      {
        title: 'Lobby, Corridors, and Stadiums',
        frequencyTypes: [
          'Vacuum all areas of carpeting, including mats and runners.',
          'Dust mop or vacuum all hard surface floors.',
          'Damp mop all hard surface floors.',
          'Clean all lobby front doors, interior area, and reception glass.',
          'Wipe clean ticket counters ',
          'Spot clean exterior face of the ticket counter.',
        ],
      },
      {
        title: 'Theaters',
        frequencyTypes: [
          'Vacuum all areas of carpeting, including mats and runners.',
          'Dust mop all hard surface floors.',
          'Damp mop all hard surface floors.',
          'Vacuum and/or wipe clean all seats.',
        ],
      },
      {
        title: 'Concession Stands and Rear Kitchen Areas',
        frequencyTypes: [
          'All receptacles will be emptied and trash removed to the dumpster area.',
          'Wipe clean exterior of trash receptacles and wall behind receptacles, as needed.',
          'Clean and disinfect counters and sinks.',
          'Clean exterior of glass displays.',
          'Damp mop all hard surface floors with disinfectant.',
        ],
      },
      {
        title: 'Resilient Tile Floor',
        frequencyTypes: [
          'Dust mop all hard surface floors.',
          'Damp mop all hard surface floors, including corners and edges.',
          'Wipe clean baseboards, as needed, to remove scuffs.',
        ],
      },
      {
        title: 'Exterior Areas, Patios, and Decks',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Sweep area, inspect and remove trash from the surrounding areas',
        ],
      },
    ],
    monthly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Detail vacuum all carpeted areas, including corners and edges.',
          'Mop hard surface floors, including corners and edges.',
          'High dust ceiling corners and remove cobwebs.',
          'Top Scrub all tile grout areas.',
          'Sweep and mop upstairs projector area floors. ',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  'Multi Tenant': {
    weekly: [
      {
        title: 'Common Areas',
        frequencyTypes: [
          'All trash receptacles will be emptied and trash removed to the dumpster area.',
          'Collect all recycling from containers and remove to recycle dumpster area.',
          'Wipe clean exterior of receptacles and wall behind receptacles.',
          'Wipe clean all horizontal surfaces of desk tops, tables, and cabinets. ',
          'Clean directory glass and all entrance glass, including side entrances.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Detail vacuum all carpet edges, corners, and beneath the furniture.',
          'Clean and Disinfect drinking fountains and water coolers.',
          'Dust mop then damp mop all hard surface floors.',
          'Clean partition glass, removing all fingerprints and smudges.',
          'Spot vacuum entranceways and inspect areas for trash. ',
          'Dust all low reach areas such as chair rungs, moldings, baseboards, windowsills, wood paneling, and door jambs.',
          'High dust ceiling corners and remove cobwebs.',
          'Disinfect telephones.',
        ],
      },
      {
        title: 'Restrooms, Showers, and Locker Rooms',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Empty sanitary napkin receptacles, disinfect, and replace liners.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Clean sinks, disinfect counters, and polish chrome.',
          'Remove splash marks from walls around sinks and urinals.',
          'Damp mop restroom floor, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
          'Clean and polish mirrors.',
          'Dust tops of partitions, light fixtures, cabinets, and dispensers.',
          'Clean and disinfect towel cabinet covers.',
          'Stock towels, tissue, hand soap, seat covers, and other expendable restroom items from janitorial supplies.',
          'Clean and disinfect shower stalls and shower floors, polish all chrome fittings.',
          'Spot clean locker fronts.',
          'Wipe clean exposed pipes, counters, ledges, and mirrors with approved disinfectant',
        ],
      },
      {
        title: 'Lobbies and Reception Areas',
        frequencyTypes: [
          'Dust mop then damp mop all hard surface floors.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Spot clean carpet each visit.',
          'Clean all interior areas, lobby front doors, and reception glass.',
          'Dust lobby furniture and damp wipe or polish as necessary.',
          'Wipe and disinfect tables.',
          'Damp wipe reception counters and spot clean exterior face of the reception ',
        ],
      },
      {
        title: 'Conference Rooms',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Dust furniture and other surfaces.',
          'Clean and polish conference tables.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Return chairs to proper positions.',
          'Remove used cups to the nearest sink.',
        ],
      },
      {
        title: 'Lunchrooms, Kitchens, and Coffee Stations',
        frequencyTypes: [
          'All receptacles will be emptied and trash removed to the dumpster area. ',
          'Wipe clean exterior of trash receptacles and wall behind receptacles.',
          'Damp wipe all tables and spot clean chairs.',
          'Damp mop all hard surface floors with disinfectant.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Clean and disinfect counters and sinks.',
          'Wipe clean exterior of all countertop appliances in Kitchen with disinfectant, including the interior of microwaves and vending machine touch points.',
          'Wipe down coffee machines and coffee stations. Check to be sure coffee machines have been turned off.',
        ],
      },
      {
        title: 'Laboratories',
        frequencyTypes: [
          'Dust mop all hard surface floors',
          'Damp mop floors to remove spillage. ',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Perform dusting and spot cleaning according to the Client’s instructions.',
        ],
      },
      {
        title: 'Stairways and Landings',
        frequencyTypes: [
          'Remove all debris from the stairwell for trash and spot mop as needed.',
          'Dust railings, banisters, ledges, moldings, and light fixtures.',
        ],
      },
      {
        title: 'Passenger Elevators',
        frequencyTypes: [
          'Vacuum all carpeting, taking care to get corners and along edges.',
          'Vacuum all elevator thresholds.',
          'Spot clean interior stainless steel and chrome surface of forward-facing cab wall and doors.',
          'Spot clean outside surfaces of all elevator doors and frames.',
          'Spot clean elevator cab floor carpeting.',
        ],
      },
      {
        title: 'Tile and Resilient Floor',
        frequencyTypes: [
          'Dust mop all hard surface floors.',
          'Damp mop all hard surface floors, removing all spills and other unsightly marks.',
        ],
      },
      {
        title: 'Warehouse, Loading Dock, and Service Entrance',
        frequencyTypes: [
          'Clean warehouse offices according to the same routine as other office areas. ',
          'Empty warehouse trash according to the Client’s instructions.',
          'Vacuum and wash off mats and inspect trash loading dock for trash. ',
          'Service entrance per Client’s instructions.',
        ],
      },
      {
        title: 'Exterior Smoking Areas, Patios, and Decks',
        frequencyTypes: [
          'Sweep and inspect the area for trash.',
          'Remove trash.',
          'Empty cigarette urns.',
        ],
      },
      {
        title: 'Janitorial Closet',
        frequencyTypes: [
          'Keep stock, including janitorial equipment, clean, neat, and orderly.',
          'Maintain all cleaning chemicals and solutions in properly labeled containers.',
          'Empty and rinse mop buckets nightly.',
        ],
      },
      {
        title: 'Rear and Side Entrances',
        frequencyTypes: ['Inspect Rear and side entrances for trash.'],
      },
    ],
    monthly: [
      {
        title: 'Common Areas',
        frequencyTypes: [
          'Remove exterior cobwebs from lobby windows.',
          'Dust all blinds and verticals.',
          'Dust all high reach areas (above eye level) including, but not limited, to door frames, ceiling vents, and grills (including heating and air conditioning systems), partitions tops, picture frames, high shelves, cabinet tops, etc.',
          'Dust all light fixtures.',
          'Vacuum under plastic carpet protectors.',
          'Spot clean waste bins as needed.',
          'Vacuum upholstered furniture.',
        ],
      },
      {
        title: 'Restroom, Showers, and Locker Rooms',
        frequencyTypes: [
          'Thoroughly clean and disinfect all tile stalls, leave in streak-free condition.',
          'Wipe and disinfect all metal partitions, walls, kick plates, and thresholds.',
        ],
      },
      {
        title: 'Lobbies and Reception Areas',
        frequencyTypes: ['Vacuum all upholstered lobby furniture.'],
      },
      {
        title: 'Lunchrooms, Kitchens, and Coffee Stations',
        frequencyTypes: ['Spot clean walls behind trash receptacles.'],
      },
      {
        title: 'Tile and Resilient Floor',
        frequencyTypes: [
          'Detail clean floor edges and corners.',
          'Clean baseboards to remove scuffs and splashes from mopping. ',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exclusions',
        frequencyTypes: [
          'Maintenance, repair, painting, handyman or remodeling services',
          'Exterior building parking lot.',
          'Washing windows above eight feet.',
          'Insect control.',
        ],
      },
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed. ',
        ],
      },
    ],
  },
  'Place of Worship': {
    weekly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Wipe clean all surfaces of desk tops, tables, cabinets, credenzas, and other similar furnishings and equipment. (Papers and folders on desks will not be moved unless by previous arrangement).',
          'Dust all office equipment, including behind computers, monitors and their bases, and any movable objects on desks.',
          'Wipe clean Conference Room tables and properly arrange chairs.',
          'Disinfect telephones.',
          'Vacuum all areas of carpeting, including mats and runners.',
          'Vacuum under all accessible desks, chairs, pews, and podiums.',
          'Clean and dust chairs, pews, podiums, and other furniture in sanctuary/chapels removing crumbs and other debris.',
          'Dust mop all hard surface floors.',
          'Damp mop all hard surface floors. ',
          'Clean directory glass and all entrance glass including side entrances.',
          'Spot clean partition glass, as needed.',
          'Inspect sanctuary and remove trash, spot vacuuming as needed.',
          'Detail vacuum all carpet corners, edges, and beneath furniture.',
          'Mop hard surface floors, including corners, edges, and under office furniture.',
          'Clean and disinfect drinking fountains.',
          'Dust window sills',
          'Dust picture frames.',
          'Dust baseboards.',
        ],
      },

      {
        title: 'Kitchen Areas',
        frequencyTypes: [
          'All trash receptacles will be emptied and trash removed to the dumpster area.',
          'Wipe clean exterior of trash receptacles and wall behind receptacles.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Clean and disinfect counters and sinks.',
          'Damp wipe all tables and spot clean chairs.',
          'Damp mop all hard surface floors with disinfectant.',
          'Wipe clean exterior of all countertop appliances in Kitchen with disinfectant, including the interior of microwaves and vending machine touch points',
          'Wipe down coffee machines and coffee stations. Check to be sure coffee machines have been turned off. ',
        ],
      },
      {
        title: 'Child Care Areas',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Sweep/dust mop then wet mop floors with germicidal disinfectant.',
          "Wipe clean and disinfect all surfaces of desks, tables, and counters per Client's instruction.",
          'Spot clean partition glass and mirrors, as needed.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Empty sanitary napkin receptacles, disinfect, and replace liners.',
          'Wipe clean exposed pipes, counters, ledges, mirrors, and air grills.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Clean and disinfect partition doors, walls, door handles, and other hardware.',
          'Clean sinks, disinfect counters, and polish chrome.',
          'Clean and polish mirrors.',
          'Remove splash marks from walls around sinks and urinals.',
          'Damp mop restroom floor with disinfectant, pouring water down drains to eliminate odors.',
        ],
      },
    ],
    monthly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Clean corners and edges of restroom floors.',
          'Spot clean and disinfect light switches.',
          'Spot clean door frames',
          'Dust all blinds and verticals.',
          'High dust HVAC vents and louvers.',
          'Dust ceiling corners and remove cobwebs.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exclusions',
        frequencyTypes: [
          'Maintenance, repair, painting, handyman, or remodeling services',
          'Exterior building parking lot.',
          'Pest/Insect control.',
        ],
      },
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  Restaurant: {
    weekly: [
      {
        title: 'Entrance and Front-of-Bar Areas',
        frequencyTypes: [
          'Clean entrance glass, empty entrance cigarette urns, and remove to dumpster area.',
          'Remove all trash and cobwebs from exterior entryway',
          'Dust and spot clean interior entrance and waiting area surfaces as needed.',
          'Vacuum entrance and walk-off mats.',
          'Dust and spot clean doors, door frames, walls, partition glass, window sills, ledges, and vertical surfaces of booths as needed.',
          'Polish brass work.',
          'Wipe clean window sills',
          'Clean rubber floor mats per Client’s instructions',
        ],
      },
      {
        title: 'Dining Area Floors and Carpets',
        frequencyTypes: [
          'Dust mop then damp mop all hard surface floor areas.',
          'Vacuum all carpeted areas, including mats and runners, moving chairs and tables as needed for complete access. ',
          'Dust low reach areas such as baseboards and chair rungs.',
          'Dust light fixtures, blinds, ceiling vents, and all other horizontal surfaces beyond the scope of normal nightly dusting.',
        ],
      },
      {
        title: 'Kitchen Floors and Behind-the-Bar Floors',
        frequencyTypes: [
          'Clean floors, scrubbing with a deck brush and using degreaser for the kitchen.',
          'Wet mop kitchen floors to rinse after degreasing. ',
          'Squeegee excess water to the floor drain.',
          'Lift and clean rubber mats and replace them after the floor is dry.',
          'Clean tops of floor drains.',
        ],
      },
      {
        title: 'Offices',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Wipe clean all horizontal surfaces of desk tops, tables, cabinets, credenzas, and other similar furnishings and equipment. (Papers and folders on desks will not be moved unless by previous arrangement).',
          'Dust low reach areas such as baseboards and chair rungs.',
          'Clean and disinfect telephones. ',
          'Vacuum all carpeted areas.',
          'Dust mop then damp mop hard surface floors as needed.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty trash receptacles and wipe, as needed.',
          'Wipe clean exposed pipes, counters, ledges, and mirrors with approved disinfectant.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Spot clean partitions and dust tops.',
          'Clean sinks, disinfect counters, and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
          'Wipe clean and disinfect tile walls and handrails.',
        ],
      },
    ],
    monthly: [
      {
        title: 'General Facility',
        frequencyTypes: ['Dust blinds. ', 'Dust HVAC vents and louvers.'],
      },
      {
        title: 'Floors',
        frequencyTypes: [
          'Thoroughly clean and disinfect all tile stalls, leave in streak-free condition.',
          'Wipe and disinfect all metal partitions, walls, kickplates, and thresholds.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  'Retail Grocery': {
    weekly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Dust mop all hard surface floors.',
          'Auto scrub all hard surface floors.',
          'Wet mop and auto scrub Deli counter, Oven Works, and Kitchen floors.',
          'Propane burnish all Retail area VCT flooring dust mopping all dust and debris afterward.',
          'Vacuum carpet in Dining Room Areas.',
          'Sweep, wet mop, and auto scrub hard surface floors in Cooler.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty trash receptacles and wipe, if needed.',
          'Dust sink traps, counters, ledges, mirrors, and air grills.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish chrome',
          'Disinfect interior and exterior of urinals. Polish chrome.',
          'Change urinal deodorizers as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Clean sinks and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Clean and disinfect drinking fountains.',
        ],
      },
    ],
    monthly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Sweep and wet mop Community Room floors. Buff as needed.',
        ],
      },
    ],
    quarterly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Machine scrub all ceramic flooring.',
          'Low moisture hot water extract carpet in the Dining Room.',
        ],
      },
    ],
    'semi annual': [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Top scrub and recoat all Retail VCT flooring with 3-4 coats of a 21% solid floor finish or better. Performed 3 months after the finish of the initial strip and refinish then again 3 months later.',
        ],
      },
    ],
    annual: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Strip and refinish all VCT flooring with 6-8 coats of a 21% solid content floor finish or better. Performed on the 12th month of service.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  School: {
    weekly: [
      {
        title: 'Offices and Classrooms',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Wipe clean telephones.',
          'Wipe clean all horizontal and vertical surfaces of desks, tables, counters, and filing cabinets per Client’s instruction.',
          'Wipe clean conference room tables and properly arrange chairs.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Detail vacuum all carpet edges, corners, and beneath the furniture.',
          'Dust mop then damp all hard surface floors. ',
          'Damp mop all hard surface floors and/or auto scrub all hard surfaces floors.',
          'Damp mop hard surface floors, including corners, edges, and under office furniture.',
          'Clean entrance area door glass.',
          'Spot clean partition glass, as needed.',
          'Wipe clean chalkboard/whiteboard ledges.',
          'Wipe clean classroom tables and chairs.',
          'Dust window sills.',
          'Dust picture frames.',
          'Dust baseboards.',
          'Dust and wipe clean entrance door tracks.',
          'Dust wall molding.',
        ],
      },
      {
        title: 'Lunchroom, Cafeteria, and Lounge',
        frequencyTypes: [
          'Wipe clean tables, chairs, sink, counters, and exterior of cabinets in the cafeteria and faculty lounge.',
          'Wipe clean exterior of all countertop appliances in the kitchen with disinfectant, including the interior of microwaves and vending machine touch points.',
          'Wipe down coffee machines and coffee stations. Check to be sure coffee machines have been turned off.',
          'Vacuum mats and runners.',
          'Wipe clean all countertops and sinks with an approved disinfectant.',
          'Clean and disinfect all drinking fountains and water coolers.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty trash receptacles and wipe with disinfectant, as needed.',
          'Wipe clean exposed pipes, counters, ledges, mirrors, and air grills with approved disinfectant.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Clean sinks, disinfect counters, and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors and fixtures.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
          'Wipe clean handrails with a disinfectant.',
          'Wipe clean tile walls with a disinfectant.',
        ],
      },
    ],
    monthly: [
      {
        title: 'Offices and Classrooms',
        frequencyTypes: [
          'Spot clean and disinfect light switches ',
          'Spot clean door frames.',
          'Disinfect telephones.',
          'Dust all blinds and verticals.',
          'High dust HVAC vents and louvers.',
          'High dust ceiling corners and remove cobwebs.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
      {
        title: 'Recommended Options',
        frequencyTypes: [
          'Clean interior of the refrigerator located in the faculty lounge. Client is responsible for the removal of food. (Fridays)',
          'Clean and disinfect the interior of lockers.',
          'Auto scrub all hard floor surfaces.',
          'Scrub and recoat all vinyl tile flooring areas.',
          'Burnish all VCT flooring areas.',
          'Strip and refinish all vinyl flooring.',
          'Top Scrub all tile grout areas.',
          'Carpet cleaning.',
        ],
      },
    ],
  },
  'Surgical Center': {
    weekly: [
      {
        title: 'Common Areas',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area. No red bag pickup.',
          'Break down boxes marked “Trash” for recycling and remove to recycling dumpster area. ',
          'Place soiled linens in the appropriate receptacle.',
          'Fill dispensers with towels, tissue, hand sanitizer, and hand soap throughout the facility. Wipe dispenser fronts.',
          'Wipe clean all vertical surfaces of desks, file cabinets, and other office furniture.',
          'Disinfect telephones.',
          'Disinfect surfaces in Pre-OP/ nurse’s station and recovery areas.',
          'Disinfect all countertops throughout the facility.',
          'Disinfect and scrub all sinks. Polish all chrome fittings.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Detail vacuum all carpet edges, corners, and beneath the furniture.',
          'Clean and disinfect drinking fountains and water coolers.',
          'Dust mop then damp mop all hard surface floors with the appropriate solution.',
          'Damp mop all hard surface floors, including corners, edges, and under office furniture.',
          'Clean narcotics office upon request. Report to management if the door is left open. If open, lock it immediately.',
          'Spot clean interior windows for fingerprints and smudges.',
          'Wash and disinfect the bottom half of walls in Pre- and Post-OP areas, surgical halls, and supply rooms.',
          'Disinfect IV poles.',
          'Dust ceiling in Pre-and Post OP areas, halls, and supply rooms.',
          'Disinfect all doors and handles. (check the jams for grease/dust)',
          'Polish all door kick-plates.',
          'Hose down rubber mats with disinfectant. (Every other week.)',
          'Wipe clean wall molding throughout the facility.',
          'Dust baseboards.',
          'Dust window sills.',
          'Dust picture frames.',
          'Dust ceiling corners and remove cobwebs.',
          'High dust HVAC vents and louvers.',
          'High dust filing cabinets.',
          'High and low dust walls and floor molding.',
        ],
      },
      {
        title: 'Patient Rooms',
        frequencyTypes: [
          'Damp mop recovery areas, pulling and replacing beds.',
          'Pull rolling carts from walls in recovery, clean behind, and replace.',
          'Dust the EKG monitors.',
        ],
      },
      {
        title: 'Lunchroom and Break Rooms',
        frequencyTypes: [
          'Wipe clean coffee stations and coffee stations. Restock and arrange coffee condiments. Check to be sure coffee machines have been turned off. ',
          'Wipe clean tables, chairs, sinks, couches, counters, and exterior of cabinets in break rooms and doctors’ lounges.',
          'Wipe clean exterior of all countertop appliances in the kitchen with disinfectant, including the interior of microwaves and vending machine touch points.',
        ],
      },
      {
        title: 'Reception and Waiting Area',
        frequencyTypes: [
          'Arrange chairs and periodicals in the waiting area. Wipe clean chairs and tables.',
          'Clean entrance area door glass.',
          'Spot clean partition glass, as needed.',
          'Dust chair bases in the waiting area. Wipe armrests with disinfectant.',
        ],
      },
      {
        title: 'Conference Rooms and Offices',
        frequencyTypes: [
          'Disinfect all surfaces of desks, (including behind computers, monitors and their bases, and beneath any movable objects on desks), tables, counters, filing cabinets, and other office equipment.',
          'Wipe clean conference room tables and properly arrange chairs.',
        ],
      },
      {
        title: 'Restroom and Locker Rooms',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty trash receptacles and wipe with disinfectant, as needed.',
          'Wipe clean exposed pipes, counters, ledges, mirrors, and air grills with approved disinfectant.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Clean and disinfect walls around toilets as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Clean sinks, disinfect counters, and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors and fixtures.',
          'Wipe clean benches.',
          'Clean and disinfect showers. Polish all chrome fittings.',
          'Disinfect and clean all ceramic walls, weekly.',
          'Disinfect door frames.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
          'Clean and disinfect drinking fountains and water coolers.',
        ],
      },
    ],
    monthly: [
      {
        title: 'Common Areas',
        frequencyTypes: [
          'Spot clean and disinfect light switches ',
          'Spot clean door frames.',
          'Dust all blinds and verticals.',
          'High dust HVAC vents and louvers.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: "Terminal Cleaning - Per Client's instructions",
        frequencyTypes: [
          'Completely wipe down from the ceiling to floor all surfaces in the operation room.',
          'Clean the O.R. table first to aid in confining the most contaminated sources (discard the solution used on the table, and add a new cleaning solution). Move bed if present in the O.R. room.',
          'Clean remaining furniture and equipment with disinfectant, starting at the top and working downward toward the floor.',
          'Clean wheels and casters to prevent debris from collecting.',
          'Damp dust and wipe dry the overhead surgery lights.',
          'Clean window sills and all wall mounts.',
          'Clean doors and knobs, with special attention to handles and push plates.',
          'Clean countertops in O.R.',
          'Spot clean soiled areas on the walls.',
          'Damp dust and wipe dry air grills.',
          'Clean counters of sub-sterile rooms.',
          'Clean and scrub sink with scouring powder and a damp sponge.',
          'Wet mop total floor area with germicidal cleaning agent.',
          'Roll all wheels and casters through the cleaning solution used for the floor.',
          'Replace surgery room equipment and furniture to its proper place.',
          'Empty cleaning solution.',
          'Remove trash to proper receptacle, and replace trash liner with a new liner.',
          "Clean and disinfect scrub sinks outside of O.R.'s.",
          'Clean and disinfect adjoining rooms. ',
        ],
      },
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
};
const oneDayTypesValues = {...oneDayTypes}
Object.keys(oneDayTypesValues).forEach(key=>{
  Object.keys(oneDayTypesValues[key]).forEach(f=>{

    oneDayTypesValues[key][f].forEach(i=>{
      i.uid = Math.random();
      i.frequencyTypes = i.frequencyTypes.map(fy=>({
        value: fy,
        uid: Math.random()
      }))
    })
  })
})
export {oneDayTypesValues};

export const weeklyTypes = {
  'Auto Dealer': {
    nightly: [
      {
        title: 'Showroom, Corridors, and Common Areas',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area. ',
          'Clean entrance and partition glass to remove fingerprints and smudges.',
          'Dust counters, ledges, furnishings, and other horizontal surfaces.',
          'Damp wipe or spot clean counters, ledges, furnishings, and other horizontal surfaces as needed to remove spills and soil, per Client’s instruction.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Dust mop, then spot mop all hard surface floors.',
          'Sweep stairwell, dust handrails, and clean elevators.',
        ],
      },
      {
        title:
          'Sales and Management Offices, Waiting Areas, and Service Offices',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area. ',
          'Dust desks, credenzas, tables, file cabinets, and other furnishings and office equipment, per Client’s instruction. ',
          'Damp wipe or spot clean exposed horizontal surfaces as needed.',
          'Spot clean partition glass to remove fingerprints and smudges.',
          'Vacuum traffic areas of carpeting, including mats and runners.',
          'Dust mop, then spot mop all hard surface floors. ',
        ],
      },
      {
        title: 'Lunchroom and Break Room Areas',
        frequencyTypes: [
          'All receptacles will be emptied and trash removed to the dumpster area.',
          'Wipe clean exterior of trash receptacles and wall behind receptacles.',
          'Clean and disinfect counters and sinks.',
          'Wipe clean exterior of all countertop appliances in Kitchen with disinfectant, including the interior of microwaves and vending machine touchpoints.',
          'Wipe down coffee machines and coffee stations. Check to be sure coffee machines have been turned off.',
          'Damp wipe all tables and spot clean chairs.',
          'Dust mop then wet mop floors with germicidal disinfectant. ',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty trash receptacles and wipe, if needed.',
          'Wipe clean exposed pipes, counters, ledges, mirrors, and air grills with approved disinfectant.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Wipe clean and disinfect sanitize tile walls and handrails.',
          'Clean sinks, disinfect counters, and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
        ],
      },
    ],
    weekly: [
      {
        title: 'Showroom, Corridors, and Common Areas',
        frequencyTypes: [
          'Spot clean carpets to remove any new spills.',
          'Thoroughly clean entrance glass from edge to edge.',
          'Dust all blinds and verticals.',
          'Dust baseboards.',
          'Auto scrub all hard surfaces floors.',
          'Dust mop or vacuum elevator then clean and polish door fronts.',
          'Remove trash to the collection point and replace liners as needed.',
        ],
      },
      {
        title:
          'Sales and Management Offices, Waiting Areas, and Service Offices',
        frequencyTypes: [
          'Spot clean carpets to remove any new spills.',
          'Spot check all partition glass.',
          'Dust baseboards.',
          'Clean and disinfect telephones, including receivers and cradles.',
          'Remove trash to the collection point and replace liners as needed.',
        ],
      },
    ],
    monthly: [
      {
        title: 'Showroom, Corridors, and Common Areas',
        frequencyTypes: [
          'Spot clean and disinfect light switches.',
          'Spot clean door frames. ',
          'Machine scrub floors to remove soil and, if the floor is finished, buff/burnish as needed.',
          'Dust all high reach areas (above eye level) including, but not limited to, door frames, ceiling vents and grills, partitions tops, picture frames, high shelves, and cabinet tops, per Client’s instruction.',
          'Dust all light fixtures.',
          'Spot clean waste bins as needed.',
          'Vacuum upholstered furniture.',
        ],
      },
      {
        title:
          'Sales and Management Offices, Waiting Areas, and Service Offices',
        frequencyTypes: [
          'Dust all blinds and verticals.',
          'Dust all high reach areas (above eye level) including, but not limited to, door frames, ceiling vents and grills, partitions tops, picture frames, high shelves, and cabinet tops, per Client’s instruction.',
          'Dust all light fixtures.',
          'Spot clean waste bins as needed.',
          'Vacuum upholstered furniture',
        ],
      },
      {
        title: 'Lunchroom and Break Room Areas',
        frequencyTypes: [
          'Spot clean walls, especially behind trash receptacles.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Thoroughly clean and disinfect all tile stalls, leave in streak-free condition.',
          'Wipe and disinfect all metal partitions, walls, kick plates, and thresholds.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
      {
        title: 'Shop Area',
        frequencyTypes: [
          'Clean shop office as per office cleaning schedule - performed Xweek.',
          'Clean shop restroom as per restroom cleaning schedule - performed Xweek.',
          'Auto scrub all shop Floors as per restroom cleaning schedule – performed Xweek.',
        ],
      },
    ],
  },
  'Bowling Alley': {
    nightly: [
      {
        title: 'Common Areas',
        frequencyTypes: [
          'All receptacles will be emptied and trashed removed to the dumpster area.',
          'Wipe clean exterior of trash receptacles and wall behind receptacles',
          'Empty all outside trash receptacles, replacing liners, and cigarette urns.',
          'Clean entrance area and door glass.',
          'Clean and disinfect door handles.',
          'Spot clean partition glass as needed.',
          'Clean and disinfect drinking fountains.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Dust mop, then spot mop all hard surface floors.',
        ],
      },
      {
        title: 'Game Room, Party Room, and Restaurant/Bar Areas',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trashed removed to the dumpster area.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Dust mop, then spot mop all hard surface floors.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty trash receptacles and wipe, if needed.',
          'Wipe clean exposed pipes, counters, ledges, mirrors, and air grills with approved disinfectant.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Clean sinks and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Machine scrub and damp mop restroom floors with disinfectant, pouring water down drains to eliminate odors.',
        ],
      },
    ],
    weekly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Damp wipe arcade games.',
          'Clean entrance windows and door glass.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: ['Clean and disinfect walls in restrooms.'],
      },
    ],
    monthly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Spot clean and disinfect light switches ',
          'Spot clean door frames.',
          'Disinfect telephones',
          'High dust HVAC vents and louvers.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: ['Clean corners and edges of restroom floors.'],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  'Country Club': {
    nightly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'All receptacles will be emptied and trash removed to the dumpster area. ',
          'Wipe clean exterior of trash receptacles and wall behind receptacles.',
          'Wipe clean all horizontal surfaces of desktops, tables, counters, and filing cabinets.',
          'Spot clean elevator’s interior stainless steel and chrome surface of forward-facing cab wall and doors.',
          'Spot clean outside surfaces of all elevator doors and frames.',
          'Spot clean elevator cab floor.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
        ],
      },
      {
        title: 'Dining, Lounge, and Bar Areas',
        frequencyTypes: [
          'All receptacles will be emptied and trash removed to the dumpster area. ',
          'Wipe clean exterior of trash receptacles and wall behind receptacles.',
          'Spot clean all entrance area door glass.',
          'Sweep entranceways, inspect areas and remove trash.',
          'Clean and disinfect drinking fountains.',
          'Remove floor mats in bar areas and hose down in designated areas with client to place back after mats dry. ',
          'Clean tops of floor drains in bar areas.',
          'Dust base of tables.',
          'Vacuum all areas of carpeting, including mats and runners.',
          'Dust mop, then spot mop all hard surface floors.',
          'Sweep balcony patio.',
        ],
      },
      {
        title: 'Restrooms, Lockers, and Saunas',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty sanitary napkin receptacles, disinfect, and re-line from stock.',
          'Empty trash receptacles and wipe, as needed.',
          'Wipe clean exposed pipes, counters, ledges, and mirrors with approved disinfectant.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Spot clean partitions and dust tops.',
          'Clean sinks, disinfect counters, and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Wipe clean and disinfect tile walls and handrails.',
          'Dust top of lockers.',
          'Spot clean Sauna entrance glass and remove any debris.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
        ],
      },
    ],
    weekly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Dust all vertical surfaces of desks, file cabinets, and other office furniture.',
          'Detail vacuum all carpet edges, corners, and beneath furniture.',
          'Disinfect telephones in offices.',
          'Damp mop hard surface floors, including corners, edges, and under office furniture.',
          'Dust all office equipment, including behind computers, monitors and their bases, and any movable objects on desks.',
          'Dust window sills.',
          'Dust picture frames.',
          'Fully clean entrance door windows.',
        ],
      },
      {
        title: 'Restroom and Locker Areas',
        frequencyTypes: ['Deck brush showers.'],
      },
    ],
    monthly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Spot clean and disinfect light switches ',
          'Spot clean door frames.',
          'Dust vertical and Venetian blinds.',
          'High dust HVAC vents and louvers.',
          'Dust ceiling corners and remove cobwebs.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  'Day Care': {
    nightly: [
      {
        title: 'Offices and Classrooms',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Wipe clean and disinfect Classroom tables and properly arrange chairs.',
          'Wipe clean and disinfect all classroom sinks and countertops. Polish all chrome fittings.',
          'Wipe clean chalkboard/whiteboard ledges.',
          'Spot clean partition glass, as needed.',
          'Clean and disinfect all drinking fountains and water coolers.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Dust mop, then spot mop all hard surface floors.',
          'Clean entrance area door glass.',
        ],
      },
      {
        title: 'Kitchen Areas',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Wipe clean and disinfect sink, counters, and exterior of cabinets in Kitchen.',
          'Wipe clean exterior of all countertop appliances in Kitchen with disinfectant, including the interior of microwaves.',
          'Sweep and spot mop all hard surface floors.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty trash receptacles and wipe with disinfectant, as needed.',
          'Wipe clean exposed pipes, counters, ledges, mirrors, and air grills with approved disinfectant.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Clean sinks, disinfect counters, and polish chrome fittings. Wipe clean tile walls with a disinfectant.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors and fixtures.',
          'Dust mop or sweep tile floor.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
        ],
      },
    ],
    weekly: [
      {
        title: 'Offices and Classrooms',
        frequencyTypes: [
          'Wipe clean baseboards per Client’s instructions.',
          'Wipe clean and disinfect window sills.',
          'Wipe clean and disinfect picture frames.',
          'Disinfect all office equipment, including behind computers, monitors, and their bases, per Client’s instruction.',
          'Damp mop hard surface floors, including corners, edges, and under office furniture.',
        ],
      },
      {
        title: 'Recommended Options',
        frequencyTypes: ['Disinfect inside of lockers'],
      },
    ],
    monthly: [
      {
        title: 'Offices and Classrooms',
        frequencyTypes: [
          'Spot clean and disinfect light switches.',
          'Spot clean door frames.',
          'Disinfect telephones.',
          'Dust all blinds and verticals.',
          'High dust HVAC vents and louvers.',
          'High dust ceiling corners and remove cobwebs.',
          'Clean interior of refrigerator with an approved solution. Client is responsible for removal of food.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  'Day Spa': {
    nightly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'All receptacles will be emptied and trash removed to the dumpster area.',
          'Remove all trash from the outside patio.',
          'Wipe clean exterior of trash receptacles and wall behind receptacles.',
          'Wipe clean all surfaces of Reception counter, tables, and counters with approved disinfectant.',
          'Clean entrance area door glass.',
          'Spot clean partition glass, as needed.',
          'Spot clean all mirrors.',
          'Wipe clean all glass tables and display glass with a glass cleaner.',
          'Wipe clean ledges and counters with disinfectant.',
          'Clean and disinfect drinking fountains.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Dust mop or vacuum all hard surface floors.',
          'Damp mop all hard surface floors.',
          'Clean interior and exterior of elevator cabs. Polish chrome.',
        ],
      },
      {
        title: 'Spa Area',
        frequencyTypes: [
          'Wipe clean all counters, cabinets, and ledges in Treatment Rooms/Suits with an approved disinfectant.',
          'Wipe clean treatment chairs, massage beds, and Mani/Pedi chairs including their bases and extensions with an approved disinfectant.',
          'Disinfect all sinks, tubs, and showers in Treatment Rooms/Suites. Polish all chrome fittings.',
        ],
      },
      {
        title: 'Fitness Center',
        frequencyTypes: [
          'Wipe clean and disinfect cardio equipment, pull up system, weight racks, weights, balls, and AB mats.',
          'Vacuum and dust mop rubber flooring in the Fitness center.',
          'Damp mop rubber flooring with the appropriate solution.',
        ],
      },
      {
        title: 'Restrooms & Lockers',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe the front of dispensers.',
          'Empty trash receptacles and wipe, as needed.',
          'Wipe clean exposed pipes, counters, ledges, mirrors, and air grills with an approved disinfectant.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Clean sinks and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Sweep tile floor.',
          'Damp mop restroom floors with disinfectant, pouring water down drains to eliminate odors.',
          'Wipe clean and disinfect saunas with an appropriate solution.',
          'Wipe clean and disinfect showers, including outside showers, tile walls, and handrails. Polish all chrome fittings.',
          'Wipe clean and disinfect     bathtub in Couple’s Room. Polish all chrome fittings.',
        ],
      },
    ],
    weekly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Detail mop hard surface floors, including corners, edges, and under office and spa furniture.',
          'Wipe clean all vertical surfaces of desks, file cabinets, and other office furniture.',
          'Wipe clean telephones.',
          'Dust window sills.',
          'Dust picture frames.',
          'Dust baseboards.',
          'Dust ceiling corners and remove cobwebs.',
          'Dust all office equipment, including behind computers, monitors, and their bases, per Client’s instruction.',
          'Spot clean and disinfect light switches     ',
          'Spot clean door frames and baseboards.',
        ],
      },
      {
        title: 'Spa Center',
        frequencyTypes: [
          'Detail vacuum with attachment under treatment chairs, massage beds, and Mani/Pedi chairs including their bases and extensions.',
        ],
      },
      {
        title: 'Fitness Center',
        frequencyTypes: [
          'Detail vacuum with attachment under fitness machines, treatment chairs.',
        ],
      },
    ],
    monthly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Disinfect telephones.',
          'High dust HVAC vents and louvers.',
          'Clean all mirrors streak-free.',
          'Dust all blinds and verticals.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Clean corners and edges of restroom floors.',
          'Polish all brass fittings.',
          'High dust tops of lockers and cabinets.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  'Dental Office': {
    nightly: [
      {
        title: 'General Office',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area. ',
          'Wipe clean all surfaces of desks, tables, counters, and filing cabinets per Client’s instruction.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Spot clean partition glass, as needed.',
          'Dust mop, then spot mop all hard surface floors.',
        ],
      },
      {
        title: 'Waiting Area',
        frequencyTypes: [
          'Wipe clean Waiting Area tables and Front Counter. Properly arrange chairs and periodicals.',
          'Clean entrance area door glass.',
          'Wipe clean glass tables with glass cleaner.',
          'Wipe clean ledges and counters with disinfectant.',
        ],
      },
      {
        title: 'Exam and Lab Rooms',
        frequencyTypes: [
          'Disinfect tops of all chair legs, Operatory chair bases, and medical equipment bases and/or legs.',
          'Disinfect tables, chairs, sink, counters, and exterior of cabinets in Lab and Exam rooms.',
          'Pull out and wipe under all items on shelves in Lab Areas per Client’s instruction.',
          'Disinfect all medical equipment, per Client’s instructions.',
        ],
      },
      {
        title: 'Kitchen and Break Area',
        frequencyTypes: [
          'Wipe clean tables, chairs, sinks, counters, and exterior of cabinets in Kitchen.',
          'Wipe clean exterior of all countertop appliances in Kitchen with disinfectant, including the interior of the microwave.  ',
          'Clean and disinfect drinking fountains/water coolers. Clean tray.',
        ],
      },
      {
        title: 'Restroom',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty trash receptacles and wipe, if needed.',
          'Wipe clean exposed pipes, counters, ledges, mirrors, and air grills with approved disinfectant.',
          'Disinfect interior and exterior of toilets and toilet seats.  Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Clean sinks and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Sweep tile floor.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
          'Wipe clean and disinfect tile walls and handrails.',
        ],
      },
    ],
    weekly: [
      {
        title: 'General Office',
        frequencyTypes: [
          'Disinfect all vertical surfaces of desks, file cabinets, and other office furniture.',
          'Detail vacuum all carpet edges, corners, and beneath furniture.',
          'Dust baseboards.',
          'Damp mop hard surface floors, including corners, edges, and under office furniture. ',
          'Dust window sills.',
          'Dust picture frames.',
        ],
      },
    ],
    monthly: [
      {
        title: 'General Office',
        frequencyTypes: [
          'Spot clean and disinfect light switches.',
          'Spot clean door frames. ',
          'Disinfect telephones.',
          'Dust all blinds and verticals.',
          'High dust HVAC vents and louvers.',
          'High dust ceiling corners and remove cobwebs.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  'Fitness Center': {
    nightly: [
      {
        title: 'Common Areas',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Wipe clean exterior of trash receptacles and wall behind receptacles.',
          'Wipe clean all surfaces of desks, tables, counters, filing cabinets, and other office equipment, per Client’s instruction.',
          'Wipe clean the  front desk counter with an approved disinfectant. ',
          'Wipe clean all glass tops and display glass with glass cleaner.',
          'Clean entrance area door glass.',
          'Spot clean partition glass, as needed.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Dust mop, then spot mop all hard surface floors.',
        ],
      },
      {
        title: 'Kitchen and Cafe Areas',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Wipe clean exterior of all countertop appliances in the kitchen with disinfectant, including the interior of microwaves and vending machine touch points.',
          'Damp wipe all tables and spot clean chairs.',
          'Clean and disinfect counters and sinks.',
          'Vacuum all mats and runners.',
          'Dust mop, then spot mop all hard surface floors.',
        ],
      },
      {
        title: 'Gym Floor Areas',
        frequencyTypes: [
          'Fill dispensers with towels and hand sanitizer. Wipe dispenser fronts.',
          'Wipe down weights, balls, and AB mats with disinfectant.',
          'Wipe clean all fitness equipment with disinfectant.',
          'Disinfect and polish drinking fountains / water cooler.',
          'Vacuum and dust mop rubber flooring.',
          'Damp mop rubber flooring with the appropriate solution.',
          'Spot clean all mirrors.',
          'Dust and spot clean TVs.',
        ],
      },
      {
        title: 'Aerobic Areas, Spinning Rooms, and Athletic Courts',
        frequencyTypes: [
          'Spot clean partition glass and mirrors, as needed.',
          'Dust mop or vacuum, then spot mop all hard surface floors.',
          'Vacuum all carpeted areas, as needed.',
        ],
      },
      {
        title: 'Restrooms and Lockers',
        frequencyTypes: [
          'Empty trash receptacles and wipe, if needed.',
          'Wipe clean exposed pipe, counters, ledges, mirrors, and air grills.',
          'Disinfect interior and exterior of toilets and toilet seats.  Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals.  Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Clean sinks, disinfect counters, and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Fill dispensers with towels, tissue, and hand soap.  Wipe dispenser fronts.',
          'Sweep tile floor.',
          'Wipe clean all showers with a disinfectant. Polish all chrome fittings.',
          'Wipe clean and disinfect tile walls.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
        ],
      },
      {
        title: 'Recommended Options',
        frequencyTypes: [
          'Wipe clean and disinfect cardio equipment, pull up system, and weight racks.',
        ],
      },
      {
        title: 'Child Care Areas',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Dust mop, then wet mop floors with germicidal disinfectant.',
          "Wipe clean and disinfect all surfaces of desks, tables, and counters per Client's instruction.",
          'Spot clean partition glass and mirrors, as needed.',
        ],
      },
    ],
    weekly: [
      {
        title: 'General Areas',
        frequencyTypes: [
          'Dust all vertical surfaces of desks and file cabinets.',
          'Dust picture frames.',
          'Damp mop hard surface floors, including corners, edges, and under office furniture.',
        ],
      },
      {
        title: 'Gym Floor',
        frequencyTypes: ['Vacuum, with attachment, under fitness machines.'],
      },
    ],
    monthly: [
      {
        title: 'General Office',
        frequencyTypes: [
          'High dust HVAC vents and louvers.',
          'Dust ceiling corners and remove cobwebs.',
          'Spot clean and disinfect light switches ',
          'Spot clean door frames. ',
          'Dust baseboards.',
          'Dust window sills.',
          'Disinfect telephones.',
          'Clean all mirrors.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Recommended Options',
        frequencyTypes: [
          'Detail vacuum under exercise equipment. Client is responsible for moving machines.',
          'Pressure clean and disinfect showers.',
          'Detail clean all partition glass and mirrors.',
        ],
      },
      {
        title: 'Exclusions',
        frequencyTypes: [
          'Maintenance, repair, painting, handyman, or remodeling services',
          'Exterior building parking lot.',
          'Insect control.',
        ],
      },
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  'General Office': {
    nightly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'All trash receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          "Wipe clean all horizontal surfaces of desks, tables, counters, and filing cabinets per Client's instruction.",
          'Wipe clean Conference Room tables and properly arrange chairs.',
          'Spot clean partition glass, as needed.',
          'Clean and disinfect all drinking fountains.',
          'Vacuum all mats and runners.',
          'Dust mop and spot mop all hard surface floors.',
          'Clean entrance area door glass.',
        ],
      },
      {
        title: 'Kitchen Areas',
        frequencyTypes: [
          'Wipe clean tables, chairs, sink, counters, and exterior of cabinets in the Kitchen.',
          'Wipe clean exterior of all countertop appliances in Kitchen with disinfectant, including the interior of microwaves, refrigerator doors and vending machine touch points.',
          'Wipe down coffee machines and coffee stations. Check to be sure coffee machines have been turned off.',
          'Dust mop and spot mop all hard surface floors.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty sanitary napkin receptacles, disinfect, and re-line from stock.',
          'Empty trash receptacles and wipe, if needed.',
          'Wipe clean exposed pipes, counters, ledges, mirrors, and air grills with an approved disinfectant.',
          'Dust mop all hard surface floors.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Clean sinks, disinfect counters, and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
        ],
      },
    ],
    weekly: [
      {
        title: 'General Office',
        frequencyTypes: [
          'Collect all recycling from containers and remove to recycle dumpster area.',
          'Wipe clean all vertical surfaces of desks, file cabinets, and other office furniture.',
          'Dust all office equipment, including computers, monitors, and their bases, per Client’s instruction.',
          'Dust picture frames.',
          'Detail vacuum all carpet areas including edges, corners, and beneath furniture.',
          'Dust mop and damp mop hard surface floors, including corners, edges, and under office furniture.',
        ],
      },
    ],
    monthly: [
      {
        title: 'General Office',
        frequencyTypes: [
          'Spot clean and disinfect light switches.',
          'Spot clean door frames.',
          'Disinfect telephones.',
          'Dust baseboards.',
          'Dust window sills.',
          'Dust all blinds and verticals.',
          'High dust HVAC vents and louvers.',
          'Dust ceiling corners and remove cobwebs.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
      {
        title: 'Exclusions',
        frequencyTypes: [
          'Changing A/C filters or burn light bulbs.',
          'Washing windows above eight feet.',
        ],
      },
    ],
  },
  Manufacturing: {
    nightly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Wipe clean all horizontal surfaces of desks, tables, counters, and filing cabinets.',
          'Wipe clean Conference Room Tables and properly arrange chairs.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Clean and disinfect drinking fountains.',
          'Dust mop, then spot mop all hard surface floors.',
          'Clean entrance area door glass.',
          'Spot clean all partition glass, as needed.',
          'Dust and/or wet wipe furniture',
          'Dust lights and lamps.',
          'Dust telephones.',
          'Wipe clean window ledges.',
        ],
      },
      {
        title: 'Kitchen and Dining Area',
        frequencyTypes: [
          'All receptacles will be emptied and trash removed to the dumpster area.',
          'Wipe clean exterior of trash receptacles and wall behind receptacles.',
          'Wipe clean tables, chairs, sink, counters, and exterior of cabinets in Cafeteria and Kitchenettes.',
          'Wipe clean exterior of all countertop appliances in Kitchen with disinfectant, including the interior of microwaves and vending machine touch points.',
        ],
      },
      {
        title: 'Restrooms & Locker',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Wipe clean exposed pipes, counters, ledges, mirrors, and air grills.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Clean sinks, and polish chrome fittings.',
          'Clean and disinfect benches.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Dust lights and lamps.',
          'Wipe clean window ledges.',
          'Machine scrub and/or wet mop restroom floors with disinfectant, pouring water down drains to eliminate odors.',
        ],
      },
    ],
    weekly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Wipe clean all vertical surfaces of desks, file cabinets, and other office furniture.',
          'Detail vacuum all carpet edges, corners, and beneath furniture.',
          'Dust baseboards.',
          'Dust window sills.',
          'Dust picture frames.',
          'Dust all office equipment, including behind computers, monitors and their bases, and any movable objects on desks.',
          'High dust ceiling corners and remove cobwebs.',
          'Mop hard surface floors, including corners, edges, and under office furniture.',
          'Spot clean all walls, counter fronts, doors, door handles, and push places.',
          'Wet mop all plastic carpet protectors.',
        ],
      },
      {
        title: 'Kitchen and Dining Area',
        frequencyTypes: [
          'Clean and disinfect refrigerators. Discard spoiled foods and containers per Client’s instruction.',
        ],
      },
    ],
    monthly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Spot clean and disinfect light switches.',
          'Spot clean door frames. ',
          'Dust all blinds and verticals.',
          'High dust HVAC vents and louvers.',
          'High speed burnish all vinyl tile flooring in Cafeteria and hallways.',
          'Vacuum and spot clean chairs in the lobby.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Clean corners and edges of restroom floors.',
          'Machine scrub all restroom and locker room floors with a germicidal solution.',
        ],
      },
    ],
    quarterly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Dust out all light fixtures on a rotational basis. To be done as one section per month until all is completed quarterly.',
        ],
      },
    ],
    annual: [
      {
        title: 'General Facility (6 months after Start Date)',
        frequencyTypes: [
          'Top scrub and recoat all vinyl tile flooring with 3 coats of 21% solid content floor finish or better. ',
          'Bonnet clean all traffic areas of carpeting, pretreating any soiled areas.',
        ],
      },
      {
        title: 'General Facility (12 months after Start Date)',
        frequencyTypes: [
          'Strip and refinish all vinyl tile flooring with 5 coats of 21% solid content floor finish or better. ',
          'Hot water extracts all carpeted areas, pretreating any soiled areas.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  'Medical Office': {
    nightly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Wipe clean all surfaces of desks, tables, counters, and filing cabinets per Client’s instruction.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Dust mop, then spot mop all hard surface floors.',
        ],
      },
      {
        title: 'Waiting Area',
        frequencyTypes: [
          'Wipe clean Waiting Area tables. Properly arrange chairs and periodicals.',
          'Clean entrance area door glass.',
          'Spot clean partition glass, as needed.',
          'Wipe and disinfect tables.',
          'Wipe clean ledges and counters with disinfectant.',
        ],
      },
      {
        title: 'Exam Rooms',
        frequencyTypes: [
          'Disinfect tables, chairs, sink, counters, and exterior of cabinets in Exam, Lab, and X-Ray rooms with an approved disinfectant.',
          'Disinfect all medical equipment, per Client’s instructions.',
          'Sweep/dust mop then wet mop floors with germicidal disinfectant.',
        ],
      },
      {
        title: 'Kitchen and Break Area',
        frequencyTypes: [
          'Disinfect tables, chairs, sinks, counters, and exterior of cabinets in the Kitchen.',
          'Wipe clean exterior of all countertop appliances in Kitchen with disinfectant, including the interior of microwaves. ',
          'Clean and disinfect drinking fountains/water coolers.  Clean tray.',
        ],
      },
      {
        title: 'Restroom',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty trash receptacles and wipe, if needed.',
          'Disinfect exposed pipes, counters, ledges, mirrors, and air grills.',
          'Disinfect interior and exterior of toilets and toilet seats.  Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed',
          'Clean sinks, disinfect counters, and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
          'Wipe clean and disinfect tile walls and handrails.',
        ],
      },
    ],
    weekly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Disinfect all vertical surfaces of desks, file cabinets, and other office furniture.',
          'Detail vacuum all carpet edges, corners and beneath furniture.',
          'Dust baseboards.',
          'Damp mop hard surface floors, including corners, edges, and under office furniture.',
          'Dust window sills.',
          'Dust picture frames.',
        ],
      },
    ],
    monthly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Spot clean and disinfect light switches.',
          'Spot clean door frames.',
          'Disinfect telephones.',
          'Dust all blinds and verticals.',
          'Dust HVAC vents and louvers.',
          'Dust ceiling corners and remove cobwebs.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  'Movie Theaters': {
    nightly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Clean all entrance door glass, including ticket windows inside and out.',
          'Vacuum all areas of carpeting, including mats and runners.',
          'Dust mop, then spot mop all hard surface floors.',
          'Sweep entranceways, inspect areas and remove trash.',
          'Clean and disinfect drinking fountains.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap.  Wipe dispenser fronts.',
          'Empty sanitary napkin receptacles, disinfect, and replace liners.',
          'Empty trash receptacles and wipe, if needed.',
          'Clean and polish mirrors and fixtures.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Wipe clean exposed pipes, counters, ledges, and mirrors with approved disinfectant.',
          'Remove splash marks from walls around sinks and urinals.',
          'Spot clean toilet partitions and dust tops.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
        ],
      },
      {
        title: 'Lobby, Corridors, and Stadiums',
        frequencyTypes: [
          'Vacuum all areas of carpeting, including mats and runners.',
          'Dust mop all hard surface floors.',
          'Damp mop all hard surface floors.',
          'Clean all lobby front doors, interior area, and reception glass.',
          'Wipe clean ticket counters.',
          'Spot clean exterior face of ticket counter.',
        ],
      },
      {
        title: 'Theaters',
        frequencyTypes: [
          'Vacuum all areas of carpeting, including mats and runners.',
          'Dust mop or vacuum, then spot mop all hard surface floors.',
        ],
      },
      {
        title: 'Concession Stands and Rear Kitchen Areas',
        frequencyTypes: [
          'All receptacles will be emptied and trash removed to the dumpster area.',
          'Wipe clean exterior of trash receptacles and wall behind receptacles, as needed.',
          'Clean and disinfect counters and sinks.',
          'Clean exterior of glass displays.',
          'Damp mop all hard surface floors with disinfectant.',
        ],
      },
      {
        title: 'Resilient Tile Floor',
        frequencyTypes: [
          'Dust mop all hard surface floors.',
          'Damp mop all hard surface floors, including corners and edges.',
          'Wipe clean baseboards, as needed, to remove scuffs.',
        ],
      },
      {
        title: 'Exterior Areas, Patios, and Decks',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Sweep the area, inspect and remove trash from the surrounding areas.',
        ],
      },
    ],
    weekly: [
      {
        title: 'General Facility',
        frequencyTypes: ['Wipe clean baseboards, as needed, to remove scuffs.'],
      },
      {
        title: 'Theaters',
        frequencyTypes: ['Vacuum and/or wipe clean all seats.'],
      },
    ],
    monthly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Detail vacuum all carpeted areas, including corners and edges.',
          'Damp Mop hard surface floors, including corners and edges.',
          'High dust ceiling corners and remove cobwebs.',
          'Top Scrub all tile grout areas.',
          'Sweep and mop upstairs projector area floors. ',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  'Multi Tenant': {
    nightly: [
      {
        title: 'General Areas',
        frequencyTypes: [
          'All trash receptacles will be emptied and removed to the dumpster area.',
          'Wipe clean all horizontal surfaces of desktops, tables, and cabinets. ',
          'Clean all entrance glass, including side entrances.',
          'Clean and disinfect drinking fountains and water coolers.',
          'Dust mop and spot mop all hard surface floors.',
          'Vacuum all mats and runners.',
          'Clean partition glass, removing all fingerprints and smudges.',
          'Clean directory glass, removing all fingerprints and smudges.',
        ],
      },
      {
        title: 'Restrooms, Showers, and Locker Rooms',
        frequencyTypes: [
          'Stock towels, tissue, hand soap, seat covers, air freshener, hand sanitizer dispensers and other expendable restroom items from janitorial supplies.',
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Empty sanitary napkin receptacles, disinfect, and replace liners.',
          'Dust tops of partitions, light fixtures, cabinets, and dispensers.',
          'Wipe clean exposed pipes, counters, ledges, and mirrors with approved disinfectant.',
          'Sweep all hard floor surfaces.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Remove splash marks from walls around sinks and urinals.',
          'Clean and disinfect shower stalls and shower floors, polish all chrome fittings.',
          'Spot clean locker fronts.',
          'Clean and disinfect towel cabinet covers.',
          'Clean sinks, disinfect counters, and polish chrome.',
          'Clean and polish mirrors.',
          'Damp mop restroom floor, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
        ],
      },
      {
        title: 'Lobbies and Reception Areas',
        frequencyTypes: [
          'Clean all interior areas (e.g. mirrors or windows) and sweep entranceways, inspect areas and remove trash.',
          'Clean directory glass, lobby front doors, and reception glass.',
          'Dust lobby furniture and damp wipe or polish as necessary.',
          'Wipe and disinfect tables.',
          'Damp wipe reception counters, spot clean exterior face of the reception area. ',
          'Dust mop and spot mop all hard surface floors.',
          'Vacuum all mats and runners.',
          'Spot clean carpet each visit as needed.',
        ],
      },
      {
        title: 'Conference Rooms',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Remove used cups to the nearest sink.',
          'Dust furniture and other surfaces.',
          'Clean and polish conference tables.',
          'Wipe clean all white boards per client instructions.',
          'Vacuum all mats and runners.',
          'Return chairs to proper positions.',
        ],
      },
      {
        title: 'Lunchrooms, Kitchens, and Coffee Stations',
        frequencyTypes: [
          'All receptacles will be emptied and trash removed to the dumpster area.',
          'Wipe clean exterior of trash receptacles and wall behind receptacles.',
          'Damp wipe all tables and spot clean chairs.',
          'Clean and disinfect counters and sinks.',
          'Wipe clean exterior of all countertop appliances in Kitchen with disinfectant, including interior of microwaves and vending machine touch points.',
          'Wipe down coffee machines and coffee stations. Check to be sure coffee machines have been turned off.',
          'Vacuum all mats and runners.',
          'Sweep tile floor.',
          'Damp mop all hard surface floors with disinfectant.',
        ],
      },
      {
        title: 'Stairways and Landings',
        frequencyTypes: ['Remove all debris from Stairwell.'],
      },
      {
        title: 'Passenger Elevators',
        frequencyTypes: [
          'Dust mop or vacuum all elevator floors, taking care to get corners and along edges.',
          'Vacuum all elevator thresholds.',
          'Spot clean interior stainless steel and chrome surface of forward-facing cab wall and doors.',
          'Clean and disinfect control panel in elevator.',
          'Spot clean outside surfaces of all elevator doors and frames.',
          'Spot clean elevator cab floor carpeting.',
        ],
      },
      {
        title: 'Tile and Resilient Floor',
        frequencyTypes: [
          'Dust mop all hard surface floors.',
          'Damp mop all hard surface floors, removing all spills and other unsightly marks.',
        ],
      },
      {
        title: 'Warehouse, Loading Dock, and Service Entrance',
        frequencyTypes: [
          'Clean warehouse offices according to the same routine as other office areas.',
          'Empty warehouse trash.',
          'Inspect loading dock for trash and remove to the dumpster area.',
          'Clean service entrance as needed.',
        ],
      },
      {
        title: 'Exterior Smoking Areas, Patios, and Decks',
        frequencyTypes: [
          'Sweep and inspect the area for trash.',
          'Remove trash.',
          'Clean and disinfect seating areas.',
          'Empty cigarette urns.',
        ],
      },
      {
        title: 'Janitorial Closet',
        frequencyTypes: [
          'Keep stocked, including janitorial equipment, clean, neat, and orderly.',
          'Maintain all cleaning chemicals and solutions in properly labeled containers.',
          'Empty and rinse mop buckets nightly.',
        ],
      },
      {
        title: 'Rear and Side Entrances',
        frequencyTypes: ['Inspect rear and side entrances for trash.'],
      },
    ],
    weekly: [
      {
        title: 'Common Areas',
        frequencyTypes: [
          'Collect all recycling from containers and remove to recycle dumpster area.',
          'Wipe clean exterior of trash receptacles and wall behind receptacles.',
          'Dust all low reach areas such as chair rungs, moldings, baseboards, windowsills, wood paneling, and door jambs.',
          'High dust ceiling corners and remove cobwebs.',
          'Detail vacuum all carpet areas including edges, corners, and beneath the furniture.',
          'Disinfect telephones.',
        ],
      },
      {
        title: 'Stairways and Landings',
        frequencyTypes: [
          'Dust railings, banisters, ledges, moldings, and light fixtures.',
          'Remove all debris from Stairwell and spot mop as needed.',
        ],
      },
    ],
    monthly: [
      {
        title: 'Lobbies and Common Areas',
        frequencyTypes: [
          'Remove exterior cobwebs from lobby windows.',
          'Dust all blinds and verticals.',
          'Dust all high reach areas (above eye level) including, but not limited, to door frames, ceiling vents, and grills (including heating and air conditioning systems), partitions tops, picture frames, high shelves, cabinet tops, etc.',
          'Dust all light fixtures.',
          'Vacuum under plastic carpet protectors.',
          'Spot clean waste bins as needed.',
          'Vacuum upholstered furniture.',
        ],
      },
      {
        title: 'Restroom, Showers, and Locker Rooms',
        frequencyTypes: [
          'Thoroughly clean and disinfect all stalls, leave in streak-free condition.',
          'Wipe and disinfect all metal partitions, walls, kick plates, and thresholds.',
          'Dust tops of all lockers.',
        ],
      },
      {
        title: 'Lunchrooms, Kitchens, and Coffee Stations',
        frequencyTypes: ['Spot clean walls behind trash receptacles..'],
      },
      {
        title: 'Tile and Resilient Floor',
        frequencyTypes: [
          'Detail clean floor edges and corners.',
          'Clean baseboards to remove scuffs and splashes from mopping.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exclusions',
        frequencyTypes: [
          'Maintenance, repair, painting, handyman or remodeling services.',
          'Exterior building parking lot.',
          'Washing windows above eight feet.',
          'Insect control.',
        ],
      },
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
        ],
      },
    ],
  },
  'Place of Worship': {
    nightly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Wipe clean all horizontal surfaces of desk tops, tables, cabinets, credenzas, and other similar furnishings and equipment. (Papers and folders on desks will not be moved unless by previous arrangement).',
          'Wipe clean Conference Room tables and properly arrange chairs.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Clean and disinfect drinking fountains.',
          'Dust mop then spot mop all hard surface floors.',
          'Clean directory glass and all entrance glass including side entrances.',
          'Spot clean partition glass, as needed.',
          'Inspect sanctuary and remove trash, spot vacuuming as needed.',
        ],
      },
      {
        title: 'Kitchen Areas',
        frequencyTypes: [
          'All trash receptacles will be emptied and trash removed to the dumpster area.',
          'Wipe clean exterior of trash receptacles and wall behind receptacles.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Clean and disinfect counters and sinks.',
          'Damp wipe all tables and spot clean chairs.',
          'Damp mop all hard surface floors with disinfectant. ',
          'Wipe clean exterior of all countertop appliances in Kitchen with disinfectant, including the interior of microwaves and vending machine touch points.',
          'Wipe down coffee machines and coffee stations. Check to be sure coffee machines have been turned off. ',
        ],
      },
      {
        title: 'Child Care Areas',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Sweep/dust mop then wet mop floors with germicidal disinfectant.',
          "Wipe clean and disinfect all surfaces of desks, tables, and counters per Client's instruction.",
          'Spot clean partition glass and mirrors, as needed.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Empty sanitary napkin receptacles, disinfect, and replace liners.',
          'Wipe clean exposed pipes, counters, ledges, mirrors, and air grills.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Clean and disinfect partition doors, walls, door handles, and other hardware.',
          'Clean sinks, disinfect counters, and polish chrome.',
          'Clean and polish mirrors.',
          'Remove splash marks from walls around sinks and urinals.',
          'Damp mop restroom floor with disinfectant, pouring water down drains to eliminate odors.',
        ],
      },
    ],
    weekly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Wipe clean all vertical surfaces of desks, tables, cabinets, credenzas, and other similar furnishings and equipment.',
          'Detail vacuum all carpet corners, edges, and beneath furniture.',
          'Damp mop hard surface floors, including corners, edges, and under office furniture.',
          'Dust window sills',
          'Disinfect telephones.',
          'Dust picture frames.',
          'Dust baseboards.',
          'Clean and dust chairs, pews, podiums, and other furniture in sanctuary/chapels removing crumbs and other debris.',
          'Vacuum under all accessible desks, chairs, pews, and podiums.',
          'Wipe clean all office equipment, including behind computers, monitors and their bases, and any movable objects on desks.',
        ],
      },
    ],
    monthly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Clean corners and edges of restroom floors.',
          'Spot clean and disinfect light switches. ',
          'Spot clean door frames.',
          'Dust all blinds and verticals.',
          'High dust HVAC vents and louvers.',
          'Dust ceiling corners and remove cobwebs.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exclusions',
        frequencyTypes: [
          'Maintenance, repair, painting, handyman, or remodeling services',
          'Exterior building parking lot.',
          'Pest/Insect control.',
        ],
      },
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  Restaurant: {
    nightly: [
      {
        title: 'Entrance and Front-of-Bar Areas',
        frequencyTypes: [
          'Clean entrance glass, empty entrance cigarette urns, and remove to the dumpster area.',
          'Remove all trash and cobwebs from the exterior entryway. ',
          'Dust and spot clean interior entrance and waiting area surfaces as needed.',
          'Vacuum entrance and walk-off mats.',
          'Dust and spot clean doors, door frames, walls, partition glass, window sills, ledges, and vertical surfaces of booths as needed.',
          'Polish brass work.',
          'Wipe clean window sills',
          'Clean rubber floor mats per Client’s instructions',
        ],
      },
      {
        title: 'Dining Area Floors and Carpets',
        frequencyTypes: [
          'Dust mop then spot mop all hard surface floor areas.',
          'Vacuum all carpeted areas, including mats and runners, moving chairs and tables as needed for complete access.',
        ],
      },
      {
        title: 'Kitchen Floors and Behind-the-Bar Floors',
        frequencyTypes: [
          'Clean floors, scrubbing with a deck brush, and using degreaser for the kitchen.',
          'Wet mop kitchen floors to rinse after degreasing. ',
          'Squeegee excess water to the floor drain.',
          'Lift and clean rubber mats and replace them after the floor is dry.',
          'Clean tops of floor drains.',
        ],
      },
      {
        title: 'Offices',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Wipe clean all horizontal surfaces of desktops, tables, cabinets, credenzas, and other similar furnishings and equipment. (Papers and folders on desks will not be moved unless by previous arrangement).',
          'Vacuum all carpeted areas.',
          'Dust mop then spot mop hard surface floors as needed.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty trash receptacles and wipe, as needed.',
          'Wipe clean exposed pipes, counters, ledges, and mirrors with an approved disinfectant.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Spot clean partitions and dust tops.',
          'Clean sinks, disinfect counters, and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
          'Wipe clean and disinfect tile walls and handrails.',
        ],
      },
    ],
    weekly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Clean and disinfect telephones. ',
          'Dust low reach areas such as baseboards and chair rungs.',
        ],
      },
      {
        title: 'Dining and Front-of-Bar Areas',
        frequencyTypes: [
          'Dust light fixtures, blinds, ceiling vents, and all other horizontal surfaces beyond the scope of normal nightly dusting.',
        ],
      },
    ],
    monthly: [
      {
        title: 'General Facility',
        frequencyTypes: ['Dust blinds. ', 'Dust HVAC vents and louvers.'],
      },
      {
        title: 'Floors',
        frequencyTypes: [
          'Thoroughly clean and disinfect all tile stalls, leave in streak-free condition.',
          'Wipe and disinfect all metal partitions, walls, kickplates, and thresholds.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  'Retail Grocery': {
    nightly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Dust mop all hard surface floors.',
          'Auto scrub all hard surface floors.',
          'Wet mop and auto scrub Deli counter, Oven Works and Kitchen floors.',
          'Propane burnish all Retail area VCT flooring dust mopping all dust and debris afterward.',
          'Vacuum carpet in Dining Room Areas.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty trash receptacles and wipe, if needed.',
          'Dust sink traps, counters, ledges, mirrors, and air grills.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish chrome',
          'Disinfect interior and exterior of urinals. Polish chrome.',
          'Change urinal deodorizers as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Clean sinks and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Clean and disinfect drinking fountains.',
        ],
      },
    ],
    weekly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Sweep, wet mop, and auto scrub hard surface floors in Cooler.',
        ],
      },
    ],
    monthly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Sweep and wet mop Community Room floors. Buff as needed.',
        ],
      },
    ],
    quarterly: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Machine scrub all ceramic flooring.',
          'Low moisture hot water extract carpet in the Dining Room.',
        ],
      },
    ],
    'semi annual': [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Top scrub and recoat all Retail VCT flooring with 3-4 coats of a 21% solid floor finish or better. Performed 3 months after the finish of the initial strip and refinish then again 3 months later.',
        ],
      },
    ],
    annual: [
      {
        title: 'General Facility',
        frequencyTypes: [
          'Strip and refinish all VCT flooring with 6-8 coats of a 21% solid content floor finish or better. Performed on the 12th month of service.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
  School: {
    nightly: [
      {
        title: 'Offices and Classrooms',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Wipe clean telephones.',
          'Wipe clean all horizontal surfaces of desks, tables, counters, and filing cabinets per Client’s instruction.',
          'Wipe clean conference room tables and properly arrange chairs.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Dust mop then spot mop all hard surface floors. ',
          'Damp mop all hard surface floors and/or auto scrub all hard surfaces floors.',
          'Clean entrance area door glass.',
          'Spot clean partition glass, as needed.',
          'Wipe clean chalkboard/whiteboard ledges.',
        ],
      },
      {
        title: 'Lunchroom, Cafeteria, and Lounge',
        frequencyTypes: [
          'Wipe clean tables, chairs, sink, counters, and exterior of cabinets in the cafeteria and faculty lounge.',
          'Wipe clean exterior of all countertop appliances in the kitchen with disinfectant, including the interior of microwaves and vending machine touch points.',
          'Wipe down coffee machines and coffee stations. Check to be sure coffee machines have been turned off.',
          'Vacuum mats and runners.',
          'Wipe clean all countertops and sinks with an approved disinfectant.',
          'Clean and disinfect all drinking fountains and water coolers.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty trash receptacles and wipe with disinfectant, as needed.',
          'Wipe clean exposed pipes, counters, ledges, mirrors, and air grills with approved disinfectant.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Clean sinks, disinfect counters, and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors and fixtures.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
          'Wipe clean handrails with a disinfectant.',
          'Wipe clean tile walls with a disinfectant.',
        ],
      },
    ],
    weekly: [
      {
        title: 'Offices and Classrooms',
        frequencyTypes: [
          'Dust all vertical surfaces of desks, file cabinets, and other office furniture.',
          'Detail vacuum all carpet edges, corners, and beneath the furniture.',
          'Dust baseboards.',
          'Damp mop hard surface floors, including corners, edges, and under office furniture.',
          'Dust window sills.',
          'Dust picture frames.',
          'Wipe clean classroom tables and chairs.',
          'Dust and wipe clean entrance door tracks.',
          'Dust wall molding.',
        ],
      },
    ],
    monthly: [
      {
        title: 'Offices and Classrooms',
        frequencyTypes: [
          'Spot clean and disinfect light switches.',
          'Spot clean door frames.',
          'Disinfect telephones.',
          'Dust all blinds and verticals.',
          'High dust HVAC vents and louvers.',
          'High dust ceiling corners and remove cobwebs.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas, and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
      {
        title: 'Recommended Options',
        frequencyTypes: [
          'Clean interior of the refrigerator located in the faculty lounge. The client is responsible for the removal of food. (Fridays)',
          'Clean and disinfect the interior of lockers.',
          'Auto scrub all hard floor surfaces.',
          'Scrub and recoat all vinyl tile flooring areas.',
          'Burnish all vinyl tile flooring areas.',
          'Strip and refinish all vinyl flooring.',
          'Top Scrub all tile grout areas.',
          'Carpet cleaning.',
        ],
      },
    ],
  },
  'Surgical Center': {
    nightly: [
      {
        title: 'Common Areas',
        frequencyTypes: [
          'All receptacles will be emptied, wiped as needed, and trash removed to the dumpster area. No red bag pickup.',
          'Break down boxes marked “Trash” for recycling and remove to recycling dumpster area. ',
          'Place soiled linens in the appropriate receptacle.',
          'Fill dispensers with towels, tissue, hand sanitizer, and hand soap throughout the facility. Wipe dispenser fronts.',
          'Disinfect telephones.',
          'Disinfect surfaces in the Pre-OP/nurse’s station and recovery areas.',
          'Disinfect all countertops throughout the facility.',
          'Disinfect and scrub all sinks. Polish all chrome fittings.',
          'Vacuum all traffic areas of carpeting, including mats and runners.',
          'Clean and disinfect drinking fountains and water coolers.',
          'Dust mop then damp mop all hard surface floors with the appropriate solution.',
          'Clean narcotics office upon request. Report to management if the door is left open. If open, lock it immediately.',
          'Spot clean interior windows for fingerprints and smudges.',
        ],
      },
      {
        title: 'Patient Rooms',
        frequencyTypes: [
          'Damp mop recovery areas, pulling and replacing beds.',
          'Pull rolling carts from walls in recovery, clean behind, and replace.',
          'Dust the EKG monitors.',
        ],
      },
      {
        title: 'Lunchroom and Break Rooms',
        frequencyTypes: [
          'Wipe clean coffee machines and coffee stations. Restock and arrange coffee condiments. Check to be sure coffee machines have been turned off. ',
          'Wipe clean tables, chairs, sinks, couches, counters, and exterior of cabinets in break rooms and doctors’ lounges.',
          'Wipe clean exterior of all countertop appliances in the kitchen with disinfectant, including the interior of microwaves and vending machine touch points.',
        ],
      },
      {
        title: 'Reception and Waiting Area ',
        frequencyTypes: [
          'Arrange chairs and periodicals in the waiting area. Wipe clean chairs and tables.',
          'Clean entrance area door glass.',
          'Spot clean partition glass, as needed.',
        ],
      },
      {
        title: 'Conference Rooms and Offices',
        frequencyTypes: [
          'Disinfect all surfaces of desks, (including behind computers, monitors and their bases, and beneath any movable objects on desks), tables, counters, filing cabinets, and other office equipment.',
          'Wipe clean conference room tables and properly arrange chairs.',
        ],
      },
      {
        title: 'Restroom and Locker Rooms',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty trash receptacles and wipe with disinfectant, as needed.',
          'Wipe clean exposed pipes, counters, ledges, mirrors, and air grills with approved disinfectant.',
          'Sweep all hard surface floors.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Clean and disinfect walls around toilets as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Clean sinks, disinfect counters, and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors and fixtures.',
          'Wipe clean benches.',
          'Clean and disinfect showers. Polish all chrome fittings.',
          'Disinfect and clean all ceramic walls, weekly.',
          'Disinfect door frames.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
          'Clean and disinfect drinking fountains and water coolers.',
        ],
      },
    ],
    weekly: [
      {
        title: 'Common Areas',
        frequencyTypes: [
          'Dust all vertical surfaces of desks, file cabinets, and other office furniture.',
          'Detail vacuum all carpet edges, corners, and beneath the furniture.',
          'Dust baseboards.',
          'Damp mop hard surface floors, including corners, edges, and under office furniture.',
          'Dust window sills.',
          'Dust picture frames.',
          'Dust ceiling corners and remove cobwebs.',
          'High dust HVAC vents and louvers.',
          'High dust filing cabinets.',
          'High and low dust walls and floor molding.',
          'Wash and disinfect the bottom half of walls in Pre- and Post-OP areas, surgical halls, and supply rooms.',
          'Disinfect IV poles.',
          'Dust ceiling in Pre-and Post OP areas, halls, and supply rooms.',
          'Disinfect all doors and handles.(check the jams for grease/dust)',
          'Polish all door kick-plates.',
          'Hose down rubber mats with disinfectant. (Every other week.)',
          'Wipe clean wall molding throughout the facility.',
          'The janitorial closet will be clean and organized at all times.',
        ],
      },
      {
        title: 'Reception and Waiting Area',
        frequencyTypes: [
          'Dust chair bases in the waiting area. Wipe armrests with disinfectant.',
        ],
      },
    ],
    monthly: [
      {
        title: 'Common Areas',
        frequencyTypes: [
          'Spot clean and disinfect light switches.',
          'Spot clean door frames. ',
          'Dust all blinds and verticals.',
          'High dust HVAC vents and louvers.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: "Terminal Cleaning - Per Client's instructions",
        frequencyTypes: [
          'Completely wipe down from the ceiling to floor all surfaces in the operation room.',
          'Clean the O.R. table first to aid in confining the most contaminated sources (discard the solution used on the table, and add a new cleaning solution). Move bed if present in the O.R. room.',
          'Clean remaining furniture and equipment with disinfectant, starting at the top and working downward toward the floor.',
          'Clean wheels and casters to prevent debris from collecting.',
          'Damp dust and wipe dry the overhead surgery lights.',
          'Clean window sills and all wall mounts.',
          'Clean doors and knobs, with special attention to handles and push plates.',
          'Clean countertops in O.R.',
          'Spot clean soiled areas on the walls.',
          'Damp dust and damp wipe dry air grills.',
          'Clean counters of sub-sterile rooms.',
          'Clean and scrub sink with scouring powder and a damp sponge.',
          'Wet mop total floor area with a germicidal cleaning agent.',
          'Roll all wheels and casters through the cleaning solution used for the floor.',
          'Replace surgery room equipment and furniture to its proper place.',
          'Empty cleaning solution.',
          'Remove trash to proper receptacle, and replace trash liner with a new liner.',
          "Clean and disinfect scrub sinks outside of O.R.'s.",
          'Clean and disinfect adjoining rooms.',
        ],
      },
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously locked doors and areas and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
};


const weeklyTypesValues = {...weeklyTypes}
Object.keys(weeklyTypesValues).forEach(key=>{
  Object.keys(weeklyTypesValues[key]).forEach(f=>{
    weeklyTypesValues[key][f].forEach(i=>{
      i.uid = Math.random();
      i.frequencyTypes = i.frequencyTypes.map(fy=>({
        value: fy,
        uid: Math.random()
      }))
    })
  })
})
export {weeklyTypesValues};

export const monthlyTypes = {
  'General Office': {
    monthly: [
      {
        title: 'General Office',
        frequencyTypes: [
          'All trash receptacles will be emptied, wiped as needed, and trash removed to the dumpster area.',
          'Disinfect telephones.',
          'Wipe clean all surfaces of desks, tables, counters, filing cabinets, and other office equipment.',
          'Wipe clean conference room tables and properly arrange chairs.',
          'Wipe clean tables, chairs, sink, counters, and exterior of cabinets in the Kitchen.',
          'Wipe clean exterior of all countertop appliances in Kitchen with disinfectant, including the interior of microwaves. ',
          'Clean and disinfect drinking fountains.',
          'Dust window sills.',
          'Dust picture frames.',
          'Vacuum all carpeted areas, including mats and runners.',
          'Dust baseboards.',
          'Disinfect and polish drinking fountains.',
          'Dust mop all hard surface floors.',
          'Damp mop hard surface floors, including corners, edges, and under office furniture.',
          'Clean entrance area door glass.',
          'Spot clean partition glass, as needed.',
          'Dust all office equipment, including computers, monitors and their bases, and any movable objects on desks per Client’s instruction.',
          'High dust ceiling corners and remove cobwebs. ',
          'Spot clean and disinfect light switches.',
          'Spot clean door frames. ',
          'Dust all blinds and verticals.',
          'Dust HVAC vents and louvers.',
        ],
      },
      {
        title: 'Restrooms',
        frequencyTypes: [
          'Fill dispensers with towels, tissue, and hand soap. Wipe dispenser fronts.',
          'Empty sanitary napkin receptacles, disinfect, and re-line from stock.',
          'Empty trash receptacles and wipe, if needed.',
          'Wipe clean exposed pipes, counters, ledges, tops of partitions, mirrors, and air grills.',
          'Sweep tile floor.',
          'Disinfect interior and exterior of toilets and toilet seats. Polish all chrome fittings.',
          'Disinfect interior and exterior of urinals. Polish all chrome fittings.',
          'Change urinal deodorizers as needed.',
          'Spot clean toilet partitions and dust tops.',
          'Clean sinks, disinfect counters, and polish chrome fittings.',
          'Remove splash marks from walls around sinks.',
          'Clean and polish mirrors.',
          'Damp mop restroom floors, including corners and edges, with disinfectant, pouring water down drains to eliminate odors.',
        ],
      },
    ],
    miscellaneous: [
      {
        title: 'Exit Procedures',
        frequencyTypes: [
          'Keep the janitorial closet and all stock, including janitorial equipment, clean, neat, and orderly. Empty and rinse mop buckets nightly.',
          'Turn off designated lights, re-secure all previously lock doors and areas and set alarms per Client’s instructions.',
          'Complete a final walk through of the facility to ensure specifications have been completed.',
        ],
      },
    ],
  },
};

const monthlyTypesValues = {...monthlyTypes}
Object.keys(monthlyTypesValues).forEach(key=>{
  Object.keys(monthlyTypesValues[key]).forEach(f=>{
    monthlyTypesValues[key][f].forEach(i=>{
      i.uid = Math.random();
      i.frequencyTypes = i.frequencyTypes.map(fy=>({
        value: fy,
        uid: Math.random()
      }))
    })
  })
})
export {monthlyTypesValues};

export const orderingItems = [
  'Nightly',
  'Weekly',
  'Every Other Week',
  'Twice Per Month',
  'Monthly',
  'Every Other Month',
  'Quarterly',
  'Semi Annual',
  'Annual',
  'Miscellaneous',
  'Other',
];

export const additionalTypes = [
  'Select One',
  'Every Other Week',
  'Twice Per Month',
  'Every Other Month',
  'Quarterly',
  'Semi Annual',
  'Annual',
  'Other',
];

export const specialNotesDefault = {
  'Pre School': 'Teachers are responsible for cleaning all learning stations.',
  School:
    'Service Providers to report any maintenance and/or security issues to the administration office.',
  'Surgical Center':
    'Cleaners shall be properly attired per policy; scrubs and gloves. All mops and cloths will be color-coded. Cleaning solutions used shall be a Quad disinfectant (EPA registered, a hospital-grade quaternary disinfectant cleaner is mandatory). Anago operations will conduct a weekly inspection for the first two months of operation, thereafter there will be a monthly inspection. One cleaner will be a supervisor. All cleaners will provide mandatory certifications to clean the facility. Surgery center employees are responsible for cleaning beds and stretchers.\nService Providers to report any maintenance and/or security issues to the building manager.',
  'General Minimum':
    'Service Providers to report any maintenance and/or security issues to the building manager.',
  'Auto Dealer':
    'Service Providers to report any maintenance and/or security issues to the building manager.',
  'Bowling Alley':
    'Service Providers to report any maintenance and/or security issues to the building manager. All hardwood flooring in the bowling areas will be handled by the Client.',
  'Country Club':
    'Service Providers to report any maintenance and/or security issues to the building manager.',
  'Day Care':
    'Service Providers to report any maintenance and/or security issues to the administration office. Teachers are responsible for cleaning all learning stations.',
  'Dental Office':
    'Service Providers to report any maintenance and/or security issues to the building manager.',
  'Fitness Center':
    'Service Providers to report any  aintenance and/or security issues to the building manager.',
  'General Office':
    'Service Providers to report any maintenance and/or security issues to the building manager.',
  Manufacturing:
    'Service Providers to report any maintenance and/or security issues to the building manager.',
  'Medical Office':
    'Service Providers to report any maintenance and/or security issues to the building manager.',
  'Movie Theater':
    'Service Providers to report any maintenance and/or security issues to the building manager.',
  'Movie Theaters':
    'Service Providers to report any maintenance and/or security issues to the building manager.',
  'Multi Tenant':
    'Service Providers to report any maintenance and/or security issues to the building manager.',
  'Place of Worship':
    'Service Providers to report any maintenance and/or security issues to the building manager.',
  Restaurant:
    'Service Providers to report any maintenance and/or security issues to the building manager.',
  'Retail Grocery':
    'Service Providers to report any maintenance and/or security issues to the building manager.',
  'Day Spa':
    'Service Providers to report any maintenance and/or security issues to the building manager.',
};

export const dataKeysByType = (
  type: 'monthly' | 'weekly' | 'oneDay',
  alreadyExistent: Array<string>
) => {
  const data =
    type === 'monthly'
      ? monthlyTypes
      : type === 'weekly'
      ? weeklyTypes
      : oneDayTypes;
  const result = { keys: [], values: [] };
  Object.values(data).map((value: any) =>
    Object.values(value).map((type: any) =>
      type.forEach((item: any) => {
        if (
          !result.keys.includes(item.title) &&
          !alreadyExistent.includes(item.title)
        ) {
          result.keys.push(item.title);
        }
        item.frequencyTypes.map(
          (freqType: string) =>
            !result.values.includes(freqType) && result.values.push(freqType)
            // result.values.filter(ft=>ft.value.includes(freqType.value)).length == 0 && result.values.push(freqType)
        );
      })
    )
  );
  return result;
};

export const disinfectionNightlyCleaning = {
  key: 'disinfection',
  styleTitle: '/i',
  mainTitle:
    'Anago Protection+ Disinfection™ (included only if Service is selected)',
  subTitle:
    'Performed with an EPA/CDC compliant disinfectant. Client will remove clutter from all surfaces needing disinfection prior to service. Note: disinfectant requires dwell time and may leave a slight haze on some surfaces.',
  frequencyTypes: [
    'Disinfect all restroom fixtures, including flush valve handles, paper and soap dispensers, and light switches.',
    'Disinfect restroom stall doors, handles, and handrails.',
    'Disinfect kitchen counters, sinks, and faucet handles.',
    'Disinfect refrigerator/freezer doors and handles, including water and ice dispensers.',
    'Disinfect microwave/toaster oven control panel and doors.',
    'Disinfect vending equipment control panels and doors.',
    'Disinfect coffee pot handles and dispensing units.',
    'Disinfect chairs in the lobby/waiting area.',
    'Disinfect elevator wall panel/button, control panel/button, and railings.',
    'Disinfect doorknobs, handles, and doorframes.',
    'Disinfect push/pull surfaces.',
    'Disinfect light switches and thermostats.',
    'Disinfect copiers, faxes, printers, and other office equipment.',
    'Disinfect all table surfaces and edges in conference rooms.',
    'Disinfect impervious chair surfaces, armrests, and mist all chair fabric surfaces in conference rooms.',
    'Disinfect all public counters.',
    'Disinfect drinking fountains and water coolers.',
    'Disinfect stair railings.',
    'Disinfect all chairs.',
    'Disinfect all cleared surfaces of desks and cabinets.',
    'Disinfect cabinet and drawer doors and handles.',
    'Disinfect workstations.',
    'Disinfect telephones.',
    'Disinfect computer mouse and keyboards.',
    'Disinfect cubicles.',
    'Disinfect doors.',
    'Disinfect A/V controls, switches, and other surfaces with potential for hand contact.',
    'Electrostatic spray of all surfaces with EPA grade disinfectant including all surfaces and hard to reach areas.',
  ],
};
