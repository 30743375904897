import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '../Checkbox';

import { InputFloatLabel } from '../InputFloatLabel';

import './styles.scss';

interface IContentItems {
  itemsPerColumn: number;
  itemsWidth: string;
}

const ContentItems = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  width: ${({ itemsPerColumn = 1, itemsWidth }: IContentItems) =>
    itemsWidth ? `${itemsWidth}` : `${100 / itemsPerColumn}%`};
  margin-bottom: 20px;
  align-items: center;
  opacity: ${({ disabled }) => (disabled ? '0.7' : '1')};
`;

interface ICheckboxList {
  selecteds: any;
  name: string;
  options: Array<any>;
  itemsPerColumn?: number;
  itemsWidth?: string;
  optionLabel?: string;
  optionValue?: string;
  additionalValue?: any;
  additionalOptionLabel?: string;
  additionalOptionName?: string;
  onChangeAdditional?: Function;
  onBlurAdditional?: Function;
  onChange: Function;
  disabled?: boolean;
  radioStyled?: boolean;
}

export const CheckboxList = ({
  selecteds,
  name,
  options,
  optionLabel,
  optionValue,
  onChange,
  itemsPerColumn = 1,
  itemsWidth,
  additionalValue,
  additionalOptionLabel,
  additionalOptionName,
  onChangeAdditional,
  onBlurAdditional,
  disabled = false,
  radioStyled = false,
}: ICheckboxList) => {
  const onClick = (value: any) => {
    const result = selecteds.includes(value)
      ? [...(selecteds.filter((item: any) => item !== value) || [])]
      : [...selecteds, value];
    onChange(name, result);
  };
  return (
    <div className="checkbox-list-container">
      {options.map((item: any, index: number) => (
        <ContentItems
          disabled={disabled}
          itemsPerColumn={itemsPerColumn}
          itemsWidth={itemsWidth}
          key={`checkbox_list_${name}_${index}`}
        >
          {radioStyled && (
            <>
              <div
                className="checkbox-list-circle"
                data-cy={`checkbox-radio-styled-${index}`}
                onClick={() =>
                  disabled === false &&
                  onClick(optionValue ? item[optionValue] : item)
                }
              >
                {selecteds.includes(optionValue ? item[optionValue] : item) && (
                  <div className="checkbox-list-selected" />
                )}
              </div>
              {optionLabel ? item[optionLabel] : item}
            </>
          )}
          {!radioStyled && (
            <Checkbox
              containerStyle={{
                alignSelf: 'flex-start',
              }}
              dataCy={`checkbox-${index}`}
              borderColor="gray"
              name={optionValue ? item[optionValue] : item}
              value={optionValue ? item[optionValue] : item}
              checked={selecteds.includes(
                optionValue ? item[optionValue] : item
              )}
              onChange={(...args) => {
                disabled === false &&
                  onClick(optionValue ? item[optionValue] : item);
              }}
              contentStyle={{
                paddingLeft: 15,
              }}
              label={optionLabel ? item[optionLabel] : item}
            />
          )}
        </ContentItems>
      ))}
      {additionalValue !== undefined && (
        <div className="checkbox-list-additional">
          <InputFloatLabel
            disabled={disabled}
            value={additionalValue}
            label={additionalOptionLabel!}
            name={additionalOptionName!}
            onChange={onChangeAdditional!}
            onBlur={onBlurAdditional}
          />
        </div>
      )}
    </div>
  );
};
