import React from "react";

function Triangle(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className={props.className}
      height={props.height}
      width={props.width}
      style={props.style}
      >
    	<g transform="matrix(1 0 0 -1 0 512)">
    		<g>
    			<g>
    				<path d="M507.521,427.394L282.655,52.617c-12.074-20.122-41.237-20.122-53.311,0L4.479,427.394    c-12.433,20.72,2.493,47.08,26.655,47.08h449.732C505.029,474.474,519.955,448.114,507.521,427.394z" dataOriginal="#000000" className="active-path" dataOld_color="#000000" fill="#FFFFFF"/>
    			</g>
    		</g>
    	</g>
    </svg>
  );
}

export default Triangle;
