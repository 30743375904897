import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { IStateToProps, IDispatchToProps } from 'modules/InspectForm/interfaces';
import { InspectFormActions } from 'modules/InspectForm/redux/actions';
import { IReduxState, IArea } from 'core/interfaces'
import './styles.scss';

import { AutoComplete } from 'core/components';

interface IState {
  newAreaName: string;
  showEmptyArea: boolean;
  suggestionClicked: boolean;
  predefinedArea: any;
}

const parseAreaName = (name: string) =>
  name ? name.toLowerCase().replace(/\((.+?)\)/g, '').trim() : ''

type IProps = IDispatchToProps & IStateToProps;
class AddAreaModal extends React.Component<IProps, IState> {

  public state = {
    newAreaName: '',
    showEmptyArea: false,
    suggestionClicked: false,
    predefinedArea: null
  }

  public onAddEmptyArea = () => {
    const { showEmptyArea } = this.state

    this.setState({
      showEmptyArea: !showEmptyArea,
      suggestionClicked: false // reseting state
    })
  }

  public getCountDuplicated = (name: string) => {
    const { Areas } = this.props;
    if(!Areas || !Areas.length){
      return ''
    }
    const result = Areas.filter((area: IArea) => parseAreaName(area.Name) === name.toLowerCase()).length
    return result ? ` (${result + 1})` : ''
  }

  public onClose = () => {
    // const { setNote, setNotesModalState, setAddAreasModal } = this.props;
    const { setAddAreasModal, addAreaRequest, setWarningModalState } = this.props;
    const { newAreaName } = this.state;

    if (newAreaName.length > 3) {
      const countDuplicated = this.getCountDuplicated(newAreaName)
      addAreaRequest(`${newAreaName}${countDuplicated}`)
      this.setState({
        newAreaName: '',
        showEmptyArea: false,
      });
      setAddAreasModal(false); // close modal
    } else {
      setWarningModalState(true, 'Please, insert a valid name')
      // alert('Please, insert a valid name');
    }
  }

  public onCancel = () => {
    const { setAddAreasModal } = this.props
    setAddAreasModal(false); // close modal
  }

  public render() {
    // const { isSent } = this.props;
    const { newAreaName } = this.state;
    return (
      <div className="notes-modal-root-div">
        <div className="notes-modal-content-div area-modal">
          <div className="notes-modal-title-container">
            <h3>Add New Area</h3>
          </div>
          <div className="text-area-container">
            {/* <span className="text-area-mensage">Select from the dropdown or add a custom area.</span> */}
            <span className="text-area-mensage">Select from the dropdown or add a custom area. Area's chosen from the dropdown will prepopulate with items to rate.</span>
              <AutoComplete
                type='area'
                placeholder='New Area'
                inputValue={newAreaName}
                onChangeInputValue={(value) => this.setState({
                  newAreaName: value,
                  suggestionClicked: false
                })}
                onClickArea={(area) => {
                  // addPreDefinedArea(area)
                  this.setState({
                    predefinedArea: area,
                    newAreaName: area.Name
                  })
                }}
                onSuggestionClicked={(value) => this.setState({ suggestionClicked: value})}
              />
          </div>
        </div>
        <div className="notes-modal-done-button">
            <button className="button-light" onClick={this.onCancel}>
              CANCEL
            </button>
            <button className="button" onClick={this.onClose}>
              DONE
            </button>
          </div>
      </div>
    )
  }
}

const mapStateToProps = (state: IReduxState) => ({
  ...state.inspectForm
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ ...InspectFormActions }, dispatch);

const Connected = connect<IStateToProps, IDispatchToProps, {} ,IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(AddAreaModal)


export {  Connected as AddAreaModal };
