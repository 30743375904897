import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { IStateToProps, IDispatchToProps } from 'modules/InspectForm/interfaces';
import { InspectFormActions } from 'modules/InspectForm/redux/actions';
import { IReduxState } from 'core/interfaces'
import './styles.scss';

interface IState {
  newItemName: string;
}

type IProps = IDispatchToProps & IStateToProps;
class AddItemModal extends React.Component<IProps, IState> {

  public state = {
    newItemName: '',
  }

  public onClickAddAreaItem = () => {
    const { newItemName } = this.state;
    const { addAreaItemRequest, setWarningModalState } = this.props;

    if (newItemName.length >= 3) {
      addAreaItemRequest(newItemName);
      this.setState({
        newItemName: '',
      });
    } else {
      setWarningModalState(true, 'Invalid name for new area item!')
      // alert('Invalid name for new area item!')
    }
  }

  public onClose = () => {
    const { newItemName } = this.state;
    const { setAddItemModalState, addAreaItemRequest, setWarningModalState } = this.props;

    if (newItemName.length >= 3) {
      addAreaItemRequest(newItemName);
      this.setState({
        newItemName: '',
      });
      setAddItemModalState(false); // close modal
    } else {
      setWarningModalState(true, 'Invalid name for new area item!')
      // alert('Invalid name for new area item!')
    }
  }

  public onCancel = () => {
    const { setAddItemModalState } = this.props
    setAddItemModalState(false); // close modal
  }

  public render() {
    // const { isSent } = this.props;
    const { newItemName } = this.state;
    return (
      <div className="notes-modal-root-div">
        <div className="notes-modal-content-div area-modal">
          <div className="notes-modal-title-container">
            <h3>Add New Item</h3>
          </div>
          <div className="text-area-container">
            <input
              placeholder="New Item"
              value={newItemName}
              onChange={(e) => this.setState({ newItemName: e.target.value })}
              // onBlur={this.onClickAddAreaItem}
              // onKeyDown={(e) => {
              //   if(e.key === 'Enter') {
              //     this.onClickAddAreaItem()
              //   }
              // }}
            />
          </div>
        </div>
        <div className="notes-modal-done-button">
            <button className="button-light" onClick={this.onCancel}>
              CANCEL
            </button>
            <button className="button" onClick={this.onClose}>
              DONE
            </button>
          </div>
      </div>
    )
  }
}

const mapStateToProps = (state: IReduxState) => ({
  ...state.inspectForm
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ ...InspectFormActions }, dispatch);

const Connected = connect<IStateToProps, IDispatchToProps, {} ,IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(AddItemModal)

export { Connected as AddItemModal };
