export const getDomain = () => {
  var parts = window.location.hostname.split('.');
  parts.shift();
  return parts.join('.');
};

export const setCookie = (name: string, value: string, days = 7) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie =
    name +
    '=' +
    encodeURIComponent(value) +
    '; expires=' +
    expires +
    '; domain= ' +
    getDomain() +
    '; path=/';
};

export const getCookie = (name: string) => {
  // if(name == "ANAGO_USER_ACCESS_TOKEN"){
  //   return 'GxHcrFWixm_HR2WgtK6zNUFj3Wq1iE24Yxn0i3NjhjRZEroz7GMbjqexW2cO4sMGtopSFW5MFxETRuxG3_nHEe6CCRdUPHDdJTZm0P0xh4j23KNC-lfiM9C-nqpX2qNarNQwF9nrkLBA6jURLqcmle4_L_lyTgl5sPrT38OO9-cr00p71k-WlOTNOJOmYiFx1cftC5kAQMGbWad1FOCJnoEtETSCXx23q2R8d97LD4H7NiUFML_EytfMsLecGSAOMRLcQIn5FTLZBBox9Ef75fCiUifAHISzTWirXgImN5f8aO1aUM9pOPv-CbKqNh3MYZTMC1rsjrXUuB1TrAU8mqZhzdhLzvYs_8vFVGGHUr40PQYCZbyiAarVNvsPWBr_kbo3X_qD3CJJE4Uy8IsXQs9fX9WWR5zN5exb-D5ozwxCPnO1yYhK6dnRLySufdF52CrwuqONtElun9tgw0dthsuACUMxnRD-fgkn9en13oLmJpcelRVpV1ajKD5HXgeIV8PX5My90egsDD1ZnGYBnMaq19uoQFRbOdigFBCfV8E'
  // }
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');
};

export const deleteCookie = (name: string) => {
  setCookie(name, '', -1);
};

export const deleteAllCookies = (): void => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    deleteCookie(name);
  }
};

export const getAllCookies = () => {
  return document.cookie;
}