import React, { memo, useEffect, useState, useMemo } from 'react';
import { withFormik, FormikProps } from 'formik';

import {
  ContentInput,
  Table,
  useFormProps,
  BoxValue,
  InputFloatLabel,
} from 'core/components';
import {
  IBidProductionRates,
  IBidProductionRatesItem,
} from 'modules/BidInfoForm/interfaces';
import {
  productionRatesValues,
  prodGroups,
} from 'modules/shared/bidDefaultValues';
import { calculateProductionRates } from 'modules/BidInfoForm/utils';
import { decimalOnePlace, decimalOnePlaceParams } from 'core/utils';
import {formatNumberByMask} from 'core/utils/inputFormatsV2';

import './styles.scss';

type IProps = FormikProps<IBidProductionRates>;
const ProductionRates = memo(({ values, setValues, setFieldValue }: IProps) => {
  const { formValues, onChange } = useFormProps();
  const [updateContext, setUpdateContext] = useState(false);
  const {
    inventory,
    frequency,
    secondFrequency,
    addFrequency,
    monthlyAddFrequency,
    sameAsAbove,
    smartClean,
    cleaning,
    secondCleaning,
    frequencyService,
    productionRates,
  } = formValues;

  useEffect(() => {
    setFieldValue('productionRates', productionRates);
  }, [productionRates]);

  const onChangeText = (index: number) => (name: string, value: any) => {
    const result = [
      ...values.productionRates.filter(
        (item: any, indexItem: number) => indexItem !== index
      ),
    ];
    result.splice(index, 0, {
      ...values.productionRates[index],
      [name]: value,
    });
    setFieldValue('productionRates', result);
    setUpdateContext(!updateContext);
  };

  const showSecond = () => {
    return (
      (formValues.frequencyService === 'weekly' &&
        formValues.addFrequency &&
        formValues.secondFrequency &&
        formValues.secondFrequency.selecteds.length > 0) ||
      (formValues.frequencyService === 'monthly' &&
        formValues.monthlyAddFrequency &&
        !!formValues.secondCleaning)
    );
  };

  const columns = useMemo(() => {
    const columnsValues = [
      {
        key: 'group',
        label: 'Group',
        width: '10%',
        render: ({ group }: IBidProductionRatesItem) => (
          <BoxValue value={group} align="center" />
        ),
      },
      {
        key: 'productionName',
        label: 'Name (Optional)',
        width: '25%',
        render: (
          { productionName }: IBidProductionRatesItem,
          index: number
        ) => (
          <InputFloatLabel
            className="input-production-name"
            value={productionName}
            label=""
            name="productionName"
            onChange={onChangeText(index)}
            onBlur={() => onChange('productionRates', values.productionRates)}
            center
            disabled={formValues.status === 2}
          />
        ),
      },
      {
        key: 'sqft',
        label: 'Sq. Ft',
        width: '20%',
        render: ({ sqft }: IBidProductionRatesItem) => (
          <BoxValue value={formatNumberByMask(sqft, {
            prefix: '',
            allowDecimal: true,
            // decimalLimit: 2,
            thousandsSeparatorSymbol: true, 
            type: 'tel',
          })} align="center" />
        ),
      },
      {
        key: 'rate',
        label: `${
          frequencyService == 'weekly'
            ? formValues.frequency.selecteds.length
            : cleaning || 0
        }x`,
        width: '15%',
        render: ({ rate }: IBidProductionRatesItem, index: number) => (
          <InputFloatLabel
            value={rate}
            label=""
            id={`rate_${index}`}
            name="rate"
            onChange={onChangeText(index)}
            onBlur={() => onChange('productionRates', values.productionRates)}
            mask={decimalOnePlace}
            maskParams={decimalOnePlaceParams}
            onFocusSelect
            center
            disabled={formValues.status === 2}
          />
        ),
      },
    ];

    if (showSecond()) {
      columnsValues.push({
        key: 'secondRate',
        label: `${
          frequencyService === 'weekly'
            ? secondFrequency.selecteds.length
            : secondCleaning
        }x`,
        width: '15%',
        render: ({ secondRate }: IBidProductionRatesItem, index: number) => (
          <InputFloatLabel
            value={secondRate}
            label=""
            id={`secondRate_${index}`}
            name="secondRate"
            onChange={onChangeText(index)}
            onBlur={() => onChange('productionRates', values.productionRates)}
            mask={decimalOnePlace}
            maskParams={decimalOnePlaceParams}
            type="tel"
            onFocusSelect
            center
            disabled={formValues.status === 2}
          />
        ),
      });
    }

    return columnsValues;
  }, [
    frequency,
    addFrequency,
    monthlyAddFrequency,
    secondFrequency,
    sameAsAbove,
    smartClean,
    values,
    frequencyService,
    cleaning,
    secondCleaning,
  ]);

  useEffect(() => {
    const result = calculateProductionRates(
      productionRates.map((item: any) => ({
        ...item,
        secondRate: secondFrequency ? item.secondRate : 0,
      })),
      inventory
    );
    setFieldValue('productionRates', result);
    setUpdateContext(!updateContext);
    // eslint-disable-next-line
  }, [inventory, frequencyService, addFrequency, frequency, secondFrequency]);

  useEffect(() => {
    onChange('productionRates', values.productionRates);
    // eslint-disable-next-line
  }, [updateContext]);

  const cleaningTitle = useMemo(() => {
    return {
      paddingRight: showSecond() ? '0' : '15px',
      textAlign: showSecond() ? 'center' : 'right',
      width: '35%',
      fontSize: '12px',
      color: '#777',
      fontFamily: 'OpenSans-Regular',
    };
  }, [addFrequency, secondFrequency, monthlyAddFrequency, secondCleaning]);

  return (
    <div className="production-rates-root">
      <div className="production-rates-content">
        <ContentInput width="100%" justify="space-between">
          <h3 className="production-rates-title">Production Rates</h3>
          {values.productionRates && values.productionRates.length > 0 && (
            <h3 className="production-rates-title" style={cleaningTitle}>
              Cleaning Frequency
            </h3>
          )}
        </ContentInput>

        <Table
          columns={columns.filter((item: any) =>
            !(formValues.secondFrequency || secondCleaning)
              ? item.key !== 'secondRate'
              : item
          )}
          data={values.productionRates.sort(
            (a, b) => prodGroups.indexOf(a.group) - prodGroups.indexOf(b.group)
          )}
        />
      </div>
    </div>
  );
});

const mapForm = {
  mapPropsToValues: () => ({
    ...productionRatesValues,
  }),
  handleSubmit: () => '',
};

const EnhancedRestroom = withFormik<any, IBidProductionRates>(mapForm)(
  ProductionRates
);

export { EnhancedRestroom as ProductionRates };
