import styled from 'styled-components';

export const AttachmentsHolder = styled.div`
  width: 670px;
  max-width: 670px;
`;

export const ManageButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 16px;
  background-color: #216eab;
  color: white;
  font-family: OpenSans-Regular;
  padding: 8px 15px 9px 15px;
  height: 40px;
  border-radius: 6px;
  margin-top: 1px;
  margin-left: 10px;
  border-width: 1px;
`;

export const Button = styled.button`
  background-color: #ededed;
  border-radius: 10px;
  width: 40px;
  min-width: 40px;
  height: 30px;
  border: 1px solid gray;
  cursor: pointer;

  & svg {
    height: 25px;
    width: 25px;
    color: gray;
  }

  ${({ disabled }) => (disabled ? `opacity: .5; cursor: auto;` : '')}
`;

export const IconInputWrapper = styled.div`
  cursor: move;
  cursor: grab;
`;
