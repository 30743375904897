import styled from 'styled-components';

interface IContent {
  width?: string;
  showButton?: boolean;
}

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #999;
  background-color: #fff;
  position: relative;
  ${({ width }: IContent) => (width ? `width: ${width};` : 'flex: 1;')}
  height: 40px;
`;

export const Input = styled.input`
  border: 0;
  outline: none;
  background-color: transparent;
  height: 100%;
  width: 100%;

  &:disabled {
    background-color: #e8e8e8;
  }

  autoCapitalize=words
`;

export const BtnSelect = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
  border-left: 1px solid #999;
  cursor: pointer;
`;

export const DataContent = styled.div`
  position: absolute;
  background-color: #fff;
  border: 1px solid #999;
  border-top: 0;
  margin-top: 41px;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  cursor: pointer;
  z-index: 5;
`;

interface IItemContent {
  isSelected: boolean;
  index: number;
}

export const ItemContent = styled.div`
  ${({ isSelected, index }: IItemContent) =>
    isSelected
      ? 'background-color: #9a9a9a; color: #fff;'
      : index % 2 === 0
      ? 'background-color: #fff;'
      : 'background-color: #eee;'}
  padding: 10px;
`;
