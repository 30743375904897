import styled from 'styled-components';

export const EditTextArea = styled.textarea`
  // background-color: transparent;
  // border: none;
  //   outline: none;
  width: 100%;
  height: auto;
  resize: none;
  font-family: 'OpenSans-Regular';
  font-size: 16px;
  font-style: italic;
  color: #515151;
  padding: 5px;
  min-height: 44px;
`;
