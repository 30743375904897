import React from 'react';
import { Heading, Wrapper, Content, Root } from './styles';
import { JanitorialCompany } from '../JanitorialCompany';
import { HotButtomItems } from '../HotButtonItems';

export const AdditionalInformation = React.memo(() => {
  return (
    <Root>
      <Wrapper>
        <Heading>Additional Info</Heading>

        <Content>
          <JanitorialCompany />
          <HotButtomItems />
        </Content>
      </Wrapper>
    </Root>
  );
});
