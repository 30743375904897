import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
// redux
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { InspectFormActions } from 'modules/InspectForm/redux/actions';
import * as Sentry from '@sentry/react';
// interfaces
import { IReduxState } from 'core/interfaces';
import {
  IStateToProps,
  IDispatchToProps,
} from 'modules/InspectForm/interfaces';
import { getRatingClassName, getQueryString } from 'core/utils';
import { isAlive } from 'modules/BidInfoForm/services/bidInfoService';
// ui
import calculatorIcon from 'assets/icons/calculator.svg';
import { CalculatorImg } from './styles';
import './styles.scss';
import 'styles/colors.scss';
import { ActionAlertModal } from '../../../../../../core/components';
import { validatePhone, validatePostalCode } from '../../../../../../core/validators/utils';

interface IOwnProps {
  setNeedsSaving(needsSaving: boolean): void;
}

type IProps = IDispatchToProps &
  IStateToProps &
  IOwnProps &
  RouteComponentProps;

class FooterBar extends React.PureComponent<IProps> {
  public state = {
    showSaveBubble: false,
    hideButtonDesktop: '',
    warningModal: null,
  };

  public componentDidMount() {
    const currentUrl = this.props.location.search;
    const hideButtonDesktop = getQueryString(currentUrl, 'hideButtonDesktop');

    this.setState({
      hideButtonDesktop,
    });
  }

  public onClickSave = () => {
    // const { isSent } = this.props;
    const { showSaveBubble } = this.state;
    // if (!isSent) {
    this.setState({ showSaveBubble: !showSaveBubble });
    // }
  };

  public validateFormData = () => {

    const incomp = [];

    if (this.props.Phone) {
      var el = document.querySelectorAll('.walk-thru-contact-phone-container > div')[0];
      if (!validatePhone(this.props.Phone)) {
        incomp.push('PHONE');
        if (el) {
          el.classList.add('is-invalid');
        }
      } else {
        if (el && el.classList.contains('is-invalid')) {
          el.classList.remove('is-invalid');
        }
      }
  }
  
  
  if (this.props.Zip) {
      var el = document.querySelectorAll('.postal-code-field-container > div')[0];
      if (!validatePostalCode(this.props.Zip.replace(/\_/,''))) {
        incomp.push('POSTAL CODE');
        if (el) {
          el.classList.add('is-invalid');
        }
      } else {
        if (el && el.classList.contains('is-invalid')) {
          el.classList.remove('is-invalid');
        }
      }
  }
  

    return incomp;
  }

  public onClickOptions = async (
    type: 'draft' | 'template' | 'sent',
    newForm?: 'new'
  ) => {

    try {
      const errors = this.validateFormData();
      if(errors.length > 0){
        this.setState({
          warningModal: {
            message: {incorrect: errors, required: []},
            proceed: () => {
              this.setState({ warningModal: null });
            },
          },
        });
        return;
      }
    } catch (e) {
      console.log({e})
      // Sentry.captureException(e);
      return;
    }

    try {
      await isAlive();
    } catch (e) {
      this.setState({
        warningModal: {
          message:
            "It looks like you don't have internet at the moment, please ensure you have internet access and try again.",
          proceed: () => {
            this.setState({ warningModal: null });
          },
        },
      });

      Sentry.captureException(e);
      return;
    }

    window.scrollTo(0, 0);
    // const { isSent } = this.props;
    console.log('newForm', newForm);
    // if (!isSent) {
    const { saveReportRequest } = this.props;
    this.setState({ showSaveBubble: false });
    // saveReportRequest(type, this.props.setNeedsSaving, newForm);
    saveReportRequest(type, newForm);
    // }
  };

  public closeSaveBubble = () => {
    this.setState({ showSaveBubble: false });
  };

  public render() {
    const { showSaveBubble, hideButtonDesktop } = this.state;
    const {
      FacilityRating,
      setNotesModalState,
      isDraft,
      isTemplate,
      isSent,
      transformedToDraft,
    } = this.props; // saveReportRequest
    const areaClassName = getRatingClassName(FacilityRating);

    console.log({isSent, isTemplate, hideButtonDesktop});
    return (
      <div className="form-footer">
        <div className="content">
          <div className="score-overall-container">
            <div className="content">
              <p>Overall Rating:</p>
              <span className={`score ${areaClassName}`}>
                {FacilityRating.toFixed(1)}
              </span>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <button
              className={this.props.Notes ? 'button-orange' : 'button-dark'}
              onClick={() => setNotesModalState(true)}
            >
              {isSent
                ? 'VIEW NOTE'
                : this.props.Notes
                ? 'EDIT NOTE'
                : 'ADD NOTE'}
              {/* {this.props.Notes ? 'EDIT NOTE' : 'ADD NOTE'} */}
            </button>

            <button
              className="button-secondary"
              onClick={() => {
                // this.onClickSave()
                hideButtonDesktop === 'true'
                  ? this.onClickOptions('template', 'new')
                  : this.onClickSave();
              }}
            >
              {/* SAVE AS */}
              {hideButtonDesktop === 'true' ? 'SAVE TEMPLATE' : 'SAVE AS'}
              {showSaveBubble && (
                <>
                  <div
                    onClick={this.closeSaveBubble}
                    className="save-as-backdrop"
                  />
                  <div className="bubble">
                    <div className="content">
                      {!isSent && isDraft && (
                        <>
                          <div
                            className="first-row underlined"
                            onClick={() => {
                              transformedToDraft
                                ? this.onClickOptions('draft', 'new')
                                : this.onClickOptions('draft');
                            }}
                          >
                            UPDATE DRAFT
                          </div>
                          <div
                            className="second-row"
                            onClick={() =>
                              this.onClickOptions('template', 'new')
                            }
                          >
                            NEW TEMPLATE
                          </div>
                        </>
                      )}

                      {!isSent && isTemplate && (
                        <>
                          <div
                            className="first-row"
                            onClick={() => {
                              this.onClickOptions('template');
                              // from desktop, creates a new one
                              // (hideButtonDesktop === 'true') ? this.onClickOptions('template', 'new') : this.onClickOptions('template')
                            }}
                          >
                            {
                              // (hideButtonDesktop === 'true') ? 'TEMPLATE' : 'UPDATE TEMPLATE'
                            }
                            UPDATE TEMPLATE
                          </div>
                        </>
                      )}
                      {isSent && (
                        <>
                          <div
                            className="first-row"
                            onClick={() => {
                              this.onClickOptions('template', 'new');
                            }}
                          >
                            TEMPLATE
                          </div>
                        </>
                      )}
                      {!(
                        isDraft ||
                        isTemplate ||
                        isSent ||
                        hideButtonDesktop === 'true'
                      ) && (
                        <>
                          <div
                            className="first-row underlined"
                            onClick={() => this.onClickOptions('draft', 'new')}
                          >
                            NEW DRAFT
                          </div>
                          <div
                            className="second-row"
                            onClick={() =>
                              this.onClickOptions('template', 'new')
                            }
                          >
                            NEW TEMPLATE
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </button>
            <button
              disabled={isTemplate || hideButtonDesktop === 'true'}
              className={`button ${
                isTemplate || hideButtonDesktop === 'true'
                  ? 'disabled button-dark'
                  : ''
              }`}
              onClick={() =>
                isSent
                  ? this.props.openSentModal()
                  : this.onClickOptions('sent')
              }
            >
              {isSent ? 'EMAIL REPORT' : 'COMPLETE'}
            </button>
          </div>
        </div>
        {this.state.warningModal && (
          <ActionAlertModal
            style={{ color: 'black' }}
            message={this.state.warningModal.message}
            onConfirm={this.state.warningModal.proceed}
            isVisible={true}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: IReduxState) => ({
  ...state.inspectForm,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...InspectFormActions }, dispatch);

const Connected = connect<IStateToProps, IDispatchToProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FooterBar));

export { Connected as FooterBar };
