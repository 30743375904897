import React from 'react';
import { defaultAreas } from 'core/utils/defaultAreas';
import { IArea } from 'core/interfaces/Area';
import '../../../styles/util.scss';
import './styles.scss';

interface IOwnProps {
  type: 'area' | 'areaItem';
  placeholder: string;
  inputValue: string;
  onChangeInputValue(value: string): void;
  onClickArea(area: IArea): void;
  onSuggestionClicked(value: boolean): void;
}

interface IState {
  showSuggestions: boolean;
  filteredSuggestions: IArea[];
  showDropdown: boolean;
}

class AutoComplete extends React.Component<IOwnProps, IState> {
  state = {
    showSuggestions: false,
    filteredSuggestions: defaultAreas,
    showDropdown: false,
  };

  public onChangeText = (userInput: string) => {
    const { onChangeInputValue } = this.props;
    if (userInput.length === 0) {
      this.setState({
        filteredSuggestions: defaultAreas,
        showSuggestions: false,
      });
    } else {
      const filteredSuggestions = defaultAreas.filter(
        area => area.Name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );
      const isShowed = filteredSuggestions.length > 0; // either true or false
      this.setState({
        filteredSuggestions,
        showSuggestions: isShowed,
      });
    }

    onChangeInputValue(userInput);
  };

  public onClickSuggestion = (suggestion: IArea) => {
    const { onChangeInputValue, onClickArea, onSuggestionClicked } = this.props;
    this.setState({
      filteredSuggestions: [],
      showSuggestions: false,
    });
    onChangeInputValue('');
    onClickArea(suggestion);
    onSuggestionClicked(true);
  };

  public renderSuggestions = () => {
    let suggestionsListComponent;
    // const { showSuggestions, filteredSuggestions, showDropdown } = this.state;
    const { showSuggestions, filteredSuggestions } = this.state;

    if (showSuggestions && filteredSuggestions.length > 0) {
      suggestionsListComponent = (
        <div className="suggestions">
          {filteredSuggestions.map((suggestion: IArea, index: number) => {
            return (
              <div
                className="row"
                key={index}
                onClick={(e: any) => {
                  this.onClickSuggestion(suggestion);
                  this.setState({
                    showSuggestions: false,
                    // showDropdown: false,
                  });
                }}
              >
                {suggestion.Name}
              </div>
            );
          })}
        </div>
      );
    }
    return suggestionsListComponent;
  };

  public render() {
    // const { placeholder, inputValue, onBlurAddArea } = this.props;
    const { placeholder, inputValue } = this.props;
    // const { showSuggestions, showDropdown } = this.state
    const { showSuggestions } = this.state;
    return (
      <React.Fragment>
        {/* {
          showSuggestions && (
            <span className="alert">Selecting from dropdown will prepopulate with items to rate!</span>
          )
        } */}
        <input
          type="text"
          // onFocus={(e) => this.setState({ showSuggestions: true })}
          onChange={e => this.onChangeText(e.target.value)}
          value={inputValue}
          placeholder={placeholder}
          className="input-autocomplete input-dropdown"
          autoCapitalize="words"
        />
        <span
          className={`btn-dropdown ${
            showSuggestions ? 'btn-dropdown-open' : ''
          }`}
          onClick={() => {
            this.setState(prevState => {
              return {
                // showDropdown: !prevState.showDropdown,
                showSuggestions: !prevState.showSuggestions,
              };
            });
          }}
        >
          {/* Dropdown */}
        </span>
        {showSuggestions && (
          <>
            <div
              onClick={() => {
                console.log('onClick suggestions');
                this.setState({
                  showSuggestions: false,
                  // showDropdown: false,
                });
              }}
              className="save-as-backdrop"
            />
            {this.renderSuggestions()}
          </>
        )}
      </React.Fragment>
    );
  }
}

export { AutoComplete };
