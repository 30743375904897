import styled from 'styled-components';
import { H1BoldItalic } from 'modules/BidInfoForm/styles';

export const Wrapper = styled.div`
  margin-top: 0px;
  padding: 20px;
  background-color: white;
`;

export const Content = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 40px;
  }
`;

export const Heading = styled(H1BoldItalic)`
  line-height: 6vw;
  text-align: left;
  margin-bottom: 20px;

  @media (min-width: 769px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const IconHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  svg {
    color: #999;
  }
`;

export const SignatureField = styled.div`
  display: flex;
`;

export const Field = styled.div`
  height: 40px;
  width: 100%;
  border-bottom: 1px solid darkgray;
  color: darkgray;
  text-align: center;
  font-style: italic;
`;
