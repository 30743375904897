type Listener = (...args: any[]) => void
type Events = { [event: string]: Listener[] };

export default class EventEmitter {
    private static readonly events: Events = {};

    constructor() {
    }

    public static on(event: string, listener: Listener): () => void {
        if(typeof this.events[event] !== 'object') this.events[event] = [];
        
        this.events[event].push(listener);
        return () => this.removeListener(event, listener);
    }

    public static removeListener(event: string, listener: Listener): void {
        if(typeof this.events[event] !== 'object') return;
        
        const idx: number = this.events[event].indexOf(listener);
        if(idx > -1) this.events[event].splice(idx, 1);
    }

    public static removeAllListeners(): void {
        Object.keys(this.events).forEach((event: string) => 
            this.events[event].splice(0, this.events[event].length)
        );
    }

    public static emit(event: string, ...args: any[]): void {
        if(typeof this.events[event] !== 'object') return;

        this.events[event].forEach(listener => listener.apply(this, args));
    }

    public static once(event: string, listener: Listener): void {
        const remove: (() => void) = this.on(event, (...args: any[]) => {
            remove();
            listener.apply(this, args);
        });
    }
}