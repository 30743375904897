import React, { useState, useRef, useEffect, useCallback } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { Content, Input, BtnSelect, DataContent, ItemContent } from './styles';
const ReactDOM = require('react-dom');

const compareFunc = (valueA: string, valueB: string) =>
  valueA && valueB
    ? valueA.toString().toLowerCase().trim() ===
        valueB.toString().toLowerCase().trim() || valueA == valueB
    : false;

interface ISelect {
  value: any;
  name: string;
  items: Array<any>;
  onChange: Function;
  getLabelFunction?: Function;
  getValueFunction?: Function;
  getStyleFunction?: Function;
  getInputStyleFunction?: Function;
  getLabelInputFuction?: Function;
  onlyNumbers?: boolean;
  width?: string;
  showButton?: boolean;
  disableInput?: boolean;
  disabled?: boolean;
  inputStyle?: any;
  containerStyle?: any;
  canTypeCustomValue?: boolean;
  blockCustomInput?: boolean;
  capitalizeFunction?: Function;
  placeholder?: string;
  grayPlaceHolder?: boolean;
  autoSelectOnBlur?: boolean;
  autoSelectOnSpace?: boolean;
  type?: string;
  className?: string;
  key?: string;
  id?: string;
}

export const Select = ({
  value,
  name,
  items,
  onChange,
  getLabelFunction,
  getValueFunction,
  getStyleFunction,
  getInputStyleFunction,
  getLabelInputFuction,
  width,
  showButton = true,
  disableInput = false,
  onlyNumbers = false,
  inputStyle = {},
  containerStyle = {},
  canTypeCustomValue = true,
  blockCustomInput = false,
  capitalizeFunction,
  disabled,
  placeholder,
  grayPlaceHolder,
  autoSelectOnBlur = false,
  autoSelectOnSpace = false,
  type,
  className,
  key,
  id,
}: ISelect) => {
  const [filteredOptions, setFilteredOptions] = useState(items);
  const [isOpen, setIsOpen] = useState(false);
  const [navigationIndex, setNavigationIndex] = useState(-1);
  const [searchText, setSearchText] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [clearFlag, setClearFlag] = useState(false);
  const [verifyStatus, setVerifyStatus] = useState(false);
  const inputRef: any = useRef(null);

  const capitalize = (value: string) => {
    return capitalizeFunction ? capitalizeFunction(value) : value;
  };

  useEffect(() => {
    setTimeout(() => {
      const filteredOptions = items.filter((item) =>
        getLabel(item)
          .toString()
          .toLowerCase()
          .includes(searchText.toLocaleLowerCase())
      );
      setFilteredOptions(filteredOptions);
      setNavigationIndex(
        filteredOptions.findIndex((item) => compareFunc(getValue(item), value))
      );

      if (verifyStatus) {
        if (clearFlag) {
          setNavigationIndex(-1);
          setClearFlag(false);
          setClearFlag(false);
        }
      }
    }, 100);
    // eslint-disable-next-line
  }, [items, searchText, value, verifyStatus]);

  const getInputValue = () => {
    if (isSearching) {
      return capitalize(searchText);
    }
    if (!value) {
      if (
        inputRef.current &&
        document.activeElement === ReactDOM.findDOMNode(inputRef.current)
      ) {
        return '';
      } else {
        return placeholder || '';
      }
    }

    const valuesFound = items.filter((item) =>
      compareFunc(
        getValue(item),
        typeof value === 'object' ? getValue(value) : value
      )
    );

    const ret =
      valuesFound.length > 0
        ? getLabel(valuesFound[0], true)
        : canTypeCustomValue &&
          inputRef.current &&
          inputRef.current.value &&
          inputRef.current.value !== placeholder
        ? getLabel(inputRef.current.value, true)
        : getLabel(value, true) || getLabel(value, false);
    return ret;
  };

  const onBlur = () => {
    if (searchText) {
      setTimeout(() => {
        handleKeyboard(new KeyboardEvent('keydown', { key: 'Tab' }));
      }, 250);
    } else {
      setTimeout(() => {
        // if (inputRef.current) {
        //   onClick(inputRef.current.value, true);
        // }
        setIsOpen(false);
      }, 250);
    }
  };

  const scrollToItem = useCallback(
    (
      index: number,
      scrollToDown: boolean = false,
      onFocus: boolean = false
    ) => {
      const scroll = () => {
        const optionHeight = 24;
        const scrollArea = 24 * 4;
        const content = document.getElementById(`select_content_${name}`);
        const element = document.getElementById(`select_item_${name}_${index}`);
        if (content && element) {
          if (onFocus) {
            setTimeout(() => {
              content.scrollTo({
                top: element.offsetTop - (scrollArea - optionHeight),
              });
            }, 0);
          } else if (
            element.offsetTop > content.scrollTop + scrollArea &&
            scrollToDown
          ) {
            content.scrollTo({ top: element.offsetTop - scrollArea });
          } else if (
            element.offsetTop < content.scrollTop + optionHeight &&
            !scrollToDown
          ) {
            content.scrollTo({ top: element.offsetTop - scrollArea / 2 });
          }
        }
      };

      if (onFocus) {
        setTimeout(scroll, 0);
      } else {
        scroll();
      }
      // eslint-disable-next-line
    },
    []
  );

  const getLabel = (item: any, input: boolean = false) => {
    if (item && input && getLabelInputFuction) {
      return getLabelInputFuction(item);
    }
    return getLabelFunction && item
      ? getLabelFunction(item)
      : item
      ? item.toString()
      : '';
    // eslint-disable-next-line
  };

  const getValue = (item: any) => {
    return getValueFunction ? getValueFunction(item) : item;
    // eslint-disable-next-line
  };

  const onClick = useCallback(
    (itemClicked: any = null, isBlur: boolean = false) => {
      const setNavIndex = (itemValue: any, isBlur: boolean = false) => {
        setTimeout(() => {
          const arrSource =
            filteredOptions && filteredOptions.length > 0 && !isBlur
              ? filteredOptions
              : items;
          const idxFound = arrSource.findIndex((item) =>
            compareFunc(getValue(item), getValue(itemValue))
          );
          setNavigationIndex(idxFound);
        }, 0);
      };

      const finallyFunc = (v = null) => {
        setIsSearching(false);
        setIsOpen(false);
        setSearchText('');
        if (v) {
          setNavIndex(v, isBlur);
        }
      };

      if (
        compareFunc(
          inputRef.current ? inputRef.current.value : searchText,
          getLabel(itemClicked)
        ) &&
        compareFunc(value, getLabel(itemClicked)) &&
        !autoSelectOnBlur
      ) {
        finallyFunc(value);
        return;
      }

      const v = searchText || inputRef.current.value;

      if (!itemClicked && !v) {
        onChange(name, '');
        setNavIndex('', false);
        setIsOpen(false);
        return;
      }

      if (v !== value && !itemClicked) {
        if (canTypeCustomValue) {
          const arr = items.filter(
            (item) =>
              getValue(item)
                .toString()
                .toLowerCase()
                .indexOf(
                  getValue(
                    (searchText || inputRef.current.value).toString().trim()
                  ).toLowerCase()
                ) >= 0
          );
          if (autoSelectOnBlur && arr.length > 0) {
            const itemSelected = arr[0];
            onChange(name, itemSelected);
            setNavIndex(itemSelected, isBlur);
          } else {
            onChange(name, searchText || inputRef.current.value);
            setNavIndex(searchText || inputRef.current.value, isBlur);
          }
        } else if (
          items.findIndex((item) =>
            compareFunc(getValue(item), getValue(searchText))
          ) >= 0
        ) {
          const itemValue = items.filter((item) =>
            compareFunc(getValue(item), getValue(searchText))
          )[0];
          onChange(name, itemValue);
          setNavIndex(itemValue, isBlur);
        } else if (searchText === '') {
          onChange(name, '');
          setNavIndex('', isBlur);
        }
      } else if (itemClicked) {
        if (canTypeCustomValue) {
          const arr = items.filter(
            (item) =>
              getValue(item)
                .toString()
                .toLowerCase()
                .indexOf(
                  getValue(itemClicked).toString().trim().toLowerCase()
                ) >= 0
          );
          if (autoSelectOnBlur && arr.length > 0) {
            const itemSelected = arr[0];
            onChange(name, itemSelected);
            setNavIndex(itemSelected, isBlur);
          } else {
            if (
              items.findIndex((item) =>
                compareFunc(getValue(item), getValue(itemClicked))
              ) >= 0
            ) {
              onChange(name, itemClicked);
              setNavIndex(itemClicked, isBlur);
            } else if (searchText && searchText !== value) {
              onChange(name, searchText);
              setNavIndex(searchText, isBlur);
            }
          }
        } else if (
          items.findIndex((item) =>
            compareFunc(getValue(item), getValue(itemClicked))
          ) >= 0
        ) {
          itemClicked = items.find((item) =>
            compareFunc(getValue(item), getValue(itemClicked))
          );
          onChange(name, itemClicked);
          setNavIndex(itemClicked, isBlur);
        }
      }

      finallyFunc();

      // eslint-disable-next-line
    },
    [onChange, searchText]
  );

  const handleKeyboard = useCallback(
    (e: any) => {
      if (e.keyCode === 38) {
        setNavigationIndex(
          navigationIndex > 0 ? navigationIndex - 1 : navigationIndex
        );
        scrollToItem(navigationIndex, false);
      }

      if (e.keyCode === 40) {
        setNavigationIndex(
          navigationIndex < filteredOptions.length - 1
            ? navigationIndex + 1
            : navigationIndex
        );
        scrollToItem(navigationIndex, true);
      }

      if ((e.keyCode === 8 || e.keyCode === 46) && !blockCustomInput) {
        if (
          e.target.selectionEnd - e.target.selectionStart ==
            e.target.value.length ||
          (e.target.selectionDirection == 'forward' &&
            e.target.selectionStart === e.target.selectionEnd &&
            e.target.selectionStart == e.target.value.length &&
            e.target.value.length == 1) ||
          (e.target.selectionDirection == 'backward' &&
            e.target.selectionStart === e.target.selectionEnd &&
            e.target.selectionStart == e.target.value.length &&
            e.target.value.length == 1)
        ) {
          setClearFlag(true);
          setVerifyStatus(true);
        }
      }

      if (e.keyCode === 9 || e.keyCode === 13 || e.key === 'Tab') {
        if (navigationIndex >= 0) {
          onClick(filteredOptions[navigationIndex]);
        } else {
          onClick('');
        }
      }

      if (
        e.keyCode === 32 &&
        autoSelectOnSpace &&
        inputRef.current &&
        inputRef.current.value.length >= 3
      ) {
        onClick(inputRef.current.value);
        setIsOpen(true);
      }

      if (
        onlyNumbers &&
        (isNaN(e.key) || e.key == ' ') &&
        e.key != ',' &&
        e.key != '.' &&
        e.key != 'Tab' &&
        e.key != 'Backspace' &&
        e.key != 'Delete' &&
        e.key != 'ArrowRight' &&
        e.key != 'ArrowLeft' &&
        e.key != 'Home' &&
        e.key != 'End'
      ) {
        e.preventDefault();
      }
    },
    [onClick, scrollToItem, navigationIndex, filteredOptions]
  );

  const onSearch = useCallback((value: string) => {
    if (blockCustomInput) {
      if (
        value != '' &&
        !items
          .map((i) => getValue(i).toString().toLowerCase())
          .includes(value.toString().toLowerCase()) &&
        items.filter(
          (i) =>
            getValue(i)
              .toString()
              .toLowerCase()
              .indexOf(value.toString().toLowerCase()) >= 0
        ).length === 0
      ) {
        return;
      }
    }
    setSearchText(value);
    setIsSearching(true);
    // eslint-disable-next-line
  }, []);

  const customizedStyleFunction =
    getInputStyleFunction && value
      ? getInputStyleFunction(value)
      : getStyleFunction && value
      ? getStyleFunction(value)
      : {};

  return (
    <Content width={width} style={containerStyle} data-cy={`${name}_wrapper`}>
      <Input
        data-cy={`${name}_input`}
        disabled={disabled}
        ref={inputRef}
        value={getInputValue()}
        onChange={(e) => {
          if (!disableInput) {
            onSearch(e.target.value);
          }
        }}
        onKeyDown={handleKeyboard}
        onBlur={onBlur}
        className={className}
        style={{
          ...inputStyle,
          color:
            getInputValue() == placeholder && grayPlaceHolder
              ? 'gray'
              : inputStyle.color,
          fontStyle:
            getInputValue() == placeholder && grayPlaceHolder
              ? 'italic'
              : inputStyle.fontStyle,
          ...customizedStyleFunction,
        }}
        onFocus={() => {
          setIsOpen(true);
          scrollToItem(navigationIndex, false, true);
        }}
        type={type}
        autoCapitalize="words"
      />
      {showButton && (
        <BtnSelect
          data-cy={`${name}_btn_select`}
          onClick={() => {
            if (inputRef && inputRef.current && !isOpen) {
              inputRef.current.focus();
            } else {
              setIsOpen(!isOpen);
            }
          }}
        >
          <FontAwesomeIcon
            icon={faChevronDown}
            size="1x"
            color="#333"
            data-cy={`${name}_btn_select_icon`}
          />
        </BtnSelect>
      )}
      {isOpen && (
        <DataContent
          id={`select_content_${name}`}
          data-cy={`${name}_dropdown_content_wrapper`}
        >
          {filteredOptions.map((item: any, index: number) => (
            <ItemContent
              data-cy={`${name}_dropdown_content_item`}
              id={`select_item_${name}_${index}`}
              key={`select_item_${name}_${index}`}
              index={index}
              isSelected={index === navigationIndex}
              onClick={() => {
                onClick(item);
              }}
              style={getStyleFunction ? getStyleFunction(item) : {}}
            >
              {getLabel(item)}
            </ItemContent>
          ))}
        </DataContent>
      )}
    </Content>
  );
};
