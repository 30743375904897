import React, { memo } from 'react';

import { ContentInput, Select, useFormProps } from 'core/components';

import {
  lengthTermOptions,
  cancellationNoticesOptions,
  cancellationReasonOptions,
  curePeriodOptions,
} from 'modules/shared/bidDefaultValues';

import './styles.scss';
import { FormikProps, withFormik } from 'formik';
import { IBidCancellationClause } from 'modules/BidInfoForm/interfaces';

type IProps = FormikProps<IBidCancellationClause>;

const CancellationClause = memo(({ values, setFieldValue }: IProps) => {
  const { formValues, onChange } = useFormProps();
  const [lengthOfTerms, setLengthOfTerms] = React.useState([]);
  const [cancellationReason, setCancellationReason] = React.useState([]);
  const [cancellationNotice, setCancellationNotice] = React.useState([]);
  const [curePeriod, setCurePeriod] = React.useState([]);

  const filterLengthOfTerms = () => {
    let result = []
    if (
      formValues.cancellationNotice == '45 Days' ||
      formValues.cancellationNotice == '60 Days' ||
      formValues.cancellationNotice == '90 Days' || 
      formValues.cancellationReason == 'Non-Performance' || 
      formValues.curePeriod != 'No Cure'
    ){
      result = ['Six Months', 'One Year', 'Two Years', 'Three Years'];
    }else{
      result = [...lengthTermOptions];
    }
    return result;
  }

  const filterCancellationReason = () => {
    let result = []
    if(formValues.lengthTerm == 'Month to Month'){
      result = ['Any Reason'];
    }else if(formValues.curePeriod != 'No Cure'){
      result = ['Non-Performance'];
    }else{
      result = [...cancellationReasonOptions];
    }
    return result;
  }

  const filterCancellationNotice = () => {
    let result = []
    if(formValues.lengthTerm == 'Month to Month'){
      result = ['30 Days'];
    }else{
      result = [...cancellationNoticesOptions];
    }
    return result;
  }

  const filterCurePeriod = () => {
    let result = []
    if(formValues.lengthTerm == 'Month to Month' || formValues.cancellationReason == 'Any Reason'){
      result = ['No Cure'];
    }else{
      result = [...curePeriodOptions];
    }
    return result;
  }

  React.useEffect(() => {
      setLengthOfTerms(filterLengthOfTerms());

      setCancellationReason(filterCancellationReason());

      setCancellationNotice(filterCancellationNotice());

      setCurePeriod(filterCurePeriod());
  }, [
    values.lengthTermOptions,
    values.cancellationNoticeOptions,
    values.cancellationReasonOptions,
    values.curePeriodOptions,
    formValues.lengthTerm,
    formValues.cancellationReason,
    formValues.cancellationNotice,
    formValues.curePeriod
  ]);

  React.useEffect(()=>{
    if(!lengthOfTerms.includes(formValues.lengthTerm)){
      onChange('lengthTerm', lengthOfTerms[0]);
    }
    if(!curePeriod.includes(formValues.curePeriod)){
      onChange('curePeriod', curePeriod[0]);
    }
    if(!cancellationReason.includes(formValues.cancellationReason)){
      onChange('cancellationReason', cancellationReason[0]);
    }
    if(!cancellationNotice.includes(formValues.cancellationNotice)){
      onChange('cancellationNotice', cancellationNotice[0]);
    }
  }, [lengthOfTerms, curePeriod, cancellationReason, cancellationNotice])

  const onChangeLength = (name: String, value: String) => {
    onChange(name, value);
    // if (value === 'Month to Month') {
    //   onChangeReason('cancellationReason', 'Any Reason');
    //   setFieldValue('cancellationReasonOptions', ['Any Reason']);
    //   return;
    // }
    // setFieldValue('cancellationReasonOptions', cancellationReasonOptions);
  };

  const onChangeReason = (name: String, value: String) => {
    onChange(name, value);
    // if (value === 'Any Reason') {
    //   onChange('curePeriod', 'No Cure');
    //   setFieldValue('curePeriodOptions', ['No Cure']);
    //   return;
    // }
    // setFieldValue('curePeriodOptions', curePeriodOptions);
  };

  return (
    <div className="cancellation-clause-root">
      <div className="cancellation-clause-content">
        <ContentInput width="100%" padding="10px 0px">
          <h3 className="cancellation-clause-title">
            Paragraph VI - Cancellation Clause
          </h3>
        </ContentInput>

        <div className="cancellation-row">
          <div className="cancellation-column">
            <div className="cancellation-label">Length Of Term</div>
            <Select
              name="lengthTerm"
              value={formValues.lengthTerm}
              onChange={onChange}
              items={lengthOfTerms}
              width="350px"
              disableInput
              disabled={formValues.status === 2}
            />
          </div>
          <div className="cancellation-column">
            <div className="cancellation-label">Cancellation Reason</div>
            <Select
              name="cancellationReason"
              value={formValues.cancellationReason}
              onChange={onChange}
              items={cancellationReason}
              width="350px"
              disableInput
              disabled={formValues.status === 2}
            />
          </div>
        </div>

        <div className="cancellation-row">
          <div className="cancellation-column">
            <div className="cancellation-label">Cancellation Notice</div>
            <Select
              name="cancellationNotice"
              value={formValues.cancellationNotice}
              onChange={onChange}
              items={cancellationNotice}
              width="350px"
              disableInput
              disabled={formValues.status === 2}
            />
          </div>

          <div className="cancellation-column">
            <div className="cancellation-label">Cure Period</div>
            <Select
              name="curePeriod"
              value={formValues.curePeriod}
              onChange={onChange}
              items={curePeriod}
              width="350px"
              disableInput
              disabled={formValues.status === 2}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

const mapForm = {
  mapPropsToValues: () => ({
    lengthTermOptions,
    cancellationNoticesOptions,
    cancellationReasonOptions,
    curePeriodOptions,
  }),
  handleSubmit: () => '',
};

const EnhancedCancellationClause = withFormik<any, IBidCancellationClause>(
  mapForm
)(CancellationClause);

export { EnhancedCancellationClause as CancellationClause };
