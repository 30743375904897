import React from 'react';

import {
  TableContent,
  Header,
  StyledTable,
  TableDataCell,
  Cell,
} from './styles';

interface IColumn {
  label: string;
  renderLabel: Function;
  key: string;
  render?: Function;
}

interface ITable {
  data: Array<any>;
  columns: Array<IColumn>;
}

export const Table = ({ data, columns }: ITable) => (
  <TableContent>
    <StyledTable>
      <thead>
        <tr>
          {columns.map((column: any, index: number) => (
            <Header key={`table_header_${column.key}_${index}`}>
              {column.renderLabel ? column.renderLabel() : column.label}
            </Header>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item: any, index) => (
          <tr key={`row_data_${index}`}>
            {columns.map((column: any, columnIndex: number) => (
              <TableDataCell
                key={`cell_data_${column.key}_${columnIndex}`}
                width={column.width}
              >
                <Cell>
                  {column.render
                    ? column.render(item, index)
                    : item[column.key]}
                </Cell>
              </TableDataCell>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  </TableContent>
);
