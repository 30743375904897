export const sicValues = [
  { id: '07', name: 'Pet Grooming, Sitting, or Training' },
  { id: '15', name: 'Construction (General Contractors & Operative Builders)' },
  {
    id: '16',
    name:
      'Heavy Construction, Except Building Construction (Highway, Street, Bridge, Tunnel & Pipeline)',
  },
  { id: '17', name: 'Construction (Special Trade Contractors)' },
  {
    id: '20',
    name:
      'Food & Kindred Products (Manufacturing or Processing of Food & Beverages)',
  },
  { id: '21', name: 'Tobacco Products' },
  { id: '22', name: 'Textile Mill Products' },
  {
    id: '23',
    name: 'Apparel, Finished Products from Fabrics & Similar Materials',
  },
  { id: '24', name: 'Lumber & Wood Products' },
  { id: '25', name: 'Furniture & Fixtures' },
  { id: '26', name: 'Paper & Allied Products' },
  { id: '27', name: 'Printing, Publishing & Allied Industries' },
  { id: '28', name: 'Chemicals & Allied Products' },
  { id: '29', name: 'Petroleum Refining & Related Industries' },
  { id: '30', name: 'Rubber & Miscellaneous Plastic Products' },
  { id: '31', name: 'Leather & Leather Products' },
  { id: '32', name: 'Stone, Clay, Glass & Concrete Products' },
  { id: '33', name: 'Primary Metal Industries' },
  { id: '34', name: 'Fabricated Metal Products' },
  { id: '35', name: 'Industrial & Commercial Machinery & Computer Equipment' },
  { id: '36', name: 'Electronic & Other Electrical Equipment & Components' },
  { id: '37', name: 'Transportation Equipment' },
  {
    id: '38',
    name: 'Measuring, Photographic, Medical, & Optical Goods, & Clocks',
  },
  { id: '39', name: 'Miscellaneous Manufacturing Industries' },
  { id: '40', name: 'Railroad Transportation' },
  {
    id: '41',
    name: 'Local & Suburban Transit & Interurban Highway Transportation',
  },
  { id: '42', name: 'Motor Freight Transportation' },
  { id: '44', name: 'Water Transportation (Sight-seeing boats & Water taxis)' },
  {
    id: '45',
    name:
      'Transportation by Air (Airport Operations offices, Airline Lounges & Terminal Furnishings)',
  },
  { id: '46', name: 'Pipelines, Except Natural Gas' },
  {
    id: '47',
    name:
      'Transportation Services (Packing services, Freight transportation & Arrangement of passenger)',
  },
  { id: '48', name: 'Communications (Radio and Television Broadcasting)' },
  { id: '49', name: 'Electric, Gas and Sanitary Services' },
  { id: '50', name: 'Wholesale Trade (Durable Goods)' },
  { id: '51', name: 'Wholesale Trade (Non durable Goods)' },
  {
    id: '52',
    name: 'Building Materials, Hardware, Garden Supplies & Mobile Homes',
  },
  {
    id: '53',
    name: 'General Merchandise Stores (Department Stores, Variety Store)',
  },
  { id: '54', name: 'Food Stores (Supermarkets)' },
  { id: '55', name: 'Automotive Dealers & Gasoline Service Stations' },
  {
    id: '56',
    name: 'Apparel & Accessory Stores (Clothing Stores, Shoe Stores)',
  },
  { id: '57', name: 'Home Furniture, Furnishings, and Equipment Stores' },
  { id: '58', name: 'Eating & Drinking Places (Restaurants & Bars)' },
  { id: '60', name: 'Depository Institutions (Banking)' },
  { id: '61', name: 'Nondepository Credit Institutions (Loan services)' },
  {
    id: '62',
    name: 'Security & Commodity Brokers, Dealers, Exchanges & Services',
  },
  { id: '63', name: 'Insurance Carriers' },
  { id: '64', name: 'Insurance Agents, Brokers & Service' },
  { id: '65', name: 'Real Estate' },
  { id: '67', name: 'Holding and Other Investment Offices' },
  { id: '70', name: 'Hotels & Other lodging places' },
  {
    id: '72',
    name:
      'Personal Services (Dry cleaners, Photography Studios, Barber & Beauty Shops, Funeral Services)',
  },
  {
    id: '73',
    name:
      'Business Services (Advertising, Credit reporting, Collection of claims & Mailing)',
  },
  {
    id: '76',
    name:
      'Miscellaneous Repair Services (Electrical, Watch, Jewelry, Reupholstery Repair)',
  },
  { id: '78', name: 'Motion Pictures (Movie Theaters)' },
  {
    id: '79',
    name: 'Amusement & Recreation Services (Physical Fitness Facilities)',
  },
  {
    id: '80',
    name:
      'Health Services (Medical Facilities, Nursing Homes & Psychiatric Hospitals)',
  },
  { id: '81', name: 'Legal Services (legal advice)' },
  {
    id: '82',
    name: 'Educational Services (Schools, Technical Institutions & Libraries)',
  },
  {
    id: '83',
    name:
      'Social Services (Social Works, Vocational Rehabilitation, Day Care & Residential Care)',
  },
  {
    id: '84',
    name: 'Museums, Art Galleries and Botanical & Zoological Gardens',
  },
  {
    id: '86',
    name:
      'Membership Organizations (Trade Associations, Labor Unions, Political and Religious Organizations)',
  },
  { id: '87', name: 'Engineering, Accounting, Research & Management Services' },
  { id: '89', name: 'Services, Not Elsewhere Classified' },
  {
    id: '91',
    name:
      'General Government, Except Finance (City Hall, Police Department, etc.)',
  },
];
