import styled from 'styled-components';

export const Heading = styled.h3`
  color: #3f3f3f;
  margin-bottom: 15px;
`;

export const Root = styled.div`
  background-color: #f5f5f5;
  width: 100%;
`;

export const Wrapper = styled.div`
  box-sizing: border-box;
  background-color: #f5f5f5;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .janitorial-company-root .janitorial-company-content,
  .hot-button-items-root .hot-button-items-content {
    padding-top: 0;
    padding-bottom: 0;

    h3 {
      font-family: 'OpenSans-Regular';
      font-size: 16px;
      color: #515151;
      text-transform: none;
    }
  }
`;

export const Content = styled.div`
  margin-left: -20px;
  margin-right: -20px;
`;
