import React from 'react';

import {
  Layout,
  RoundSubSection,
  SubSectionHeader,
} from 'modules/BidInfoForm/components/layout';
import { useFormProps } from 'core/components';
import { SectionTitle } from 'modules/BidInfoForm/styles';

import './styles.scss';

const Contact = (props: any) => {
  const [equalsOrBiggerThan768, setEqualsOrBiggerThan768] = React.useState(
    false
  );
  const [biggerThan796, setBiggerThan796] = React.useState(false);
  const [biggerThan768, setBiggerThan768] = React.useState(false);
  const [minorThan768, setMinorThan768] = React.useState(false);
  const [minorThan450, setMinorThan450] = React.useState(false);

  const setBreakpoints = () => {
    setEqualsOrBiggerThan768(window.innerWidth >= 768);
    setBiggerThan796(window.innerWidth >= 796);
    setBiggerThan768(window.innerWidth >= 769);
    setMinorThan768(window.innerWidth < 768);
    setMinorThan450(window.innerWidth < 450);
  };
  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  const { formValues, onChange } = useFormProps();
  const { userEvaluation } = props;
  return (
    <div
      style={{
        marginBottom: '25px',
        marginLeft: '25px',
        marginRight: '25px',
        marginTop: !minorThan768 ? '-80px' : '0',
        width: 'calc(100% - 50px)',
      }}
    >
      <RoundSubSection
        marginTop="30px"
        width="100%"
        paddingBottom="10px"
        backgroundColor="#fff"
      >
        <SubSectionHeader
          backgroundColor="#216eab"
          color="#fff"
          padding={'20px 0 20px 0'}
          height={minorThan768 ? '25px' : '10px'}
        >
          <SectionTitle maxFontSize="20px" marginLeft="25px" color="#fff">
            WE VALUE YOUR BUSINESS
          </SectionTitle>
        </SubSectionHeader>
        <Layout width="100%" margin="0">
          <div className="contact">
            {/* <span className="contact-title">Person Contacted</span>

            <input
              className="input"
              value={formValues.personContacted}
              onChange={(e) => onChange('personContacted', e.target.value)}
              disabled={userEvaluation}
              style={{ outline: 'none' }}
            /> */}

            <span className="description">
              {/* Anago Cleaning System's mission is to ensure your complete
              satisfaction with the cleaning services being provided. If you
              feel there are any areas that need attention, please use this form
              to provide any specific details so that they can be addressed
              immediately. The Regional Office and our Service Providers are
              committed to ensuring excellence and welcome your feedback. */}
              {props.selectedFranchise && props.selectedFranchise['PEFMessage']}
            </span>
            <span className="contact-title">Company Representative</span>
            {/* <span className="contact-content">Consecteur Adipiscing Elit</span> */}
            {/* <input
              className="input"
              value={formValues.companyRepresentative}
              onChange={(e) =>
                onChange('companyRepresentative', e.target.value)
              }
              disabled={userEvaluation || props.isCompleted}
              style={{ outline: 'none' }}
            /> */}
            <span className="contact-company-rep">{formValues.companyRepresentative}</span>
          </div>
        </Layout>
      </RoundSubSection>
    </div>
  );
};

export { Contact };
