import React from 'react';
import ReactDatePicker, {
  CalendarContainer,
} from '../../../lib/react-datepicker';
import '../../../lib/react-datepicker/src/stylesheets/mixins.scss';
import '../../../lib/react-datepicker/src/stylesheets/datepicker.scss';

interface IDatePicker {
  placeholder?: string;
  className?: string;
  onChange: Function;
  onBlur: Function;
  disabled?: boolean;
  selected: Date;
  showPopperArrow?: boolean;
  disabledKeyboardNavigation?: boolean;
  popperModifiers?: any;
  popperPlacement?: string;
  filterDate?: Function;
}

export const DatePicker = ({
  placeholder = 'Select Date',
  className,
  onChange,
  onBlur,
  disabled = false,
  selected,
  showPopperArrow = false,
  disabledKeyboardNavigation = true,
  popperPlacement = 'auto',
  popperModifiers = {},
  filterDate = () => true,
  ...props
}: IDatePicker) => (
  <CalendarContainer>
    <ReactDatePicker
      placeholderText={placeholder}
      className={`${selected ? 'has-value' : ''} ${className}`}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      selected={selected}
      showPopperArrow={showPopperArrow}
      disabledKeyboardNavigation={disabledKeyboardNavigation}
      popperPlacement="top"
      showTodayButton
      popperModifiers={{
        offset: {
          enabled: true,
          offset: '5px, 10px',
        },
        preventOverflow: {
          enabled: true,
          escapeWithReference: false,
          boundariesElement: 'viewport',
        },
        ...popperModifiers,
      }}
      filterDate={filterDate}
      {...props}
    />
  </CalendarContainer>
);
