import React from 'react';

import './styles.scss';

interface IProps {
  isVisible: boolean;
  title?: string;
  titleStyle?: any;
  message: string | string[];
  renderMessage?: Function;
  dismissText?: string;
  renderDismissButton?: Function;
  confirmText?: string;
  renderConfirmButton?: Function;
  onDismiss?: () => any;
  onConfirm: () => any;
  containerStyle?: any;
  style?: any;
  isDismissPrimary?: boolean;
  htmlMessage?: string;
}

const ActionAlertModal = (props: IProps) => {
  const finishArr = Array.isArray(props.message) && props.message;
  const customizedError =
    props.message && Object.keys(props.message).includes('required')
      ? props.message
      : null;


  return (
    <React.Fragment>
      {props.isVisible && (
        <div
          className="action-alert-modal-backdrop"
          style={props.containerStyle}
        >
          <div
            className="action-alert-modal-content"
            style={props.style}
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
          >
            {props.title && (
              <h2 className="alert-title" style={props.titleStyle}>
                {props.title}
              </h2>
            )}
            {props.message && finishArr && (
              <p className="alert-message">
                <span className="alert-header" data-cy="required-fields">
                  Oops!
                  {` The following field${
                    props.message.length === 1 ? '' : 's'
                  }`}{' '}
                  {`${props.message.length === 1 ? 'is' : 'are'} `}
                  required:
                </span>
                <br />
                <br />
                {finishArr.map((str: string) => (
                  <>
                    <span className="required-field">
                      {str.replace('>', '').replace('<', '')}
                    </span>
                    <br />
                  </>
                ))}
                <br />
                <span data-cy="required-message">
                  Ensure you fill out all required fields and try again
                </span>
              </p>
            )}

            {props.message && customizedError && (
              <p className="alert-message">
                {customizedError.required &&
                  customizedError.required.length > 0 && (
                    <>
                      <span
                        className="alert-header"
                        data-cy="required-fields-title"
                      >
                        Oops!
                        {` The following field${
                          customizedError.required.length === 1 ? '' : 's'
                        }`}{' '}
                        {`${
                          customizedError.required.length === 1 ? 'is' : 'are'
                        } `}
                        required:
                      </span>

                      <br />
                      <br />
                      {customizedError.required.map(
                        (str: string, idx: number) => (
                          <>
                            <span
                              className="required-field"
                              data-cy={`required-fields-${idx}`}
                            >
                              {str.replace('>', '').replace('<', '')}
                            </span>
                            <br />
                          </>
                        )
                      )}
                      <br />
                    </>
                  )}

                {customizedError.incorrect &&
                  customizedError.incorrect.length > 0 && (
                    <>
                      <br />
                      <span
                        className="alert-header"
                        data-cy="incorrect-fields-title"
                      >
                        Oops!
                        {` The following field${
                          customizedError.incorrect.length === 1 ? '' : 's'
                        }`}{' '}
                        {`${
                          customizedError.incorrect.length === 1 ? 'is' : 'are'
                        } `}
                        incorrect:
                      </span>

                      <br />
                      <br />
                      {customizedError.incorrect.map(
                        (str: string, idx: number) => (
                          <>
                            <span
                              className="required-field"
                              data-cy={`incorrect-fields-${idx}`}
                            >
                              {str ? str.replace('>', '').replace('<', ''): ''}
                            </span>
                            <br />
                          </>
                        )
                      )}
                    </>
                  )}
                <br />
                <span data-cy="required-incorrect-message">
                  {`Ensure you fill out all required fields ${
                    customizedError.incorrect &&
                    customizedError.incorrect.length > 0
                      ? 'and fix all the incorrect ones'
                      : ''
                  } and try again`}
                </span>
              </p>
            )}

            {!customizedError &&
              !finishArr &&
              (props.renderMessage ? (
                props.renderMessage()
              ) : props.htmlMessage ? (
                <p
                  className="alert-message"
                  dangerouslySetInnerHTML={{ __html: props.htmlMessage }}
                ></p>
              ) : (
                <p className="alert-message">{props.message}</p>
              ))}

            <div className="buttons-wrapper">
              {props.onDismiss && props.renderDismissButton ? (
                props.renderDismissButton()
              ) : props.onDismiss ? (
                <button
                  className={
                    !props.isDismissPrimary ? 'button-secondary' : 'button'
                  }
                  onClick={props.onDismiss}
                >
                  {props.dismissText || 'CANCEL'}
                </button>
              ) : (
                ''
              )}

              {props.renderConfirmButton ? (
                props.renderConfirmButton()
              ) : (
                <button
                  className="button"
                  onClick={props.onConfirm}
                  data-cy="confirm-button"
                >
                  {props.confirmText || 'OK'}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ActionAlertModal;
