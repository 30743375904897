import styled from 'styled-components';

export const Actions = styled.div`
  button + button {
    margin-left: 20px;
  }
  svg {
    margin-right: 10px;
  }
`;
