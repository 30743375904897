export default function placeArrow(tooltipEl, align, targetElementId) {
  if (!tooltipEl || !align || !targetElementId) {
    return;
  }
  const arrowEl = tooltipEl.querySelector('.rc-tooltip-arrow');
  const targetEl = document.getElementById(targetElementId);
  const rect = targetEl.getBoundingClientRect();
  if (align.overflow.adjustX) {
    arrowEl.style.left = `${rect.right - rect.width / 2}px`;
  } else {
    arrowEl.style.top = `${rect.top - rect.height / 2}px`;
  }
}
