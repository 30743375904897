import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import loadingBuffering from 'assets/img/loading-buffering.gif';

function getTransform(
  isSkewable: boolean,
  isReversed: boolean,
  isContent = false
) {
  if (!isSkewable) {
    return 'transform: skew(0deg);';
  }

  if (isContent) {
    if (isReversed) {
      return 'transform: skew(30deg);';
    }
    return 'transform: skew(-30deg);';
  }

  if (isReversed) {
    return 'transform: skew(-30deg);';
  }

  return 'transform: skew(30deg);';
}

function getDisabledStyles(isDisabled: any, disabledStyles: any) {
  if (!isDisabled) {
    return '';
  }

  let styles = '';

  if (disabledStyles) {
    if (disabledStyles.color) {
      styles += `color: ${disabledStyles.color};`;
    }

    if (disabledStyles.background) {
      styles += `background: ${disabledStyles.background};`;
    }

    if (disabledStyles.border) {
      styles += `border: ${disabledStyles.border};`;
    }
  } else {
    styles += 'opacity: 0.7;';
  }

  return styles;
}

export const Content = styled.div`
  display: flex;
  transform: skew(30deg);
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const ContentLoading = ({ label }) => {
  return (
    <Content>
      <img
        src={loadingBuffering}
        style={{
          width: '25px',
          marginRight: '-10px',
          paddingLeft: '15px',
        }}
      />
      <span
        style={{
          fontSize: '14px',
        }}
      >
        {label}
      </span>
    </Content>
  );
};

export const StyledButton = styled.button<IButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width = '240px' }: IButton) => width};
  height: ${({ height = '54px' }: IButton) => height};
  background-color: ${({ bgColor }) => bgColor || '#3f3f3f'};
  font-family: 'OpenSans-SemiBoldItalic';
  color: ${({ color }) => color || '#fff'};
  border: ${({ border = '0 none' }) => border};
  border-radius: ${({ borderRadius = '0' }) => borderRadius};
  font-size: 20px;
  padding: ${({ padding = 0 }) => padding};
  transform: skew(0deg);
  ${({ skewable = true, reverseSkew = false }) =>
    getTransform(skewable, reverseSkew)}
  ${({ kind, color, bgColor }) =>
    kind === 'outline'
      ? `background-color: ${bgColor || 'transparent'}; 
        border: 3px solid ${color};
        border-left: 4px solid ${color};
        border-right: 4px solid ${color};
        // border: 1px solid ${color};
        `
      : ''}
    ${({ margin }) => (margin ? `margin: ${margin}` : '')}
    ${({ disabled, disabledStyles = null }) =>
    getDisabledStyles(disabled, disabledStyles)}
    @media (max-width: 900px) {
    width: 150px;
  }

  ${Content} {
    transform: skew(0deg);
    ${({ skewable = true, reverseSkew = false }) =>
      getTransform(skewable, reverseSkew, true)};
  }

  ${({ isLoading }) => (isLoading ? `pointer-events: none;` : '')}
`;

interface IButton {
  children: any;
  onClick: Function;
  color?: string;
  bgColor?: string;
  kind?: 'outline';
  skewable?: boolean;
  reverseSkew?: boolean;
  margin?: string;
  disabled?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  width?: string;
  height?: string;
  padding?: string;
  border?: string;
  borderRadius?: string;
  disabledStyles?: { color: string; background: string; border: string };
}

export const Button = (props: IButton) => {
  return (
    <StyledButton {...props}>
      {!props.isLoading && <Content>{props.children}</Content>}
      {props.isLoading && <ContentLoading label={props.loadingText} />}
    </StyledButton>
  );
};

const BackButtonWrapper = styled(StyledButton)`
  width: 100%;

  svg {
    margin-right: 10px;
  }

  @media (max-width: 900px) {
    width: auto;
  }

  @media (min-width: 767px) {
    width: auto;
  }
`;

const BackButtonLabel = styled.span`
  font-size: 16px;
`;

export const BackButton = (props: IButton) => (
  <BackButtonWrapper
    color="#fff"
    bgColor="#216eab"
    width="auto"
    padding="0 25px"
    style={props.style}
    onClick={props.onClick}
    skewable={props.skewable}
    reverseSkew={props.reverseSkew}
  >
    <Content>
      <FontAwesomeIcon icon={faReply} />
      <BackButtonLabel>BACK</BackButtonLabel>
    </Content>
  </BackButtonWrapper>
);
