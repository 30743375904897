import React, { useState, useCallback, useEffect, memo, useRef, useLayoutEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faPlus,
  faGripVertical,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';

import {useInvalidCleaningSpecs} from 'core/hooks/useInvalidCleaningSpecs';
import {useGenericErrorHandler} from 'core/hooks/useGenericErrorHandler';

import { IconButton } from '../IconButton';
import { AutoCompleteInput } from '../AutoCompleteInput';
import { DraggableSection } from '../DraggableSection';
import listAdd from '../../../assets/icons/list-add.svg';
import trash from '../../../assets/icons/trash.svg';
import {
  Content,
  BoxAccordion,
  ItemContent,
  ItemWrapper,
  IconContent,
  IconArea,
  WrapperRemove,
} from './styles';

import { Checkbox } from 'core/components';

import { useMediaQuery } from 'react-responsive';

interface IAccordion {
  data: any;
  onChange: Function;
  title: string;
  uid?: string;
  itemKey: string;
  titleColor?: string;
  titleStyle?: any;
  parentItemStyle?: any;
  removeData?: Function;
  dataKeys?: Array<string>;
  dataValues?: Array<string>;
  resetRender?: boolean;
  removeValue?: Function;
  onBlur?: any;
  addData?: any;
  addValue?: any;
  draggable?: boolean;
  headerDragable?: boolean;
  onDragEnd?: Function;
  isOpen?: boolean;
  subListOpened?: Array<string>;
  setAccordionState?: Function;
  disabled?: boolean;
  setAutoCompleteFilter?: Function;
  multiDelete?: boolean;
  canRemoveParent?: boolean;
  editState?: boolean;
  selectedOptions?: Array<string>;
  addSelectedOption?: Function;
  removeSelectedOptions?: Function;
  onChangeTitle?: Function;
  onBlurTitle?: Function;
}

interface IHeader {
  title: string;
  titleColor?: string;
  subTitle?: string;
  styleTitle?: string;
  titleStyle?: any;
  fontBold?: boolean;
  onClick: any;
  open: boolean;
  showBorder?: boolean;
  showAutoComplete?: boolean;
  dataAutoComplete?: Array<string>;
  onChange?: Function;
  onChangeSubTitle?: Function;
  onBlur?: any;
  draggable?: boolean;
  headerDragable?: boolean;
  disabled?: boolean;
  inputRef?: any;
  hasChildren?: boolean;
  showCheckbox?: Function;
  dataCy?: string;
  textTransform?: string;
  itemsCount?: number
  orderChange?: boolean;
  uid?: string;
  changeAutoCompleteFilter?: Function;
}

const Header = ({
  title,
  titleColor,
  subTitle,
  styleTitle,
  titleStyle,
  onClick,
  open,
  fontBold = true,
  showBorder,
  showAutoComplete,
  dataAutoComplete,
  onChange,
  onChangeSubTitle,
  draggable,
  disabled = false,
  inputRef,
  hasChildren = true,
  showCheckbox,
  dataCy,
  onBlur,
  textTransform,
  itemsCount,
  orderChange,
  uid,
  changeAutoCompleteFilter = ()=>{}
}: IHeader) => (
  <BoxAccordion showBorder={showBorder} data-cy={dataCy}>
    {draggable && (
      <IconArea style={{ justifyContent: 'flex-start' }}>
        <FontAwesomeIcon
          data-cy={`${dataCy}_gripIcon`}
          style={{ paddingRight: 15, width: 'auto', cursor: 'grab' }}
          icon={faGripVertical}
          color="#999"
        />
      </IconArea>
    )}
    {showAutoComplete ? (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div
          onClick={onClick}
          style={{ width: '100%', justifyContent: 'center' }}
        >
          {showCheckbox && showCheckbox()}
          <AutoCompleteInput
            dataCy={`${dataCy}_input`}
            itemsCount={itemsCount}
            id={`${dataCy}_id_${uid}`}
            value={title}
            onChange={(name: string, value: any, event: any) => {
              onChange(value);
              // const target = event ? event.currentTarget || event.target : null;
              // if (target) {
              //   const cursor = target.selectionStart;
              //   setTimeout(() => {
              //     target.selectionStart = cursor;
              //   }, 1000);
              // }
            }}
            onFocus={()=>changeAutoCompleteFilter()}
            data={dataAutoComplete || []}
            onBlur={(value: any) => (onBlur ? onBlur(value) : onChange(value))}
            bold={fontBold}
            inputRef={title === '' ? inputRef : null}
            disabled={disabled}
            autoCapitalize="sentences"
            textTransform={textTransform}
            titleStyles={
              !styleTitle
                ? { ...titleStyle }
                : {
                    ...titleStyle,
                    fontStyle:
                      styleTitle.indexOf('/i') >= 0 ? 'italic' : 'normal',
                  }
            }
          />
        </div>
        {subTitle && open && (
          <div style={{ width: 'auto', marginLeft: '10px' }}>
            <AutoCompleteInput
              dataCy={`${dataCy}_subTitle_input`}
              id={`${dataCy}_id_${uid}`}
              itemsCount={1}
              orderChange={orderChange}
              value={subTitle}
              autoCapitalize="sentences"
              data={[]}
              textTransform={textTransform}
              // onFocus={()=>changeAutoCompleteFilter()}
              onChange={(name: string, value: any) => onChangeSubTitle(value)}
              onBlur={(value: any) => onChangeSubTitle(value)}
              disabled={disabled}
            />{' '}
          </div>
        )}
      </div>
    ) : (
      <>
        {showCheckbox && showCheckbox()}
        <span style={{ color: titleColor, ...titleStyle }}>{title}</span>
      </>
    )}
    <IconArea>
      {hasChildren && (
        <IconContent onClick={onClick} data-cy={`${dataCy}_chevronIcon`}>
          <FontAwesomeIcon
            icon={open ? faChevronUp : faChevronDown}
            size="1x"
            color="#333"
          />
        </IconContent>
      )}
    </IconArea>
  </BoxAccordion>
);

export const Accordion = memo(
  ({
    data,
    onChange,
    title,
    uid,
    itemKey: key,
    titleColor,
    titleStyle,
    parentItemStyle,
    removeData,
    dataKeys,
    dataValues,
    resetRender,
    removeValue,
    onBlur,
    addData,
    addValue,
    draggable,
    headerDragable,
    onDragEnd,
    isOpen,
    subListOpened,
    setAccordionState,
    disabled,
    setAutoCompleteFilter,
    multiDelete,
    canRemoveParent,
    editState,
    selectedOptions = [],
    addSelectedOption = () => {},
    removeSelectedOptions = () => {},
    onChangeTitle,
    onBlurTitle,
  }: IAccordion) => {
    const [open, setOpen] = useState(!!isOpen);
    const [subListOpen, setSubListOpen] = useState(subListOpened || []);
    const [values, setValues] = useState([]);
    const lastAdded = useRef(null);
    const [orderChange, setOrderChange] = useState(null);
    const {invalidCleaningSpecs, subListsOpen} = useInvalidCleaningSpecs();
    const {setError} = useGenericErrorHandler();
    const biggerThan768 = useMediaQuery({
      query: '(min-device-width: 769px)',
    });

    const biggerThan980 = useMediaQuery({
      query: '(min-device-width: 981px)',
    });

    const biggerThan1440 = useMediaQuery({
      query: '(min-device-width: 1439px)',
    });

    useEffect(() => {
      try{
        setValues(JSON.parse(JSON.stringify(data)));
      }catch(e){
        setError(e);
      }
      // eslint-disable-next-line
    }, [data, resetRender]);

    useEffect(() => {
      if (setAccordionState) {
        setAccordionState({
          [key]: {
            state: open,
            subListState: subListOpen,
          },
        });
      }
    }, [open, subListOpen]);

    const onOpenSublist = useCallback(
      (key: string) => () =>
        setSubListOpen(
          subListOpen.includes(getTitle(key)) || subListsOpen.includes(getTitle(key))
            ? subListOpen.filter((item: string) => item !== key)
            : [...subListOpen, key]
        ),
      [subListOpen]
    );

    const onChangeKey = useCallback(
      (index: number) => (field: string) => (value: any) => {
        const result = data.filter(
          (item: any, dataIndex: number) => dataIndex !== index
        );
        if (field) {
          result.splice(index, 0, { ...data[index], [field]: value });
        } else {
          result.splice(index, 0, value);
        }
        onChange(result);
      },
      // eslint-disable-next-line
      [data, onChange]
    );

    const onChangeValue = useCallback(
      (dataIndex: number, valueIndex: number) => (name: string, value: any) => {
        // const result = data.filter(
        //   (item: any, index: number) => index !== dataIndex
        // );
        // const frequencyTypes = data[dataIndex].frequencyTypes.filter(
        //   (item: any, index: number) => index !== valueIndex
        // );
        // frequencyTypes.splice(valueIndex, 0, {...data[dataIndex].frequencyTypes[valueIndex], value: value});
        // result.splice(dataIndex, 0, { ...data[dataIndex], frequencyTypes });

        const result = [...data];
        result[dataIndex].frequencyTypes.splice(valueIndex, 1, {...result[dataIndex].frequencyTypes[valueIndex], value: value});

        onChange(result);
      },
      // eslint-disable-next-line
      [data, onChange]
    );

    const setNewValues = (newValues) => {

      const invalidState = [];
      for(var el of document.getElementById(key).getElementsByClassName('is-invalid')){
        invalidState.push(el.id);
        el.classList.remove('is-invalid');
      }

      setValues(newValues);
      onDragEnd(key, newValues);
      setOrderChange(Math.random());

      setTimeout(() => {
        for(var id of invalidState){
          document.getElementById(id).classList.add('is-invalid');
        }

        for (var el of document
          .getElementById(key)
          .getElementsByTagName('textarea')) {

            if(el){
              el.style.height = "fit-content";
              el.style.height = (el.scrollHeight)+"px";
            }
        }

      })
      // eslint-disable-next-line
    };

    useEffect(() => {
      // setSubListOpen([]);
      if (data && data.length > 0 && data[0].title == '') {
        setOpen(true);
      }
      // eslint-disable-next-line
    }, [resetRender, data]);

    useEffect(()=>{
      if(invalidCleaningSpecs.length > 0){
        if(invalidCleaningSpecs.filter(i=>i.toLowerCase().includes(title.toLowerCase()) || i.toLowerCase().includes(key.toLowerCase())).length > 0){
          setOpen(true);
          setTimeout(() => {
            invalidCleaningSpecs.map(id=>{
              let el = document.getElementById(id);
              if(el){
                el.parentNode.classList.add('is-invalid')
              }
            })
          })
        }
      }
    }, [invalidCleaningSpecs])

    const customCheckboxSquareStyle = {
      width: '16px',
      height: '16px',
      minWidth: '16px',
      minHeight: '16px',
      borderRadius: '4px',
    };

    const customCheckboxCheckStyle = {
      fontSize: '0.73em',
    };

    const createSubItems =
      (
        item: any,
        index: number,
        parentLength: number,
        key: string,
        checkAndAddOption: Function,
        checkAndRemoveOption: Function
      ) =>
      (frequencyItem: any, dataIndex: number, arr: Array<any>) =>
        (
          <ItemWrapper
            key={`subitem_${index}_${frequencyItem.uid}`}
            data-cy={`${key}_${index}_subItem_wrapper`}
          >
            <ItemContent
              data-cy={`${key}_${index}_subItem_content`}
              margin
              border
              style={{
                display: 'inherit',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {draggable && (
                <IconArea style={{ justifyContent: 'flex-start' }}>
                  <FontAwesomeIcon
                    data-cy={`${key}_${index}_subItem_gripIcon`}
                    style={{
                      paddingLeft: 1,
                      paddingRight: 10,
                      width: 'auto',
                      cursor: 'grab',
                    }}
                    icon={faGripVertical}
                    size="1x"
                    color="#999"
                  />
                </IconArea>
              )}
              {multiDelete && editState && (
                <Checkbox
                  dataCy={`${key}_${dataIndex}_subItem_checkbox`}
                  containerStyle={{
                    alignSelf: 'flex-start',
                    paddingTop: '3px',
                  }}
                  squareStyle={customCheckboxSquareStyle}
                  checkStyle={customCheckboxCheckStyle}
                  borderColor="gray"
                  name={frequencyItem.value}
                  value={frequencyItem.value}
                  checked={selectedOptions.includes(`${key}_#${dataIndex}#${frequencyItem.value}`)}
                  onChange={(name, value, isChecked) => {
                    if (
                      isChecked &&
                      !selectedOptions.includes(`${key}_#${dataIndex}#${value}`)
                    ) {
                      checkAndAddOption(`${key}_#${dataIndex}#${value}`);
                    } else if (selectedOptions.includes(`${key}_#${dataIndex}#${value}`)) {
                      checkAndRemoveOption(`${key}_#${dataIndex}#${value}`);
                    }
                  }}
                />
              )}

              <AutoCompleteInput
                dataCy={`${key}_${index}_subItem_input`}
                id={`${key}_${index}_subItem_id_${frequencyItem.uid}`}
                itemsCount={arr.length}
                orderChange={orderChange}
                disabled={disabled}
                autoCapitalize="sentences"
                onFocus={()=>setAutoCompleteFilter(title.toLowerCase())}
                value={frequencyItem.value}
                onChange={onChangeValue(index, dataIndex)}
                data={dataValues || []}
                onBlur={(value) => onChangeValue(index, dataIndex)(null, value)}
              />
            </ItemContent>
            <IconArea
              style={{
                alignItems: 'center',
                paddingBottom: 25,
                paddingRight: 5,
              }}
            >
              {item.frequencyTypes.length > 1 && !editState && (
                <IconButton
                  customIcon={trash}
                  label={null}
                  fontSize={20}
                  dataCy={`${key}_${index}_subItem_removeIcon`}
                  disabled={disabled}
                  padding="0px 0px 0px 10px"
                  style={{ width: 20, height: 20 }}
                  onClick={() => removeValue(index, dataIndex)}
                />
              )}

              {item.frequencyTypes.length - 1 === dataIndex &&
                parentLength >= 1 && (
                  <IconButton
                    customIcon={listAdd}
                    fontSize={20}
                    style={{ width: 20, height: 20, paddingTop: 3 }}
                    disabled={disabled}
                    dataCy={`${key}_${index}_subItem_${dataIndex}_addIcon`}
                    onClick={() => addValue(index)}
                    label={null}
                  />
                )}

              {item.frequencyTypes.length - 1 !== dataIndex && (
                <IconButton
                  dataCy={`${key}_${index}_subItem_${dataIndex}_addIcon`}
                  disabled={true}
                  onClick={() => {}}
                  customIcon={' '}
                  fontSize={20}
                  style={{ width: 20, height: 20 }}
                  label={null}
                />
              )}
            </IconArea>
          </ItemWrapper>
        );

    const getTitle = (item: any, onlyMain: boolean = false) =>
      typeof item === 'string'
        ? item
        : item.mainTitle
        ? onlyMain
          ? item.mainTitle
          : `${item.mainTitle} - ${item.subTitle}`
        : item.title;

    const createItems =
      (checkAndAddOption: Function, checkAndRemoveOption: Function) =>
      (item: any, index: number, parentArr: Array<any>) =>
        (
          <ItemWrapper
            key={`accordion_${key}_${index}`}
            data-cy={`${key}_${index}_item_wrapper`}
          >
            <ItemContent data-cy={`${key}_${index}_item_content`}>
              <Header
                changeAutoCompleteFilter={()=>setAutoCompleteFilter(title.toLowerCase())}
                dataCy={`${key}_${index}_item_header`}
                itemsCount={parentArr.length}
                orderChange={orderChange}
                showCheckbox={() =>
                  multiDelete && editState ? (
                    <Checkbox
                      dataCy={`${key}_${index}_item_checkbox`}
                      containerStyle={{
                        alignSelf: 'flex-start',
                        position: 'absolute',
                        paddingTop: '3px',
                      }}
                      squareStyle={customCheckboxSquareStyle}
                      checkStyle={customCheckboxCheckStyle}
                      borderColor="gray"
                      name={item.mainTitle ? item.mainTitle : getTitle(item)}
                      value={item.mainTitle ? item.mainTitle : getTitle(item)}
                      checked={
                        selectedOptions.includes(
                          `${key}_#${index}#${
                            item.mainTitle ? item.mainTitle : getTitle(item)
                          }`
                        )
                        // &&
                        // item.frequencyTypes.filter((i) =>
                        //   selectedOptions.includes(
                        //     `${title}_${
                        //       item.mainTitle ? item.mainTitle : getTitle(item)
                        //     }_${i}`
                        //   )
                        // ).length == item.frequencyTypes.length
                      }
                      onChange={(name, value, isChecked) => {
                        if (
                          isChecked &&
                          !selectedOptions.includes(`${key}_#${index}#${value}`)
                        ) {
                          // addSelectedOption([
                          //   `${title}_${value}`,
                          //   ...item.frequencyTypes.map(
                          //     (item) => `${title}_${value}_${item}`
                          //   ),
                          // ]);

                          const items = item.frequencyTypes
                            ? [
                                ...item.frequencyTypes.map(
                                  (item, idx) => `${key}_#${index}#${value}_#${idx}#${item.value}`
                                ),
                              ]
                            : [];
                            checkAndAddOption(`${key}_#${index}#${value}`, items);
                          } else if (
                            selectedOptions.includes(`${key}_#${index}#${value}`)
                            ) {
                          const items = item.frequencyTypes
                            ? [
                                ...item.frequencyTypes.map(
                                  (item, idx) => `${key}_#${index}#${value}_#${idx}#${item.value}`
                                ),
                              ]
                            : [];

                          removeSelectedOptions([
                            `${key}`,
                            `${key}_#${index}#${value}`,
                            ...items,
                          ]);
                        }
                      }}
                    />
                  ) : null
                }
                title={item.mainTitle ? item.mainTitle : getTitle(item)}
                titleColor={titleColor}
                subTitle={item.subTitle ? item.subTitle : ''}
                styleTitle={item.styleTitle}
                titleStyle={{
                  paddingLeft: multiDelete && editState ? '30px' : '0px',
                  ...parentItemStyle,
                }}
                fontBold={
                  !(!item.frequencyTypes || item.frequencyTypes.length == 0)
                }
                onClick={onOpenSublist(getTitle(item, true))}
                open={subListOpen.includes(getTitle(item, true)) || subListsOpen.includes(getTitle(item, true))}
                // showBorder={!subListOpen.includes(getTitle(item))}
                showBorder={true}
                dataAutoComplete={dataKeys}
                onChange={
                  typeof item === 'string'
                    ? onChangeKey(index)(null)
                    : onChangeKey(index)('title')
                }
                onChangeSubTitle={onChangeKey(index)('subTitle')}
                onBlur={onChangeKey(index)}
                showAutoComplete
                inputRef={getTitle(item) === '' ? lastAdded : null}
                draggable={draggable}
                disabled={disabled}
                hasChildren={
                  item.frequencyTypes
                    ? item.frequencyTypes.length > 0
                    : item.items
                    ? item.items.length > 0
                    : false
                }
                uid={item.uid}
              />
              {(subListOpen.includes(getTitle(item, true)) || subListsOpen.includes(getTitle(item, true))) && (
                <ItemContent data-cy={`${key}_${index}_item_content`}>
                  {item.frequencyTypes &&
                    item.frequencyTypes.length &&
                    draggable && (
                      <DraggableSection
                        data-cy={`${key}_${index}_item_draggableSection`}
                        id={getTitle(item, true)}
                        onChange={(orderedValues) =>
                          setFrequenceTypes(index, orderedValues)
                        }
                        values={item.frequencyTypes}
                      >
                        {item.frequencyTypes.map(
                          createSubItems(
                            item,
                            index,
                            parentArr.length,
                            `${key}_#${index}#${
                              item.mainTitle ? item.mainTitle : getTitle(item)
                            }`,
                            (itemToAdd) => {
                              if (
                                item.frequencyTypes.filter((i, idx) =>
                                  selectedOptions.includes(
                                    `${key}_#${index}#${
                                      item.mainTitle
                                        ? item.mainTitle
                                        : getTitle(item)
                                    }_#${idx}#${i}`
                                  )
                                ).length ==
                                item.frequencyTypes.length - 1
                              ) {
                                checkAndAddOption(
                                  `${key}_#${index}#${
                                    item.mainTitle
                                      ? item.mainTitle
                                      : getTitle(item)
                                  }`,
                                  [itemToAdd]
                                );
                              } else {
                                addSelectedOption([itemToAdd]);
                              }
                            },
                            (itemToRemove) => {
                              checkAndRemoveOption([
                                `${key}_#${index}#${
                                  item.mainTitle
                                    ? item.mainTitle
                                    : getTitle(item)
                                }`,
                                itemToRemove,
                              ]);
                            }
                          )
                        )}
                      </DraggableSection>
                    )}

                  {item.frequencyTypes &&
                    item.frequencyTypes.length &&
                    !draggable &&
                    item.frequencyTypes.map(
                      createSubItems(
                        item,
                        index,
                        parentArr.length,
                        `${key}_#${index}#${
                          item.mainTitle ? item.mainTitle : getTitle(item)
                        }`,
                        (itemToAdd) => {
                          if (
                            item.frequencyTypes.filter((i) =>
                              selectedOptions.includes(
                                `${key}_#${index}#${
                                  item.mainTitle
                                    ? item.mainTitle
                                    : getTitle(item)
                                }_${i.value}`
                              )
                            ).length ==
                            item.frequencyTypes.length - 1
                          ) {
                            checkAndAddOption(
                              `${key}_#${index}#${
                                item.mainTitle ? item.mainTitle : getTitle(item)
                              }`,
                              [itemToAdd]
                            );
                          } else {
                            addSelectedOption([itemToAdd]);
                          }
                        },
                        (itemToRemove) => {
                          checkAndRemoveOption([
                            `${key}_#${index}#${
                              item.mainTitle ? item.mainTitle : getTitle(item)
                            }`,
                            ...itemToRemove,
                          ]);
                        }
                      )
                    )}

                  {item.items &&
                    item.items.map(
                      createSubItems(
                        item,
                        index,
                        parentArr.length,
                        `${key}_#${index}#${
                          item.mainTitle ? item.mainTitle : getTitle(item)
                        }`,
                        (itemToAdd) => {
                          if (
                            item.frequencyTypes.filter((i) =>
                              selectedOptions.includes(
                                `${key}_#${index}#${
                                  item.mainTitle
                                    ? item.mainTitle
                                    : getTitle(item)
                                }_${i.value}`
                              )
                            ).length ==
                            item.frequencyTypes.length - 1
                          ) {
                            checkAndAddOption(
                              `${key}_#${index}#${
                                item.mainTitle ? item.mainTitle : getTitle(item)
                              }`,
                              [itemToAdd]
                            );
                          } else {
                            addSelectedOption([itemToAdd]);
                          }
                        },
                        (itemToRemove) => {
                          checkAndRemoveOption([
                            `${key}_#${index}#${
                              item.mainTitle ? item.mainTitle : getTitle(item)
                            }`,
                            ...itemToRemove,
                          ]);
                        }
                      )
                    )}
                </ItemContent>
              )}
            </ItemContent>
            <IconArea style={{ paddingBottom: 25, paddingRight: 5 }}>
              {data.length > 1 && canRemoveParent && !editState && (
                // <IconButton disabled={disabled} onClick={() => removeData(index)} />
                <IconButton
                  customIcon={trash}
                  label={null}
                  fontSize={20}
                  dataCy={`${key}_${index}_item_removeIcon`}
                  disabled={disabled}
                  padding="0px 0px 0px 10px"
                  style={{ width: 20, height: 20 }}
                  onClick={() => {
                    removeData(index)
                  }}
                />
              )}

              {data.length - 1 !== index &&
                (!item.items || item.items.length == 0) &&
                (!item.frequencyTypes || item.frequencyTypes.length == 0) && (
                  <IconButton
                    dataCy={`${key}_${index}_item_addIcon`}
                    disabled={true}
                    onClick={() => {}}
                    customIcon={' '}
                    fontSize={20}
                    style={{ width: 20, height: 20 }}
                    label={null}
                  />
                )}

              {data.length - 1 === index &&
                ((item.items && item.items.length > 0) ||
                  (item.frequencyTypes && item.frequencyTypes.length > 0)) && (
                  <IconButton
                    disabled={disabled}
                    onClick={() => {
                      setAutoCompleteFilter(title.toLowerCase());
                      addData();
                      setTimeout(() => {
                        if (lastAdded.current) {
                          lastAdded.current.focus();
                        }
                      }, 10);
                    }}
                    icon={faPlus}
                    label="Add"
                    dataCy={`${key}_${index}_item_addIcon`}
                    style={{
                      position: 'absolute',
                      right: '-64px',
                      marginTop: 15,
                    }}
                  />
                )}

              {data.length - 1 === index &&
                (!item.items || item.items.length == 0) &&
                (!item.frequencyTypes || item.frequencyTypes.length == 0) && (
                  <IconButton
                    customIcon={listAdd}
                    fontSize={20}
                    style={{ width: 20, height: 20, paddingTop: 3 }}
                    disabled={disabled}
                    dataCy={`${key}_${index}_item_addIcon`}
                    onClick={() => {
                      setAutoCompleteFilter(title.toLowerCase());
                      addData();
                      setTimeout(() => {
                        if (lastAdded.current) {
                          lastAdded.current.focus();
                        }
                      }, 10);
                    }}
                    label={null}
                  />
                )}
            </IconArea>
          </ItemWrapper>
        );

    const setFrequenceTypes = (index, frequencyTypes) => {
      const newValues = [...values];

      newValues[index] = {
        ...values[index],
        frequencyTypes,
      };

      setNewValues(newValues);
    };

    // React.useEffect(() => {
    //   if (lastAdded.current) {
    //     setTimeout(() => {
    //       if (lastAdded.current) {
    //         lastAdded.current.focus();
    //       }
    //     }, 10);
    //   }
    // }, [lastAdded.current]);

    return (
      <Content data-cy={`${key}_wrapper`}>
        <Header
          // changeAutoCompleteFilter={setAutoCompleteFilter(title.toLowerCase())}
          itemsCount={1}
          dataCy={`${key}_header`}
          showAutoComplete={!!onChangeTitle}
          onChange={onChangeTitle}
          onBlur={onBlurTitle}
          textTransform="capitalize"
          title={title}
          titleStyle={{
            paddingLeft: multiDelete && editState ? '30px' : '0px',
            ...titleStyle,
          }}
          showCheckbox={() =>
            multiDelete && editState ? (
              <Checkbox
                dataCy={`${key}_checkbox`}
                containerStyle={{
                  alignSelf: 'flex-start',
                  position: 'absolute',
                  paddingTop: '3px',
                }}
                squareStyle={customCheckboxSquareStyle}
                checkStyle={customCheckboxCheckStyle}
                borderColor="gray"
                name={key}
                value={key}
                checked={selectedOptions.includes(key)}
                onChange={(name, value, isChecked) => {
                  if (isChecked && !selectedOptions.includes(value)) {
                    // alert(JSON.stringify(values));
                    const items = [key];
                    values.map((i, index) => {
                      items.push(`${key}_#${index}#${i.title}`);

                      i.frequencyTypes &&
                        i.frequencyTypes.map((ft, idx) => {
                          items.push(`${key}_#${index}#${i.title}_#${idx}#${ft.value}`);
                        });

                      !i.frequencyTypes && items.push(`${key}_#${index}#${i}`);
                    });
                    addSelectedOption(items);
                  } else if (selectedOptions.includes(value)) {
                    const items = [key];
                    values.map((i, index) => {
                      items.push(`${key}_#${index}#${i.title}`);
                      i.frequencyTypes &&
                        i.frequencyTypes.map((ft, idx) => {
                          items.push(`${key}_#${index}#${i.title}_#${idx}#${ft.value}`);
                        });
                      !i.frequencyTypes && items.push(`${key}_#${index}#${i}`);
                    });
                    removeSelectedOptions(items);
                  }
                }}
              />
            ) : null
          }
          titleColor={titleColor}
          onClick={() => setOpen(!open)}
          open={open}
          showBorder={open}
          draggable={headerDragable}
          uid={uid}
        />
        {open && (
          <div>
            {!!values && !!values.length && draggable && (
              <DraggableSection
                id={key}
                onChange={(orderedValues) => setNewValues(orderedValues)}
                values={values}
                data-cy={`${key}_draggableSection`}
              >
                {values.map(
                  createItems(
                    (itemToAdd, subItems) => {
                      if (
                        values.filter((i, index) =>
                          i.frequencyTypes
                            ? selectedOptions.includes(`${key}_#${index}#${i.title}`)
                            : selectedOptions.includes(`${key}_#${index}#${i}`)
                        ).length ==
                        values.length - 1
                      ) {
                        addSelectedOption([key, itemToAdd, ...subItems]);
                      } else {
                        addSelectedOption([itemToAdd, ...subItems]);
                      }
                    },
                    (itemsToRemove) => {
                      removeSelectedOptions([key, ...itemsToRemove]);
                    }
                  )
                )}
              </DraggableSection>
            )}
            {values &&
              !!values.length &&
              !draggable &&
              values.map(
                createItems(
                  (itemToAdd, subItems) => {
                    if (
                      values.filter((i) =>
                        selectedOptions.includes(`${key}_${i.title}`)
                      ).length ==
                      values.length - 1
                    ) {
                      addSelectedOption([key, itemToAdd, ...subItems]);
                    } else {
                      addSelectedOption([itemToAdd, ...subItems]);
                    }
                  },
                  (itemsToRemove) => {
                    removeSelectedOptions([key, ...itemsToRemove]);
                  }
                )
              )}
          </div>
        )}
      </Content>
    );
  }
);
