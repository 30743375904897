import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

interface IIconButton {
  onClick: any;
  label?: string;
  padding?: string;
  icon?: any;
  customIcon?: any;
  disabled?: boolean;
  style?: any;
  iconSize?: SizeProp;
  fontSize?: number;
  color?: string;
  dataCy?: string;
}

const StyledButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-right: 15px;
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
  ${({ padding }: IIconButton) => (padding ? `padding: ${padding};` : '')}
  ${({ disabled }: IIconButton) => (disabled ? `opacity: 0.7` : '')}
  ${({ style }: IIconButton) => (style ? style : '')}
`;

export const IconButton = ({
  onClick,
  label = 'Delete',
  padding,
  icon,
  disabled,
  style,
  iconSize,
  color,
  fontSize,
  customIcon,
  dataCy,
}: IIconButton) => (
  <StyledButton
    onClick={() => (disabled ? '' : onClick())}
    padding={padding}
    disabled={disabled}
    style={style}
    data-cy={dataCy}
  >
    {customIcon ? (
      <div
        style={{
          background: `url(${customIcon}) no-repeat`,
          width: fontSize,
          height: fontSize,
        }}
      />
    ) : (
      <FontAwesomeIcon
        icon={icon || faTrashAlt}
        style={{ fontSize: fontSize }}
        size={iconSize || '1x'}
        color={color || '#999'}
      />
    )}
    {label}
  </StyledButton>
);
