import React, { useState } from 'react';
import { withFormik, FormikProps } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormProvider, GenericModal } from 'core/components';
import ActionAlertModal from 'core/components/ActionAlertModal';
import { getQueryString, axiosInstance } from 'core/utils';
import { stripHTMLTags, decodeEntities, isWebView } from 'core/utils';
import { getBaseURL } from '../../utils';
import * as Sentry from '@sentry/react';
import {
  LoadingPage,
  BlockingOverlay,
} from 'modules/BidInfoForm/components/organisms';
import { toast } from 'react-toastify';
import { PerformanceEvaluationMenuScreen } from 'modules/PerformanceEvaluation/screens';

import { Header, Footer, Contact, Evaluation } from './sections';
import {
  postPef,
  postUserPef,
  getPefById,
  getUserPefById,
  uploadSignature,
  uploadUserSignature,
  getSignatureUri,
  generatePDFWithoutSave,
} from '../../services/performanceEvaluationService';
import { parseValues } from '../../services/parser';
import { validateForm } from '../../../../core/validators/PerformanceFormValidator';
import { HiddenAnchor } from '../../../BidInfoForm/components/atoms';

import './styles.scss';
import { getPerformanceUrl } from '../../../BidInfoForm/utils';
import {
  getBase64ByUrl,
  getDirectPdfDownloadUrl,
  isAlive,
  verifyServerUnavailable,
} from '../../../BidInfoForm/services/bidInfoService';
import SignNowScreen from '../../../BidInfoForm/screens/SignNowScreen';
import { IWarningModal } from '../../../shared/bidInterfaces';
import BlockingPopupModal from '../../../BidInfoForm/screens/BlockingPopupModal';
import { useActivityTracking } from '../../../../core/hooks/useActivityTracking';
import { useAuthenticationHandler } from '../../../../core/hooks/useAuthenticationHandler';
import { useGenericErrorHandler } from '../../../../core/hooks/useGenericErrorHandler';
import { ANAGO_CLEANSOURCE_DEV_LOGIN_URL } from '../../../../core/utils';

const PerformanceEvaluation = ({
  userEvaluation,
  values,
  setFieldValue,
  setValues,
  dirty,
  history,
  ...props
}: any) => {
  const [showExit, setShowExit] = useState(false);
  const [url, setUrl] = React.useState(null);
  const setAllValues = (params: any) => {
    return setValues({ ...values, ...params });
  };
  const [warningModal, setWarningModal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [needsSaving, setNeedsSaving] = useState(false);
  const [franchises, setFranchises] = useState([]);
  const [selectedFranchise, setSelectedFranchise] = useState(null);
  const [signatureData, setSignatureData] = useState(null);
  const [isTouchable, setIsTouchable] = React.useState(false);
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [commentsBlocked, setCommentsBlocked] = React.useState(false);
  const [showMenu, setShowMenu] = useState();
  const [blockingModal, setBlockingModal] = useState<IWarningModal>(null);
  const [pdfUrl, setPdfUrl] = React.useState(null)

  const logSentry = useActivityTracking(()=>values);

  const {error, setError} = useAuthenticationHandler();
  const {error: genericError, setError: setGenericError} = useGenericErrorHandler();

  React.useEffect(()=>{
    if(!!error && error.status == 401){
      setIsLoading(false);
      setWarningModal({
        title: 'Access Denied',
        message:
          "You do not have permissions to access this form. Please contact Anago Support if you believe you have reached this in error.",
        proceed: () => {
          // setWarningModal(null);
          // setError(undefined)
          window.location.href =  ANAGO_CLEANSOURCE_DEV_LOGIN_URL.replace('login', 'home')

        },
      });
    }else if (!!error && error.status == 403) {
      try {
        if (error.data.includes('CLIENT_VERSION_MISMATCH')) {
          setIsLoading(false);
          setWarningModal({
            title: 'Attention',
            message:
              'Seems like your app is outdated. This may happen due to browser cache issues. Click OK to get the latest version from server.',
            proceed: () => {
              window.location.reload(true);
            },
          });
        }
      } catch (e) {
        setGenericError(e);
      }
    }

  }, [error])

  React.useEffect(()=>{
    if(!!genericError){
      setIsLoading(false);
      logSentry(genericError, values);
      setWarningModal({
        title: 'Attention',
        message:
          'An unexpected error has occurred. Please contact AFISupport with Error ID: ' +
          sessionStorage.getItem('transaction_id'),
        proceed: () => {
          setWarningModal(null);
          setGenericError(undefined)
        },
      });
    }
  }, [genericError])


  const { search } = props.location;

  React.useEffect(
    () => setIsTouchable('ontouchstart' in document.documentElement),
    []
  );

  React.useEffect(() => {
    setIsLoading(true);

    const uniqueId = getQueryString(search, 'formUniqueId');

    const fetch = async () => {
      let userName = null;
      if (!userEvaluation) {
        const userData = await axiosInstance.get(
          `GetUserById?userid=${userId}`
        );
        userName = userData.data['Name'];

        const listCloud = await axiosInstance.get(
          `InspectionReport/getFranchises?userid=${userId}`
        );

        setFranchises(listCloud.data);
      }

      let parsedValues = {};

      if (uniqueId) {
        const goGetIt = () => (userEvaluation ? getUserPefById(uniqueId) : getPefById(uniqueId));

        const foundValues = await goGetIt();
        parsedValues = parseValues(foundValues.data, true);
        setIsCompleted(parsedValues['isCompleted']);
      } else {
        const pid = decodeURI(getQueryString(search, 'pid'));
        const companyName = decodeURI(getQueryString(search, 'companyName'));
        const franchiseId = decodeURI(getQueryString(search, 'franchiseId'));
        const companyRepresentative = decodeURI(
          getQueryString(search, 'companyRepresentative')
        );

        parsedValues = {
          pid: pid !== 'undefined' ? pid : values.pid,
          company:
            companyName !== 'undefined' ? companyName : values.companyName,
          franchiseId:
            franchiseId !== 'undefined'
              ? parseInt(franchiseId)
              : values.franchiseId,
          companyRepresentative:
            companyRepresentative !== 'undefined'
              ? companyRepresentative
              : values.companyRepresentative,
        };
      }

      setAllValues({
        ...parsedValues,
        date: parsedValues.date ? new Date(parsedValues.date) : null,
        companyRepresentative: userName
          ? userName
          : parsedValues['companyRepresentative'],
      });

      setTimeout(() => {
        setNeedsSaving(false);
        setIsLoading(false);
        const franchiseId = decodeURI(getQueryString(search, 'franchiseId'));

        if (franchiseId && franchiseId !== 'undefined') {
          setFieldValue('franchiseId', franchiseId);
        }
      }, 1000);
    };

    fetch().catch((e)=>{
      logSentry(e, {uid: uniqueId});
      if(e.status === 500){
        history.push(
          `/forms/unable-to-open`
        );
      }
    });
  }, [search]);

  const showDeleteConfirmationModal = (proceedFunction: Function) => {
    setWarningModal({
      message: 'Are you sure you want to delete this item?',
      proceed: () => {
        proceedFunction();
        setWarningModal(null);
      },
      dismiss: () => setWarningModal(null),
    });
  };
  const [errors, setErrors] = useState([]);

  const userId = getQueryString(window.location.href, 'userId');

  const doPost = async (values, callback = () => undefined) => {
    if (!values.createdBy) {
      values.createdBy = userId;
    }
    const postValues = parseValues(values, false);
    const response = await (userEvaluation ? postUserPef(postValues) : postPef(postValues));

    if (response.error) {
      setWarningModal({
        message: response.error as string,
        proceed: () => {
          setIsLoading(false);
          setWarningModal(null);
        },
      });
      return response.error;
    }
    callback(response.data);

    return response.data;
  };

  const onClickPrintPdf = async () => {
    if (!values.isCompleted) {
      const errors = validateForm(values, userEvaluation || isDirty);
      setErrors(errors);
      if (errors && errors.length > 0) {
        return;
      }

      setIsLoading(true);

      generatePDFWithoutSave(values).then(({ data }) => {
          setPdfUrl(data.Pdfpath);
          setTimeout(() => {
            document.getElementById('hiddenAnchorBlank').click();
            setTimeout(() => {
              setIsLoading(false);
            }, 700)
          },0)
      });

    } else {
      setIsLoading(true);

      setPdfUrl(values.pdfPath);
      setTimeout(() => {
        document.getElementById('hiddenAnchor').click();
        setTimeout(() => {
          setIsLoading(false);
        }, 700)
      },0)

    }
  };

  const onCompleteButtonClick = async (callback = () => {}) => {
    try {
      await isAlive();
    } catch (e) {
      setWarningModal({
        message:
          "It looks like you don't have internet at the moment, please ensure you have internet access and try again.",
        proceed: () => {
          setWarningModal(null);
        },
      });
      callback();
      Sentry.captureException(e);
      return;
    }

    const errors = validateForm(values, userEvaluation || isDirty);
    if ((userEvaluation || isDirty) && !signatureData && isTouchable) {
      errors.push('SIGNATURE');
    }
    setErrors(errors);
    if (errors && errors.length > 0) {
      callback();
      return;
    }

    if (values.uid && values.isTemplate) {
      values.uid = null;
    }

    values.sentStatus = true;
    values.isTemplate = false;
    values.isCityTemplate = false;

    if (userEvaluation || isDirty) {
      values.generatePdf = true;
      if (signatureData) {
        const signatureId = await (userEvaluation ? uploadUserSignature(signatureData) : uploadSignature(signatureData));
        values.signatureId = signatureId['Id'];
      }
    }

    setIsLoading(true);

    await doPost(values, (data) => {
      setAllValues({ ...parseValues(data, true) });
      setIsLoading(false);
      toast.info('Saved Successfully');

      const encodedUrl = encodeURI(data['PEFLink']);
      callback();
      if (userEvaluation || isDirty) {
        window.location.href = `#/user-evaluation/thanks?formUniqueId=${data.PEFUniqID}`;
        return;
      }
      history.push({
        pathname: `/performance-evaluation/summary-success/${data.PEFUniqID}`,
        state: {
          userId,
          encodedUrl,
          userName: values.companyRepresentative,
          pefEmail: data.PEFEmail,
        },
      });
      //redirectToSummarySuccess
    });
  };

  const saveDraft = async (values, userId) => {
    if (
      (values.uid || values.pefId) &&
      (values.isTemplate || values.sentStatus)
    ) {
      values.uid = null;
      values.isTemplate = false;
      values.isCityTemplate = false;
    }

    values.sentStatus = false;

    const result = await doPost(values);

    if (result.error) {
      setWarningModal({
        message: result.error as string,
        proceed: () => {
          setIsLoading(false);
          setWarningModal(null);
        },
      });
      return null;
    }

    toast.info('Saved Successfully');

    return result;
  };

  const onSaveDraft = async () => {
    setIsLoading(true);
    const responseData = await saveDraft(values, userId);

    if (responseData) {
      setFieldValue('uid', responseData['PEFUniqID']);
    }

    setIsLoading(false);

    return responseData;
  };

  const saveTemplate = async (values, userId) => {
    if (values.uid && !values.isTemplate) {
      values.uid = null;
    }

    values.isTemplate = true;
    values.sentStatus = false;

    const result = await doPost(values);

    if (result.error) {
      setWarningModal({
        message: result.error as string,
        proceed: () => {
          setIsLoading(false);
          setWarningModal(null);
        },
      });
      return null;
    }

    toast.info('Saved Successfully');

    return result;
  };

  const showCommentsWarning = (currentLenght) => {
    if (currentLenght > 584) {
      setCommentsBlocked(true);
      setWarningModal({
        message: `The maximum length of the comments is 584. Currently, you are using ${currentLenght}. Please remove ${
          currentLenght - 584
        } characteres to continue.`,
        proceed: () => {
          setWarningModal(null);
        },
      });
    } else {
      setCommentsBlocked(false);
    }
  };

  const onSaveTemplate = async () => {
    setIsLoading(true);
    const responseData = await saveTemplate(values, userId);

    if (responseData) {
      setFieldValue('uid', responseData['PEFUniqID']);
    }

    setIsLoading(false);

    return responseData;
  };

  const onOpenSearch = () => {
    setShowMenu({
      renderTemplateSection: false,
      renderDraftSection: false,
      renderSentSection: true,
      renderSignedSection: true,
    });
  };

  React.useEffect(() => {
    (async () => {
      const user = userId || values.createdBy;

      if(user){
        try {
          const result = await verifyServerUnavailable(user);
          if (!result || result.error) {
            setBlockingModal({
              message: 'Server under maintenance at this time, please try later',
            });
          } else {
            setBlockingModal(null);
          }
        } catch (e) {
          if (e.response.status == '503') {
            setBlockingModal({
              message: 'Server under maintenance at this time, please try later',
            });
          }
        }
      }
    })();
  }, [values]);

  return (
    <FormProvider
      needsSaving={needsSaving}
      setNeedsSaving={setNeedsSaving}
      formValues={values}
      onChange={setFieldValue}
      setFormValues={setAllValues}
      showWarningModal={setWarningModal}
      showDeleteConfirmationModal={showDeleteConfirmationModal}
    >
      <div className="inspection-root-div bid-form-root">
        <div className="inspection-content-screen bid-form-content-screen">
          {isLoading && <LoadingPage />}

          {blockingModal && (
            <BlockingOverlay>
              <BlockingPopupModal message={blockingModal.message} />
            </BlockingOverlay>
          )}

          <Header
            onExit={() => setShowExit(true)}
            openSearch={onOpenSearch}
            setIsLoading={setIsLoading}
            setNeedsSaving={setNeedsSaving}
            userEvaluation={userEvaluation}
            cities={franchises}
            onSelectFranchise={setSelectedFranchise}
            onBack={() => {
              if (isWebView()) {
                history.push('/close-form');
              } else {
                document.location.href = getPerformanceUrl();
              }
            }}
            isCompleted={isCompleted}
          />
          <Contact
            userEvaluation={userEvaluation}
            selectedFranchise={selectedFranchise}
            isCompleted={isCompleted}
          />
          <Evaluation
            setSignatureData={setSignatureData}
            userEvaluation={userEvaluation || isDirty}
            setIsDirty={setIsDirty}
            isCompleted={isCompleted}
            showCommentsWarning={showCommentsWarning}
          />
          {!commentsBlocked && (
            <Footer
              onCompleteButtonClick={onCompleteButtonClick}
              saveDraft={onSaveDraft}
              saveTemplate={onSaveTemplate}
              userEvaluation={userEvaluation || isDirty}
              onClickPrintPdf={onClickPrintPdf}
              isCompleted={isCompleted}
            />
          )}
          {isCompleted && <div style={{ height: '40px' }} />}
        </div>
      </div>
      {warningModal && (
        <ActionAlertModal
          message={warningModal.message}
          title={warningModal.title}
          onConfirm={warningModal.proceed}
          onDismiss={warningModal.dismiss}
          isVisible={true}
        />
      )}
      <ActionAlertModal
        title="Exit without saving?"
        message="Remember, that if you exit without saving, you will lose all progress."
        onConfirm={() => {
          setShowExit(true);

          if (isWebView()) {
            history.push('/close-form');
          } else {
            document.location.href = getPerformanceUrl();
          }
        }}
        onDismiss={() => setShowExit(false)}
        isVisible={showExit}
      />

      <ActionAlertModal
        message={errors}
        onConfirm={() => setErrors([])}
        isVisible={errors && errors.length > 0}
      />

      <HiddenAnchor
        href={getDirectPdfDownloadUrl(pdfUrl,`PerfEvalCompleted_${values.pid}` )}
        download={`PerfEvalCompleted_${values.pid}`}
        id="hiddenAnchor"
        title="download"
      />
      <HiddenAnchor
        href={getDirectPdfDownloadUrl(pdfUrl,`PerfEval_${values.pid}`)}
        download={`PerfEval_${values.pid}`}
        id="hiddenAnchorBlank"
        title="download"
      />

      {showMenu && (
        <GenericModal
          isOpen={showMenu}
          showHeader={false}
          clickOutsideToClose={false}
          fullscreen={false}
          height="710px"
          width="600px"
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <PerformanceEvaluationMenuScreen
            {...props}
            showBackground={false}
            {...showMenu}
            doneAction={(state) => {
              setShowMenu(null);
              setIsLoading(state);
            }}
            history={history}
          />
        </GenericModal>
      )}
    </FormProvider>
  );
};

const mapForm = {
  mapPropsToValues: () => ({
    ...{},
  }),
  handleSubmit: () => '',
  enableReinitialize: true,
};

const FormEnhanced = withFormik<any, any>(mapForm)(
  withRouter(PerformanceEvaluation)
);

export { FormEnhanced as PerformanceEvaluation };
