/**
 *
 * @param url string
 * @param key string
 * @return query string case insensitive
 */
export function getQueryString(url: string, key: string) {
  const stringVars = []
  let hash:any[];
  const hashes = (url.slice(url.indexOf('?') + 1).split('&'));

  for (let i = 0; i < hashes.length; i++) {
      hash = hashes[i].split('=');
      stringVars.push(hash[0].toLowerCase());
      stringVars[hash[0].toLowerCase()] = hash[1];
  }
  return stringVars[key.toLowerCase()];
}
