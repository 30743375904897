import styled from 'styled-components';

type TModalWrapper = {
  isOpen: boolean;
  height: string;
  width: string;
  maxWidth: string;
  fullscreen: boolean;
};

type TModalBackdrop = {
  isOpen: boolean;
};

const getModalWrapperDimensions = (
  isFullscreen,
  height = '95%',
  width = '95%',
  maxWidth="46em"
) => {
  if (isFullscreen) {
    return `
      width: 100%;
      height: 100%;
      margin: 0;
    `;
  }

  return `    
    height: ${height};
    width: ${width};
    max-height: 98%;
    margin: 1% auto;

    @media (min-width: 39em) {
      height: ${height};
      /* max-height: 42em; */
      max-width: ${maxWidth};
    }
  `;
};

export const ModalWrapper = styled.div<TModalWrapper>`
  background-color: white;
  position: relative;
  margin: 1% auto;
  padding: 0;
  overflow-y: auto;

  ${({ fullscreen, height, width, maxWidth }) =>
    getModalWrapperDimensions(fullscreen, height, width, maxWidth)}

  ${({ isOpen }) => (!isOpen ? 'display: none' : '')}
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #163354;
  height: 50px;
  padding: 0 8px;

  h2 {
    color: #fff;
    padding: 0;
    font-weight: normal;
    font-size: 16px;
    margin: 0;
  }

  button {
    border: 0 none;
    padding: 5px;
    cursor: pointer;
    background-color: transparent;

    svg {
      color: #fff;
    }
  }
`;

export const ModalBody = styled.div<{ isShowingHeader }>`
  flex: 1;
  display: flex;
  background-color: #fff;

  height: ${({ isShowingHeader = false }) =>
    isShowingHeader ? `calc(100% - 50px)` : '100%'};
`;

export const ModalBackdrop = styled.div<TModalBackdrop>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.6);

  ${({ isOpen }) => (!isOpen ? 'display: none' : '')}
`;
