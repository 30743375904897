import React, { memo, useState } from 'react';
import { withFormik, FormikProps } from 'formik';
import { IBidSupplies } from 'modules/BidInfoForm/interfaces';
import {
  suppliesValues,
  suppliesOptions,
} from 'modules/shared/bidDefaultValues';

import {
  ContentInput,
  BoxInput,
  Select,
  useFormProps,
  InputFloatLabel,
} from 'core/components';
import { moneyMask, moneyMaskParams } from 'core/utils/masks';

import { useMediaQuery } from 'react-responsive';

import './styles.scss';

const ServiceProvider = memo(({}: any) => {
  const { onChange, setFormValues, formValues } = useFormProps();
  const biggerThan768 = window.innerWidth >= 769;

  return (
    <div className="service-provider-root">
      <div className="service-provider-content">
        <ContentInput width="100%" justify="flex-start">
          <h3 className="service-provider-title">ASSIGNED SERVICE PROVIDER</h3>
        </ContentInput>
        <div
          style={{
            paddingLeft: '5px',
            paddingRight: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <ContentInput width="33%" padding>
            <InputFloatLabel
              value={formValues.franId}
              name="franId"
              label="FranID"
              onChange={onChange}
              onBlur={() => {}}
              disabled={formValues.status === 2}
            />
          </ContentInput>

          <ContentInput width="31%" padding>
            <InputFloatLabel
              value={formValues.franName}
              name="franName"
              label="Company Name"
              onChange={onChange}
              onBlur={() => {}}
              disabled={formValues.status === 2}
            />
          </ContentInput>

          <ContentInput width="30%" padding></ContentInput>
        </div>
      </div>
    </div>
  );
});

const mapForm = {
  mapPropsToValues: () => ({
    ...suppliesValues,
  }),
  handleSubmit: () => '',
};

const EnhancedServiceProvider = withFormik<any, IBidSupplies>(mapForm)(
  ServiceProvider
);

export { EnhancedServiceProvider as ServiceProvider };
