import React from 'react';
import styled from 'styled-components';

interface IToggleButton {
  value: boolean;
  name: string;
  onClick: Function;
  label: string;
}

interface IStyledButton {
  selected: boolean;
}

const StyledButton = styled.button`
  padding: 10px;
  ${({ selected }: IStyledButton) =>
    selected
      ? 'background-color: #216eab; color: #fff; transition: 0.2s'
      : 'background-color: transparent;transition: 0.2s; border: 1px solid #9a9a9a;'}
`;

export const ToggleButton = ({
  value,
  onClick,
  label,
  name,
}: IToggleButton) => (
  <StyledButton selected={value} onClick={() => onClick(name, !value)}>
    {label}
  </StyledButton>
);
