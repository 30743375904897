export const limitTextAreaRows = (
  className,
  counterClassName,
  isTextArea = true,
  wrapperClass,
  charLimit = 584,
  charLineCount = 73,
) => {
  var ua = navigator.userAgent.toLowerCase();
  var isAndroid = ua.indexOf('android') > -1; //&& ua.indexOf("mobile");
  var androidEventAlreadyFired = false;

  var limitLines = function (e) {
    const setCounter = (target, value) => {
      if (counterClassName) {
        var counterElement;
        if(wrapperClass){
          let el = target.closest(wrapperClass);
          if(el != null){
            counterElement = el.getElementsByClassName(
              counterClassName
            )[0];
          }else{
            counterElement = document.getElementsByClassName(
              counterClassName
            )[0];
          }
        }else{
          counterElement = document.getElementsByClassName(
              counterClassName
            )[0];
        }

        const lines = value
          ? value.split(/\r*\n/)
          : isTextArea
          ? target.value.split(/\r*\n/)
          : target.innerText.split(/\r*\n/);
          
        if(lines[lines.length-1] === "" && !isTextArea){
          lines.pop();
        }

        let qtdChar = lines.length > 0 ? lines.reduce((acc, el, index, array) => {
          if (index > 0 && array.length === index + 1) {
            acc += el.length;
          } else {
            if (el.length < charLineCount && index < array.length - 1) {
              acc += charLineCount;
            } else {
              acc += el.length;
            }
          }
          return acc;
        }, 0) : 0;

        if(lines.length===2 && lines.filter(i=>i != "").length === 0 && !isTextArea) {
          qtdChar = 0;
        }

        counterElement.innerHTML = qtdChar;
      }
    };

    if (isAndroid) {
      if (androidEventAlreadyFired) {
        androidEventAlreadyFired = false;
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();
        setTimeout(() => {
          setCounter(e.target, e.target.value);
        }, 10);
        return;
      } else {
        androidEventAlreadyFired = true;
      }
    }

    // if (e.keyCode == 17) {
    //   ctrlDown = true;
    //   setTimeout(() => {
    //     setCounter(e.target, e.target.value);
    //   }, 10);
    //   return;
    // }

    // if (e.keyCode == 86 && e.ctrlKey && e.type == 'keydown') {
    //   setTimeout(() => {
    //     setCounter(e.target, e.target.value);
    //   }, 10);
    //   return;
    // }

    let str = '';
    if (e.type === 'paste') {
      str = (wrapperClass ? e.target.innerText : e.target.value) + e.clipboardData.getData('Text');
    } else {
      if (isTextArea) {
        str = e.target.value;
      } else {
        str = e.target.innerText;
      }
    }

    const lines = str.split(/\r*\n/);
    
    if(lines[lines.length-1] === "" && !isTextArea){
      lines.pop();
    }

    let selectionValue = e.target.selectionStart - e.target.selectionEnd;
    if (selectionValue < 0) {
      selectionValue = selectionValue * -1;
    }

    let qtdChar = lines.length > 0 ? lines.reduce((acc, el, index, array) => {

      if (index > 0 && array.length === index + 1) {
        acc += el.length;
      } else {
        if (el.length < charLineCount && index < array.length - 1) {
          acc += charLineCount;
        } else {
          acc += el.length;
        }
      }
      
      return acc;
    }, 0) : 0;

    if(lines.length===2 && lines.filter(i=>i != "").length === 0 && !isTextArea) {
      qtdChar = 0;
    }

    if (
      charLimit && qtdChar > charLimit &&
      e.keyCode != 8 &&
      e.keyCode != 46 &&
      (e.keyCode < 35 || e.keyCode > 40) &&
      selectionValue < 1
    ) {
      e.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();
      setTimeout(() => {
        setCounter(e.target, isTextArea ? e.target.value : e.target.innerText);
      }, 10);
      return;
    }

    if (charLimit && qtdChar == charLimit && e.keyCode == 13) {
      e.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();
      setTimeout(() => {
        setCounter(e.target, isTextArea ? e.target.value : e.target.innerText);
      }, 10);
      return;
    }

    if (e.type === 'paste') {
      if (charLimit && qtdChar > charLimit) {
        const target = e.target;
        target.value = str.substr(0, charLimit);
        setTimeout(() => {
          setCounter(target, str);
        }, 10);
        return;
      }else if(!charLimit){
        const target = e.target;
        target.value = str.substr(0, charLimit);
        setTimeout(() => {
          setCounter(target, str);
        }, 10);
        return;
      }
    }

    const target = e.target;
    setTimeout(() => {
      setCounter(target, isTextArea ? target.value : target.innerText);
    }, 10);
  };

  var limitedElements = document.getElementsByClassName(className);

  Array.from(limitedElements).forEach(function (element) {
    element.addEventListener('keydown', limitLines);
    element.addEventListener('onchange', limitLines);
    // element.addEventListener('keyup', (e) => {
    //   if (e.keyCode == 17) ctrlDown = false;
    // });
    element.addEventListener('paste', limitLines);
  });
};
