import { axiosInstance } from 'core/utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import { IAPIResponse } from '../interfaces';
import { parseToApi } from '../parser';
import { defaultPostPayload as payload } from 'modules/shared/bidDefaultValues';
import * as Sentry from '@sentry/react';
import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

const pathUrl = '/BidSheet/Client';

export const getBidInfoById = (bidInfoId: string) => {
  return axiosInstance.get(`${pathUrl}/${bidInfoId}`);
};

export const getBlankPdfUrl = (bidUniqueId: string) => {
  return axiosInstance.get(`/BidSheet/GetBlankPdfUrl/${bidUniqueId}`);
};

export const postBidInfo = (values: any): Promise<IAPIResponse> => {
  return axiosInstance.post(`${pathUrl}`, values, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const postSetNotes = (values: any): Promise<IAPIResponse> => {
  return axiosInstance.post(`${pathUrl}/SetNotes`, values, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const postArchiveBid = (values: any): Promise<IAPIResponse> => {
  return axiosInstance.post(`${pathUrl}/SetArchived`, values, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getFranchiseById = (franchiseId: string) => {
  // http://anago-forms.api.staging.test.conceptsol.com/api/GetAllFranchises?FranchiseId=2085
  return axiosInstance.get(`/GetAllFranchises?FranchiseId=${franchiseId}`);
};

export const checkIsGeneratingPDF = (uniqueId: string) => {
  return axiosInstance.get(`/BidSheet/CheckIsGeneratingPDF?id=${uniqueId}`);
};

export const isAlive = () => {
  return axiosInstance.get(`/itsalive`);
};

export const isTabIdValid = (uid, tabId) => {
  return axiosInstance.get(
    `${pathUrl}/CheckIfIsValidForThisTab?BidUniqueID=${uid}&TabUniqID=${tabId}`
  );
};

export const getByUniqID = (uniqueID: string) => {
  return axiosInstance.get(`${pathUrl}/GetByUniqID/${uniqueID}`);
};

export const getSignNowDownloadLink = (uniqueID: string) => {
  return axiosInstance.get(`${pathUrl}/getdocumentlink/${uniqueID}`);
};

export const getEasterEggTemplate = () => {
  return axiosInstance.get(`${pathUrl}/GetEasterEgg`);
};

export const getEasterEggSSTemplate = () => {
  return axiosInstance.get(`${pathUrl}/GetEasterEggSS`);
};

export const getProtectionJson = () => {
  return axiosInstance.get(`${pathUrl}/GetProtectionJson`);
};

export const getNextPageBids = (
  type: String,
  userId: string,
  page: string,
  pid: string,
  isSpecialty: boolean,
  config: any
) => {
  const getNext = (uri) =>
    axiosInstance.get(
      `${pathUrl}/${uri}?id=${userId}&page=${page || '0'}&pid=${pid || ''}${
        isSpecialty ? '&isSS=true' : ''
      }`,
      config
    );

  switch (type) {
    case 'Signed':
      return getNext('GetSignedsByUser');
      break;
    case 'Sents':
      return getNext('GetSentsByUser');
      break;
    case 'Drafts':
      return getNext('GetDraftsByUser');
      break;
    case 'Templates':
      return getNext('GetTemplatesByUser');
      break;
    default:
      return getNext('GetBidsByUser');
      break;
  }
};

export const getBidsByUserId = (
  userId: string,
  page: string,
  pid: string,
  isSpecialty: boolean,
  config: any
) => {
  return axiosInstance.get(
    `${pathUrl}/GetBidsByUser?id=${userId}&page=${page || '0'}&pid=${
      pid || ''
    }${isSpecialty ? '&isSS=true' : ''}`,
    config
  );
};

export const getSetSigned = (formUniqueId: string): Promise<IAPIResponse> => {
  return axiosInstance.get(`${pathUrl}/SetSignedDocument/${formUniqueId}`);
};

export const setInvalidDocument = (
  formUniqueId: string
): Promise<IAPIResponse> => {
  return axiosInstance.get(
    `${pathUrl}/SetInvalidDocument?guid=${formUniqueId}`
  );
};

export const getSignNowStatus = (
  formUniqueId: string
): Promise<IAPIResponse> => {
  return axiosInstance.get(
    `${pathUrl}/CheckPendDocSpecificBid?guid=${formUniqueId}`
  );
};

export const postToApi = async ({ values, userId, showToastr = true }) => {
  const parsed = parseToApi(values);
  const data = {
    ...payload,
    ...parsed,
  };

  if (!data['BidUniqueID'] || !data['CreatedBy']) {
    data['CreatedBy'] = userId;
  }

  try {
    const response: IAPIResponse = await postBidInfo(data);
    console.debug(response.data);
    console.log(JSON.stringify(response.data));

    if (showToastr) {
      toast.info('Saved Successfully');
    }

    return {
      error: false,
      data: response.data,
    };
  } catch (e) {
    const msg =
      e && e.response && e.response.data && typeof e.response.data == 'string'
        ? e.response.data
        : undefined;
    Sentry.captureException(e);
    return {
      error:
        msg || 'There was an error while performing a request to the server',
      data: null,
      status: e.response.status,
    };
  }
};

/**
 * Conditions:
 * draft = sentStatus === false && isTemplate === false
 * template = sentStatus === false && isTemplate === true
 * sent = sentStatus = true
 * */
export const saveDraft = async (values, userId, isAutoSave = false) => {
  if (
    (values.uid || values.bidInfoId) &&
    (values.isTemplate || values.sentStatus) &&
    !isAutoSave
  ) {
    values.uid = null;
    values.isTemplate = false;
    values.isCityTemplate=false;
  }

  values.sentStatus = false;
  values.generateBlankPDF = false;

  return postToApi({ values, userId, showToastr: !isAutoSave });
};

export const saveTemplate = async (values, userId) => {
  if (values.uid && !values.isTemplate && !values.isAutoSave) {
    values.uid = null;
  }

  values.isTemplate = true;
  values.isAutoSave = false;
  values.sentStatus = false;

  return postToApi({ values, userId });
};

export const removeTemp = async (bidOriginalId) => {
  return axiosInstance.get(
    `${pathUrl}/removetemp?BidUniqueIDOriginal=${bidOriginalId}`
  );
};

export const createSignProvider = (userId) =>
  axiosInstance.get(`${pathUrl}/CreateSignProvider?id=${userId}`);
export const verifyUser = (userId) =>
  axiosInstance.get(`${pathUrl}/VerifyUser?id=${userId}`);

export const getPDFCall = async (bidInformationSheetId: string) => {
  const getURL = (id: string) => {
    const href = document.location.href;
    let str = env.REACT_APP_BACKEND_PORTAL_URL || 'http://anago.app.dev.test.conceptsol.com/';

    // if (href.includes('localhost') || href.includes('app-dev')) {
    //   str = 'http://anago.app.dev.test.conceptsol.com/PDF/Print/[id]/9';
    // } else if (href.includes('app-stag')) {
    //   str = 'http://anago.app.staging.test.conceptsol.com/PDF/Print/[id]/9';
    // } else if (href.includes('app-rc')) {
    //   str = 'http://anago.app.rc.test.conceptsol.com/PDF/Print/[id]/9';
    // } else {
    //   // production
    //   str = 'https://admin.anago.conceptsol.com/PDF/Print/[id]/9';
    // }

    str = str + 'PDF/Print/[id]/9'

    str = str.replace('[id]', id);

    return str;
  };

  const url = getURL(bidInformationSheetId);

  const result = await axiosInstance.get(url);
  return result.Value;
};

export const getPdfBase64 = async (bidId: string) => {
  var base64 = await axiosInstance.get(
    `${pathUrl}/GetPDFBase64?bidId=${bidId}`
  );
  return base64.data;
};

export const getNewPdfBase64 = async (bidUniqueId: string) => {
  var base64 = await axiosInstance.get(
    `${pathUrl}/GetSignedPDFBase64?biduniqueid=${bidUniqueId}`
  );
  return base64.data;
};

export const checkMaintenenceMode = async () => {
  return await await axiosInstance.get(`/CheckMaintenceMode`);
};

export const getEnvironmentUrl = () => {
  const href = document.location.href;
  
  let str = env.REACT_APP_BACKEND_PORTAL_URL || 'http://anago.app.dev.test.conceptsol.com/';

  // if (href.includes('localhost') || href.includes('app-dev')) {
  //   str = 'http://anago.app.dev.test.conceptsol.com/';
  // } else if (href.includes('app-stag')) {
  //   str = 'http://anago.app.staging.test.conceptsol.com/';
  // } else if (href.includes('app-rc')) {
  //   str = 'http://anago.app.rc.test.conceptsol.com/';
  // } else {
  //   // production
  //   str = 'https://admin.anago.conceptsol.com/';
  // }

  return str;
};

export const getAttachmentsPdf = (id: string) => {
  return `${getEnvironmentUrl()}Media/${id}.pdf`;
};

export const getInsurancePdf = (franchiseName: string) => {
  return `${getEnvironmentUrl()}Insurances/Insurance_${franchiseName}.pdf`;
};

export const verifyServerUnavailable = (userId: string) => {
  return axiosInstance.get(`/ServerMaintenance?userId=${userId}`);
};

export const getSystemAttachments = () => {
  return axiosInstance.get(`/Franchise/Client/getAllSystemAttachments`)
}

export const getFranchiseAttachments = (franchiseId: string) => {
  return axiosInstance.get(`/Franchise/getAllFranchiseAttachments?franchiseId=${franchiseId}`)
}