import React from 'react';

import { IEditLabel } from 'core/interfaces/Components';

import './styles.scss';

export const EditLabel = ({
  value,
  onChange,
  name,
  className = '',
  style = {},
  focus = false,
  onBlur = undefined,
  disabled = false,
}: IEditLabel) => (
  <input
    className={`edit-label-input ${className}`}
    name={`input_${name}`}
    id={`input_${name}`}
    value={value !== null && value !== undefined ? value : ''}
    onChange={e => onChange(name, e.target.value)}
    style={style}
    autoFocus={focus}
    onBlur={onBlur}
    disabled={disabled}
    autoCapitalize="words"
  />
);
