import React, { memo, useCallback } from 'react';
import { withFormik, FormikProps } from 'formik';
import { IBidJanitorial } from 'modules/BidInfoForm/interfaces';
import { janitorialValues } from 'modules/shared/bidDefaultValues';

import { ContentInput, InputFloatLabel, InputFloatLabelWithValidation, useFormProps } from 'core/components';
import { moneyMask, moneyMaskParams } from 'core/utils/masks';

import './styles.scss';

type IProps = FormikProps<IBidJanitorial>;
const JanitorialCompany = memo(({}: IProps) => {
  const { onChange, formValues } = useFormProps();
  const onBlur = useCallback(
    (name: string) => () => onChange(name, formValues[name]),
    [formValues, onChange]
  );

  return (
    <div className="janitorial-company-root">
      <div className="janitorial-company-content">
        <ContentInput width="100%" justify="flex-start">
          <h3 className="janitorial-company-title">
            Current Janitorial Company
          </h3>
          <div className="janitorial-info">(if Applicable)</div>
        </ContentInput>

        <ContentInput justify="flex-start" width="100%">
          <ContentInput padding flex minWidth="50%">
            <InputFloatLabel
              value={formValues.janitorialName}
              name="janitorialName"
              label="Name"
              onChange={onChange}
              onBlur={onBlur('janitorialName')}
              disabled={formValues.status === 2}
            />
          </ContentInput>
          <ContentInput padding flex>
            <InputFloatLabelWithValidation
              value={formValues.janitorialPrice}
              name="janitorialPrice"
              label="Current Price"
              type="tel"
              onChange={onChange}
              mask={moneyMask}
              maskParams={{
                ...moneyMaskParams, 
                thousandsSeparatorSymbol: true
              }}
              onBlur={(name, value)=>{
                onChange(name, value);
              }}
              disabled={formValues.status === 2}
            />
          </ContentInput>
          <ContentInput flex>
            <InputFloatLabel
              value={formValues.janitorialHours}
              name="janitorialHours"
              label="Hours"
              type="tel"
              onChange={(name: string, value: any) =>
                onChange(name, value.replace(/[^0-9]/, ''))
              }
              onBlur={onBlur('janitorialHours')}
              disabled={formValues.status === 2}
            />
          </ContentInput>
        </ContentInput>
      </div>
    </div>
  );
});

const mapForm = {
  mapPropsToValues: () => ({
    ...janitorialValues,
  }),
  handleSubmit: () => '',
};

const EnhancedJanitorial = withFormik<any, IBidJanitorial>(mapForm)(
  JanitorialCompany
);

export { EnhancedJanitorial as JanitorialCompany };
