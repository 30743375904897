export const decodeEntities = (str: string) => {
  if (!str || typeof str !== 'string') {
    return '';
  }

  const div = document.createElement('div');

  // Trick to decode the entities
  div.innerHTML = str;
  str = div.textContent;

  return str;
};
