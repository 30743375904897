import React from 'react';
import { Summary } from '../Summary';
import { CustomizeTerms } from 'modules/BidInfoForm/screens/CustomizeTerms';
import GenericModal from 'core/components/GenericModal';
import { IWarningModal } from 'modules/shared/bidInterfaces';

type TCallbackArgs = { [x: string]: any };

const fn = (x: TCallbackArgs) => undefined;

interface IModal {
  success: boolean;
  onBackButtonClick: (data: TCallbackArgs) => void;
  onSaveButtonClick: (data: TCallbackArgs) => void;
  onCustomizeTermsButtonClick: (data: TCallbackArgs) => void;
  setWarningModal(data: IWarningModal): void;
  showDeleteConfirmationModal(fn: Function): void;
  bidData;
  bidFullData;
  franchiseId?: Number;
  successModalData;
  isSpecialty?: boolean;
  franchiseData: any
}

export const ConfirmationModal = ({
  success = false,
  successModalData,
  bidData,
  franchiseId,
  bidFullData,
  isSpecialty,
  franchiseData,
  setWarningModal,
  showDeleteConfirmationModal,
  onBackButtonClick = fn,
  onSaveButtonClick = fn,
}: IModal) => {
  const summaryRef = React.useRef();
  const [showCustomizeTerms, setShowCustomizeTerms] = React.useState(false);

  const summaryHandlers = {
    onSaveButtonClick() {
      let data;

      if (summaryRef.current) {
        // @ts-ignore
        data = summaryRef.current.getData();
      }

      onSaveButtonClick(data);
    },

    onBackButtonClick() {
      let data;

      if (summaryRef.current) {
        // @ts-ignore
        data = summaryRef.current.getData();
      }

      onBackButtonClick(data);
    },

    onCustomizeTermsButtonClick() {
      setShowCustomizeTerms(true);
    },

    bidData,

    bidFullData,

    franchiseId,

    franchiseData,

    setWarningModal,

    showDeleteConfirmationModal
  };

  if (success) {
    const emails = successModalData.emails.length
      ? successModalData.emails.join(';')
      : '';
    if (isSpecialty) {
      successModalData.history.push(
        `/specialty-form/summary-success/${successModalData.formUniqueId}/${emails}`
      );
    } else {
      successModalData.history.push(
        `/bid-info-form/summary-success/${successModalData.formUniqueId}/${emails}`
      );
    }

    return <div />;
  }

  return (
    <>
      <div
        className="summary-modal"
        style={{ display: showCustomizeTerms ? 'block' : 'none' }}
      >
        <CustomizeTerms
          location={{ search: `?formId=${bidData.id}` }}
          uuid={bidData.id}
          showing={showCustomizeTerms}
          franchiseId={franchiseId}
          onSave={() => setShowCustomizeTerms(false)}
          onCancel={() => setShowCustomizeTerms(false)}
        />
      </div>

      <div
        className="summary-modal"
        style={{ display: showCustomizeTerms ? 'none' : 'block' }}
      >
        <GenericModal
          isOpen={true}
          showHeader={false}
          clickOutsideToClose={false}
          height="auto"
        >
          {!success && (
            <Summary
              ref={summaryRef}
              {...summaryHandlers}
              isSpecialty={isSpecialty}
            />
          )}
        </GenericModal>
      </div>
    </>
  );
};
