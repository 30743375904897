import React, { ReactElement } from 'react';

import './styles.scss';

type TRadioButtonRenderer = (item: any) => JSX.Element;

interface IRadioButton {
  value: any;
  name: string;
  label?: string;
  renderLabel?: Function;
  options: Array<any>;
  optionLabel: string;
  optionValue: string;
  onChange: Function;
  disabled?: boolean;
  contentStyle?: any;
  containerStyle?: any;
  itemStyle?: any;
  radioStyle?: any;
  render?: (
    item,
    renderButton: TRadioButtonRenderer,
    index: number,
    disabled: boolean
  ) => ReactElement;
}

export const RadioButton = ({
  value,
  name,
  label,
  renderLabel,
  options,
  optionLabel,
  optionValue,
  onChange,
  disabled,
  contentStyle,
  containerStyle,
  itemStyle,
  radioStyle,
  render,
}: IRadioButton) => {
  const renderButton = (item, index) => (
    <div
      data-cy={`radio-button-${index}`}
      className="radio-button-circle"
      onClick={!disabled ? () => onChange(name, item[optionValue]) : undefined}
      style={radioStyle}
    >
      {value === item[optionValue] && <div className="radio-button-selected" />}
    </div>
  );

  if (render && typeof render === 'function') {
    return options.map((item: any, index: number) =>
      render(item, renderButton, index, disabled)
    );
  }

  return (
    <div className="radio-button-container" style={containerStyle}>
      {renderLabel && renderLabel()}
      {label && <div className="radio-button-label">{label}</div>}

      <div
        className={`radio-button-content ${disabled ? 'disabled' : ''}`}
        style={contentStyle}
      >
        {options.map((item: any, index: number) => (
          <div
            key={`radio_button_${optionLabel}_${index}`}
            className="radio-button-item"
            style={itemStyle}
          >
            {renderButton(item, index)}
            {item[optionLabel]}
          </div>
        ))}
      </div>
    </div>
  );
};
