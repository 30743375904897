import { extractNumberFromMoneyMask } from "./formatInputs";

export const formatNumberByMask = (value: string, maskParams: any) => {
    if(!value){
        return value;
    }
    let formattedValue =
      maskParams && maskParams.prefix
        ? value.replace(maskParams.prefix, '')
        : value;
    formattedValue =
      maskParams && maskParams.decimalLimit
        ? Number(extractNumberFromMoneyMask(formattedValue)).toFixed(
            maskParams.decimalLimit
          )
        : formattedValue;

    if(!formattedValue){
        return value;
    }
    if (maskParams.thousandsSeparatorSymbol) {
      return formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return formattedValue;
    }
  };

  
export const formatNumberByMaskWithLimit = (value: string, maskParams: any) => {
  if(!value){
      return value;
  }
  let formattedValue =
    maskParams && maskParams.prefix
      ? value.replace(maskParams.prefix, '')
      : value;
  formattedValue =
    maskParams && maskParams.decimalLimit
      ? Number(extractNumberFromMoneyMask(formattedValue)).toFixed(
          maskParams.decimalLimit
        )
      : formattedValue;

  if(!formattedValue){
      return value;
  }
  if (maskParams.thousandsSeparatorSymbol) {
    return formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return formattedValue;
  }
};