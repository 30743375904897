import React,  { useEffect }  from 'react';
import EventEmitter from 'core/utils/events';

export const useInvalidCleaningSpecs = () => {
  const [invalidCleaningSpecs, setInvalidCleaningSpecs] = React.useState([]);
  const [subListsOpen, setSubListsOpen] = React.useState([]);

  const handle = ({invalidSpecs, subListOpen}) => {
    setInvalidCleaningSpecs(invalidSpecs);
    setSubListsOpen(subListOpen);
  } 

  useEffect(() => {
    EventEmitter.on('SET_INVALID_SPECS', handle)
    return () => {
      EventEmitter.removeListener('SET_INVALID_SPECS', handle);
    };
  }, []);


  return {invalidCleaningSpecs, subListsOpen}
}

export const setInvalidCleaningSpecs = (invalidSpecs, subListOpen) => {
  EventEmitter.emit('SET_INVALID_SPECS',{invalidSpecs, subListOpen});
}

 



  