import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ModalWrapper, ModalHeader, ModalBody, ModalBackdrop } from './styles';

type TModalArgs = {
  title?: string;
  isOpen: boolean;
  children: React.ReactNode;
  showHeader: boolean;
  onCloseRequest?: () => void;
  height?: string;
  width?: string;
  maxWidth?: string;
  fullscreen?: boolean;
  clickOutsideToClose?: boolean;
};

function preventBodyScroll() {
  // document.body.style.height = '100vh';
  // document.body.style.overflowY = 'hidden';
  // document.body.style.paddingRight = '15px';
}

function recoverBodyScroll() {
  // document.body.removeAttribute('style');
}

export default function ({
  isOpen = false,
  title,
  height,
  width,
  maxWidth,
  fullscreen,
  onCloseRequest = () => null,
  children,
  showHeader = true,
  showTitle = true,
  clickOutsideToClose = true,
  style = {},
}: TModalArgs) {
  if (!isOpen) {
    return <div />;
  }

  React.useEffect(() => {
    return () => {
      recoverBodyScroll();
    };
  }, []);

  preventBodyScroll();

  return (
    <ModalBackdrop
      className={'generic-modal-backdrop'}
      isOpen={isOpen}
      style={style}
      onClick={() => clickOutsideToClose && onCloseRequest()}
    >
      <ModalWrapper
        isOpen={isOpen}
        height={height}
        width={width}
        maxWidth={maxWidth}
        fullscreen={fullscreen}
        onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
      >
        {showHeader && (
          <ModalHeader>
            <h2>{title}</h2>
            <button onClick={() => onCloseRequest()}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </ModalHeader>
        )}
        <ModalBody isShowingHeader={showHeader}>{children}</ModalBody>
      </ModalWrapper>
    </ModalBackdrop>
  );
}
