import styled from 'styled-components';

export const ButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ButtonGroupRadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ButtonGroupLabel = styled.div`
  margin-right: 10px;
  font-size: 14px;
`;

export const ButtonGroupRadioLabel = styled.div`
  margin-right: 10px;
  font-size: 14px;
`;

interface IButtonGroupItem {
  lastItem: boolean;
  selected: boolean;
  index: number;
  disabled?: boolean;
  padding?: string;
  backgroundSelectedColor?: string;
}

export const ButtonGroupRadioItem = styled.button`
  &:before {
    content: '';
    display: inline-block;
    width: 30px;
    height: 30px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;

    ${({ selected, backgroundSelectedColor }: IButtonGroupItem) =>
      selected
        ? `background-color: ${
            backgroundSelectedColor
              ? `${backgroundSelectedColor};  border:1px solid ${backgroundSelectedColor}`
              : '#216eab'
          }; transition: 0.2s`
        : 'border: 1px solid silver;transition: 0.2s;'}

    margin-right: 15px;
  }

  ${({ selected, backgroundSelectedColor }: IButtonGroupItem) =>
    selected
      ? `color: ${backgroundSelectedColor ? backgroundSelectedColor : ''}`
      : ''}

  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  font-family: OpenSans-SemiBold;
  font-size: 15px;
`;

export const ButtonGroupItem = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }: IButtonGroupItem) => padding || '10px 18px'};
  border: 1px solid #9a9a9a;
  margin-right: 0;
  margin-left: 0;
  ${({ index }: IButtonGroupItem) =>
    index === 0 &&
    'border-top-left-radius: 6px; border-bottom-left-radius: 6px;'}
  ${({ lastItem }: IButtonGroupItem) =>
    lastItem &&
    'border-top-right-radius: 6px; border-bottom-right-radius: 6px;'}
  ${({ lastItem }: IButtonGroupItem) => !lastItem && 'border-right-width: 0;'}
  ${({ selected, backgroundSelectedColor }: IButtonGroupItem) =>
    selected
      ? `background-color: ${
          backgroundSelectedColor ? backgroundSelectedColor : '#216eab'
        }; color: #fff !important; transition: 0.2s`
      : 'background-color: #fff;transition: 0.2s;'}
      
  ${({ disabled }) => (disabled ? 'opacity: 0.7;' : '')}
  cursor: pointer;
  font-size: inherit;
  min-height: 42px;

  ${({ style }) => (style.height ? `height: ${style.height};` : '')}
`;
