export const validateEmail = (email: string)=>{
    if (
        !email ||
        !/^((([!#$%&'*+\-/=?^_`{|}~\w])|([!#$%&'*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~\.\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$/.test(
            email
        )
      ){
          return false;
      }

      return true;
}


export const validatePhone = (phone: string)=>{
    if(
        !phone ||
        !/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(
            phone
        )
    ){
        return false;
    }
    
    return true
}

export const validatePostalCode = (postalCode: string)=>{
    if(!postalCode){
        return false;
    }

    const firstLetter = postalCode.charAt(0);
    if (isNaN(parseInt(firstLetter))) {
        if(postalCode.length < 7){
            return false;
        }
    }else if(postalCode.length < 5){
        return false;
    }
    
    return true
}