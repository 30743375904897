import { IArea } from '../interfaces/Area';

const newArrayAreaItems = (arrayNames: string[]) =>
  arrayNames.map((name: string) => newAreaItem(name));

const newAreaItem = (name: string) => ({
  AreaItemId: 0,
  Name: name,
  Grade: 0,
  AreaId: 0
})

const LobbyRatingItems = () =>
  newArrayAreaItems(['Door Glass Frame', 'Hard Surface Floors', 'Vacuuming', 'Corners and Edges', 'High Dust', 'Low Dust', 'Furniture', 'Trash Receptacles', 'Blinds and Verticals', 'Outside Trash', 'Cigarette Urns'])

const OfficeRatingItems = () =>
  newArrayAreaItems(['Hard Surface Floors', 'Vacuuming', 'Partition Glass', 'Corners and Edges', 'Desk, File Cabinets', 'Phones', 'Trash Receptacles', 'High Dust', 'Low Dust', 'Water Fountains', 'Switch Plates, Door Frame', 'Spot Cleaning', 'HVAC Vents and Louvers', 'Blinds and Verticals'])

const RestroomRatingItems = () =>
  newArrayAreaItems(['Door, Plates, Frames', 'Mirrors', 'Fixtures', 'Sinks, Counters, and Fittings', 'Dispensers', 'Toilets and Toilet Seats', 'Urinals', 'High Dust', 'Low Dust', 'Partitions', 'Walls', 'Tile Floor', 'Corners and Edges', 'Main Drains', 'Supplies'])

const JanitorialClosetRatingItems = () =>
  newArrayAreaItems(['Appearance of Equipment', 'Orderly', 'Clean', 'Mop Buckets'])

const SecurityRatingItems = () =>
  newArrayAreaItems(['Designated Lights', 'Doors Locked', 'Alarm Set'])

const KitchenAndBreakAreaRatingItems = () =>
  newArrayAreaItems(['Tables and Chairs', 'Sinks and Counters', 'Cabinets', 'Kitchen Appliances', 'Coffee Stations', 'Hard Surface Floors', 'Vacuuming', 'Switch Plates', 'Water Fountains'])

const CafeteriaRatingItems = () =>
  newArrayAreaItems(['Trash Receptacles', 'Tables and Chairs', 'Sinks and Counters', 'Cabinets', 'Appliances', 'Coffee Stations', 'Hard Surface Floors', 'Vacuuming', 'Water Fountains'])

const GymFloorRatingItems = () =>
  newArrayAreaItems(['Drinking Fountains', 'Rubber Flooring', 'Mirrors', 'Televisions', 'Dispensers', 'Gym Equipment'])

const ChildCareAreaRatingItems = () =>
  newArrayAreaItems(['Trash Receptacles', 'Hard Surface Floors', 'Desks, Tables, and Counters', 'Vacuuming', 'Mirrors', 'Play Stations', 'Switch Plates'])

const ShowroomRatingItems = () =>
  newArrayAreaItems(['Trash Receptacles', 'Entrance and Partition Glass', 'Desks and File Cabinets', 'Furniture', 'Corners and Edges', 'Hard Surface Floors', 'Vacuuming', 'Blinds and Verticals', 'Switch Plates'])

const RestaurantBarAreaRatingItems = () =>
  newArrayAreaItems(['Trash Receptacles', 'Hard Surface Floors', 'Vacuuming', 'Entrance Glass', 'Outside Trash', 'Cigarette Urns', 'High Dust', 'Low Dust', 'Switch Plates', 'Water Fountains', 'Floor Mats', 'Balcony Patio'])

const SparAreaRatingItems = () =>
  newArrayAreaItems(['Trash Receptacles', 'Counters, Cabinets, and Ledges', 'Treatment Furniture', 'Sinks, Tubs, and Showers', 'Chrome Fittings', 'Vacuuming', 'Hard Surface Floors'])

const LockersSaunaRatingItems = () =>
  newArrayAreaItems(['Sauna Cleanliness', 'Shower Walls and Handrails', 'Entrance Glass', 'Hard Surface Floors', 'Bathtubs'])

const ClassroomRatingItems = () =>
  newArrayAreaItems(['Trash Receptacles', 'Tables and Chairs', 'Desks and Filing Cabinets', 'Sinks, Countertops, and Fittings', 'Vacuuming', 'Hard Surface Floors', 'Corners and Edges', 'Chalkboard/Whiteboard', 'High Dust', 'Low Dust', 'Entrance and Partition Glass', 'Water Fountains', 'Lockers', 'Switch Plates', 'Door Frames', 'Blinds and Verticals'])

const ExamAndLabRoomRatingItems = () =>
  newArrayAreaItems(['Trash Receptacles', 'Operatory Chairs', 'Tables and Chairs', 'Sinks and Counters', 'Cabinets', 'Shelves', 'Medical Equipment', 'Hard Floor Surfaces'])

const TerminalCleanRatingItems = () =>
  newArrayAreaItems(['Operating Table', 'Furniture', 'Walls', 'Surgery Lights', 'Medical Equipment', 'Equipment Wheels', 'Windowsills and Wall Mounts', 'Door Frame and Knobs', 'Sinks and Counters', 'Air Grills', 'Hard Floor Surfaces'])

const ConferenceRoomRatingItems = () =>
  newArrayAreaItems(['Trash Receptacles', 'Tables and Chairs', 'Furniture', 'Hard Surface Floors', 'Vacuuming'])

export const defaultAreaItems = [
  ...LobbyRatingItems(),
  ...JanitorialClosetRatingItems(),
  ...OfficeRatingItems(),
  ...RestroomRatingItems(),
]

export const getDefaultAreaItems = (name: string) => {
  switch(name && name.split(' (')[0]) {
    case 'Lobby Rating':
      return LobbyRatingItems();
    case 'Office Rating':
      return OfficeRatingItems();
    case 'Restroom Rating':
      return RestroomRatingItems();
    case 'Janitorial Closet Rating':
      return JanitorialClosetRatingItems();
    case 'Security Rating':
      return SecurityRatingItems();
    case 'Kitchen and Break Area Rating':
      return KitchenAndBreakAreaRatingItems();
    case 'Cafeteria Rating':
      return CafeteriaRatingItems();
    case 'Gym Floor Rating':
      return GymFloorRatingItems();
    case 'Child Care Area Rating':
      return ChildCareAreaRatingItems();
    case 'Showroom Rating':
      return ShowroomRatingItems();
    case 'Restaurant/Bar Area Rating':
      return RestaurantBarAreaRatingItems();
    case 'Spa Area Rating':
      return SparAreaRatingItems();
    case 'Lockers/Sauna Rating':
      return LockersSaunaRatingItems();
    case 'Classroom Rating':
      return ClassroomRatingItems();
    case 'Exam and Lab Room Rating':
      return ExamAndLabRoomRatingItems();
    case 'Terminal Clean Rating':
      return TerminalCleanRatingItems();
    case 'Conference Room Rating':
      return ConferenceRoomRatingItems();
    default:
      return [];
  }
}

const newArrayDefaultAreas = (arrayNames: any[]) =>
  arrayNames.map(({ name, areaItems }) => newDefaultArea(name, areaItems));

const newDefaultArea = (name: string, areaItems: any) => ({
  AreaId: 0,
  Name: name,
  Amount: '',
  RatingAverage: 0,
  RatingTotal: 0,
  Selected: false,
  DomainFloorTypeId: 0,
  Length: 0,
  Width: 0,
  TotalSqFt: 0,
  InspectionReportId: 0,
  AllowSelection: false,
  AreaItems: areaItems,
  AreaPhotos: []
})

const inspectionReportDefaultAreasItems = [{
  name: 'Lobby Rating',
  areaItems: LobbyRatingItems()
},
{
  name: 'Office Rating',
  areaItems: OfficeRatingItems()
},
{
  name: 'Restroom Rating',
  areaItems: RestroomRatingItems()
},
{
  name: 'Janitorial Closet Rating',
  areaItems: JanitorialClosetRatingItems()
}]

export const inspectionReportDefaultAreas: IArea[] = newArrayDefaultAreas([
  ...inspectionReportDefaultAreasItems
])

export const defaultAreas: IArea[] = newArrayDefaultAreas([
  ...inspectionReportDefaultAreasItems,
  {
    name: 'Security Rating',
    areaItems: SecurityRatingItems()
  },
  {
    name: 'Kitchen and Break Area Rating',
    areaItems: KitchenAndBreakAreaRatingItems()
  },
  {
    name: 'Cafeteria Rating',
    areaItems: CafeteriaRatingItems()
  },
  {
    name: 'Gym Floor Rating',
    areaItems: GymFloorRatingItems()
  },
  {
    name: 'Child Care Area Rating',
    areaItems: ChildCareAreaRatingItems()
  },
  {
    name: 'Showroom Rating',
    areaItems: ShowroomRatingItems()
  },
  {
    name: 'Restaurant/Bar Area Rating',
    areaItems: RestaurantBarAreaRatingItems()
  },
  {
    name: 'Spa Area Rating',
    areaItems: SparAreaRatingItems()
  },
  {
    name: 'Lockers/Sauna Rating',
    areaItems: LockersSaunaRatingItems()
  },
  {
    name: 'Classroom Rating',
    areaItems: ClassroomRatingItems()
  },
  {
    name: 'Exam and Lab Room Rating',
    areaItems: ExamAndLabRoomRatingItems()
  },
  {
    name: 'Terminal Clean Rating',
    areaItems: TerminalCleanRatingItems()
  },
  {
    name: 'Conference Room Rating',
    areaItems: ConferenceRoomRatingItems()
  }
])
