import { axiosInstance } from 'core/utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import { parseToApi } from '../parser';
import * as Sentry from '@sentry/react';

const pathUrl = '/Performance';

export const getPefById = (pefUiqueId: string) => {
  return axiosInstance.get(`${pathUrl}/GetByUniqID?guid=${pefUiqueId}`);
};

export const getUserPefById = (pefUiqueId: string) => {
  return axiosInstance.get(`${pathUrl}/Client/GetByUniqID?guid=${pefUiqueId}`);
};

export const generatePDFWithoutSave = (values: any): Promise<any> => {
  return axiosInstance.post(`${pathUrl}/GeneratePDFWithoutSave`, values, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};


export const postUserPef = (values: any): Promise<any> => {
  return axiosInstance.post(
    `${pathUrl}/Client/SavePerformanceEvaluationForm`,
    values,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};
export const postPef = (values: any): Promise<any> => {
  return axiosInstance.post(
    `${pathUrl}/SavePerformanceEvaluationForm`,
    values,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const uploadUserSignature = async (signatureBase64) => {
  const img = await fetch(signatureBase64).then((res) => res.blob());

  const formData = new FormData();
  formData.append('name', img);
  const res = await axiosInstance
    .post(`${pathUrl}/uploadSignature`, formData)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });
  return res;
};

export const uploadSignature = async (signatureBase64) => {
  const img = await fetch(signatureBase64).then((res) => res.blob());

  const formData = new FormData();
  formData.append('name', img);
  const res = await axiosInstance
    .post(`${pathUrl}/uploadSignature`, formData)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });
  return res;
};

export const getSignatureUri = async (id: number) => {
  const res = await axiosInstance
    .get(`${pathUrl}/getImageUri/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      Sentry.captureException(error);
      return error;
    });
  return res;
};

export const getFranchiseById = (franchiseId: string) => {
  // http://anago-forms.api.staging.test.conceptsol.com/api/GetAllFranchises?FranchiseId=2085
  return axiosInstance.get(`/GetAllFranchises?FranchiseId=${franchiseId}`);
};

export const getNextPagePerformance = (
  type: String,
  userId: string,
  page: string,
  pid: string,
  config: any = {}
) => {
  const getNext = (uri) =>
    axiosInstance.get(
      `${pathUrl}/${uri}?id=${userId}&page=${page || '0'}&pid=${pid || ''}`,
      config
    );

  switch (type) {
    case 'Signed':
      return getNext('GetSignedsByUser');
      break;
    case 'Sents':
      return getNext('GetSentsByUser');
      break;
    case 'Drafts':
      return getNext('GetDraftsByUser');
      break;
    case 'Templates':
      return getNext('GetTemplatesByUser');
      break;
    default:
      return getNext('GetPEFByUser');
      break;
  }
};

export const getPerformancesByUserId = (
  userId: string,
  page: number,
  pid: string,
  config: any
) => {
  return axiosInstance.get(
    `${pathUrl}/GetPEFByUser?id=${userId}&page=${page || '0'}&pid=${pid || ''}`,
    config
  );
};

export const getUserIp = () => {
  return axiosInstance.get(`${pathUrl}/Client/GetUserIP`);
};
