import styled from 'styled-components';
import { H1BoldItalic } from '../../../../../styles';
import { Paragraph as ParagraphBase } from '../../../../../components/atoms';

export const Heading = styled(H1BoldItalic)`
  line-height: 6vw;
  margin-bottom: 20px;

  @media (min-width: 769px) {
    font-size: 24px;
    line-height: 28px;
    text-align: left;
    margin-bottom: 10px;
  }
`;

export const Wrapper = styled.div`
  margin-top: 15px;
`;

export const Content = styled.div``;

export const Clipper = styled.div<{ opened?: boolean }>`
  position: relative;
  max-height: 70px;
  overflow: hidden;
  transition: max-height 0.5s ease-in;

  ${({ opened }) => (opened ? 'max-height: 1000px;' : '')}

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    bottom: 0;
    height: 40px;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 35%,
      rgba(255, 255, 255, 0) 100%
    );

    @media (min-width: 768px) {
      height: 65px;
    }
  }
`;

export const Control = styled.div`
  display: flex;
  justify-content: center;

  button {
    cursor: pointer;
  }
`;

export const Paragraph = styled(ParagraphBase)`
  font-size: 10px;
  line-height: 15px;
  padding-bottom: 50px;

  @media (min-width: 769px) {
    font-size: 14px;
    line-height: 18px;
  }
`;
