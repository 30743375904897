import styled from 'styled-components';

// export const Cell = styled.div`
//   display: flex;
//   flex-direction: column;
// `;

export const TextInputWrapper = styled.div`
  width: 70%;
  margin: 0 auto;

  .div-float-label {
    bottom: unset;
  }

  input {
    padding-left: 0;
    height: 40px;
    margin-top: 0;

    &:placeholder-shown:not(:focus)::placeholder {
      opacity: 1 !important;
    }
  }
`;

export const CentralizedSection = styled.div`
  width: 100%;
  display: flex;
  padding: 0px 0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 768px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;

  button {
    padding: 0;
    width: 58px;
    justify-content: center;
  }
`;

export const RadioItem = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  opacity: ${({ disabled = false }) => (disabled ? '0.7' : '1')};
`;

export const Holder = styled.div`
  display: flex;
  height: 42px;
  align-items: center;

  ${RadioItem} {
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
`;

export const QuoteLabel = styled.label`
  font-size: 14px;
  color: black;
  font-weight: bold;
  padding-left: 0;
`;

export const Label = styled.div`
  margin: 0 10px 5px 0;
  font-size: 12px;
`;

export const LabelPlus = styled.div`
  font-size: 16px;
`;
export const Button = styled.button`
  font-size: 16px;
  font-family: 'OpenSans-Regular';

  &:disabled {
    opacity: 0.7;
  }
`;
