import styled from 'styled-components';


export const EditButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 16px;
  background-color: #216eab;
  color: white;
  font-family: OpenSans-Regular;
  padding: 8px 15px 9px 15px;
  height: 40px;
  border-radius: 6px;
  margin-top: 1px;
  margin-left: 10px;
  border-width: 1px;
`;
