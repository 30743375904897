import styled, { css } from 'styled-components';
import { StyledButton, Content as BtnContent } from 'core/components/Button';

const HeadingBase = css`
  color: #3f3f3f;
  text-transform: unset;
  font-style: normal;
  font-family: 'OpenSans-Bold';
  text-align: center;
  margin-bottom: 15px;

  @media (min-width: 767px) {
    text-align: left;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  font-size: 14px;
`;

interface IContent {
  bgColor?: string;
  padding?: string;
}
export const Content = styled.div<IContent>`
  padding: ${({ padding = '20px' }) => padding};
  background-color: ${({ bgColor = '#fff' }) => bgColor};
`;

export const Footer = styled(Content)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 35px;

  > div {
    display: flex;
    flex-direction: column;
  }

  button {
    margin-bottom: 10px;
  }

  @media (min-width: 767px) {
    flex-direction: row;

    button {
      margin-bottom: 0;
    }

    button:not(:last-of-type) {
      margin-right: 20px;
    }

    > div {
      flex-direction: row;
    }
  }
`;

interface IHeading {
  size?: string;
  italic?: boolean;
}
export const Heading = styled.h2<IHeading>`
  ${HeadingBase}

  font-size: ${({ size = '36px' }) => size};

  span:last-of-type {
    margin-left: 10px;
    font-weight: normal;
    font-family: OpenSans-Regular;
  }

  font-family: ${({ italic = false }) =>
    italic ? 'OpenSans-BoldItalic' : 'OpenSans-Bold'};
`;

interface IText {
  size?: string;
}
export const Text = styled.p<IText>`
  color: #3f3f3f;
  font-size: ${({ size = '16px' }) => size};

  small {
    font-size: 12px;
  }
`;

interface IHolder {
  margin?: string;
}
export const Holder = styled.div<IHolder>`
  margin: ${({ margin = '0px' }) => margin};
`;

export const IconHolder = styled.span`
  margin-left: 10px;
`;

export const TooltipContent = styled.div`
  width: 200px;
`;

export const Header = styled.header`
  text-align: center;
  background-color: #216eab;
  padding: 35px 30px 30px;

  ${Heading}, ${Text} {
    color: #fff;
  }

  ${Heading} {
    text-align: center !important;

    &:not(:last-of-type) {
      margin-bottom: 26px;
    }

    &:last-of-type {
      margin-bottom: 10px;
    }
  }
`;

export const HeaderTop = styled.div``;

export const HeaderBottom = styled.div`
  ${Text} {
    &:first-of-type {
      margin-top: 0;
    }
  }
`;

export const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #e6e6e6;
  padding: 5px 25px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: center;
`;

interface ICell {
  width?: string;
  textAlign?: string;
  label?: string;
}

export const Cell = styled.div<ICell>`
  padding: 5px;
  box-sizing: border-box;
  min-width: ${({ width = '130px' }) => width};
  text-align: ${({ textAlign = 'center' }) => textAlign};
  position: relative;

  ${({ label }) =>
    label
      ? `
    padding-top: 30px;
    &:before {
      height: 30px;
      width: 100%;
      position: absolute;
      content: '${label}';
      top: 4px;
      left: 0;
      font-weight: bold;
    }
  `
      : ''}

  @media (min-width: 767px) {
    padding-top: 0;
    &:before {
      content: none;
    }
  }
`;

export const CellHeader = styled(Cell)`
  font-weight: bold;
  display: none;
  /* padding: 5px 25px; */

  @media (min-width: 767px) {
    display: block;
  }
`;

export const Row = styled.div<{ wrap?: string }>`
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: ${({ wrap = 'unset' }) => wrap};

  @media (min-width: 767px) {
    flex-direction: row;

    ${({ wrap = 'unset' }) =>
      wrap === 'unset'
        ? `
     ${Cell} {
      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }
    `
        : ''}
  }
`;

export const RowHolder = styled.div`
  margin-bottom: 20px;

  @media (min-width: 767px) {
    margin-bottom: 0;
  }
`;

export const RadioItem = styled.div`
  display: flex;
  align-items: center;

  .radio-button-circle {
    margin-right: 10px;
  }
`;

export const ButtonContent = BtnContent;

export const Button = styled(StyledButton)`
  width: 100%;

  svg {
    margin-right: 10px;
  }

  @media (max-width: 900px) {
    width: auto;
  }

  @media (min-width: 767px) {
    width: auto;
  }
`;

export const ButtonLabel = styled.span`
  font-size: 16px;
`;

export const InputText = styled.input`
  background: transparent;
  border: 0 none;
  padding: 0;
  height: 36px;
  font-size: 14px;
  font-family: 'OpenSans-Regular';
  font-style: italic;
  color: #515151;
  text-transform: none;
`;

export const InputLabel = styled.span`
  color: #343434;
`;

export const CheckboxHolder = styled.div`
  display: flex;
`;

export const QuoteOptionWrapper = styled.div`
  border-radius: 5px;
  padding: 20px;

  ${({ unselected }) =>
    !!unselected
      ? `
  `
      : ''};
`;

export const QuoteTitleWrapper = styled.div`
  font-family: OpenSans-Bold;
  border-bottom: 1px solid gray;
  margin-bottom: 10px;
`;

export const QuoteOptionRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid gray;
  color: #515151;
  padding-bottom: 20px;
`;

export const QuoteOptionBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 20%;
  color: #515151;
`;

export const QuoteOptionValue = styled.div`
  background: #e6e6e6;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3f3f3f;
  margin-top: 5px;
  flex-direction: column;
  position: relative;
  border: 1px solid gray;
`;

export const ItemizedListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
`;
export const ItemizedItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 75%;
  color: white;
  padding-bottom: 5px;
  color: #515151;
`;

export const ItemizedItemRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:nth-child(n + 3) {
    margin-top: 20px;
  }
`;

export const ItemizedItem = styled.div`
  background: #e6e6e6;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  color: #515151;
  border-radius: 10px;
  width: 75%;
  font-family: 'OpenSans-SemiBold';
  border: 1px solid gray;
`;

export const ProtectionGuidanceLabel = styled.label`
  color: #515151;
  font-size: 10px;
  margin-top: 10px;
  margin-bottom: -20px;
`;

export const ProtectedOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ProtectionItemWrapper = styled.div`
  background: #e6e6e6;
  border-radius: 10px;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  width: 77.5%;
  border: 1px solid gray;
  position: relative;
`;

export const ProtectionOption = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuoteOptionTotalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${QuoteOptionValue} {
    background: white;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
  }
`;

export const QuoteOptiontotalLabel = styled.div`
  width: 77.5%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #515151;
  font-family: 'OpenSans-Bold';
  font-size: 18px;
`;

export const ProtectionPlans = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 70px;
  margin-right: 20px;
  width: 100%;

  & label {
    font-family: 'OpenSans-Bold';
    font-size: 14px;
    color: #515151;
    padding-left: 0px;
  }
`;

export const ProtectionPlanWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
`;

export const ProtectionPlanValue = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44%;
  padding: 10px;
  font-family: 'OpenSans-SemiBold';
  color: white;
  font-size: 14px;
  text-align: center;
`;

export const ProtectionRow = styled.div`
  display: flex;
  flex-directon: row;
  justify-content: space-between;
`;

export const QuoteOptionDisinfection = styled.span`
  color: #f7a11d;
  font-size: 11px;
  margin-top: -30px;
  text-align: center;
  padding-bottom: 6.5px;
  position: absolute;
  bottom: 0;
`;

export const ProtectionMonthlyServiceQuoteOptionsWrapper = styled.div`
  position: relative;
`;
