import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  .radio-button-item {
    margin-bottom: 20px;

    strong {
      font-style: italic;
    }
  }
`;

export const AccountTypeHolder = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    flex: 1;
  }

  @media (min-width: 769px) {
    flex-direction: row;
  }
`;

export const ImageHolder = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  img {
    display: block;
    height: 160px;
  }
`;

export const AccountTypeHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media (min-width: 769px) {
    flex-direction: row;
    margin-bottom: 10px;

    .radio-button-container {
      margin-left: 20px;
      margin-top: 5px;
    }

    .radio-button-content {
      flex-direction: row;
    }

    .radio-button-item {
      margin-bottom: 0;

      &:first-of-type {
        margin-right: 40px;
      }
    }
  }
`;
