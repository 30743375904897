import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ContentInput, Checkbox } from 'core/components';
import { formatNumberByMask } from 'core/utils';
import { moneyMaskParams } from 'core/utils/masks';
import { TermsAndConditions } from '../components/organisms';

import {
  Layout,
  ResponsiveLayout,
  ResponsiveBoxes,
  OrangeSection,
  WhiteSection,
  RoundSubSection,
  SubSectionHeader,
  SubSection,
  GraySection,
} from '../../../components/layout';
import { Paragraph, Input } from '../../../components/atoms';

import {
  H1BoldItalic,
  H3Bold,
  BannerServicesOfferingsPage3,
  HexagonItem,
  ServicesText,
  SectionTitle,
  IntroductionTitle,
  H3SemiBold,
  HeaderBase,
  SpecialtyServiceDescription,
  CheckBoxHolder,
  DisclaimerParagraph,
  H3RegularItalic,
} from '../../../styles';

const renderBoxes = (
  frequency: any,
  active: boolean,
  isHeader: boolean = false,
  biggerThan768: boolean = false,
  salesTax: boolean = false
) => {
  const bgColor = active ? '#216eab' : '#efefef';
  const color = active ? '#fff' : null;
  return (
    <Layout
      padding="15px"
      paddingLeft="20px"
      paddingRight="20px"
      margin="0"
      width="100%"
      style={{
        backgroundColor: bgColor,
        border: !active ? '1px solid gray' : '1px solid #216eab',
        borderRadius: '5px',
        color: color,
      }}
    >
      <ContentInput
        paddingTop="0"
        paddingLeft="0"
        flex
        flexDirection="row"
        justify="space-between"
      >
        <HeaderBase
          fontSize={'14px'}
          padding="5px"
          textAlign="left"
          paddingLeft="0"
          marginLeft="0"
          color={color}
          data-cy={`specialty-service-header-${frequency.index}`}
        >
          {frequency.value}
        </HeaderBase>
        {biggerThan768 && (
          <HeaderBase
            fontSize="14px"
            padding="5px"
            marginRight="-2px !important"
            textAlign="right"
            color={color}
            width={'48%'}
            data-cy={`specialty-service-input-${frequency.index}`}
          >
            {!frequency.uponRequest &&
              !frequency.unit &&
              `${frequency.oneTimePrice}${
                frequency.perHour
                  ? ' /hr.'
                  : frequency.perMonth
                  ? ' /mo.'
                  : ' /srvc.'
              }`}
            {frequency.uponRequest &&
              (biggerThan768 ? 'Price Available Upon Request' : 'Upon Request')}
            {frequency.unit &&
              `$${formatNumberByMask(
                frequency.unitPrice.toString(),
                {
                  ...moneyMaskParams,
                  thousandsSeparatorSymbol: ',',
                }
              )} per ${frequency.unitLabel}`}
          </HeaderBase>
        )}
      </ContentInput>
      <hr
        style={{
          width: '100%',
          borderColor: 'white',
          marginTop: '-5px',
        }}
      />
      {!biggerThan768 && (
        <ContentInput paddingTop="0">
          <H3Bold
            maxFontSize="14px"
            padding="5px"
            textAlign="left"
            color={color}
            data-cy={`specialty-service-price-header-${frequency.index}`}
            marginLeft="0"
            paddingLeft="0"
          >
            Price{' '}
            <small>
              {salesTax && <sup>†</sup>}
              <sup>‡</sup>
            </small>
          </H3Bold>
          <HeaderBase
            fontSize="17px"
            padding="5px"
            textAlign="left"
            color={color}
            marginLeft="0"
            paddingLeft="0"
            data-cy={`specialty-service-price-input-${frequency.index}`}
          >
            {!frequency.uponRequest &&
              !frequency.unit &&
              `${frequency.oneTimePrice}${
                frequency.perHour
                  ? ' /hr.'
                  : frequency.perMonth
                  ? ' /mo.'
                  : ' /srvc.'
              }`}
            {frequency.uponRequest &&
              (biggerThan768 ? 'Price Available Upon Request' : 'Upon Request')}
            {frequency.unit &&
              `$${formatNumberByMask(
                frequency.unitPrice.toString(),
                {
                  ...moneyMaskParams,
                  thousandsSeparatorSymbol: ',',
                }
              )} per ${frequency.unitLabel}`}
          </HeaderBase>
        </ContentInput>
      )}
      {frequency.serviceDescription && (
        <ContentInput paddingTop="0">
          <H3RegularItalic
            maxFontSize="13.5px"
            padding="5px"
            textAlign="left"
            color={color}
            marginLeft="0"
            paddingLeft="0"
            marginTop="-8px"
            data-cy={`specialty-service-description-header-${frequency.index}`}
          >
            Service Description:
          </H3RegularItalic>
          <SpecialtyServiceDescription
            fontSize="13px"
            padding="5px"
            marginTop="-10px"
            marginLeft="0"
            textAlign="left"
            paddingLeft="0"
            color={active ? 'white' : 'black'}
            data-cy={`specialty-service-description-input-${frequency.index}`}
            style={{
              whiteSpace: 'pre-line',
            }}
            dangerouslySetInnerHTML={{ __html: frequency.serviceDescription }}
          />
        </ContentInput>
      )}
    </Layout>
    // <ResponsiveLayout
    //   width="100%"
    //   paddingLeft="20px"
    //   marginBottom="0"
    //   paddingTop={biggerThan768 ? '0' : '30px'}
    // >
    //   <ResponsiveBoxes
    //     padding="0 10px 0 10px"
    //     minWidth="140px"
    //     width="80%"
    //     boxSizing="border-box"
    //     marginBottom="20px"
    //   >
    //     {isHeader && (
    //       <H3SemiBold color="#fff" maxFontSize="16px" padding="5px">
    //         Service Type
    //       </H3SemiBold>
    //     )}
    //     <Input
    //       defaultValue={frequency.value}
    //       backgroundColor={bgColor}
    //       color={color}
    //     />
    //   </ResponsiveBoxes>

    //   <ResponsiveBoxes
    //     padding="0 10px 0 10px"
    //     minWidth="200px"
    //     width="20%"
    //     boxSizing="border-box"
    //     marginBottom="20px"
    //   >
    //     {isHeader && (
    //       <H3SemiBold color="#fff" maxFontSize="16px" padding="5px">
    //         Price <small>‡</small>
    //       </H3SemiBold>
    //     )}
    //     <Input
    //       defaultValue={`${frequency.oneTimePrice}${
    //         frequency.perHour ? ' p/hr.' : ''
    //       }`}
    //       backgroundColor={bgColor}
    //       color={color}
    //     />
    //   </ResponsiveBoxes>
    // </ResponsiveLayout>
  );
};

const Page2 = (props, ref) => {
  const {
    data,
    parsedData,
    franchiseData,
    updateData,
    selectedValues,
    selectedItem,
  } = props;
  const values = data || {};
  const franchiseValues = franchiseData || [];

  const [equalsOrBiggerThan768, setEqualsOrBiggerThan768] = React.useState(
    false
  );

  const [equals768, setEqual768] = React.useState(false);
  const [biggerThan796, setBiggerThan796] = React.useState(false);
  const [biggerThan768, setBiggerThan768] = React.useState(false);
  const [minorThan768, setMinorThan768] = React.useState(false);
  const [minorThan450, setMinorThan450] = React.useState(false);

  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const setBreakpoints = () => {
    setEqualsOrBiggerThan768(window.innerWidth >= 768);
    setBiggerThan796(window.innerWidth >= 796);
    setBiggerThan768(window.innerWidth >= 769);
    setMinorThan768(window.innerWidth < 768);
    setMinorThan450(window.innerWidth < 450);
    setEqual768(window.innerWidth == 768);
  };

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  const biggerThan830 = useMediaQuery({
    query: '(min-device-width: 830px)',
  });

  const [additionalQuotes, setAdditionalQuotes] = useState(
    values['AdditionalQuotes']
      ? values['AdditionalQuotes']
          .filter((i) => i['IsSelected'] == true)
          .map((item) => item['BidInformationAdditionalQuotesId'])
      : []
  );

  const [dayPorter, setDayPorter] = React.useState(false);

  const hasProtectionPackages =
    parsedData &&
    (parsedData.protectionPackages.length > 0 ||
      parsedData.secondProtectionPackages.length > 0);

  // const [dayPorter, setDayPorter] = React.useState(
  //   values['IncludeDayPorterInPrice'] &&
  //     (values['CostOfDayPorter'] > 0 || values['DayPorterPriceOverride'] > 0) &&
  //     !hasProtectionPackages
  // );

  useEffect(() => {
    if (
      values['IncludeDayPorterInPrice'] &&
      (values['CostOfDayPorter'] > 0 || values['DayPorterPriceOverride'] > 0)
    ) {
      setDayPorter(true);
    } else {
      setDayPorter(false);
    }
  }, [values, hasProtectionPackages]);

  useEffect(() => {
    // if (!additionalQuotes || additionalQuotes.length === 0) {
    //   setAdditionalQuotes(
    //     values['AdditionalQuotes']
    //       ? values['AdditionalQuotes']
    //           .filter(i => i['IsSelected'] == true || i['IsSelected'] == 'true')
    //           .map(item => item['BidInformationAdditionalQuotesId'])
    //       : []
    //   );
    // }
  }, [values, data, additionalQuotes]);

  const [isDirty, setIsDirty] = useState(false);

  const onCheckboxClick = (item, name, value, isChecked: boolean) => {
    let arr = [];
    if (
      isChecked &&
      additionalQuotes.includes(item['BidInformationAdditionalQuotesId']) !=
        true
    ) {
      arr = [...additionalQuotes, value];
      setAdditionalQuotes(arr);
      setIsDirty(true);
    } else {
      arr = [...additionalQuotes];
      arr.splice(arr.indexOf(value), 1);
      setAdditionalQuotes(arr);
      setIsDirty(true);
    }
    // updateData("AdditionalQuotes", arr);
  };

  React.useImperativeHandle(ref, () => ({
    getData: () => ({ additionalQuotes, isDirty, data, dayPorter }),
    clearIsDirty: () => {
      setIsDirty(false);
    },
  }));

  const getDaysPorterDays = () => {
    let ret = '';
    if (values['DaysPotterMonday']) {
      ret += 'Monday, ';
    }
    if (values['DaysPotterTuesday']) {
      ret += 'Tuesday, ';
    }
    if (values['DaysPotterWednesday']) {
      ret += 'Wednesday, ';
    }
    if (values['DaysPotterThursday']) {
      ret += 'Thursday, ';
    }
    if (values['DaysPotterFriday']) {
      ret += 'Friday, ';
    }
    if (values['DaysPotterSaturday']) {
      ret += 'Saturday, ';
    }
    if (values['DaysPotterSunday']) {
      ret += 'Sunday, ';
    }
    return ret.substr(0, ret.length - 2);
  };

  const hasAdditionalQuotes =
    values['AdditionalQuotes'] &&
    values['AdditionalQuotes'].length > 0 &&
    values['AdditionalQuotes'].filter(
      (i) =>
        i['Total'] > 0 ||
        i['TotalOverride'] > 0 ||
        i['UponRequest'] ||
        i['UnitPrice'] > 0
    ).length > 0;

  return (
    <Layout margin="0">
      {!values['IsSS'] && (
        <WhiteSection padding="40px 0 0 0">
          <IntroductionTitle maxFontSize="30px" textAlign="center">
            Specialty Services
          </IntroductionTitle>

          <Paragraph
            textAlign="center"
            fontSize={equalsOrBiggerThan768 ? '14px' : '12px'}
            lineHeight="18px"
            fontWeight="normal"
          >
            Below are some of the many services our franchisees offer in
            addition to regular janitorial maintenance
          </Paragraph>
        </WhiteSection>
      )}
      <div>
        <BannerServicesOfferingsPage3>
          <H1BoldItalic
            fontSize="22px"
            textAlign="left"
            color="#fff"
            lineHeight={biggerThan768 ? '40px' : 'inherit'}
          >
            WE OFFER THE FOLLOWING SERVICES{' '}
            <span style={{ whiteSpace: 'nowrap' }}>AND MANY MORE</span>
          </H1BoldItalic>
        </BannerServicesOfferingsPage3>
      </div>
      <Layout
        width="100%"
        backgroundColor="white"
        paddingBottom="0"
        justifyContent="start"
      >
        <OrangeSection
          maxWidth={equalsOrBiggerThan768 ? '1440px' : 'auto'}
          margin="auto !important"
          width="100%"
        >
          <SubSection maxWidth={equalsOrBiggerThan768 ? '900px' : 'auto'}>
            <ResponsiveLayout
              style={{
                display: 'flex',
                flexDirection: equalsOrBiggerThan768 ? 'row' : 'column',
                flexWrap: equalsOrBiggerThan768 ? 'wrap' : 'no-wrap',
              }}
              paddingTop="20px"
            >
              <ContentInput
                width={equalsOrBiggerThan768 ? '45%' : '100%'}
                minWidth={equalsOrBiggerThan768 ? '45%' : '100%'}
                style={{
                  marginLeft: '0',
                  paddingRight: equalsOrBiggerThan768 ? '0px' : '5px',
                }}
                paddingTop="0"
                minHeight="160px"
                flex
              >
                <div>
                  <HexagonItem type="1" />
                </div>

                <ContentInput paddingTop="2px" marginLeft="-6px">
                  <ServicesText>
                    <H3Bold>High-Touch Disinfection</H3Bold>
                    <Paragraph
                      textAlign="justify"
                      style={{
                        fontSize: biggerThan768 ? '14px' : '12px',
                        width: equals768 ? 'calc(100% - 5px)' : '100%',
                      }}
                    >
                      Anago’s franchise owners offer disinfection application
                      for high-frequency touch areas such as doorknobs, light
                      switches, elevator buttons, phones, and keyboards.
                    </Paragraph>
                  </ServicesText>
                </ContentInput>
              </ContentInput>
              <ContentInput
                width={equalsOrBiggerThan768 ? '45%' : '100%'}
                minWidth={equalsOrBiggerThan768 ? '45%' : '100%'}
                style={{
                  marginLeft: '-5px',
                }}
                minHeight="160px"
                flex
                paddingTop="0"
              >
                <div>
                  <HexagonItem type="2" />
                </div>
                <ContentInput paddingTop="2px" marginLeft="-6px">
                  <ServicesText
                    style={{
                      paddingRight: equalsOrBiggerThan768 ? '12px' : '0px',
                    }}
                  >
                    <H3Bold>Electrostatic Disinfection</H3Bold>
                    <Paragraph
                      textAlign="justify"
                      style={{ fontSize: biggerThan768 ? '14px' : '12px' }}
                    >
                      Our franchisees use hospital-grade, EPA approved cleaning
                      products and a CDC compliant disinfection spray process to
                      disinfect up to 99.9% of your workspace.
                    </Paragraph>
                  </ServicesText>
                </ContentInput>
              </ContentInput>
              <ContentInput
                width={equalsOrBiggerThan768 ? '45%' : '100%'}
                minWidth={equalsOrBiggerThan768 ? '45%' : '100%'}
                style={{
                  marginLeft: '0',
                  paddingRight: equalsOrBiggerThan768 ? '0px' : '5px',
                }}
                paddingTop="0"
                minHeight="160px"
                flex
              >
                <div>
                  <HexagonItem type="3" />
                </div>
                <ContentInput paddingTop="2px" marginLeft="-6px">
                  <ServicesText>
                    <H3Bold>Comprehensive Detail Cleaning</H3Bold>
                    <Paragraph
                      textAlign="justify"
                      style={{
                        fontSize: biggerThan768 ? '14px' : '12px',
                        width: equals768 ? 'calc(100% - 5px)' : '100%',
                      }}
                    >
                      Whether it’s one specific area or your entire office that
                      needs a thorough, in-depth cleaning, Anago’s franchisees
                      can be there to get it done.
                    </Paragraph>
                  </ServicesText>
                </ContentInput>
              </ContentInput>

              <ContentInput
                width={equalsOrBiggerThan768 ? '45%' : '100%'}
                minWidth={equalsOrBiggerThan768 ? '45%' : '100%'}
                style={{
                  marginLeft: '-5px',
                }}
                minHeight="160px"
                paddingTop="0"
                flex
              >
                <div>
                  <HexagonItem type="4" />
                </div>
                <ContentInput paddingTop="2px" marginLeft="-6px">
                  <ServicesText
                    style={{
                      paddingRight: equalsOrBiggerThan768 ? '12px' : '0px',
                    }}
                  >
                    <H3Bold paddingRight="11px" marginTop="-1px">
                      Ceramic Tile & Grout Cleaning
                    </H3Bold>
                    <Paragraph
                      textAlign="justify"
                      style={{ fontSize: biggerThan768 ? '14px' : '12px' }}
                    >
                      Our franchise owners are versed in the latest methods such
                      as machine scrubbing and acid washing, for bringing back
                      the luster to your ceramic tile and grout.
                    </Paragraph>
                  </ServicesText>
                </ContentInput>
              </ContentInput>

              <ContentInput
                width={equalsOrBiggerThan768 ? '45%' : '100%'}
                minWidth={equalsOrBiggerThan768 ? '45%' : '100%'}
                style={{
                  marginLeft: '0',
                  paddingRight: equalsOrBiggerThan768 ? '0px' : '5px',
                }}
                minHeight="160px"
                paddingTop="0"
                flex
              >
                <div>
                  <HexagonItem type="5" />
                </div>
                <ContentInput paddingTop="2px" marginLeft="-6px">
                  <ServicesText>
                    <H3Bold>Hard Surface Floor Care</H3Bold>
                    <Paragraph
                      textAlign="justify"
                      style={{
                        fontSize: biggerThan768 ? '14px' : '12px',
                        width: equals768 ? 'calc(100% - 5px)' : '100%',
                      }}
                    >
                      Our franchisees offer cleaning solutions for all types of
                      hard surface flooring, such as vinyl composite tile,
                      linoleum, concrete, and marble floors.
                    </Paragraph>
                  </ServicesText>
                </ContentInput>
              </ContentInput>
              <ContentInput
                width={equalsOrBiggerThan768 ? '45%' : '100%'}
                minWidth={equalsOrBiggerThan768 ? '45%' : '100%'}
                style={{
                  marginLeft: '-5px',
                }}
                flex
                minHeight="160px"
                paddingTop="0"
              >
                <div>
                  <HexagonItem type="6" />
                </div>
                <ContentInput paddingTop="2px" marginLeft="-6px">
                  <ServicesText
                    style={{
                      paddingRight: equalsOrBiggerThan768 ? '12px' : '0px',
                    }}
                  >
                    <H3Bold>Carpet Cleaning</H3Bold>
                    <Paragraph
                      textAlign="justify"
                      style={{ fontSize: biggerThan768 ? '14px' : '12px' }}
                    >
                      Our franchisees’ professional carpet and upholstery
                      cleaning services, such as spot cleaning, extraction, and
                      bonnet cleaning help your facility maintain a clean image.
                    </Paragraph>
                  </ServicesText>
                </ContentInput>
              </ContentInput>
              <ContentInput
                width={equalsOrBiggerThan768 ? '45%' : '100%'}
                minWidth={equalsOrBiggerThan768 ? '45%' : '100%'}
                style={{
                  marginLeft: '0',
                  paddingRight: equalsOrBiggerThan768 ? '0px' : '5px',
                }}
                flex
                minHeight="160px"
                paddingTop="0"
              >
                <div>
                  <HexagonItem type="7" />
                </div>
                <ContentInput paddingTop="2px" marginLeft="-6px">
                  <ServicesText>
                    <H3Bold>Hardwood Floor Care</H3Bold>
                    <Paragraph
                      textAlign="justify"
                      style={{
                        fontSize: biggerThan768 ? '14px' : '12px',
                        width: equals768 ? 'calc(100% - 5px)' : '100%',
                      }}
                    >
                      Anago’s franchise owners keep your hardwood floors looking
                      great using specialized cleaning processes and commercial
                      grade products to ensure superior durability.
                    </Paragraph>
                  </ServicesText>
                </ContentInput>
              </ContentInput>
              <ContentInput
                width={equalsOrBiggerThan768 ? '45%' : '100%'}
                minWidth={equalsOrBiggerThan768 ? '45%' : '100%'}
                style={{
                  marginLeft: '-5px',
                }}
                flex
                minHeight="160px"
                paddingTop="0"
              >
                <div>
                  <HexagonItem type="8" />
                </div>
                <ContentInput paddingTop="2px" marginLeft="-6px">
                  <ServicesText
                    style={{
                      paddingRight: equalsOrBiggerThan768 ? '12px' : '0px',
                    }}
                  >
                    <H3Bold>Emergency Services</H3Bold>
                    <Paragraph
                      textAlign="justify"
                      style={{ fontSize: biggerThan768 ? '14px' : '12px' }}
                    >
                      Anago’s highly skilled janitorial franchise owners are
                      available 24/7 for any emergency clean-up needed at your
                      office, including water damage.
                    </Paragraph>
                  </ServicesText>
                </ContentInput>
              </ContentInput>
            </ResponsiveLayout>
          </SubSection>
        </OrangeSection>
      </Layout>
      <GraySection width="auto" margin="0" padding="30px 20px 150px 20px">
        <RoundSubSection
          width="100%"
          backgroundColor="#white"
          maxWidth={equalsOrBiggerThan768 ? '900px' : 'auto'}
        >
          <SubSectionHeader
            backgroundColor="#216eab"
            color="#fff"
            padding="20px 0 20px 0"
            alignItems={biggerThan768 ? 'start' : 'center'}
          >
            <SectionTitle maxFontSize="20px" color="#fff">
              Specialty Service Quote(s)
            </SectionTitle>
          </SubSectionHeader>
          <Layout
            alignItems="start"
            color="#fff"
            padding="20px"
            width="100%"
            backgroundColor="#fff"
            boxSizing="border-box"
          >
            {(hasAdditionalQuotes ||
              values['CostOfDayPorter'] > 0 ||
              values['DayPorterPriceOverride'] > 0) && (
              <>
                <Paragraph fontSize="14px" marginTop="0" marginBottom="20px">
                  Please select any of the Specialty Services you would like.
                </Paragraph>
                <ContentInput flex justify="space-between" width="100%">
                  <H3Bold
                    width="100%"
                    maxFontSize="16px"
                    paddingBottom="10px"
                    marginLeft={equalsOrBiggerThan768 ? '62px' : '0px'}
                    textAlign={equalsOrBiggerThan768 ? 'left' : 'center'}
                  >
                    Service Type
                  </H3Bold>
                  {equalsOrBiggerThan768 && (
                    <H3Bold
                      width="100%"
                      maxFontSize="16px"
                      paddingBottom="10px"
                      marginRight={equalsOrBiggerThan768 ? '15px' : '0px'}
                      textAlign={equalsOrBiggerThan768 ? 'right' : 'center'}
                    >
                      Price
                      <small>
                        {franchiseData && franchiseData.SalesTax && (
                          <sup>†</sup>
                        )}
                        <sup>‡</sup>
                      </small>
                    </H3Bold>
                  )}
                </ContentInput>
                {hasAdditionalQuotes &&
                  values['AdditionalQuotes']
                    .filter(
                      (i) =>
                        i['Total'] > 0 ||
                        i['TotalOverride'] > 0 ||
                        i['UponRequest'] ||
                        i['UnitPrice'] > 0
                    )
                    .map((item, index) => (
                      <Layout
                        flexDirection="row"
                        marginRight="0"
                        marginLeft="0"
                        width="100%"
                        marginBottom={'20px'}
                      >
                        <CheckBoxHolder>
                          <Checkbox
                            containerStyle={{
                              alignSelf: 'flex-start',
                            }}
                            dataCy={`specialty-service-${index}`}
                            borderColor="gray"
                            name={item['BidInformationAdditionalQuotesId']}
                            value={item['BidInformationAdditionalQuotesId']}
                            checked={additionalQuotes.includes(
                              item['BidInformationAdditionalQuotesId']
                            )}
                            onChange={(...args) => {
                              onCheckboxClick(item, ...args);
                            }}
                          />
                        </CheckBoxHolder>

                        {renderBoxes(
                          {
                            value: item['QuotesType'],
                            index: index,
                            oneTimePrice: `$${formatNumberByMask(
                              (item['TotalOverride']
                                ? item['TotalOverride']
                                : item['Total']
                              ).toString(),
                              {
                                ...moneyMaskParams,
                                thousandsSeparatorSymbol: ',',
                              }
                            )}`,
                            perHour: item['PerHour'],
                            perMonth: item['PerMonth'],
                            uponRequest: item['UponRequest'],
                            serviceDescription: item['ServiceDescription'],
                            unit: item['Unit'],
                            unitPrice: item['UnitPrice'],
                            unitLabel: item['UnitLabel'],
                          },
                          additionalQuotes.includes(
                            item['BidInformationAdditionalQuotesId']
                          ),
                          index === 0 || !equalsOrBiggerThan768,
                          equalsOrBiggerThan768,
                          franchiseData && franchiseData.SalesTax
                        )}
                      </Layout>
                    ))}

                {(values['CostOfDayPorter'] > 0 ||
                  values['DayPorterPriceOverride'] > 0) && (
                  <Layout
                    flexDirection="row"
                    marginRight="0"
                    marginLeft="0"
                    width="100%"
                    marginBottom={biggerThan768 ? '0' : '50px'}
                  >
                    <CheckBoxHolder>
                      <Checkbox
                        containerStyle={{
                          alignSelf: 'flex-start',
                        }}
                        dataCy={`specialty-service-day-porter`}
                        name="daysPorter"
                        value="daysPorter"
                        borderColor="gray"
                        checked={dayPorter}
                        disabled={values['IncludeDayPorterInPrice']}
                        onChange={(...args) => setDayPorter(!dayPorter)}
                      />
                    </CheckBoxHolder>

                    {renderBoxes(
                      {
                        value: `Day Porter Services ${
                          values['IncludeDayPorterInPrice'] ? '(included)' : ''
                        }`,
                        index: 'day-porter',
                        oneTimePrice: `$${formatNumberByMask(
                          (values['DayPorterPriceOverride'] > 0
                            ? values['DayPorterPriceOverride']
                            : values['CostOfDayPorter']
                          ).toString(),
                          {
                            ...moneyMaskParams,
                            thousandsSeparatorSymbol: ',',
                          }
                        )}`,
                        perHour: false,
                        perMonth: true,
                        serviceDescription: `${getDaysPorterDays()} for ${
                          values['DayPorterHoursPerDay']
                        } hours per day`,
                        uponRequest: false,
                        unit: false,
                      },
                      dayPorter,
                      !equalsOrBiggerThan768,
                      equalsOrBiggerThan768,
                      franchiseData && franchiseData.SalesTax
                    )}
                  </Layout>
                )}
              </>
            )}

            {(hasAdditionalQuotes ||
              values['CostOfDayPorter'] > 0 ||
              values['DayPorterPriceOverride'] > 0) &&
              franchiseValues.SalesTax && (
                <DisclaimerParagraph
                  italic
                  paddingTop="15px"
                  paddingBottom="0px"
                  marginTop="0px"
                  marginBottom="0px"
                  width={'100%'}
                  style={{
                    fontSize: '14px',
                    paddingTop: '2px',
                    textAlign: equalsOrBiggerThan768 ? 'right' : 'center',
                  }}
                >
                  <i
                    style={{
                      paddingRight: equalsOrBiggerThan768 ? '14px' : null,
                    }}
                  >
                    <small className="tm">†</small> Plus Applicable Sales Tax.
                  </i>
                </DisclaimerParagraph>
              )}

            <DisclaimerParagraph
              italic
              textAlign="justify !important"
              style={{ fontSize: '14px', lineHeight: '15px' }}
            >
              {(hasAdditionalQuotes ||
                values['CostOfDayPorter'] > 0 ||
                values['DayPorterPriceOverride'] > 0) && (
                <>
                  {values['ServiceFooter'] && <small className="tm">‡</small>}
                  {/* Client is responsible to
                  remove and replace furniture and items from floor being
                  serviced. A minimum charge of $150 for the first hour plus an
                  additional $25.00 per hour thereafter will apply if Anago’s
                  representatives must move items. */}
                  {values['ServiceFooter']}
                </>
              )}

              {!hasAdditionalQuotes &&
                !values['CostOfDayPorter'] &&
                !values['DayPorterPriceOverride'] && (
                  <>
                    If you'd like to learn more about any of our Specialty
                    Services, please contact your{' '}
                    {values['MasterFranchiseCity']} Representative.
                  </>
                )}
            </DisclaimerParagraph>
          </Layout>
        </RoundSubSection>
        {values['IsSS'] && (
          // ||
          //   selectedValues.page1.selectedFrequency == -1 ||
          //   selectedValues.page2.selectedFrequency == -1
          <TermsAndConditions
            isSS
            values={values}
            franchiseValues={franchiseValues}
            isTermsChecked={isTermsChecked}
            setIsTermsChecked={setIsTermsChecked}
            pageChanged={selectedItem}
          />
        )}
      </GraySection>
    </Layout>
  );
};

export default React.forwardRef(Page2);
