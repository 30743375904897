import React, { useState, useCallback } from 'react';
import { Button, ReactCalculator } from 'core/components';

import { ModalWrapper } from './styles';

interface ICalculatorModal {
  onClose: Function;
}

export const CalculatorModal = ({ onClose }: ICalculatorModal) => {
  return (
    <ModalWrapper>
      <div className="additional-notes-content-div">
        <div className="additional-notes-title-container">
          <h3>Calculator</h3>
        </div>
        <div className="text-area-container">
          <div className="calculator-holder">
            <ReactCalculator />
          </div>
        </div>
        <div className="actions-area-container"></div>
      </div>

      <div className="additional-notes-done-button">
        <Button
          color="#fff"
          bgColor="#216eab"
          reverseSkew={true}
          onClick={onClose}
        >
          DONE
        </Button>
      </div>
    </ModalWrapper>
  );
};
