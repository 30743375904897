import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Box, BoxWrapper, BoxLabel } from './styles';

type TCheckbox = {
  value: any;
  name: string;
  label: string;
  onChange: (name: string, value: any, isChecked: boolean) => undefined;
  onClick: (e: Event) => undefined;
  disabled?: boolean;
  checked: boolean;
  borderColor?: string;
  children: React.ReactNode;
  inverted?: boolean;
  containerStyle?: any;
  contentStyle?: any;
  squareStyle?: any;
  checkStyle?: any;
  dataCy?: string;
  id?: string;
  pointerEvents?: string;
};

export const Checkbox = ({
  value,
  name,
  label,
  checked = false,
  disabled = false,
  pointerEvents,
  borderColor,
  onClick: onClickCheckbox = () => undefined,
  onChange = () => undefined,
  children,
  inverted,
  containerStyle,
  contentStyle,
  squareStyle,
  checkStyle,
  id,
  dataCy,
}: TCheckbox) => {
  const onClick = (e) => {
    if (!disabled) {
      e.preventDefault();
      e.stopPropagation();
      onChange(name, value, !checked, e);
      if (onClickCheckbox) {
        onClickCheckbox(e);
      }
    } else {
      if (onClickCheckbox && pointerEvents) {
        onClickCheckbox(e);
      }
    }
  };

  const getLabel = (content) => (
    <BoxLabel style={contentStyle} onClick={onClick}>
      {content}
    </BoxLabel>
  );
  const content = getLabel(label || children);

  return (
    <BoxWrapper
      disabled={disabled}
      pointerEvents={pointerEvents}
      style={containerStyle}
      data-cy={`${dataCy}_wrapper`}
    >
      {inverted && content}
      <Box
        id={id}
        data-cy={dataCy}
        onClick={onClick}
        checked={checked}
        borderColor={borderColor}
        style={squareStyle}
      >
        {checked && (
          <FontAwesomeIcon
            data-cy={`${dataCy}_checkIcon`}
            size="lg"
            color="#18b614"
            icon={faCheck}
            style={checkStyle}
          />
        )}
      </Box>
      {!inverted && content}
    </BoxWrapper>
  );
};
