const MapKeys = [
  {
    key: 'Delete',
    label: 'C',
    type: 'command',
    command: 'clearAction',
  },
  {
    key: '+/-',
    label: '\u00B1',
    type: 'command',
    command: 'switchOperatorAction',
  },
  {
    key: '%',
    label: '%',
    type: 'percent',
    command: 'percentAction',
  },
  {
    key: '/',
    label: '\u00F7',
    type: 'operator',
    command: 'operatorAction',
  },
  {
    key: '7',
    label: '7',
    type: 'number',
    command: 'addAction',
  },
  {
    key: '8',
    label: '8',
    type: 'number',
    command: 'addAction',
  },
  {
    key: '9',
    label: '9',
    type: 'number',
    command: 'addAction',
  },
  {
    key: '*',
    label: '\u00D7',
    type: 'operator',
    command: 'operatorAction',
  },
  {
    key: '4',
    label: '4',
    type: 'number',
    command: 'addAction',
  },
  {
    key: '5',
    label: '5',
    type: 'number',
    command: 'addAction',
  },
  {
    key: '6',
    label: '6',
    type: 'number',
    command: 'addAction',
  },
  {
    key: '-',
    label: '\u2212',
    type: 'operator',
    command: 'operatorAction',
  },
  {
    key: '1',
    label: '1',
    type: 'number',
    command: 'addAction',
  },
  {
    key: '2',
    label: '2',
    type: 'number',
    command: 'addAction',
  },
  {
    key: '3',
    label: '3',
    type: 'number',
    command: 'addAction',
  },
  {
    key: '+',
    label: '+',
    type: 'operator',
    command: 'operatorAction',
  },
  {
    key: '0',
    label: '0',
    type: 'number',
    command: 'addAction',
  },
  {
    key: '.',
    label: '.',
    type: 'command',
    command: 'commaAction',
  },
  {
    key: 'Backspace',
    label: '\u232B',
    type: 'command',
    command: 'deleteAction',
  },
  {
    key: 'Enter',
    label: '=',
    type: 'result',
    command: 'resultAction',
  },
];

export default MapKeys;
