export const objectToArray = (object: any) =>
  Object.keys(object).map((key) => [key, object[key]]);

export const arrayToObject = (array: Array<any>) =>
  array.reduce((acc, ele) => {
    acc[ele[0]] = ele[1];
    return acc;
  }, {});

export const objectExcept = (
  obj: { [x: string]: any },
  exceptKeys: string[]
) => {
  if (!exceptKeys.length) {
    return obj;
  }

  return eval(`(({ ${exceptKeys.toString()}, ...o }) => o)(obj)`);
};

export const booleanValue = (value) =>
  value === 'true' ? true : value === 'false' ? false : value;

export const getAlphabetLetter = (number) =>
  `${(number + 10).toString(36).toUpperCase()}`;
