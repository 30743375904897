import { AnyAction } from 'redux';
import { Types } from './types';

import { IStateToProps } from '../interfaces';

const initialState: IStateToProps = {
  loading: false,
};

export default function bidForm(state = initialState, action: AnyAction) {
  switch (action.type) {
    case Types.SET_LOADING_ON_CLOSE_STATE:
      return {
        ...state,
        bidFormLoadingOnclose: action.payload,
      };
    default:
      return state;
  }
}
