

import { defaultAreas } from 'core/utils/defaultAreas';
import { IArea } from '../../../../core/interfaces';

import lobby from './lobby.png';
import office from './office.png';
import restroom from './restroom.png';
import janitorialcloset from './janitorialcloset.png';
import security from './security.png';
import kitchen from './kitchen.png';
import cafeteria from './cafeteria.png';
import gym from './gym.png';
import childcare from './childcare.png';
import showroom from './showroom.png';
import restaurant from './restaurant.png';
import spa from './spa.png';
import sauna from './sauna.png';
import classroom from './classroom.png';
import exam from './exam.png';
import terminalclean from './terminalclean.png';
import conference from './conference.png';
import manualyadded from './manualyadded.png';

export const getDefaultAreaIcons = (name: string) => {
  switch(name && name.split(' (')[0]) {
    case 'Lobby Rating':
      return lobby;
    case 'Office Rating':
      return office;
    case 'Restroom Rating':
      return restroom;
    case 'Janitorial Closet Rating':
      return janitorialcloset;
    case 'Security Rating':
      return security;
    case 'Kitchen and Break Area Rating':
      return kitchen;
    case 'Cafeteria Rating':
      return cafeteria;
    case 'Gym Floor Rating':
      return gym;
    case 'Child Care Area Rating':
      return childcare;
    case 'Showroom Rating':
      return showroom;
    case 'Restaurant/Bar Area Rating':
      return restaurant;
    case 'Spa Area Rating':
      return spa;
    case 'Lockers/Sauna Rating':
      return sauna;
    case 'Classroom Rating':
      return classroom;
    case 'Exam and Lab Room Rating':
      return exam;
    case 'Terminal Clean Rating':
      return terminalclean;
    case 'Conference Room Rating':
      return conference;
    default:
      return manualyadded;
  }
}

export const defaultAreaNames = defaultAreas.reduce((acc: any, curr: IArea, index: number) => {
  const obj = {
    ...acc,
    [curr.Name]: {
      id: index + 1,
      areaName: curr.Name,
      src: getDefaultAreaIcons(curr.Name)
    }
  }

  return obj;
}, {
  'Manually Added': {
    id: 0,
    areaName: '',
    src: getDefaultAreaIcons('manuallyAdded')
  }
})


export const getDefaultAreaNames = (name: string) => {
  switch(name && name.split(' (')[0]) {
    case 'Lobby Rating':
      return 'Lobby Rating';
    case 'Office Rating':
      return 'Office Rating';
    case 'Restroom Rating':
      return 'Restroom Rating';
    case 'Janitorial Closet Rating':
      return 'Janitorial Closet Rating';
    case 'Security Rating':
      return 'Security Rating';
    case 'Kitchen and Break Area Rating':
      return 'Kitchen and Break Area Rating';
    case 'Cafeteria Rating':
      return 'Cafeteria Rating';
    case 'Gym Floor Rating':
      return 'Gym Floor Rating';
    case 'Child Care Area Rating':
      return 'Child Care Area Rating';
    case 'Showroom Rating':
      return 'Showroom Rating';
    case 'Restaurant/Bar Area Rating':
      return 'Restaurant/Bar Area Rating';
    case 'Spa Area Rating':
      return 'Spa Area Rating';
    case 'Lockers/Sauna Rating':
      return 'Lockers/Sauna Rating';
    case 'Classroom Rating':
      return 'Classroom Rating';
    case 'Exam and Lab Room Rating':
      return 'Exam and Lab Room Rating';
    case 'Terminal Clean Rating':
      return 'Terminal Clean Rating';
    case 'Conference Room Rating':
      return 'Conference Room Rating';
    default:
      return 'Manually Added';
  }
}
