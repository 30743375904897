import React, { useRef, useState } from 'react';
import {
  Button,
  useFormProps,
  BubbleButton,
  ActionAlertModal,
} from 'core/components';
import { IconButton } from 'modules/BidInfoForm/components/molecules';
import { getQueryString } from 'core/utils';
import { NoteModal } from './NoteModal';
import { CalculatorModal } from './CalculatorModal';
import addNoteIcon from '../../../../assets/icons/add-note.svg';
import calculatorIcon from '../../../../assets/icons/calculator.svg';
import { CalculatorImg, UnstyledButton, IconButtonsHolder } from './styles';
import { validateSaveDraft } from 'core/validators/BidInfoFormValidator';
import { useMediaQuery } from 'react-responsive';
import { faEdit, faSave } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Footer = ({
  onCompleteButtonClick,
  completeButtonEnabled,
  ...props
}) => {
  const { formValues } = useFormProps();
  const [openNote, setOpenNote] = useState(false);
  const [openCalculator, setOpenCalculator] = useState(false);
  const [openBuble, setOpenBuble] = useState(false);
  const [errors, setErrors] = useState([]);
  const userId = getQueryString(window.location.href, 'userId');
  const formId = getQueryString(window.location.href, 'formId');
  const formUniqueId =
    getQueryString(window.location.href, 'formUniqueId') || formValues.uid;
  const formIsDraft = !formValues.sentStatus && !formValues.isTemplate;
  const formAlreadySentUnsigned =
    (formId || formUniqueId) && formValues.sentStatus && !formValues.isTemplate;
  const [completeButtonDisabled, setCompleteButtonDisabled] = useState(false);

  const biggerThan768 = window.innerWidth >= 769;

  const onCompleteButtonClickWrapper = (saveAndComplete = true) => {
    setCompleteButtonDisabled(true);
    onCompleteButtonClick(saveAndComplete, () => setCompleteButtonDisabled(false));
  };
  const saveDraft = async () => {
    const errors = validateSaveDraft(formValues);
    setErrors(errors);

    if (errors && errors.length > 0) {
      return;
    }

    await props.saveDraft();
    setOpenBuble(false);
  };

  const duplicateProposal = async () => {
    props.duplicateProposal();
    setOpenBuble(false);
  };

  const saveTemplate = async (isCityTemplate = false, isSaveAs = false) => {
    const errors = validateSaveDraft(formValues);
    setErrors(errors);

    if (errors && errors.length > 0) {
      return;
    }

    await props.saveTemplate(isCityTemplate, isSaveAs);
    setOpenBuble(false);
  };

  const getCompleteButtonLabel = () => {
    if (formValues.status === 2) {
      return 'VIEW PROPOSAL';
    }

    if (formIsDraft) {
      return 'COMPLETE';
    }

    // COMPLETED-UNSIGNED bids
    if (formAlreadySentUnsigned) {
      return 'UPDATE';
    }

    // Waiting for ticket to handle the logic for
    // COMPLETED-SIGNED bids
    // if (condition) {
    //   return 'VIEW PROPOSAL'
    // }

    return 'COMPLETE';
  };

  const getBubbleItems = () => {
    let arr = [];

      arr.push({
        value: 'DUPLICATE',
        onClick: duplicateProposal,
      });

      if(!(formValues.isTemplate && !formValues.isCityTemplate)) {
        arr.push({
          value: 'MY TEMPLATE',
          onClick: () => saveTemplate(false, true)
        })
      }
  
      if(!(formValues.isTemplate && formValues.isCityTemplate)){
        arr.push({
          value: 'CITY TEMPLATE',
          onClick: () => saveTemplate(true, true)
        })
      }

    // arr.push({
    //   value: formValues.uid
    //     ? formValues.isTemplate
    //       ? 'UPDATE TEMPLATE'
    //       : 'NEW TEMPLATE'
    //     : 'NEW TEMPLATE',
    //   onClick: saveTemplate,
    // });

    if (!formIsDraft && !formAlreadySentUnsigned && formValues.status != 2) {
      arr.push({
        value: 'NEW DRAFT',
        onClick: saveDraft,
      });
    }

    return arr;
  };

  const onClickSave = () => {
    if(formValues.isCityTemplate) {
      saveTemplate(true)
    }else if(formValues.isTemplate){
      saveTemplate(false)
    }else if(formIsDraft) {
      saveDraft()
    }else {
      onCompleteButtonClickWrapper(false);
    }
  }

  return (
    <>
      <div className="footer-root">
        <div className="footer-content">
          <IconButtonsHolder>
            <UnstyledButton onClick={() => setOpenCalculator(true)}>
              <CalculatorImg src={calculatorIcon} />
            </UnstyledButton>

            <div
              style={{
                background: `url(${addNoteIcon}) no-repeat`,
                width: '32px',
                height: '32px',
              }}
              onClick={() => setOpenNote(true)}
            />
          </IconButtonsHolder>

          <div
            style={{
              display: 'flex',
            }}
          >
            {props.onClickCustomizeTerms && (
              <div style={{ marginRight: '25px' }}>
                <IconButton
                  title="CUSTOMIZE TERMS"
                  icon={faEdit}
                  onClick={props.onClickCustomizeTerms}
                />
              </div>
            )}
            {formValues.status !== 2 && (
              <div style={{ marginRight: '35px', display: 'flex', alignItems: 'center', pointerEvents: completeButtonDisabled ? 'none' : 'auto'}}
                onClick={onClickSave}
              >
                <FontAwesomeIcon
                  icon={faSave}
                  style={{ fontSize: 36 }}
                  size={'1x'}
                  color={'black'}
                />
              </div>
            )}
              <BubbleButton
                reverseSkew
                color="#216eab"
                kind="outline"
                margin="0px 30px 0px 0px"
                showBubble={openBuble}
                onCloseBubble={() => setOpenBuble(false)}
                onClick={() => setOpenBuble(true)}
                bubbleStyles={{ color: '#216eab' }}
                bubbleItems={getBubbleItems()}
                style={
                  (formValues.isTemplate &&
                    !biggerThan768 && {
                      left: '66% !important',
                      zIndex: 999,
                    }) || { zIndex: 999 }
                }
              >
                SAVE AS
              </BubbleButton>

            {/* {!formAlreadySentUnsigned && (
            )} */}

            {!formValues.isTemplate && (
              <Button
                disabled={completeButtonDisabled}
                isLoading={!completeButtonEnabled}
                loadingText="Auto-Save in Progress"
                reverseSkew
                bgColor="#216eab"
                disabledStyles={{
                  color: '#fff',
                  background: '#ccc',
                }}
                style={{
                  width: formValues.status === 2 ? '200px' : '150px',
                }}
                onClick={onCompleteButtonClickWrapper}
              >
                {getCompleteButtonLabel()}
              </Button>
            )}
          </div>
        </div>
      </div>
      {openNote && (
        <NoteModal
          onCancel={()=> setOpenNote(false)}
          canEdit={formValues.status !== 2}
          onClose={() => setOpenNote(false)}
        />
      )}
      {openCalculator && (
        <CalculatorModal onClose={() => setOpenCalculator(false)} />
      )}

      <ActionAlertModal
        message={errors}
        onConfirm={() => setErrors([])}
        isVisible={errors && errors.length > 0}
      />
    </>
  );
};
