import React from 'react';
import RenderPdf from 'core/components/RenderPdf';
import { getQueryString } from 'core/utils';

import {
  Content,
} from 'modules/BidInfoForm/components/atoms';
import { getBase64ByParams } from '../../services/bidInfoService';

export const PdfPreview = (props) => {
  const [biggerThan796, setBiggerThan796] = React.useState(false);
  const [pdfUrl, setPdfUrl] = React.useState(false);
  const [pid, setPid] = React.useState();
  const [uid, setUid] = React.useState();
  const [type, setType] = React.useState();

  const setBreakpoints = () => {
    setBiggerThan796(window.innerWidth >= 796);
  };

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  React.useEffect(()=>{

    const uniqueId = getQueryString(window.location.href, 'uid');
    setUid(uniqueId)

    const type = getQueryString(window.location.href, 'typepdf');
    setType(type)

    const pdfUrlParam = getQueryString(window.location.href, 'pdfurl');
    setPdfUrl(pdfUrlParam)

    const formPid = getQueryString(window.location.href, 'pid');
    setPid(formPid)

    if(type && uniqueId && !pdfUrlParam){
      (async ()=>{
        const {data} = await getBase64ByParams(type, uniqueId, false);
        setPdfUrl(data['URL']);
        setPid(data['PID']);
      })();
    }
  }, [])

  let pdfName = '';

  switch (type) {
    case '10':
      pdfName = `AnagoProposal_${pid}`
    break;
    case '11':
      pdfName = `AnagoAgreement_${pid}`
    break;
    case '12':
      pdfName = `AnagoSpSvcCompleted_${pid}`
    break;
    case '13':
      pdfName = `AnagoAgreement_${pid}`
    break;
    case '20':
      pdfName = `SpSvcProposal_${pid}`
    break;
    case '21':
      pdfName = `SpSvcAgreement_${pid}`
    break;
    case '22':
      pdfName = `SpSvcCompleted_${pid}`
    break;
    case '23':
      pdfName = `SpSvcAgreement_${pid}`
    break;
    case '30':
      pdfName = `InspectionPics_${pid}`
    break;
    case '31':
      pdfName = `Inspection_${pid}`
    break;
    case '41':
      pdfName = `PerfEvalCompleted_${pid}`
    break;
    case '42':
      pdfName = `PerfEval_${pid}`
    break;
    default:
      pdfName = `Document_${pid}`
    break;
  }

  return (
    <>
      <Content padding="30px" maxWidth="720px" margin="auto">
        <RenderPdf
            onClickClose={null}
            pdfName={pdfName}
            type={type}
            uid={uid}
            pdfUrl={pdfUrl}
          />
      </Content>
    </>
  );
};
