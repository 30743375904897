import React from 'react';
// redux
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { InspectFormActions } from 'modules/InspectForm/redux/actions';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DatePicker } from 'core/components';
// interfaces
import { IReduxState } from 'core/interfaces';
import {
  IStateToProps,
  IDispatchToProps,
} from 'modules/InspectForm/interfaces';
// ui
import './styles.scss';
import { formatDate, getQueryString, axiosInstance } from 'core/utils';
import getError from 'core/utils/getError';
import styled from 'styled-components';

interface IOwnProps {
  toggleNeedsSaving(): void;
  onEnter: Function;
}

type IProps = IDispatchToProps &
  IStateToProps &
  IOwnProps &
  RouteComponentProps;

class FirstForm extends React.PureComponent<IProps> {
  public DateInputRef = React.createRef<HTMLInputElement>();

  public state = {
    PID: '',
    InspectionClassNumber: 'Inspection Class #',
    Date: new Date(),
    ContactForm: '1',
    MasterCity: '',
    masterCities: [],
  };

  public onKeyDown = (id: string) => (e: any) => {
    if (e!.key === 'Enter') {
      this.props.onEnter(id);
    }
  };

  public async componentDidMount() {
    // workaround to hide the keyboard on mobile while using the react-datepicker
    const datePickers = document.getElementsByClassName(
      'react-datepicker__input-container'
    );
    Array.from(datePickers).forEach((el) =>
      el.childNodes[0].setAttribute('readOnly', true)
    );

    const {
      editing,
      PID,
      InspectionClassNumber,
      Date: dateFromRedux,
      MasterCity,
      FranchiseId,
      ContactForm,
    } = this.props;

    if (editing && PID) {
      this.setState({
        PID,
        InspectionClassNumber,
        // Date: formatDate(Date),
        Date: dateFromRedux ? new Date(dateFromRedux) : new Date(),
        ContactForm,
      });
    }
    if (MasterCity) {
      this.setState({ MasterCity });
    }
    const list = await this.getMasterCities();
    const masterCitiesList = Array.isArray(list) ? list : [list]; // making sure we return an array

    if (masterCitiesList.length > 0) {
      this.setState(
        {
          MasterCity: masterCitiesList[0].Id,
        },
        () => {
          console.log('master city selected', this.state.MasterCity);
          setTimeout(() => {
            this.updateFirstForm();
          }, 500);
        }
      );
    }

    if (FranchiseId) {
      this.setState({
        MasterCity: FranchiseId,
      });
    }

    this.setState({
      masterCities: masterCitiesList,
    });
  }

  public getMasterCities = async (): Promise<any> => {
    const currentUrl = this.props.location.search;

    // const params = new URLSearchParams(currentUrl);
    // const userId = params.get("userId");
    const userId = getQueryString(currentUrl, 'userId');
    console.log('userId', getQueryString(currentUrl, 'userId'));
    console.log('userId', userId);

    const listCloud = await axiosInstance.get(
      `InspectionReport/getFranchises?userid=${userId}`
    );

    return listCloud.data;
  };

  public componentWillReceiveProps(nextProps: IProps) {
    const {
      editing,
      PID,
      InspectionClassNumber,
      ContactForm,
      FranchiseId,
    } = nextProps;
    const dateReceived = nextProps.Date;
    if (editing && PID !== this.props.PID) {
      this.setState({
        PID,
        InspectionClassNumber,
        // Date: formatDate(dateReceived),
        Date: dateReceived ? new Date(dateReceived) : '',
        ContactForm,
        MasterCity: FranchiseId,
      });
    }
  }

  public isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  public updateFirstForm = () => {
    const {
      PID,
      InspectionClassNumber,
      Date: formDate,
      ContactForm,
      MasterCity,
    } = this.state;

    const isValidDate = String(new Date(formDate));
    const date = isValidDate === 'Invalid Date' ? '' : formatDate(formDate);

    // const date = !this.isValidDate(String(new Date(formDate))) ? '' : formDate;

    this.props.setFirstForm({
      PID,
      InspectionClassNumber,
      Date: date,
      ContactForm,
      MasterCity,
    });
  };

  public render() {
    // const { PID, InspectionClassNumber, Date, ContactForm } = this.state;
    const { PID, InspectionClassNumber, Date: date } = this.state;
    const { isSent, errors } = this.props;
    return (
      <>
        {this.state.masterCities && this.state.masterCities.length > 1 && (
          <div className="master-city-container">
            <div
              className={`master-city-content ${
                getError('MasterCity', errors).className
              }`}
            >
              <label>
                Master City
                {getError('MasterCity', errors).showError &&
                  ` (${getError('MasterCity', errors).msg})`}
              </label>
              <select
                className="master-city-select"
                value={this.state.MasterCity}
                disabled={isSent}
                onChange={(e) => {
                  this.setState({ MasterCity: e.target.value }, () => {
                    this.updateFirstForm();
                    this.props.toggleNeedsSaving();
                  });
                }}
              >
                {/* {(!this.state.masterCities ||
                  this.state.masterCities.length !== 1) && (
                  <option disabled={true} value=''>
                    Select a City
                  </option>
                )} */}
                {this.state.masterCities &&
                  this.state.masterCities.map((city: any) => (
                    <option key={city.Id} label={city.Name} value={city.Id}>
                      {city.Name}
                    </option>
                  ))}
              </select>
            </div>
            {/* <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  // disabled={formValues.status === 2}
                  onClick={this.props.openSearch}
                >
                  Open Inspection forms
                </Button>
              </div>
            </div> */}
          </div>
        )}
        <div className="first-form-container">
          <div className="first-form-content">
            <div
              className={`first-input-div ${getError('PID', errors).className}`}
            >
              <label>
                PID
                {getError('PID', errors).showError &&
                  ` (${getError('PID', errors).msg})`}
              </label>
              <input
                value={PID}
                onChange={(e) => {
                  this.setState({ PID: e.target.value });
                  this.props.toggleNeedsSaving();
                }}
                onBlur={() => this.updateFirstForm()}
                placeholder="PID"
                maxLength={10}
                disabled={isSent}
                className={`auto-capitalize`}
                onKeyDown={this.onKeyDown('company-name')}
              />
              {/* <p className='validation-error'>{errors && errors.PID}</p> */}
            </div>
            <div
              className={`second-input-div ${
                getError('InspectionClassNumber', errors).className
              }`}
            >
              <label>
                Inspection Class Number{' '}
                {getError('InspectionClassNumber', errors).showError &&
                  ` (${getError('InspectionClassNumber', errors).msg})`}
              </label>
              <select
                id="inspection-class-number"
                onChange={(e) => {
                  this.setState({ InspectionClassNumber: e.target.value });
                  this.props.toggleNeedsSaving();
                }}
                value={InspectionClassNumber}
                onBlur={() => this.updateFirstForm()}
                disabled={isSent}
                className={`${
                  InspectionClassNumber === 'Inspection Class #'
                    ? 'disabled-option'
                    : ''
                }`}
              >
                <option disabled={true} value="Inspection Class #">
                  Inspection Class #
                </option>
                {InspectionClassNumber === '' && <option value=""> </option>}
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="4*">4*</option>
                <option value="4**">4**</option>
                <option value="N/A">N/A</option>
              </select>
            </div>
            {/*  <div
              className={`second-input-div ${
                getError('ContactForm', errors).className
              }`}
            >
              <label>
                Contact Method{' '}
                {getError('ContactForm', errors).showError &&
                  ` (${getError('ContactForm', errors).msg})`}
              </label>
              <select
                onChange={e => {
                  this.setState({ ContactForm: e.target.value });
                  this.props.toggleNeedsSaving();
                }}
                value={ContactForm}
                disabled={isSent}
                onBlur={() => this.updateFirstForm()}
              >
                <option value='1'>In Person</option>
                <option value='2'>Phone Call</option>
                <option value='3'>E-mail</option>
              </select>
            </div> */}
            <div
              className={`third-input-div ${
                getError('Date', errors).className
              }`}
            >
              <label>
                Date
                {getError('Date', errors).showError &&
                  ` (${getError('Date', errors).msg})`}
              </label>

              <DatePicker
                onChange={(date) => {
                  this.setState({ Date: date }, () => {
                    this.updateFirstForm();
                  });
                  this.props.toggleNeedsSaving();
                }}
                onBlur={() => {
                  this.updateFirstForm();
                }}
                selected={date}
                disabled={isSent}
                popperModifiers={{
                  offset: {
                    enabled: true,
                    offset: '5px, 10px',
                  },
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: IReduxState) => ({
  ...state.inspectForm,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...InspectFormActions }, dispatch);

const WithRouter = withRouter(FirstForm);

const Connected = connect<
  IStateToProps,
  IDispatchToProps,
  IOwnProps,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(WithRouter);

export { Connected as FirstForm };
