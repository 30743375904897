
import { all } from 'redux-saga/effects';
import {
  watchAddArea,
  watchUpdateAreaItemRange,
  watchRemoveArea,
  watchUpdateAreaItems,
  watchUpdateArea,
  watchRemoveAreaItem,
  watchAddAreaItem,
  watchCalculateRates,
  watchUpdateDuplicatedAreas,
  watchAddAreaPhoto,
  watchRemoveAreaPhoto,
  watchSaveReport,
  watchGetEditFormRequest,
  watchGetPublicEditFormRequest,
  watchGetInspections,
  watchArchiveForm,
  watchSendEmailRequest,
  watchGetNextInspectionsPage
} from 'modules/InspectForm/redux/sagas'

export default function* rootSaga(){
  return yield all([
    // Inspection Form
    watchAddArea(),

    watchUpdateAreaItemRange(),
    watchRemoveArea(),
    watchUpdateAreaItems(),
    watchUpdateArea(),
    watchRemoveAreaItem(),
    watchAddAreaItem(),
    watchCalculateRates(),
    watchUpdateDuplicatedAreas(),
    watchAddAreaPhoto(),
    watchRemoveAreaPhoto(),
    watchSaveReport(),
    watchGetEditFormRequest(),
    watchGetPublicEditFormRequest(),
    watchGetNextInspectionsPage(),

    // Menu Inspection
    watchGetInspections(),
    watchArchiveForm(),

    // email pdf
    watchSendEmailRequest(),
  ])
}
