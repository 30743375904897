import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter, BrowserRouter } from 'react-router-dom';
import { store } from 'redux/store';
import { Router } from 'navigation/router';

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <HashRouter>
        <Router />
        <ToastContainer autoClose={3000} />
      </HashRouter>
    </Provider>
  );
}

export default App;
