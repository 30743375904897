import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export const postalCodeMask = (value: string) => {
  const firstLetter = value.charAt(0);
  if (isNaN(parseInt(firstLetter))) {
    // if the first character is a letter
    // canadian zip code
    return [/[A-Z]/i, /\d/, /[A-Z]/i, ' ', /\d/, /[A-Z]/i, /\d/];
  }
  // american zip code
  return [/\d/, /\d/, /\d/, /\d/, /\d/];
};

export const phoneMask = () => [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const moneyMaskParams = {
  prefix: '$',
  allowDecimal: true,
  decimalLimit: 2,
  type: 'tel',
  // integerLimit: 5
};

export const moneyMaskParams6Digits = {
  prefix: '$',
  allowDecimal: true,
  decimalLimit: 2,
  type: 'tel',
  integerLimit: 6,
};

export const decimalOnePlaceParams = {
  prefix: '',
  allowDecimal: true,
  decimalLimit: 1,
  type: 'tel',
};

export const decimalTwoPlaceParams = {
  ...decimalOnePlaceParams,
  decimalLimit: 2,
};

export const numberMask = createNumberMask({
  prefix: '',
  includeThousandsSeparator: false,
});

export const formatNumberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const moneyMask = createNumberMask(moneyMaskParams);
export const moneyMask6Digits = createNumberMask(moneyMaskParams6Digits);

export const decimalOnePlace = createNumberMask(decimalOnePlaceParams);
export const decimalTwoPlace = createNumberMask(decimalOnePlaceParams);

export const formatDecimal = (value: string) =>
  value ? value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '';

export const timeMaskParams = {
  prefix: '',
  allowDecimal: true,
  decimalLimit: 1,
  type: 'tel',
  thousandsSeparatorSymbol: '',
};
export const timeMask = createNumberMask(timeMaskParams);

export const stateMask = () => [/[A-Za-z]/, /[A-Za-z]/];
