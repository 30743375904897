import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faPlus,
  faTrashAlt,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';

import {
  Content,
  Header,
  ContentInput,
  InputTextarea,
  IconButton,
  ButtonsWrapper,
  StyledInput,
} from './styles';

import { useFormProps } from 'core/components';

interface ISpecialNotes {
  data: Array<any>;
  onChange: Function;
  onBlur?: any;
  resetRender?: boolean;
  disabled?: boolean;
}

export const SpecialNotes = ({
  data,
  onChange,
  onBlur,
  resetRender,
  disabled = false,
}: ISpecialNotes) => {
  const [open, setOpen] = useState(false);
  const [subListOpen, setSubListOpen] = useState([]);
  const { showDeleteConfirmationModal } = useFormProps();

  useEffect(() => {
    setOpen(false);
    setSubListOpen([]);
    // eslint-disable-next-line
  }, [resetRender]);

  return (
    <Content>
      <Header onClick={() => setOpen(!open)} style={{ textTransform: 'none' }}>
        Special Notes
        <FontAwesomeIcon icon={faEdit} size="1x" color="#999" />
      </Header>

      {open &&
        Object.keys(data).map((key: any, index: number) => (
          <>
            <ContentInput key={`special_notes_${index}`}>
              <InputTextarea
                disabled={disabled}
                value={data[key].description}
                onChange={(event: any) =>
                  onChange('specialNotes', {
                    ...data,
                    [key]: { ...data[key], description: event.target.value },
                  })
                }
                onBlur={onBlur}
              />
              <ButtonsWrapper>
                {Object.keys(data).length - 1 === index && (
                  <IconButton
                    disabled={disabled}
                    onClick={() => {
                      const newData = Object.keys(data).map((key) => data[key]);
                      newData.push({ description: '', additionalNotes: [''] });
                      onChange(
                        'specialNotes',
                        newData.reduce((acc, item, index) => {
                          acc[index] = item;
                          return acc;
                        }, {})
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} size="1x" color="#999" />
                  </IconButton>
                )}
                {Object.keys(data).length > 1 && (
                  <IconButton
                    disabled={disabled}
                    onClick={() => {
                      const item = Object.keys(data)
                        .filter((itemKey) => itemKey === key)
                        .map((key) => data[key]);
                      const proceed = () => {
                        const newData = Object.keys(data)
                          .filter((itemKey) => itemKey !== key)
                          .map((key) => data[key]);
                        onChange(
                          'specialNotes',
                          newData.reduce((acc, item, index) => {
                            acc[index] = item;
                            return acc;
                          }, {})
                        );
                      };
                      if (
                        item[0].description === '' &&
                        item[0].additionalNotes.filter((item) => item !== '')
                          .length === 0
                      ) {
                        proceed();
                      } else {
                        showDeleteConfirmationModal(proceed);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} size="1x" color="#999" />
                  </IconButton>
                )}
              </ButtonsWrapper>
            </ContentInput>
          </>
        ))}
    </Content>
  );
};
