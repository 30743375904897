import React from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import placeArrow from './placeArrow';
type placement =
  | 'left'
  | 'right'
  | 'top'
  | 'bottom'
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight';

type trigger = 'hover' | 'click' | 'focus';

interface ITooltip {
  placement: placement;
  trigger: trigger;
  overlay: string;
  children: any;
  alignedItemId?: string;
  positioningFunction?: Function;
}

const TooltipWrapper = ({
  children,
  positioningFunction,
  ...props
}: ITooltip) =>
  props.overlay ? (
    <Tooltip
      {...props}
      onPopupAlign={(node, align) =>
        positioningFunction
          ? positioningFunction(node, align)
          : placeArrow(node, align, props.alignedItemId)
      }
    >
      {children}
    </Tooltip>
  ) : (
    children
  );

export { TooltipWrapper as Tooltip };
