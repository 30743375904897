import React, { memo, useEffect, useState } from 'react';

import { ContentInput, useFormProps, BoxValue } from 'core/components';
import { calculateFloorTypeTotals } from 'modules/BidInfoForm/utils';
import { formatDecimal } from 'core/utils';

import './styles.scss';
import { formatNumberByMask } from '../../../../core/utils/formatInputs';

export const FloorTypeTotals = memo(() => {
  const { formValues, onChange } = useFormProps();
  const { inventory } = formValues;
  const [floorTotals, setFloorTotals] = useState([
    { floorType: '', totalSqft: 0 },
  ]);

  useEffect(() => {
    setFloorTotals(calculateFloorTypeTotals(inventory));
    // eslint-disable-next-line
  }, [inventory]);

  useEffect(() => {
    onChange('floorTypeTotals', floorTotals);
    // eslint-disable-next-line
  }, [floorTotals]);

  return (
    <div className="floor-type-totals-root">
      <div className="floor-type-totals-content">
        <ContentInput width="100%" padding="10px 0px">
          <h3 className="floor-type-totals-title">Floor Type Totals</h3>
        </ContentInput>

        {floorTotals.map((item: any, index: number) => (
          <div key={`floor_total_${index}`} className="floor-totals-list">
            <div className="floor-total-item">
              <BoxValue value={item.floorType} />
            </div>
            <div className="floor-total-item">
              <BoxValue
                value={`Sq. Ft. ${formatNumberByMask(item.totalSqft, {
                  prefix: '',
                  allowDecimal: true,
                  // decimalLimit: 2,
                  thousandsSeparatorSymbol: true, 
                  type: 'tel',
                })}`}
              />
            </div>
          </div>
        ))}

        {floorTotals.length > 1 && (
          <div key={`floor_total_totals`} className="floor-totals-list">
            <div className="floor-total-item">
              <BoxValue
                value="Grand Total"
                contentStyle={{ textAlign: 'right' }}
              />
            </div>
            <div className="floor-total-item">
              <BoxValue
                value={`Sq. Ft. ${formatDecimal(
                  `${floorTotals.reduce(
                    (acc, item) => acc + item.totalSqft,
                    0
                  )}`
                )}`}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
