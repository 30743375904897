import React, { ReactText } from 'react';
import styled from 'styled-components';

const SelectElement = styled.select`
  &:disabled {
    opacity: 1;
    color: #666;
    background-color: #f1f1f1;
  }
`;

const getContentWidth = (width: number | ReactText) => {
  if (width < 80) {
    return 80;
  }

  return width;
};

const Content = styled.div<IContent>`
  width: ${({ width = '29.5%' }) => getContentWidth(width) + 'px'};
`;

interface IContent {
  width?: number;
}

interface ISelect {
  value: any;
  name: string;
  onChange: Function;
  items: Array<any>;
  optionLabel: string;
  optionValue: string;
  placeholder: string;
  width?: number;
  disabled?: boolean;
}

export const Select = ({
  value,
  name,
  onChange,
  items,
  optionLabel,
  optionValue,
  placeholder = 'Select a value',
  disabled,
  width,
}: ISelect) => (
  <Content width={width}>
    <SelectElement
      disabled={disabled}
      value={value}
      onChange={(e) => onChange(name, e.target.value, e.target)}
    >
      {(!items || items.length !== 1) && (
        <option disabled={true} value="">
          {placeholder}
        </option>
      )}
      {items &&
        items.map((item: any, index: number) => (
          <option
            style={{ backgroundColor: '#F7A11D' }}
            key={`select_item_${index}`}
            label={item[optionLabel]}
            value={item[optionValue]}
          >
            {item[optionLabel]}
          </option>
        ))}
    </SelectElement>
  </Content>
);
