import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// redux
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { InspectFormActions } from 'modules/InspectForm/redux/actions';
// interfaces
import { IReduxState } from 'core/interfaces';
import {
  IStateToProps,
  IDispatchToProps,
} from 'modules/InspectForm/interfaces';
// components
import { InspectionsModal, NotesModal, LoadingModal } from 'core/components';

import ActionAlertModal from 'core/components/ActionAlertModal';

import { getQueryString } from 'core/utils';

type IProps = IStateToProps & IDispatchToProps & RouteComponentProps;

interface IState {
  userId: string;
  franchiseId: string;
}

class InspectionMenuScreen extends React.Component<IProps, IState> {
  public state = {
    userId: '',
    franchiseId: '',
  };

  public componentDidMount() {
    const { location } = this.props;
    const { getInspectionsRequest } = this.props;
    const currentUrl = location.search;
    // const params = new URLSearchParams(currentUrl);
    // const userId = params.get('userId');
    const userId = getQueryString(currentUrl, 'userId');
    let franchiseId = getQueryString(currentUrl, 'FranchiseId');
    if (!franchiseId) {
      franchiseId = this.props['MasterCity'];
    }
    if (userId && franchiseId) {
      this.setState({ userId, franchiseId });
      getInspectionsRequest(userId);
    }
  }

  public componentWillReceiveProps(nextProps: IProps) {
    const { userId } = this.state;
    const { getInspectionsRequest, setWarningModalState } = this.props;
    const { successOnArchive, error } = nextProps;

    if (successOnArchive && successOnArchive !== this.props.successOnArchive) {
      // alert('Form archived successfully!');
      getInspectionsRequest(userId);
      setWarningModalState(true, 'Form archived successfully!');
    }
    if (error && error !== this.props.error) {
      // setWarningModalState(true, error.toString())
      setWarningModalState(
        true,
        'There was an error while performing a request to the server'
      );
      // alert(error);
    }
  }

  public navigateToForm = (
    id: string,
    isSent: boolean,
    isTemplateURL: boolean
  ) => {
    const { history } = this.props;
    const { userId, franchiseId } = this.state;
    if(isSent){
      this.props.setIsSent(isSent);
    }else{
      this.props.setIsNotSent(isSent);
    }

    this.props.setIsTemplate(isTemplateURL);
    
    history.push({
      pathname: '/inspection-report-form',
      search: `?userId=${userId}&id=${id}&FranchiseId=${franchiseId}&Owner=Anago&isSent=${isSent}&isTemplate=${isTemplateURL}&v=${Math.random()
        .toString(36)
        .substring(7)}`,
    });
    this.props.doneAction && this.props.doneAction();
    // history.push(`/inspection-form/?userId=${userId}`);
    // history.push(`/menu?id=${id}&FranchiseId=${userId}&Owner=Anago&isSent=${isSent}`);
  };

  public closeWarningModal = () => {
    const { setWarningModalState } = this.props;
    setWarningModalState(false, '');
    // this.props.doneAction();
  };

  public render() {
    const { showNotesModal, showWarningModal } = this.props;

    return (
      <>
        <InspectionsModal
          navigateToForm={(
            id: string,
            isSent: boolean,
            isTemplateURL: boolean
          ) => this.navigateToForm(id, isSent, isTemplateURL)}
          doneAction={this.props.doneAction}
        />
        {showNotesModal && <NotesModal showCancel isFromMenu />}
        {showWarningModal.status && (
          <ActionAlertModal
            // title={showWarningModal.message}
            message={showWarningModal.message}
            onConfirm={this.closeWarningModal}
            // onDismiss={this.props.closeExitModal}
            isVisible={showWarningModal.status}
          />
        )}
        {/* <LoadingModal /> */}
      </>
    );
  }
}

const mapStateToProps = (state: IReduxState) => ({
  ...state.inspectForm,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...InspectFormActions }, dispatch);

const WithRouter = withRouter(InspectionMenuScreen);

const Connected = connect<IStateToProps, IDispatchToProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(WithRouter);

export { Connected as InspectionMenuScreen };
