
import React, {memo, useCallback} from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

interface IDraggableSection {
    id: string;
    onChange: Function;
    values: any;
    children: Array<any>;
    transformFunctionBefore?: Function;
    transformFunctionAfter?: Function;
}

export const DraggableSection = memo(({
    id,
    onChange,
    values,
    children,
    transformFunctionBefore,
    transformFunctionAfter
  }: IDraggableSection) => {
    
    const reorder = (list:Array<any>, startIndex:number, endIndex:number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
      };
    
    const onDragEnd = useCallback((result:any) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const reorderFunction = (values:Array<any>) => reorder(
            values,
            result.source.index,
            result.destination.index
        )

        const valuesToOrder = transformFunctionBefore ? transformFunctionBefore(values) : values;

        var resultValues = transformFunctionAfter ? transformFunctionAfter(reorderFunction(valuesToOrder)) : reorderFunction(valuesToOrder);

        onChange(resultValues);
        // eslint-disable-next-line
    }, [values])

    return <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={id || Math.random().toString()}>
        {(droppableProvided:any) => (
          <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
            {children.map((child: any, index: number) => (
              <Draggable draggableId={child.key} index={index}>
                  {(draggableProvided:any) => (
                    <div key={`${index}`} ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} {...draggableProvided.dragHandleProps}>
                        {child}
                    </div>
                  )}
            </Draggable>))}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  });