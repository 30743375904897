import React, { memo } from 'react';
import { withFormik, FormikProps } from 'formik';
import { IBidDayPorter } from 'modules/BidInfoForm/interfaces';
import { dayPorterValues, daysOfWeek } from 'modules/shared/bidDefaultValues';
import {
  calculatePorterMonthly,
  mathRoundToFixed2,
} from 'modules/BidInfoForm/utils';
import { moneyMask, moneyMaskParams } from 'core/utils/masks';

import { formatPrice } from 'core/utils';

import {
  ContentInput,
  useFormProps,
  ButtonGroup,
  RadioButton,
  InputFloatLabel,
  InputFloatLabelWithValidation,
  DisabledBoxValue as BoxValue,
} from 'core/components';

import './styles.scss';

const booleanOptions = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' },
];

type IProps = FormikProps<IBidDayPorter>;
const DayPorter = memo(({ backgroundColor, isSpecialty, sectionId}: IProps) => {
  const { onChange, setFormValues, formValues } = useFormProps();

  const onBlur = (name, value = null) => {
    if(!value){
      value = formValues[name];
    }
    const monthlyPorterPrice = calculatePorterMonthly({
      ...formValues,
      [name]: value,
    });
    
    setFormValues({ monthlyPorterPrice, [name]: value });
  };

  const onSelectDays = (name: string, value: any) => {
    const monthlyPorterPrice = calculatePorterMonthly({
      ...formValues,
      [name]: value,
    });
    setFormValues({ monthlyPorterPrice, [name]: value });
  };

  return (
    <div className="day-porter-root" style={{ backgroundColor }}>
      <div className="day-porter-content">
        {isSpecialty && (
          <ContentInput width="100%" justify="flex-start">
            <h3 className="day-porter-title">Day Porter Services</h3>
          </ContentInput>
        )}

        <div className="day-porter-row">
          <ButtonGroup
            className="button-days validate-porterDays"
            tabIndexable={false}
            label="Day Porter Days"
            options={daysOfWeek}
            name="porterDays"
            onChange={onSelectDays}
            value={formValues.porterDays}
            disabled={formValues.status === 2}
          />

          <div className="porter-radio-button-content">
            <ButtonGroup
              disabled={formValues.status === 2}
              tabIndexable={false}
              options={[
                { value: true, label: 'Include in Price' },
                { value: false, label: 'Quote Separately' },
              ]}
              optionLabel="label"
              optionValue="value"
              // multiple={false}
              type="oneOption"
              name="includeInPrice"
              onChange={(name, item) => onChange(name, item)}
              value={!!formValues.includeInPrice}
              style={{
                transformOrigin: 'right',
                transform: 'scale(.8)',
              }}
              buttonsStyle={{
                width: '105px',
                padding: '10px',
              }}
            />

            {/* <div className="day-porter-price-label">Include in Price:</div>
            <RadioButton
              value={formValues.includeInPrice}
              onChange={(name: string, value: any) => {
                onChange(name, value);
              }}
              name="includeInPrice"
              options={booleanOptions}
              optionLabel="label"
              optionValue="value"
              disabled={formValues.status === 2}
            /> */}
          </div>
        </div>

        <div className="day-porter-row">
          <ContentInput padding flex>
            <InputFloatLabel
              value={formValues.porterHoursDay}
              name="porterHoursDay"
              className="validate-porterHoursDay"
              label="Porter Hours Per Day"
              type="tel"
              onChange={(name: string, value: any) =>
                onChange(name, value.replace(/[^0-9]/, ''))
              }
              onFocusSelect
              onBlur={()=>onBlur('porterHoursDay')}
              disabled={formValues.status === 2}
            />
          </ContentInput>
          <ContentInput padding flex>
            <InputFloatLabel
              value={formValues.porterTimeWindow}
              name="porterTimeWindow"
              label="Time Window"
              onChange={onChange}
              onFocusSelect
              onBlur={() =>
                onChange('porterTimeWindow', formValues.porterTimeWindow)
              }
              disabled={formValues.status === 2}
            />
          </ContentInput>
          <ContentInput flex>
          <InputFloatLabelWithValidation
              sectionId={sectionId}
              mask={moneyMask}
              maskParams={{
                ...moneyMaskParams, 
                thousandsSeparatorSymbol: true
              }}
              onBlur={onBlur}
              value={formValues.porterValueHour}
              className="validate-porterValueHour"
              name="porterValueHour"
              label="$ Per Hour"
              onChange={onChange}
              disabled={formValues.status === 2}
            />
          </ContentInput>
        </div>

        <div className="porter-space-content" />
        <div className="day-porter-row">
          <BoxValue
            width="50%"
            label="Calc’d Monthly Porter Price:"
            value={formatPrice(formValues.monthlyPorterPrice || 0)}
            disabled={formValues.status === 2}
          />
          <ContentInput padding="0px 0px 0px 15px" flex width="50%">
          <InputFloatLabelWithValidation
              mask={moneyMask}
              maskParams={{
                ...moneyMaskParams, 
                thousandsSeparatorSymbol: true
              }}
              onBlur={onBlur}
              value={formValues.porterOverride}
              name="porterOverride"
              label="Override"
              onChange={onChange}
              onFocusSelect
              disabled={formValues.status === 2}
            />
          </ContentInput>
        </div>
      </div>
    </div>
  );
});

const mapForm = {
  mapPropsToValues: () => ({
    ...dayPorterValues,
  }),
  handleSubmit: () => '',
};

const EnhancedDayPorter = withFormik<any, IBidDayPorter>(mapForm)(DayPorter);

export { EnhancedDayPorter as DayPorter };
