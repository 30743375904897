export const quotesOptionsWithDescription = {
    "Acid Wash & Grout Cleaning Ceramic Floor": [
        "Machine scrub ceramic tile in designated area with low-speed scrubber. Manually scrub grout with grout brush."
    ],
    "Bonnet Carpet Cleaning": [
        "Pretreat designated area with bonnet and high-quality traffic-lane carpet cleaner, then bonnet using low-speed buffer with bonnet pad to clean the treated area."
    ],
    "Burnish Vinyl Floor": [
        "Sweep and/or vacuum vinyl tile in designated area, then damp mop to remove all dirt and debris from designated area.",
        "Burnish floor with a high-speed burnisher to achieve a high-gloss finish."
    ],
    "Buff Vinyl Floor": [
        "Sweep and/or vacuum vinyl tile in designated area, then damp mop to remove all dirt and debris from designated area. ",
        "Apply spray buff liquid to vinyl floor then use a high-speed floor buffer to achieve a high-gloss finish.",
    ],
    "Comprehensive Detail Clean": [
        "To be performed prior to regularly scheduled service; all items listed in the Cleaning Specifications section of the contract will be performed during a single visit."
    ],
    "Window Cleaning (External Only)": [
        "Apply window cleaner and scrub all designated windows to remove dirt and debris, then squeegee them clean."
    ],
    "Window Cleaning (Internal Only)": [
        "Apply window cleaner and scrub all designated windows, then squeegee them clean."
    ],
    "Window Cleaning (Internal & External)": [
        "Apply window cleaner and scrub all designated windows to remove dirt and debris, then squeegee them clean."
    ],
    "Hot Water Carpet Extraction": [
        "Vacuum all carpet in designated areas, pretreat with high-quality traffic-lane carpet cleaner, apply commercial grade fiber rinse to extractor, and use extractor to clean and vacuum the carpeted areas."
    ],
    "Upholstery Cleaning": [
        "Pretreat designated furniture with high-quality upholstery cleaner then use extractor to clean and vacuum the furniture."
    ],
    "Machine Scrub Ceramic Tile Floor": [
        "Sweep and/or vacuum ceramic tile in designated area to remove dirt and debris. Apply professional-grade degreaser or neutral chemical to floor, then machine scrub ceramic tile with low speed scrubber. ",
        "Use a hand tool to scrub grout lines, as needed.",
    ],
    "Machine Scrub Ceramic Tile Floor & Apply Sealant": [
        "Sweep and/or vacuum ceramic tile in designated area to remove dirt and debris. Apply professional-grade degreaser or neutral chemical to floor, then machine scrub ceramic tile with low speed scrubber. ",
        "Use a hand tool to scrub grout lines, as needed. ",
        "Apply sealant to tile and grout to protect it from dirt build up. ",
        "NOTE: Sealant will eventually wear off and the floor will need to be scrubbed and sealed again at a future date.",
    ],
    "Scrub & Recoat Vinyl Floor": [
        "Spray vinyl floor in designated area with degreaser solution, or other low pH chemical, then use a non-aggressive pad with a low-speed floor scrubber to clean surface layer of wax, removing any surface damage. ",
        "Vacuum then damp mop area, and once dry, apply 2 coats of wax to treated area.",
        "NOTE: It is recommended to have this service performed multiple times per year depending on traffic and facility needs.",
    ],
    "Strip & Refinish Vinyl Floor": [
        "Spray vinyl floor in designated area with stripper chemical, then use an aggressive pad with a low-speed floor scrubber to remove all coats of wax. ",
        "Vacuum then damp mop area, and once dry, apply 3 coats of wax to treated area. ",
        "More coats of finish can be applied as requested or recommended for an additional price. ",
        "NOTE: It is recommended to have this service performed at least once per year depending on traffic and facility needs.",
    ],
    "Post-Construction Clean-up": [
        "Complete one final Post-Construction clean of the facility. Specifications to include, but are not limited to, cleaning of all vertical and horizontal surfaces, washing of all interior glass, mopping and/or vacuuming of all flooring, removal of all small debris and general removal of construction dust."
    ],
    "Emergency Services (Floods, etc.)": [
        "Provide emergency clean up services based on client needs. ",
        "NOTE: Cleanup of any bodily fluids is deemed a biohazard cleanup and additional charges may apply.",
    ],
    "Pressure Washing": [
        "Use pressure washer to spray off and clean designated areas."
    ],
    "Crystallization of Marble Floor": [
        "Spray marble floors in designated area with marble crystallization chemical, then use a low-speed floor buffer and crystallization pad to achieve a high-gloss finish."
    ],
    "Diamond Cut, Acid Wash, and Crystallization of Marble Floor": [
        "Perform a diamond cut and acid wash on marble floors in the designated area. Once dry, crystallize floor to bring the gloss up."
    ],
    "Electrostatic Disinfection": [
        "Electrostatic spray of all surfaces with EPA-grade disinfectant, including all surfaces and hard to reach areas. High touch point disinfection will be used in areas where electrostatic spraying may not be suitable. Client to remove all papers and fragile items from surfaces prior to service.",
        "NOTE: Some surfaces may have a slight haze after disinfectant chemical dries, which can be easily removed with a cloth or paper towel.",
    ],
    "Machine Scrub Concrete Floors": [
        "Sweep and/or vacuum concrete floor in designated area to remove all dirt and debris. Apply professional-grade degreaser or neutral chemical to floor and then machine scrub concrete floor with low-speed scrubber."
    ],
    "Machine Scrub and Seal Concrete Floors": [
        "Sweep and/or vacuum concrete floor in designated area to remove all dirt and debris. Apply professional-grade degreaser or neutral chemical to floor and then machine scrub concrete floor with low speed scrubber. ",
        "Apply sealant to concrete to protect it from dirt build up. ",
        "NOTE: It is recommended to have this service performed at least once per year depending on traffic and facility needs.",
    ],
    "Autoscrub Floors": [
        "Sweep or dust mop, then vacuum floors in designated area to remove all dirt and debris. Fill autoscrubber with fresh, clean water and neutral floor cleaner. Use autoscrubber to scrub floor clean and pick up dirty water."
    ],
    "Refrigerator Cleaning":[
        "Clean and disinfect refrigerators. Discard spoiled foods and containers per Client’s instruction."
    ],
    "Surgical Terminal Cleaning (for operating rooms, pre/post surgery)": [
        "Thoroughly clean & disinfect all surfaces including floors, walls, ceiling, surgical lights, and reusable equipment within the designated area."
    ],
    "Pre-Op & Recovery Terminal Cleaning":[
        "Thoroughly clean & disinfect all surfaces including floors, surgical lights, and reusable equipment within the designated area."
    ]
}