import React from 'react';
import PropTypes from 'prop-types';
import Calculator from './calculator';
import Styles from '../../scss/home.module.scss';
import Sound from '../model/sound';

class Home extends React.Component {
  componentDidMount() {
    this.onClick = this.onButtonClick.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.displayRef = React.createRef();
    this.doScroll = this.doScroll.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);

    document.addEventListener('keydown', this.onKeyDown, false);
    document.addEventListener('keyup', this.onKeyUp, false);
    document.addEventListener('touchstart', this.onTouchStart, false);

    this.sound = new Sound();
    this.sound.setup();

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        document
          .getElementsByClassName(`${Styles.home}`)[0]
          .classList.add('fadeIn');
      });
    });
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false);
    document.removeEventListener('keyup', this.onKeyUp, false);
    document.removeEventListener('touchstart', this.onTouchStart, false);
  }

  doScroll() {
    const element = this.displayRef.current;
    if (!element) {
      return;
    }

    setTimeout(() => {
      element.scrollTo({
        top: 0,
        left: element.offsetWidth + 100,
        behavior: 'smooth',
      });
    }, 200);
  }

  onTouchStart(evt) {
    evt.preventDefault();
  }

  onKeyDown(evt) {
    let button = this.calculator.refs[evt.key];

    if (button && !button.isActive()) {
      this.props.keyDownAction(evt.key);
    }

    this.doScroll();
  }

  onKeyUp(evt) {
    let button = this.calculator.refs[evt.key];

    if (button) {
      this.sound.mute(this.props.muted);
      this.sound.play();
    }

    this.props.keyDownAction('');
    this.props.keyUpAction(evt.key, this.props);
    this.doScroll(evt.currentTarget);
  }

  onButtonClick(key) {
    this.sound.mute(this.props.muted);
    this.sound.play();
    this.props.keyDownAction('');
    this.props.keyUpAction(key, this.props);
    this.doScroll();
  }

  onMouseDown(key) {
    this.props.keyDownAction(key);
    this.doScroll();
  }

  onMuteIconClick(value) {
    this.props.muteAction(value);
  }

  render() {
    return (
      <div className="react-calculator">
        <div className={Styles.home}>
          <div className={Styles.home__content}>
            <Calculator
              ref={calculator => (this.calculator = calculator)}
              displayRef={this.displayRef}
              {...this.props}
              onMouseDown={this.onMouseDown.bind(this)}
              buttonClick={this.onButtonClick.bind(this)}
              muteIconClick={this.onMuteIconClick.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  muteAction: PropTypes.func,
  muted: PropTypes.bool,
  keyDownAction: PropTypes.func,
  keyDown: PropTypes.string,
  keyUpAction: PropTypes.func,
};

export default Home;
