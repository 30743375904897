import styled from 'styled-components';
import { ICustomizable, IParagraph, IHeader } from '../interfaces';
import headerBackground from '../../assets/header-background-xl.png';
import introductionPic from '../../assets/introduction.jpg';

import backBackground from '../../assets/back-background.png';
import nextButtonBackground from '../../assets/button-background-yellow.png';
import { Section } from '../layout';

import { Button } from 'core/components';

const commonCuystomizable = (props: ICustomizable) => `
    ${props.justifyContent ? `justify-content: ${props.justifyContent}` : ''};
    ${props.paddingRight ? `padding-right: ${props.paddingRight};` : ''}
    ${props.paddingLeft ? `padding-left: ${props.paddingLeft};` : ''}
    ${props.paddingTop ? `padding-top: ${props.paddingTop};` : ''}
    ${props.paddingBottom ? `padding-bottom: ${props.paddingBottom};` : ''}
    ${props.padding ? `padding: ${props.padding};` : ''}
    ${props.marginRight ? `margin-right: ${props.marginRight};` : ''}
    ${props.marginLeft ? `margin-left: ${props.marginLeft};` : ''}
    ${props.marginTop ? `margin-top: ${props.marginTop};` : ''}
    ${props.marginBottom ? `margin-bottom: ${props.marginBottom};` : ''}
    ${props.margin ? `margin: ${props.margin};` : ''}
    ${props.alignItems ? `align-items: ${props.alignItems};` : ''}
    ${props.width ? `width: ${props.width};` : ''}
    ${props.maxWidth ? `max-width: ${props.maxWidth};` : ''}
    ${props.minWidth ? `min-width: ${props.minWidth};` : ''}
    ${props.color ? `color: ${props.color};` : ''}
    ${
      props.backgroundColor ? `background-color: ${props.backgroundColor};` : ''
    }
    ${props.flex ? `display: flex;` : ''}
    ${props.direction ? `flex-direction: ${props.direction};` : ''}
    ${props.lineHeight ? `line-height: ${props.lineHeight};` : ''}
`;

export const Content = styled.div<ICustomizable>`
  ${(props) => commonCuystomizable(props)}
`;

export const StepsHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StepsButtonPlaceholder = styled.div`
  width: 185px;
`;

export const StepperBody = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  &:not(:last-of-type) {
    margin-bottom: 30px;
  }
`;

export const BackButton = styled.button`
  position: relative;
  background: transparent;
  color: white;
  width: 185px;
  height: 54px;
  font-family: 'OpenSans-SemiBoldItalic';
  font-size: 14px;
  border-width: 0px;

  &::before {
    background: url(${backBackground}) no-repeat;
    background-position: center;
    background-size: contain;
    width: 180px;
    content: '';
    height: 54px;
    position: absolute;
    margin-left: 0;
    top: 0;
    left: 0;
  }

  &::after {
    background-position: center;
    background-size: contain;
    width: 180px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ title }: any) => (title ? `content: ${title};` : 'content: "BACK";')}
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 13px;
  }
  ${(props) => commonCuystomizable(props)}

  @media (min-width: 769px) {
    font-size: 20px;
  }
`;

export const NextButton = styled.button`
  position: relative;
  background: transparent;
  color: white;
  width: 185px;
  height: 54px;
  font-family: 'OpenSans-SemiBoldItalic';
  font-size: 14px;
  border-width: 0px;

  &::before {
    background: url(${nextButtonBackground}) no-repeat;
    transform: scaleX(-1);
    background-position: center;
    background-size: contain;
    width: 180px;
    content: '';
    height: 46px;
    position: absolute;
    /* margin-left: -85px; */
    top: 4px;
    right: 0;
  }

  &::after {
    background-position: center;
    background-size: contain;
    width: 180px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ title }: any) =>
      title ? `content: "${title}";` : 'content: "NEXT STEP";'}
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 4px;
    right: 0;
  }
  ${(props) => commonCuystomizable(props)}

  @media (min-width: 769px) {
    font-size: 20px;
  }
`;

export const DoneButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  top: -70px;
`;

export const DoneButton = styled(Button)`
  background-color: #216eab;
`;

export const Hexagon = styled.img`
  width: auto;
  height: 125px;
  max-width: 145px;
  max-height: 155px;
`;

export const Stamps = styled.img`
  width: 20%;
  height: 100%;
  max-width: 175px;
  max-height: 125px;
`;

export const Logo = styled.img`
  width: 120px;
  @media (min-width: 400px) {
    width: 140px;
  }
  @media (min-width: 769px) {
    width: 160px;
  }
`;

export const HeaderPic = styled.img`
  max-width: 300px;
  height: auto;
  width: 65vw;
  z-index: 10;
`;

export const Background = styled.div`
  position: absolute;
  background-color: #F7A11D;
  width:100%
  height:50%
  bottom: 0;
  z-index: -1;
`;

export const BlueTitle = styled.div`
  background: linear-gradient(0deg, #47A2DA 5px, #216eab 5px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
  width:100%
  height:40px;
  color: #fff;
  font-size: 20px;
`;

export const HeaderLogo = styled.img`
  max-width: 180px;
  height: auto;
  width: 30vw;
`;
export const BackgroundLogo = styled.div`
  padding-top: 30px;
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%
  height: 100px;
  background-color: #F7A11D;
  position: relative;
  margin-bottom: 30px;

  @media (min-width: 768px){
    margin-top: -62px;
  }
  
  ${HeaderLogo} {
    max-width: 130px;
    margin-top: -10px;
  }
  ::before{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: -50px;
    margin-left: -0.5em;
    box-sizing: border-box;
    border: 5.5em solid white;
    border-color: transparent transparent #F7A11D #F7A11D;
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    box-shadow: -6px 6px 6px 0 rgba(255,255,255,0.2);
    z-index: 1;

    @media (min-width: 768px){
      top: -70px;
      margin-top: 15px;
      margin-right: 15px;
    }
  }

  ::after{
    content: "";
    width: 100%;
    height: 7.5px;
    position: absolute;
    background-color: #216eab;
    bottom: 0;
  }
`;
export const IntroductionPicture = styled.div`
  margin-top: 20px;
  background: url(${introductionPic}) bottom left no-repeat;
  height: 133px;
  width: calc(100% + 50px);
  max-width: 860px;
  height: 133px;
  background-size: cover;
  background-position-y: 58%;

  @media (min-width: 768px) {
    height: 180px;
    max-width: 900px;
    background-position-y: 65%;
  }
`;

export const AboutAnagoPicture = styled.img`
  width: 100%;
  max-width: 325px;
  height: auto;
  padding-top: 25px;
  margin: auto;

  @media (min-width: 768px) {
    position: absolute;
    right: 20px;
    bottom: 0px;
    max-width: 420px;
  }
`;

export const PeoplePicture = styled.img`
  width: 100%;
  height: auto;
  max-width: 200px;
`;

export const Paragraph = styled.p`
  width: 100%;
  text-align: left;
  ${(props) => commonCuystomizable(props)}
  ${({ fontSize }: IParagraph) =>
    fontSize ? `font-size: ${fontSize};` : 'font-size: 14px'}
    ${({ textAlign }: IParagraph) =>
    textAlign ? `text-align: ${textAlign};` : ''}
    ${({ clear }: IParagraph) => (clear ? `color: #fff;` : 'color: #343434;')}
    ${({ color }: IParagraph) => (color ? `color: ${color};` : '')}
    ${({ lineHeight }: IParagraph) =>
    lineHeight ? `line-height: ${lineHeight};` : ''}
    ${({ position }: IParagraph) => (position ? `position: ${position};` : '')}
    ${({ right }: IParagraph) => (right ? `right: ${right};` : '')}
    ${({ bottom }: IParagraph) => (bottom ? `bottom: ${bottom};` : '')}
    ${({ fontWeight }: IParagraph) =>
    fontWeight ? `font-weight: ${fontWeight};` : ''}
`;

export const AboutParagraph = styled(Paragraph)`
  font-size: 12px;
  line-height: 22px;
  @media (min-width: 769px) {
    font-size: 14px;
    line-height: 25px;
  }
`;

export const HexagonParagraph = styled(AboutParagraph)`
  line-height: 1.4;
  margin-top: 5px;
  font-size: 12px;
  min-height: 100px;
`;

export const SectionParagraph = styled(Paragraph)`
  margin-left: 15px;
  margin-right: 15px;
  width: auto;

  p {
    margin: 0;
  }
`;

export const SignatureLine = styled.hr`
  width: 92%;
  color: black;
  ${({ paddingRight }: ICustomizable) =>
    paddingRight ? `padding-right: ${paddingRight};` : ''}
  ${({ paddingLeft }: ICustomizable) =>
    paddingLeft ? `padding-left: ${paddingLeft};` : ''}
    ${({ paddingTop }: ICustomizable) =>
    paddingTop ? `padding-top: ${paddingTop};` : ''}
    ${({ paddingBottom }: ICustomizable) =>
    paddingBottom ? `padding-bottom: ${paddingBottom};` : ''}
    ${({ padding }: ICustomizable) => (padding ? `padding: ${padding};` : '')}
    ${({ marginRight }: ICustomizable) =>
    marginRight ? `margin-right: ${marginRight};` : ''}
    ${({ marginLeft }: ICustomizable) =>
    marginLeft ? `margin-left: ${marginLeft};` : ''}
    ${({ marginTop }: ICustomizable) =>
    marginTop ? `margin-top: ${marginTop};` : ''}
    ${({ marginBottom }: ICustomizable) =>
    marginBottom ? `margin-bottom: ${marginBottom};` : ''}
    ${({ margin }: ICustomizable) => (margin ? `margin: ${margin};` : '')}
    ${({ color }: ICustomizable) => (color ? `color: ${color};` : '')}

    @media (min-width: 768px) {
    width: 100%;
  }

  visibility: hidden;
`;

export const HiddenAnchor = styled.a`
  visibility: hidden;
  height: 0;
  display: none;
`;

export const FakeAnchor = styled.span`
  text-decoration: underline;
  cursor: pointer;
  ${(props) => commonCuystomizable(props)}
  ${({ color }: ICustomizable) =>
    color ? `color: ${color};` : 'color: #216eab;'}
`;

export const Anchor = styled.a`
  text-decoration: underline;
  cursor: pointer;
  ${(props) => commonCuystomizable(props)}
  ${({ color }: ICustomizable) =>
    color ? `color: ${color};` : 'color: #216eab;'}
`;

export const DivInput = styled.div`
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  ${(props) => commonCuystomizable(props)}
  ${({ backgroundColor }: ICustomizable) =>
    backgroundColor
      ? `background-color: ${backgroundColor};`
      : 'background-color: #fff;'}
  ${({ color }: ICustomizable) =>
    color ? `color: ${color};` : 'color: #216eab;'}
  border: none;
  border: 1px solid #fff;
  height: 40px;
  text-align: center;
  font-family: 'OpenSans-SemiBold';
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ hasDesinfection }: any) => (hasDesinfection ? `height:58px;` : '')}

  ${({ paddingDesinfection }: any) =>
    paddingDesinfection ? `padding-bottom:20px;` : ''}
`;

export const InputBlock = styled.div`
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  ${(props) => commonCuystomizable(props)}
  ${({ backgroundColor }: ICustomizable) =>
    backgroundColor
      ? `background-color: ${backgroundColor};`
      : 'background-color: #fff;'}
  ${({ color }: ICustomizable) =>
    color ? `color: ${color};` : 'color: #216eab;'}
  border: none;
  border: 1px solid #fff;
  height: 40px;
  text-align: center;
  pointer-events: none;
  ${({ hasDesinfection }: any) => (hasDesinfection ? `height:60px;` : '')}

  ${({ paddingDesinfection }: any) =>
    paddingDesinfection ? `padding-bottom:20px;` : ''}

  font-family: 'OpenSans-SemiBold';
  font-size: 14px;
  line-height: auto;

  span {
    font-family: 'OpenSans-SemiBold';
    font-size: 14px;
    line-height: auto;
  }
`;

export const Input = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  ${(props) => commonCuystomizable(props)}
  ${({ backgroundColor }: ICustomizable) =>
    backgroundColor
      ? `background-color: ${backgroundColor};`
      : 'background-color: #fff;'}
  ${({ color }: ICustomizable) =>
    color ? `color: ${color};` : 'color: #216eab;'}
  border: none;
  border: 1px solid #fff;
  height: 40px;
  text-align: center;
  pointer-events: none;
  ${({ hasDesinfection }: any) => (hasDesinfection ? `height:60px;` : '')}

  ${({ paddingDesinfection }: any) =>
    paddingDesinfection ? `padding-bottom:20px;` : ''}
`;

export const CustomInput = styled.div`
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  ${(props) => commonCuystomizable(props)}
  ${({ backgroundColor }: ICustomizable) =>
    backgroundColor
      ? `background-color: ${backgroundColor};`
      : 'background-color: #fff;'}
  ${({ color }: ICustomizable) =>
    color ? `color: ${color};` : 'color: #216eab;'}
  border: none;
  border: 1px solid #fff;
  height: 40px;
  text-align: center;
`;

const HBase = styled.span`
  text-transform: none;
  font-size: 7vw;
  margin-bottom: 0;
  display: block;
  ${(props) => commonCuystomizable(props)}

  ${({ fontSize }: IHeader) => (fontSize ? `font-size: ${fontSize};` : '')}
    ${({ textAlign }: IHeader) =>
    textAlign ? `text-align: ${textAlign};` : 'text-align: center;'}
    ${({ color }: IHeader) => (color ? `color: ${color};` : 'color: #000')}
    ${({ width }: IHeader) => (width ? `width: ${width};` : '')}

    @media (min-width: 768px) {
    ${({ color }: IHeader) => (color ? `color: ${color};` : 'color: #000')}
  }
`;

export const HeaderTitle = styled(HBase)`
  font-family: 'OpenSans-BoldItalic';
  @media (min-width: 400px) {
    font-size: 6.2vw;
  }

  @media (min-width: 768px) {
    ${({ maxFontSize }: IHeader) =>
      maxFontSize ? `font-size: ${maxFontSize};` : ''}
    line-height: 50px
  }

  @media (min-width: 1024px) {
    font-size: 60px;
  }
`;

export const HeaderCompanyTitle = styled(HBase)`
  line-height: 1;
  font-size: 5vw;
  font-family: 'OpenSans-Bold';
  padding-top: 5%;

  @media (min-width: 400px) {
    font-size: 4vw;
  }

  @media (min-width: 768px) {
    ${({ maxFontSize }: IHeader) =>
      maxFontSize ? `font-size: ${maxFontSize};` : ''}
  }
`;
export const HeaderCompanyAddress = styled(HBase)`
  font-family: 'OpenSans-SemiBold';
  font-size: 3vw;
  padding-top: 0;

  @media (min-width: 400px) {
    font-size: 2.5vw;
    padding-top: 2%;
  }

  @media (min-width: 768px) {
    font-family: 'OpenSans-Regular';
    letter-spacing: 1px;
    padding-top: 20px;
    ${({ maxFontSize }: IHeader) =>
      maxFontSize ? `font-size: ${maxFontSize};` : ''}
  }
`;

export const HeaderPrepared = styled(HBase)`
  padding-right: 5px;
  font-family: 'OpenSans-LightItalic';
  font-size: 3.88vw;

  @media (min-width: 400px) {
    font-size: 2.88vw;
  }
  @media (min-width: 768px) {
    ${({ maxFontSize }: IHeader) =>
      maxFontSize ? `font-size: ${maxFontSize};` : ''}
  }
`;

export const HeaderPreparedPerson = styled(HBase)`
  font-family: 'OpenSans-LightItalic';

  font-size: 3.88vw;

  @media (min-width: 400px) {
    font-size: 2.88vw;
  }
  @media (min-width: 768px) {
    font-size: 2.1vw;
  }
  @media (min-width: 769px) {
    font-weight: normal;
    font-size: 16px;
  }

  @media (min-width: 900px) {
    ${({ maxFontSize }: IHeader) =>
      maxFontSize ? `font-size: ${maxFontSize};` : ''}
  }
`;

export const StepsSeparator = styled.hr`
  width: 40px;
  border: 0;
  background-color: #b1b1b1;
  height: 1px;

  @media (max-width: 420px) {
    width: 20px;
  }
`;

export const StepsNumber = styled.div`
  font-family: 'OpenSans-LightItalic';
  width: 30px;
  height: 30px;
  padding: 8px;
  padding-top: 6px;
  background: transparent;
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ selected }: any) =>
    selected
      ? 'border-radius: 50%; border: 2px solid #F7A11D; color: #F7A11D; font-size: 28px; margin: 0 10px;'
      : ''}
`;
// Removed the property max-width from below because it was breaking at higher resolutions
// max-width: 1366px;
export const HeaderSection = styled(Section)`
  position: relative;
  padding-top: 50px;
  padding-bottom: 20px;

  @media (min-width: 621px) {
    padding-top: 30px;
    padding-bottom: 25px;
  }

  ::before {
    content: '';
    margin-top: -15px;
    position: absolute;
    background: url(${headerBackground}) bottom left no-repeat;
    opacity: 0.2;
    z-index: -1;
    background-size: cover;
    background-position-x: 0;
    color: #000;
    width: 100%;
    height: 100%;
    max-width: unset;
    @media (max-width: 375px) {
      background-position-x: -180px;
    }
    @media (min-width: 768px) {
      background: url(${headerBackground}) bottom center no-repeat;
      background-size: auto;
      margin-top: 0px;
    }
    @media (min-width: 900px) {
      background-position-x: 0;
      background-size: cover;
    }
  }
`;
