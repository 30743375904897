import React from 'react';
import { Button } from 'core/components';
import { Header, Heading, Actions } from './styles';
import { ModalWrapper, Layout } from '../../components/layout';
import { createSignProvider, verifyUser } from '../../services/bidInfoService';

export default ({ userId, status, setStatus, setLoading }) => {
  const [message, setMessage] = React.useState(
    status != null && status == 0 ? (
      <>
        Error! A verification email was previously sent to you. Please check
        your email and click the link to activate your account. Then click below
        to verify your account is set up correctly.
      </>
    ) : (
      <>
        In order to user the new Bid Information Sheet, you will need to create
        a SignNow account. Click below to generate a verification email
        from SignNow to activate your account.
      </>
    )
  );
  const [hasError, setHasError] = React.useState(false);

  const createAccount = () => {
    setLoading(true);
    createSignProvider(userId).then(result => {
      if (result.data['Error']) {
        setMessage(
          <>
            Error! A verification email was previously sent to you. Please check
            your email and click the link to activate your account. Then click
            below to verify your account is set up correctly.
          </>
        );
        setHasError(true);
      } else {
        setStatus(0);
        setMessage(
          <>
            Great! A verification email has been sent. Please check your email
            and activate your SignNow account. 
            <br />
            <br />
            Once your account is active, return to this screen and click below
            to verify your account is set up correctly.
          </>
        );
      }
      setLoading(false);
    });
  };

  const verifyAccount = () => {
    setLoading(true);
    verifyUser(userId).then(result => {
      if (result.data['Error']) {
        setMessage(
          <>
            Error! A verification email was previously sent to you. Please check
            your email and click the link to activate your account. Then click
            below to verify your account is set up correctly.
          </>
        );
      } else {
        setStatus(1);
        setMessage(
          <>Success! You can now use the new Bid Information Sheet! </>
        );
      }
      setLoading(false);
    });
  };

  const closeModal = () => {
    setStatus(2);
  };

  // React.useEffect(() => {
  //   if (status != null && status == 0) {
  //     setMessage(
  //       <>
  //         Error! A verification email was previously sent to you. Please check
  //         your email and click the link to activate your account. Then click
  //         below to verify your account is set up correctly.
  //       </>
  //     );
  //   }
  // }, [status]);

  return (
    <ModalWrapper>
      <Layout
        padding="50px 20px"
        margin="40px"
        maxWidth="900px"
        width="90%"
        backgroundColor="#014984"
        position="relative"
        overflow="auto"
        borderRadius="10px"
      >
        <Header>
          <Heading>{message}</Heading>
        </Header>

        <Actions>
          {!hasError && (status == null || status == -1) && (
            <Button
              color="#014984"
              bgColor="#ffff"
              reverseSkew={true}
              onClick={() => createAccount()}
            >
              Generate
            </Button>
          )}

          {((status != null && status == 0) || hasError) && (
            <Button
              color="#014984"
              bgColor="#ffff"
              reverseSkew={true}
              onClick={() => verifyAccount()}
            >
              Verify
            </Button>
          )}

          {status != null && status == 1 && (
            <Button
              color="#014984"
              bgColor="#ffff"
              reverseSkew={true}
              onClick={() => closeModal()}
            >
              OK
            </Button>
          )}
        </Actions>
      </Layout>
    </ModalWrapper>
  );
};
