import React from 'react';
import { connect } from 'react-redux';
import { IStateToProps } from 'modules/InspectForm/interfaces';
import { IReduxState } from 'core/interfaces';
import './styles.scss';

interface ownProps {
  localLoading?: boolean;
}

type IProps = IStateToProps & ownProps;

class LoadingModal extends React.PureComponent<IProps> {
  public render() {
    const {
      loading,
      localLoading,
      bidFormLoadingOnclose,
      lowOpacity,
      message = 'Loading ...',
    } = this.props;
    const lowOpacityClass = lowOpacity ? 'low-opacity' : '';
    const className =
      loading || localLoading || bidFormLoadingOnclose
        ? 'loading-modal-root-div'
        : 'loading-modal-hide';
    return (
      <div className={`${className} ${lowOpacityClass}`}>
        <div className={`loading-modal-content-div`}>
          <div className="loader" />
          <p>{message}</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IReduxState) => ({
  ...state.inspectForm,
  ...state.bidForm,
});

const Connected = connect<IStateToProps, {}, {}, IReduxState>(
  mapStateToProps,
  {}
)(LoadingModal);

export { Connected as LoadingModal };
