import React from 'react';

import './styles.scss';
import './floatingLabel.scss';
import { formatNumberByMask, capitalizeInput } from 'core/utils';
import EventEmitter from 'core/utils/events';
import {formatNumberByMaskWithLimit} from 'core/utils/inputFormatsV2';;

interface IInputLabel {
  ref?: any;
  id?: string;
  value: any;
  name: string;
  label: string;
  onChange: Function;
  onBlur?: any;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  mask?: any;
  maskParams?: any;
  type?: string;
  maxLength?: number;
  center?: boolean;
  onFocusSelect?: boolean;
  capitalizeFunction?: Function;
  placeholder?: string;
  style?: any;
  autoCapitalize?: string;
  dataCy?: string;
  hasCounter?: boolean;
  fieldName: string;
}

export const InputFloatLabelWithValidation = (props: IInputLabel) => {
  const {
    ref,
    id,
    disabled,
    name,
    dataCy,
    label,
    onChange,
    onBlur,
    className,
    inputClassName,
    mask,
    maskParams,
    type,
    maxLength,
    center,
    onFocusSelect = maskParams
      ? maskParams.type == 'number' || maskParams.type == 'tel'
      : false,
    capitalizeFunction = capitalizeInput,
    placeholder,
    style,
    autoCapitalize,
    hasCounter,
    fieldName,
    ...otherProps
  } = props;
  const [hasError, setHasError] = React.useState(false);
  const [hasFocus, setHasFocus] = React.useState(false);

  const capitalize = (value: string) =>
    capitalizeFunction ? capitalizeFunction(value) : value;

  const value =
    typeof props.value === 'string' ? capitalize(props.value) : props.value;


  const paddZeroToRight = (value: string | number) =>{
    if((!value && value != 0) || (typeof value !== 'string' && typeof value !== 'number')){
      return value;
    }

    if(typeof value === 'number'){
      value = value.toString();
    }
    const thousandSeparatorPosition = value.indexOf(',') >= 0 ? value.indexOf(',') : value.length;
    const decimalSeparatorPosition = value.indexOf('.') >= 0 ? value.indexOf('.') : value.length;
    const diff = decimalSeparatorPosition - thousandSeparatorPosition;

    if(diff > 0 && diff <= 3){
      const pad = new Array(4 - (decimalSeparatorPosition - thousandSeparatorPosition)).fill("0").join("");
      return value.substring(0, decimalSeparatorPosition) + pad + value.substring(decimalSeparatorPosition, value.length);
    }
    return value;
  }

  const applyMask = (value: string | number) => {
    if(!maskParams || (!value && value != 0) || (typeof value !== 'string' && typeof value !== 'number')){
      return value;
    }
    if(typeof value === 'number'){
      value = value.toString();
    }
    const withMask = `${formatNumberByMask(paddZeroToRight(value), maskParams)}`;
    return withMask;
  };

  const onBlurWrapper = (fieldValue: string) => {
    setHasFocus(false);
    const trimmedValue = fieldValue.toString().trim()
    try {
      if (trimmedValue == '') {
        setHasError(false);
        if (onBlur){
          onBlur(name, trimmedValue);
        }else{
          onChange(name, trimmedValue);
        } 
        return;
      }
      const value = applyMask(trimmedValue);
      const numberValue =  Number(
        value.toString().replace(',', '')
      );
      if (
        value && trimmedValue !== ',' &&
        !isNaN(numberValue) && numberValue >= 0 
        // && (maskParams.integerLimit && trimmedValue.split(".")[0].length <= maskParams.integerLimit || !maskParams.integerLimit)
      ) {
        if (onBlur){
          onBlur(name, `${value}`);
        }else{
          onChange(name, `${value}`);
        } 
      } else {
        setHasError(true);
        return;
      }
    } catch (e) {
      setHasError(true);
      return;
    }
    setHasError(false);
  };

  const onKeyDown = (e: any) => {
    let cursorPos = Number(e.target.selectionStart);
    let keyCode = Number(e.keyCode);

    if (cursorPos === 0 && keyCode === 194) {
      e.preventDefault();
      e.target.value = '0.';
      e.target.selectionStart = 2;
      if (onChange) {
        onChange(name, e.target.value);
      }
    }
  };

  const getValue = () => {
    const v = (!!value || value === 0 || value === "0")
              ? `${
                  maskParams && maskParams.prefix && !hasFocus && !hasError
                    ? maskParams.prefix
                    : ''
                }${!hasFocus && !hasError ? applyMask(value) : value}`
              : ''
      return v;
  }

  const validateFieldsListener = ()=> {
    const v = document.getElementById(id || name).value;
    if(v){
      setHasFocus(false);
      const trimmedValue = v.toString().trim()
      try {
        if (trimmedValue == '') {
          setHasError(false);
          return;
        }
        const value = applyMask(trimmedValue);
        const numberValue =  Number(
          value.toString().replace(',', '')
        );
        if (
          value && trimmedValue !== ',' &&
          !isNaN(numberValue) && numberValue >= 0 
          // && (maskParams.integerLimit && trimmedValue.split(".")[0].length <= maskParams.integerLimit || !maskParams.integerLimit)
        ) {
        } else {
          setHasError(true);
          return;
        }
      } catch (e) {
        setHasError(true);
        return;
      }
      setHasError(false);
    }
  }

  React.useEffect(() => {
    EventEmitter.on('VALIDATE_FIELDS', validateFieldsListener)
    return () => {
      EventEmitter.removeListener('VALIDATE_FIELDS', validateFieldsListener);
    };
  }, []);

  return (
    <div className={`input-label-content ${className || ''}`} style={style}>
      <div
        className={`div-float-label ${hasError ? 'has-error is-invalid' : ''}`}
        data-cy={`${name || dataCy}_wrapper`}
        data-label={fieldName || label}
        data-section-id={props.sectionId}
      >
        <input
          data-cy={name || dataCy}
          ref={ref}
          disabled={disabled}
          className={`${center ? 'float-input-center' : ''}${
            inputClassName || ''
          }`}
          id={id || name}
          value={
            getValue()
          }
          onKeyDown={onKeyDown}
          onChange={(e) => onChange(name, capitalize(e.target.value))}
          placeholder={placeholder || ' '}
          type={type}
          onBlur={(e)=> onBlurWrapper(e.target.value)}
          maxLength={maxLength}
          onFocus={(e: any) => {
            const target = e.target;
            setHasFocus(true);
            if (onFocusSelect && target) {
              setTimeout(() => {
                target.select();
              });
            }
          }}
          autoCapitalize={autoCapitalize || 'words'}
        />

        <label htmlFor={id || name}>{label}</label>
        {hasCounter && maxLength && (
          <span
            style={{
              left: 'auto',
              right: 0,
              bottom: '-20px',
              top: 'auto',
            }}
          >
            {`${value.length}/${maxLength}`}
          </span>
        )}
      </div>
    </div>
  );
};