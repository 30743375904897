import React, { useState, useEffect } from 'react';
import PdfModal from './pdfmodal';
import { LoadingPage } from '../../components/organisms';
import { getQueryString, isWebView } from 'core/utils';
import { getBidURL } from 'modules/BidInfoForm/utils';

export const PdfView = ({ location, history }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pdfId, setPdfId] = useState(null);
  const [pdfUniqueId, setPdfUniqueId] = useState(null);

  React.useEffect(() => {
    const openingOldPDF =
      getQueryString(location.search, 'openingOldBidPDF') === 'true';

    const openingNewPDF =
      getQueryString(location.search, 'openingNewBidPDF') === 'true';

    if (openingOldPDF) {
      setPdfUniqueId(null);
      const formId = getQueryString(location.search, 'formId');
      setPdfId(formId);
    } else if (openingNewPDF) {
      setPdfId(null);
      const formUniqueId = getQueryString(location.search, 'formUniqueId');
      setPdfUniqueId(formUniqueId);
    } else {
      setPdfId(null);
      setPdfUniqueId(null);
    }
  }, [history]);

  return (
    <>
      {isLoading && <LoadingPage />}
      {(pdfId || pdfUniqueId) && (
        <PdfModal
          pdfId={pdfId}
          pdfUniqueId={pdfUniqueId}
          setIsLoading={setIsLoading}
          onHitsClose={() => {
            if (isWebView()) {
              history.push('/close-form');
            } else {
              document.location.href = getBidURL();
            }
          }}
        />
      )}
    </>
  );
};
