const getProp = path => object =>
  path.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), object);

export default (path, errors) => {
  if (getProp([path])(errors))
    return {
      msg: getProp([path])(errors),
      showError: true,
      className: 'is-invalid'
    };
  return { msg: null, showError: false, className: '' };
};
