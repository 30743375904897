export default function simp(provided: string[]) {
  const base = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  if (provided[0] === 'Sun') {
    provided.shift();
    provided.push('Sun');
  }

  let obj = {};
  let mod = 0;
  for (let index = 0; index < base.length; index++) {
    obj[mod] = obj[mod] || [];
    const idx = index + mod;
    const item = provided[index];
    const baseNext = base[idx + 1];
    const itemNext = provided[index + 1];
    const nextIsInSequence = !!baseNext && !!itemNext && baseNext === itemNext;

    if (idx === 0) {
      obj[mod].push(item);
      if (itemNext) {
        obj[mod].push(', ');
      }

      continue;
    }

    if (nextIsInSequence) {
      if (!baseNext && item) {
        obj[mod].push(item);
      } else {
        if (obj[mod][obj[mod].length - 1] === ', ') {
          obj[mod].pop();
        }

        obj[mod].push(' - ');
      }

      continue;
    }

    if (item) {
      obj[mod].push(item);
    }

    if (itemNext) {
      mod++;
      obj[mod] = obj[mod] || [];
      obj[mod].push(itemNext);
    }
  }

  // Flatting the result
  let result = Object.keys(obj).reduce((acc, curr, idx) => {
    const unique = [...new Set(obj[idx])];
    acc = acc.concat(unique, []);
    acc.push(', ');
    return acc;
  }, []);

  result.pop();

  return result.join('');
}
