import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;

  .additional-notes-content-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    width: 50vh;
    min-height: unset;
    overflow: hidden;

    .text-area-container {
      width: 50vh;
      height: 76vh;
      margin: 0;
      padding: 0;
      max-width: unset;
      border: 0;
    }
  }

  .additional-notes-done-button {
    position: relative;
    top: -24px;
  }

  .additional-notes-title-container {
    display: flex;
    width: 100%;
    height: 5vh;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 31, 56);

    h3 {
      color: white;
    }
  }

  .calculator-holder {
    position: relative;
    height: 100%;
    padding: 15px;

    .button {
      background-image: none;

      width: inherit;
      height: 100%;
    }

    .row > .button {
      color: #313131;
    }
  }
`;
