import styled from 'styled-components';

export const CalculatorImg = styled.img`
  display: block;
  margin: 0 auto;
  width: 32px;
  height: 32px;
`;

export const UnstyledButton = styled.button`
  border: 0 none;
  background-color: transparent;
`;

export const IconButtonsHolder = styled.div`
  display: flex;
  margin-right: 0px;

  ${UnstyledButton} {
    margin-right: 15px;
  }
`;
